.container .ui.menu {
	margin: 0;
	padding: 0;
	position: absolute;
	right: 50%;
	top: 0;
	z-index: 1;
	height: auto;
	padding: 1rem 0;
}

.container .ui.menu .item {
	margin-right: 10px;
}
.container .ui.grid > .row {
	padding: 1rem;
	margin: 0;
}
.container .ui.grid > .row > .column {
	padding: 0;
}
.container .ui.grid .segment {
	box-shadow: none;
	border-radius: 0;
	border: none;
}

.container .ui.grid .segment .image-editor,
.container .ui.grid .segment .pdf-editor {
}
.container .ui.grid .ten {
	width: 100% !important;
	padding: 0;
	border: none;
}
.container .editor > canvas {
	max-width: 72%;
	height: auto;
}

.contenteditable-parent {
	display: flex;
}
.contenteditable-div {
	border: 1px dotted #ccc;
	text-align: left;
	white-space: nowrap;
	min-width: 30px;
	background-color: transparent;
	margin: 0;
	box-sizing: border-box;
}

.contenteditable-div:focus-visible {
	/*padding:10px;*/
}

/* Editor menus */

.editor-menu {
	width: calc(20% + 3px);
	right: 2%;
	position: fixed;
	top: 0px;
	/* height: 70vh; */
	z-index: 100;
	overflow-x: scroll;
}

.editor-menu.editor-menu__buttons {
	top: 95px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.editor-menu.editor-menu__actions {
	top: 160px;
	height: 70vh;
}

.editor-menu .undo-redo-buttons {
	display: flex;
	flex-grow: 2;
}

.editor-menu .undo-redo-buttons .btn {
	margin-right: 5px;
}

.editor-menu .empty-button {
	flex-grow: 5;
}

.editor-menu .empty-button .btn {
	line-height: unset;
}

.info-bubble.is-open {
	z-index: 101;
}
.editor-menu .editing {
	width: 100%;
	min-height: 200px;
	background: #fff;
	border-radius: 15px;
	margin-bottom: 1rem;
}

.editor-menu .editing .headeredit {
	padding: 1rem;
	font-weight: bold;
	border-bottom: 1px solid #ededed;
}

.editor-menu .editing .content {
	padding: 1rem;
}

.editor-menu .help {
	padding: 1rem 1rem 1rem 2rem;
	border-radius: 1rem;
	background-color: rgba(0, 75, 152, 0.08);
	color: #023062;
}

.editor-menu .help > i {
	margin-left: -1.5rem;
	width: 1rem;
	margin-right: 0.5rem;
}

.select select {
	color: #000 !important;
	height: 40px !important;
	padding-left: 12px !important;
}

button.color {
	width: 56px;
	height: 32px;
	border: 2px solid rgba(2, 48, 98, 0.1);
	border-radius: 4px;
}

.radios {
	padding: 1rem;
	color: #023062;
	display: inline-flex;
	background-color: #f0f2f5;
	border-radius: 4px;
}
.radios > input {
	display: none;
}

.radios > label {
	padding: 0 1rem;
	border-left: 1px solid #ccc;
	opacity: 0.24;
	cursor: pointer;
}

.radios > label.selected {
	opacity: 1;
}

.radios > label:first-of-type {
	border: none;
	padding-left: 0;
}

.radios > label:last-of-type {
	padding-right: 0;
}

.radios.radios-textalign {
	background: none;
	padding: 0;
}

.radios.radios-textalign > label {
	border: none;
	width: 32px;
	height: 32px;
}

.radios.radios-textalign > label.selected {
	background-color: #f0f2f5;
	border-radius: 4px;
}

.radios.radios-textalign > label div {
	height: 2px;
	background-color: #023062;
	width: 16px;
	margin-bottom: 4px;
}

.radios.radios-textalign > label div:first-of-type {
	width: 24px;
}
.radios.radios-textalign > label.ft-center div {
	margin: 0 auto 4px auto;
}
.radios.radios-textalign > label div:last-of-type {
	width: 8px;
}

.radios.radios-textalign > label.ft-right div {
	margin-left: 8px;
}
.radios.radios-textalign > label.ft-right div:first-of-type {
	margin-left: 0;
}

.radios.radios-textalign > label.ft-right div:last-of-type {
	margin-left: 16px;
}
.radios.radios-textalign > label,
.radios.radios-textalign > label:last-of-type,
.radios.radios-textalign > label:first-of-type {
	padding: 8px 4px;
}

.draghandle {
	content: '....';
	width: 40px;
	height: 35px;
	display: inline-block;
	overflow: hidden;
	line-height: 5px;
	padding: 3px 4px;
	cursor: move;
	vertical-align: middle;
	margin-top: -0.3em;
	font-size: 30px;
	font-family: sans-serif;
	letter-spacing: 2px;
	color: white;
	text-shadow: 0px 0px 3px black;
	position: absolute;
	left: -20px;
}

.draghandle::after {
	content: '.. .. .. ..';
}

.icon-delete {
	cursor: pointer;
	background: white;
	border-radius: 10px;
	text-shadow: 0 0 5px #fff;
}

.icon-duplicate {
	cursor: pointer;
	background: rgba(255, 255, 255, 0.5);
	box-shadow: 0 0 5px #fff;
	font-size: 0;
}

.icon-duplicate::before {
	content: '';
	display: inline-block;
	width: 15px;
	height: 15px;
	background-image: url('../../public/assets/img/icon-duplicate.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.react-resizable {
	position: relative;
}
.react-resizable-handle {
	position: absolute;
	width: 10px;
	height: 10px;
	background: #fff;
	border: 1px solid #000;
	padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
	bottom: -5px;
	left: -5px;
	cursor: sw-resize;
}
.react-resizable-handle-se {
	bottom: -5px;
	right: -5px;
	cursor: se-resize;
}
.react-resizable-handle-nw {
	top: -5px;
	left: -5px;
	cursor: nw-resize;
}
.react-resizable-handle-ne {
	top: -5px;
	right: -5px;
	cursor: ne-resize;
}
.react-resizable-handle-w,
.react-resizable-handle-e {
	top: 50%;
	margin-top: -15px;
	cursor: ew-resize;
}
.react-resizable-handle-w {
	left: -5px;
}
.react-resizable-handle-e {
	right: -5px;
}
.react-resizable-handle-n,
.react-resizable-handle-s {
	left: 50%;
	margin-left: -5px;
	cursor: ns-resize;
}
.react-resizable-handle-n {
	top: -5px;
}
.react-resizable-handle-s {
	bottom: -5px;
}

.editor-body__section header {
	position: absolute;
	left: 0%;
	width: 100%;
	top: 0;
}

.editor-body__section .container .ui.menu {
	display: flex;
	flex-direction: row;
	height: 60px;
	padding: 0.7rem;
}

.editor-body__section .container .ui.menu p {
	color: #7d93a8;
	margin-top: -5px;
	font-size: 0.8rem;
	text-align: center;
}

/* shape and text controllers */
.editor-menu.editor-menu__text,
.editor-menu.editor-menu__shape {
	top: 64px;
}

.editor-menu.editor-menu__text.hasCurrentExemple,
.editor-menu.editor-menu__shape.hasCurrentExemple {
	top: 128px;
}

.editor-menu .input {
	display: flex;
	flex-direction: column;
}

.editor-menu .input label {
	display: block;
	font-weight: bold;
	margin-right: 0.5rem;
	margin-bottom: 0.2rem;
}

.editor-menu .input input {
	height: 42px;
	width: 6em;
	padding: 12px;
}

.editor-menu .editor-menu__item {
	border-bottom: 1px solid #ebebeb;
	padding: 1rem 0;
}

.editor-menu .editor-menu__item:last-child {
	border-bottom: unset;
}

.editor-menu .editor-menu__item h4 {
	margin-bottom: 0.5rem;
}

.editor-menu .select {
	font-size: 13px;
}

.editor-menu .editor-menu__item .btn.bottomindex,
.editor-menu .editor-menu__item .btn.upindex {
	color: #000;
	line-height: 1.2em;
	height: auto;
	padding: 0.8em;
}

.editor-menu .editor-menu__item .btn.upindex {
	margin-left: 1em;
}

.btn--delete {
	background-color: #f2362f;
}

@media (min-width: 1281px) {
	.editor-menu {
		width: calc(16% + 3px);
	}
	.container .editor > canvas {
		max-width: 75%;
		height: auto;
	}
}

@media (min-width: 1100px) {
	.editor-menu .input {
		flex-direction: row;
		align-items: baseline;
	}

	.editor-menu .input.input__disposition {
		flex-direction: column;
		align-items: unset;
	}

	.editor-menu .input div {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	.editor-menu .input label {
		flex: 1 1 0%;
		margin-bottom: unset;
	}

	.editor-menu .input div:last-child {
		display: flex;
		align-items: center;
		margin-bottom: unset;
	}

	.editor-menu.editor-menu__shape .editor-menu__item > div {
		display: flex;
		align-items: center;
	}

	.editor-menu .editor-menu__item h4 {
		flex: 3;
		line-height: 32px;
		margin-bottom: unset;
	}

	.editor-menu .input {
		margin-bottom: 16px;
	}

	.editor-menu .input:last-child,
	.editor-menu .input.input__opacity {
		margin-bottom: unset;
	}

	.editor-menu .slider {
		margin-bottom: 0.5rem;
	}
}

@media (min-width: 1480px) {
	.editor-menu .input {
		flex-direction: row;
	}

	.editor-menu .input div {
		flex: 1;
		align-items: baseline;
		margin-right: 0.5rem;
	}

	.editor-menu .input div:last-child {
		margin-right: unset;
		margin-left: 0.5rem;
		justify-content: end;
		align-items: baseline;
	}

	.editor-menu .input.input__disposition div {
		margin-right: unset;
	}

	.editor-menu .input.input__disposition div:last-child {
		margin-left: unset;
	}
}

/* Surcharge ContentEditable */

/* Feature undo redo */
.icon-undo::before {
	content: '';
	display: inline-block;
	width: 15px;
	height: 15px;
	background-image: url('../../public/assets/img/undo.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.icon-redo::before {
	content: '';
	display: inline-block;
	width: 15px;
	height: 15px;
	background-image: url('../../public/assets/img/redo.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
