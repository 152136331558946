/**
 * ffr-ligue - 
 * @version v1.0.0
 */
@charset "UTF-8";
/* Spacing */
/* Font familly */
/* Font Size */
/* Basic colors*/
/* Main colors */
/* Black and white */
/* Border */
/* Background */
/* Other colors */
/* Size */
/* Shadow */
/* Transition */
/* z-index */
@font-face {
    font-family: 'ArialMT';
    font-style: normal;
    font-weight: normal;
    src: local('ArialMT Regular'), url('../font/arialmt.woff') format('woff');
}

@font-face {
    font-family: 'Arial';
    font-style: normal;
    font-weight: normal;
    src: local('Arial'), url('../font/ARIAL.woff') format('woff');
}

@font-face {
    font-family: 'Arial';
    font-style: normal;
    font-weight: bold;
    src: local('Arial Black'), url('../font/ARIBLK.woff') format('woff');
}


@font-face {
	font-family: 'Din';
	src: url('../font/din-regular.woff2') format('woff2'), url('../font/din-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Din';
	src: url('../font/din-medium.woff2') format('woff2'), url('../font/din-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Din';
	src: url('../font/din-bold.woff2') format('woff2'), url('../font/din-bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Din';
	src: url('../font/din-black.woff2') format('woff2'), url('../font/din-black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'DinPro';
	src: url('../font/DINPro-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'DinPro';
	src: url('../font/DINPro.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DinPro';
	src: url('../font/DINPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'DinPro';
	src: url('../font/DINPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../font/roboto-light.woff2') format('woff2'), url('../font/roboto-light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../font/roboto-light-italic.woff2') format('woff2'), url('../font/roboto-light-italic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../font/roboto-regular.woff2') format('woff2'), url('../font/roboto-regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../font/roboto-regular-italic.woff2') format('woff2'),
		url('../font/roboto-regular-italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('../font/roboto-medium.woff2') format('woff2'), url('../font/roboto-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../font/roboto-bold.woff2') format('woff2'), url('../font/roboto-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'DK-Cinnabar-Brush';
	src: url('../font/DK-Cinnabar-Brush.ttf.woff') format('woff'),
		url('../font/DK-Cinnabar-Brush.ttf.svg#DK-Cinnabar-Brush') format('svg'), url('../font/DK-Cinnabar-Brush.ttf.eot'),
		url('../font/DK-Cinnabar-Brush.ttf.eot?#iefix') format('embedded-opentype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'icon';
	src: url('../font/icon.eot?otja1m');
	src: url('../font/icon.eot?otja1m#iefix') format('embedded-opentype'),
		url('../font/icon.ttf?otja1m') format('truetype'), url('../font/icon.woff?otja1m') format('woff'),
		url('../font/icon.svg?otja1m#ffr') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Exo';
	src: url('../font/Exo-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Exo';
	src: url('../font/Exo-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SummerLovingSolid';
	src: url('../font/summerlovingsolid.woff2') format('woff2'),
		url('../font/editor/summerlovingsolid.ttf') format('ttf');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Verdana';
	src: url('../font/Verdana-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Verdana';
	src: url('../font/Verdana-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Mulish';
	src: url('../font/mulish/Mulish-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Mulish';
	src: url('../font/mulish/Mulish-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

.container {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left: 16px;
	padding-right: 16px;
}

.container--ride-up {
	margin-top: -148px;
}

.row {
	margin-left: -20px;
}
.row:after {
	display: table;
	clear: both;
	content: '';
}

.col {
	position: relative;
	float: left;
	min-height: 1px;
	padding-left: 20px;
	width: 100%;
}

.col--center {
	margin-left: auto;
	margin-right: auto;
	float: none;
}

.bb1 {
	border-bottom: 1px solid #ebebeb;
}

.col.no-padding {
	padding-bottom: 0;
}

.no-padding {
	padding-bottom: 0;
}

.z1 {
	z-index: 1;
}

.z2 {
	z-index: 2;
}

.z3 {
	z-index: 3;
}

.col-xs-1 {
	width: 25%;
}

.col-xs-2 {
	width: 50%;
}

.col-xs-3 {
	width: 75%;
}

@media (min-width: 768px) {
	.container {
		max-width: 1272px;
		padding-left: 16px;
		padding-right: 16px;
	}
	.row {
		margin-left: -16px;
	}
	.col {
		padding-left: 16px;
	}
	.col-md-1 {
		width: 8.33333%;
	}
	.col-md-2 {
		width: 16.66667%;
	}
	.col-md-3 {
		width: 25%;
	}
	.col-md-4 {
		width: 33.33333%;
	}
	.col-md-5 {
		width: 41.66667%;
	}
	.col-md-6 {
		width: 50%;
	}
	.col-md-7 {
		width: 58.33333%;
	}
	.col-md-8 {
		width: 66.66667%;
	}
	.col-md-9 {
		width: 75%;
	}
	.col-md-10 {
		width: 83.33333%;
	}
	.col-md-11 {
		width: 91.66667%;
	}
	.col-md-12 {
		width: 100%;
	}
}

@media (min-width: 1080px) {
	.container.has-sidebar .col-lg-8 {
		width: 66.66667%;
	}
}

@media (min-width: 1280px) {
	.container {
		max-width: 1680px;
		padding-left: 60px;
		padding-right: 60px;
	}
	.container--tv {
		padding-left: 40px;
		padding-right: 40px;
	}
	.container--tv .row {
		margin-left: -40px;
	}
	.container--tv .col {
		padding-left: 40px;
	}
	.row {
		margin-left: -32px;
	}
	.col {
		padding-left: 32px;
	}
	.col-lg-1 {
		width: 8.33333%;
	}
	.col-lg-offset-1 {
		margin-left: 8.33333%;
	}
	.col-lg-2 {
		width: 16.66667%;
	}
	.col-lg-offset-2 {
		margin-left: 16.66667%;
	}
	.col-lg-3 {
		width: 25%;
	}
	.col-lg-offset-3 {
		margin-left: 25%;
	}
	.col-lg-4 {
		width: 33.33333%;
	}
	.col-lg-offset-4 {
		margin-left: 33.33333%;
	}
	.col-lg-5 {
		width: 41.66667%;
	}
	.col-lg-offset-5 {
		margin-left: 41.66667%;
	}
	.col-lg-6 {
		width: 50%;
	}
	.col-lg-offset-6 {
		margin-left: 50%;
	}
	.col-lg-7 {
		width: 58.33333%;
	}
	.col-lg-offset-7 {
		margin-left: 58.33333%;
	}
	.col-lg-8 {
		width: 66.66667%;
	}
	.col-lg-offset-8 {
		margin-left: 66.66667%;
	}
	.col-lg-9 {
		width: 75%;
	}
	.col-lg-offset-9 {
		margin-left: 75%;
	}
	.col-lg-10 {
		width: 83.33333%;
	}
	.col-lg-offset-10 {
		margin-left: 83.33333%;
	}
	.col-lg-11 {
		width: 91.66667%;
	}
	.col-lg-offset-11 {
		margin-left: 91.66667%;
	}
	.col-lg-12 {
		width: 100%;
	}
	.col-lg-offset-12 {
		margin-left: 100%;
	}
	.col-lg-offset-1 {
		margin-left: 8.33333%;
	}
	.col-lg-offset-1 {
		margin-left: 16.66667%;
	}
	.col-lg-1on9 {
		width: 11.11111%;
	}
	.col-lg-2on9 {
		width: 22.22222%;
	}
	.col-lg-3on9 {
		width: 33.33333%;
	}
	.col-lg-4on9 {
		width: 44.44444%;
	}
	.col-lg-5on9 {
		width: 55.55556%;
	}
	.col-lg-6on9 {
		width: 66.66667%;
	}
	.col-lg-7on9 {
		width: 77.77778%;
	}
	.col-lg-8on9 {
		width: 88.88889%;
	}
	.col-lg-9on9 {
		width: 100%;
	}
	.col-lg-offset-1on9 {
		margin-left: 11.11111%;
	}
	.col-lg-fixed {
		width: 424px;
	}
	.col-lg-fixed + .col {
		width: calc(100% - 424px);
	}
}

.hide-minScreen {
	display: block;
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
	.hide-minScreen {
		display: none;
	}
}

.display-desktop {
	display: none;
}
@media screen and (min-width: 1280px) {
	.display-desktop {
		display: block;
	}
}

.grid-debug {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	opacity: 0;
	pointer-events: none;
	display: none;
}
.grid-debug.is-visible {
	display: block;
	opacity: 0.4;
}
.grid-debug .container {
	height: 100%;
}
.grid-debug .row {
	height: 100%;
}
.grid-debug .col {
	height: 100%;
	background: rgba(27, 122, 181, 0.3);
}
.grid-debug .col div {
	height: 100%;
	background: rgba(27, 122, 181, 0.3);
}

html {
	overflow-x: hidden;
}

body {
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #292929;
	font-family: 'Roboto', arial, sans-serif;
	font-size: 14px;
	line-height: 1.58;
	font-weight: 400;
	margin: 0;
	padding: 0;
	background-color: #f0f2f5;
}
body.prevent-scroll {
	position: fixed;
	width: 100%;
	overflow: hidden;
}
body.body-dark {
	background-color: #04182d;
	color: #fff;
}

a,
[role='button'] {
	outline: none;
}

@media screen and (max-device-width: 480px) {
	body {
		-webkit-text-size-adjust: none;
	}
}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

blockquote,
caption,
details,
dl,
figure,
hgroup,
label,
ol,
p,
pre,
td,
textarea,
th,
ul {
	margin: 0 0 8px;
}

body,
figure,
html,
label,
textarea {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 700;
	font-family: 'Din';
}

ol,
ul {
	padding-left: 0;
}

table {
	margin-bottom: 0;
}

/* HTML5 tags */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
	display: block;
}

/* max values */
blockquote,
code,
img,
input,
pre,
table,
td,
textarea,
video {
	max-width: 100%;
}

img {
	height: auto;
}

div,
h1,
p,
table,
td,
textarea,
th {
	word-wrap: break-word;
	-webkit-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

input,
select,
textarea {
	font-family: inherit;
	font-size: 14px;
	font-weight: 400;
}
input:disabled,
select:disabled,
textarea:disabled {
	cursor: not-allowed;
}

input[type='button'],
input[type='reset'],
input[type='submit'] {
	-webkit-appearance: none;
	font: inherit;
}

button {
	-webkit-appearance: none;
	background: none;
	border: 0;
	font: inherit;
	cursor: pointer;
}

img {
	vertical-align: middle;
}

a img {
	border: 0;
}

/* scripts */
body > script {
	display: none !important;
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}
a:hover {
	text-decoration: underline;
	color: #2a9bff;
}

ul {
	list-style: none;
}

figure img,
figure div {
	margin-bottom: 8px;
}

figcaption {
	font-size: 12px;
	line-height: 18px;
	color: #999;
	margin-bottom: 16px;
	font-weight: 500;
}

iframe {
	border: 0;
	overflow: hidden;
}

.clearfix:after {
	content: ' ';
	display: table;
	clear: both;
}

.clear {
	clear: both;
}

::-webkit-input-placeholder {
	color: #999;
}

:-ms-input-placeholder {
	color: #999;
}

::-ms-input-placeholder {
	color: #999;
}

::placeholder {
	color: #999;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #999;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: #999;
}

:-ms-input-placeholder {
	/* IE!)+ */
	color: #999 !important;
}

::-ms-input-placeholder {
	/* Edge */
	color: #999;
}

::-webkit-search-cancel-button {
	-webkit-appearance: none;
	height: 14px;
	width: 14px;
	border-radius: 100%;
	background: url(../img/icon-delete.svg) no-repeat center center;
	background-size: contain;
}

@media (max-width: 450px) {
	.mobile-ban:not(.mobile-ban--force-display) {
		display: none;
	}
	.mobile-only.mobile-only--force-hide {
		display: none;
	}
}

@media (min-width: 451px) {
	.mobile-only {
		display: none;
	}
}

.mt-0 {
	margin-top: 0;
}

.mb-0 {
	margin-bottom: 0;
}

.mt-1 {
	margin-top: 8px;
}

.mb-1 {
	margin-bottom: 8px;
}

.mt-2 {
	margin-top: 16px;
}

.mb-2 {
	margin-bottom: 16px;
}

.mt-3 {
	margin-top: 24px;
}

.mb-3 {
	margin-bottom: 24px;
}

.mt-4 {
	margin-top: 32px;
}

.mb-4 {
	margin-bottom: 32px;
}

.mt-5 {
	margin-top: 40px;
}

.mb-5 {
	margin-bottom: 40px;
}

.mt-6 {
	margin-top: 48px;
}

.mb-6 {
	margin-bottom: 48px;
}

.mt-7 {
	margin-top: 56px;
}

.mb-7 {
	margin-bottom: 56px;
}

.mt-8 {
	margin-top: 80px;
}

.mb-8 {
	margin-bottom: 80px;
}

@media (min-width: 768px) {
	.mt-md-0 {
		margin-top: 0;
	}
	.mb-md-0 {
		margin-bottom: 0;
	}
	.mt-md-1 {
		margin-top: 8px;
	}
	.mb-md-1 {
		margin-bottom: 8px;
	}
	.mt-md-2 {
		margin-top: 16px;
	}
	.mb-md-2 {
		margin-bottom: 16px;
	}
	.mt-md-3 {
		margin-top: 24px;
	}
	.mb-md-3 {
		margin-bottom: 24px;
	}
	.mt-md-4 {
		margin-top: 32px;
	}
	.mb-md-4 {
		margin-bottom: 32px;
	}
	.mt-md-5 {
		margin-top: 40px;
	}
	.mb-md-5 {
		margin-bottom: 40px;
	}
	.mt-md-6 {
		margin-top: 48px;
	}
	.mb-md-6 {
		margin-bottom: 48px;
	}
	.mt-md-7 {
		margin-top: 56px;
	}
	.mb-md-7 {
		margin-bottom: 56px;
	}
	.mt-md-8 {
		margin-top: 80px;
	}
	.mb-md-8 {
		margin-bottom: 80px;
	}
	.mt-md-60 {
		margin-top: 60px;
	}
}

@media (min-width: 1280px) {
	.mt-lg-0 {
		margin-top: 0;
	}
	.mb-lg-0 {
		margin-bottom: 0;
	}
	.mt-lg-1 {
		margin-top: 8px;
	}
	.mb-lg-1 {
		margin-bottom: 8px;
	}
	.mt-lg-2 {
		margin-top: 16px;
	}
	.mb-lg-2 {
		margin-bottom: 16px;
	}
	.mt-lg-3 {
		margin-top: 24px;
	}
	.mb-lg-3 {
		margin-bottom: 24px;
	}
	.mt-lg-4 {
		margin-top: 32px;
	}
	.mb-lg-4 {
		margin-bottom: 32px;
	}
	.mt-lg-5 {
		margin-top: 40px;
	}
	.mb-lg-5 {
		margin-bottom: 40px;
	}
	.mt-lg-6 {
		margin-top: 48px;
	}
	.mb-lg-6 {
		margin-bottom: 48px;
	}
	.mt-lg-7 {
		margin-top: 56px;
	}
	.mb-lg-7 {
		margin-bottom: 56px;
	}
	.mt-lg-8 {
		margin-top: 80px;
	}
	.mb-lg-8 {
		margin-bottom: 80px;
	}
}

.ft-h1,
.ft-h2,
.ft-h3,
.ft-h4,
.ft-h5,
.ft-h6 {
	font-weight: 700;
	font-family: 'Din';
}
.ft-h1.is-inline,
.ft-h2.is-inline,
.ft-h3.is-inline,
.ft-h4.is-inline,
.ft-h5.is-inline,
.ft-h6.is-inline {
	display: inline-block;
}

h1,
.ft-h1 {
	font-size: 40px;
	line-height: 40px;
}

h2,
.ft-h2 {
	font-size: 30px;
	line-height: 36px;
}

h3,
.ft-h3 {
	font-size: 20px;
	line-height: 24px;
}

h4,
.ft-h4 {
	font-size: 17px;
	line-height: 22px;
}
@media (min-width: 768px) {
	h4,
	.ft-h4 {
		font-size: 15px;
		line-height: 20px;
	}
}

h5,
.ft-h5 {
	font-size: 15px;
	line-height: 20px;
}

h6,
.ft-h6 {
	font-size: 12px;
	line-height: 20px;
}

.ft-primary {
	font-size: 20px;
	line-height: 32px;
}

.ft-secondary {
	font-size: 17px;
	line-height: 24px;
}

.ft-secondary-32 {
	font-size: 17px;
	line-height: 32px;
}

.ft-tertiary {
	font-size: 12px;
	line-height: 16px;
}

.ft-up {
	text-transform: uppercase;
}

.ft-center {
	text-align: center;
}

.ft-italic {
	font-style: italic;
}

.text-inline {
	white-space: nowrap;
}

.ft-right {
	float: right;
}

.ft-300 {
	font-weight: 300;
}

.ft-400 {
	font-weight: 400;
}

.ft-500 {
	font-weight: 500;
}

.ft-700 {
	font-weight: 700;
}

.ft-truncat {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ft-expo {
	display: inline-block;
}
.ft-expo span {
	float: right;
	margin-top: 4px;
	margin-left: 4px;
	font-size: 8px;
	line-height: 7px;
	font-family: 'Din';
	color: rgba(41, 41, 41, 0.64);
}

.ft-black {
	color: #292929;
}

.ft-black-secondary {
	color: #999;
}

.ft-black-tertiary {
	color: #ccc;
}

.ft-white {
	color: #fff;
}

.ft-white-secondary {
	color: rgba(255, 255, 255, 0.8);
}

.ft-white-tertiary {
	color: rgba(255, 255, 255, 0.6);
}

.ft-white-quaternary {
	color: rgba(255, 255, 255, 0.32);
}

.ft-white-quaternary {
	color: rgba(255, 255, 255, 0.32);
}

.ft-color-tertiary {
	color: #7d93a8;
}

.ft-red {
	color: #f2362f;
}

.ft-white {
	color: #fff;
}

.icon {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	font-family: 'icon';
	font-size: 14px;
	font-weight: 400;
	line-height: 1;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	font-style: normal;
	speak: none;
}
.icon.is-inline {
	margin-right: 8px;
}

.icon-arobase:before {
	content: '\e95b';
}

.icon-calendar:before {
	content: '\e904';
}

.icon-mail-border2:before {
	content: '\e958';
}

.icon-phone3:before {
	content: '\e957';
}

.icon-trophy:before {
	content: '\e91c';
}

.icon-messenger:before {
	content: '\e955';
}

.icon-whatsapp:before {
	content: '\e956';
}

.icon-exclamation:before {
	content: '\e953';
}

.icon-repair:before {
	content: '\e954';
}

.icon-flickr:before {
	content: '\e952';
}

.icon-view:before {
	content: '\e951';
}

.icon-drop:before {
	content: '\e934';
}

.icon-animations:before {
	content: '\e94d';
}

.icon-concours:before {
	content: '\e94e';
}

.icon-hotesses:before {
	content: '\e94f';
}

.icon-reunions:before,
.icon-salles_reunions:before {
	content: '\e950';
}

.icon-exp:before {
	content: '\e94c';
}

.icon-car:before {
	content: '\e948';
}

.icon-food:before {
	content: '\e949';
}

.icon-gift:before {
	content: '\e94a';
}

.icon-walk:before {
	content: '\e94b';
}

.icon-star:before {
	content: '\e946';
}

.icon-check:before {
	content: '\e944';
}

.icon-dashboard:before {
	content: '\e945';
}

.icon-link:before {
	content: '\e947';
}

.icon-ball:before {
	content: '\e942';
}

.icon-cage:before {
	content: '\e943';
}

.icon-shirt:before {
	content: '\e940';
}

.icon-substitution:before {
	content: '\e941';
}

.icon-logout:before {
	content: '\e93f';
}

.icon-favorite:before {
	content: '\e93e';
}

.icon-record:before {
	content: '\e93b';
}

.icon-alert:before {
	content: '\e93c';
}

.icon-camera:before {
	content: '\e93d';
}

.icon-mail-border:before {
	content: '\e93a';
}

.icon-close_alt:before {
	content: '\e938';
}

.icon-ticket:before {
	content: '\e939';
}

.icon-store:before {
	content: '\e937';
}

.icon-success:before {
	content: '\e935';
}

.icon-phone2:before {
	content: '\e936';
}

.icon-play:before {
	content: '\e933';
}

.icon-shield-alt:before {
	content: '\e92b';
}

.icon-arrow-down:before {
	content: '\e92c';
}

.icon-arrow-up:before {
	content: '\e92d';
}

.icon-coment-fb:before {
	content: '\e92e';
}

.icon-coment-insta:before {
	content: '\e92f';
}

.icon-like-fb:before {
	content: '\e930';
}

.icon-like-insta:before {
	content: '\e931';
}

.icon-rt:before {
	content: '\e932';
}

.icon-sync:before {
	content: '\e92a';
}

.icon-chrevron-down:before {
	content: '\e928';
}

.icon-chrevron-up:before {
	content: '\e929';
}

.icon-arrow-left:before {
	content: '\e901';
}

.icon-arrow-right:before {
	content: '\e902';
}

.icon-arrow-triangle-bottom:before {
	content: '\e906';
}

.icon-arrow-triangle-up:before {
	content: '\e914';
}

.icon-articles-empty:before {
	content: '\e920';
}

.icon-chevron-left:before,
.icon-chrevron-left:before {
	content: '\e923';
}

.icon-chevron-right:before,
.icon-chrevron-right:before {
	content: '\e924';
}

.icon-jersey-empty:before {
	content: '\e925';
}

.icon-menu:before {
	content: '\e926';
}

.icon-publications:before {
	content: '\e927';
}

.icon-chevron-down:before {
	content: '\e928';
}

.icon-account:before {
	content: '\e900';
}

.icon-calendar-empty:before {
	content: '\e903';
}

.icon-case:before {
	content: '\e905';
}

.icon-clock:before {
	content: '\e907';
}

.icon-close:before {
	content: '\e908';
}

.icon-delete:before {
	content: '\e909';
}

.icon-documents:before {
	content: '\e90a';
}

.icon-download:before {
	content: '\e90b';
}

.icon-external:before,
.icon-external_link:before {
	content: '\e90c';
}

.icon-facebook:before {
	content: '\e90d';
}

.icon-geoloc:before {
	content: '\e90e';
}

.icon-info:before {
	content: '\e90f';
}

.icon-instagram:before {
	content: '\e910';
}

.icon-jersey:before {
	content: '\e911';
}

.icon-list:before {
	content: '\e912';
}

.icon-mail:before {
	content: '\e913';
}

.icon-phone:before {
	content: '\e915';
}

.icon-pj:before {
	content: '\e916';
}

.icon-place:before {
	content: '\e917';
}

.icon-search:before {
	content: '\e918';
}

.icon-share:before {
	content: '\e919';
}

.icon-shield:before {
	content: '\e91a';
}

.icon-site:before {
	content: '\e91b';
}

.icon-twitter:before {
	content: '\e91d';
}

.icon-valid:before {
	content: '\e91e';
}

.icon-youtube:before {
	content: '\e91f';
}

.icon-zoom_moins:before {
	content: '\e921';
}

.icon-zoom_plus:before {
	content: '\e922';
}

.icon-gear:before {
	content: '\e959';
}

.icon-chart:before {
	content: '\e95a';
}

.icon-profile:before {
	content: '\e955';
}

/* Special icon */
.icon-menu.is-open:before {
	content: '\e938';
}

.icon-play--circle {
	width: 48px;
	height: 48px;
	line-height: 48px;
	border-radius: 100%;
	background: #fff;
	color: #7d93a8;
}
.icon-play--circle:before {
	position: relative;
	left: 2px;
}

.icon-play--border {
	width: 60px;
	height: 60px;
	line-height: 56px;
	border-radius: 100%;
	border: 2px solid rgba(255, 255, 255, 0.32);
	color: #fff;
}
.icon-play--border:before {
	position: relative;
	left: 2px;
}

.icon-calendar {
	top: -1px;
}

.circle-icon {
	position: relative;
	width: 80px;
	height: 80px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-width: 4px;
	border-style: solid;
	border-radius: 100%;
}
.circle-icon:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	opacity: 0.08;
}
.circle-icon:after {
	font-family: 'icon';
}
.circle-icon.circle-icon--check {
	border-color: #2ecc71;
}
.circle-icon.circle-icon--check:before {
	background-color: #2ecc71;
}
.circle-icon.circle-icon--check:after {
	content: '\e935';
	color: #2ecc71;
}
.circle-icon.circle-icon--blue {
	border-color: #2a9bff;
}
.circle-icon.circle-icon--blue:after {
	content: '\e935';
	color: #2a9bff;
}
.circle-icon.circle-icon--star {
	border-color: #ffb200;
}
.circle-icon.circle-icon--star:before {
	background-color: #ffb200;
}
.circle-icon.circle-icon--star:after {
	content: '\e946';
	font-size: 22px;
	color: #ffb200;
}
.circle-icon.circle-icon--alert {
	border-color: #f2362f;
}
.circle-icon.circle-icon--alert:before {
	background-color: #f2362f;
}
.circle-icon.circle-icon--alert:after {
	content: '\e93c';
	font-size: 22px;
	color: #f2362f;
}

.icon--red {
	color: #f2362f;
}

.btn {
	display: inline-block;
	vertical-align: middle;
	height: 48px;
	min-width: 48px;
	line-height: 48px;
	border-radius: 4px;
	border: 0;
	font-size: 14px;
	font-weight: 500;
	-webkit-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
	cursor: pointer;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	color: #fff;
	text-align: center;
	outline: none;
}
.btn:hover {
	color: #fff;
	text-decoration: none;
}
.btn:focus {
	color: #fff;
	text-decoration: none;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
}
.btn.is-disabled,
.btn[disabled] {
	pointer-events: none;
	background: #fafafa;
	color: #ccc;
	-webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
}
.btn.accordion-trigger:after {
	display: none;
}

.btn--mail,
.btn--primary,
.btn--social,
.btn--competition,
.btn--red {
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(255, 255, 255, 0)),
		to(rgba(0, 35, 72, 0.06))
	);
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 35, 72, 0.06) 100%);
}
.btn--mail:hover,
.btn--primary:hover,
.btn--social:hover,
.btn--competition:hover,
.btn--red:hover {
	background-image: -webkit-gradient(
		linear,
		left bottom,
		left top,
		from(rgba(255, 255, 255, 0)),
		to(rgba(0, 35, 72, 0.06))
	);
	background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(0, 35, 72, 0.06) 100%);
}
.btn--mail:focus,
.btn--primary:focus,
.btn--social:focus,
.btn--competition:focus,
.btn--red:focus {
	background-image: none;
}

.btn--primary {
	white-space: nowrap;
	padding: 0 32px;
	background-color: #2a9bff;
}
.btn--primary:focus {
	background-color: #2077c5;
}
.btn--primary i {
	opacity: 0.48;
	top: -1px;
}
.btn--primary .icon-list {
	font-size: 10px;
}

.btn--gold {
	background-color: #e8a801;
}
.btn--gold:focus {
	background-color: #e8a801;
}

.btn--twitter {
	background-color: #4fadf5;
}
.btn--twitter:focus {
	background-color: #4190cc;
}

.btn--facebook {
	background-color: #3a5798;
}
.btn--facebook:focus {
	background-color: #2b4170;
}

.btn--mail {
	background-color: #50b748;
}
.btn--mail:focus {
	background-color: #50b748;
}

.btn--messenger,
.btn--messenger:focus {
	background: #0084ff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 35, 72, 0.05)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 35, 72, 0.05) 100%);
}

.btn--whatsapp,
.btn--whatsapp:focus {
	background: #25d366;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 35, 72, 0.05)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 35, 72, 0.05) 100%);
}

.btn--instagram,
.btn--instagram:hover,
.btn--instagram:focus {
	background-image: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
		radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
		radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
		radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
		radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
		radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
		radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
		-webkit-gradient(linear, left top, left bottom, from(#6559ca), color-stop(30%, #bc318f), color-stop(50%, #e33f5f), color-stop(70%, #f77638), to(#fec66d));
	background-image: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
		radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
		radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
		radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
		radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
		radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
		radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
		linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.btn--social {
	white-space: nowrap;
	width: 220px;
	height: 56px;
	min-width: 56px;
	line-height: 56px;
	padding: 0;
}
.btn--social i {
	opacity: 0.48;
	position: relative;
	top: -1px;
}
.btn--social.btn--small,
.btn--social.btn--medium {
	width: 24px;
	height: 24px;
	min-width: auto;
	line-height: 24px;
	border-radius: 2px;
}
.btn--social.btn--small i,
.btn--social.btn--medium i {
	opacity: 1;
	font-size: 14px;
}
.btn--social.btn--medium {
	width: 32px;
	height: 32px;
	line-height: 32px;
}

.btn--white {
	background-color: #fff;
	color: #292929;
	border: 1px solid #ebebeb;
	padding: 0 24px;
}
.btn--white i {
	color: #7d93a8;
}
.btn--white .icon-camera,
.btn--white .icon-record {
	color: #f2362f;
	font-size: 10px;
}
.btn--white:hover,
.btn--white:focus,
a.card:hover .btn--white {
	color: #2a9bff;
}
.btn--white:hover i,
.btn--white:focus i,
a.card:hover .btn--white i {
	color: #2a9bff;
}

.btn--primary.btn--white {
	color: #2a9bff;
	background: #fff;
	-webkit-box-shadow: initial;
	box-shadow: initial;
}
.btn--primary.btn--white:hover,
.btn--primary.btn--white:focus {
	background: #f0f2f5;
}

.btn--youtube:hover,
.btn--youtube:focus {
	color: #f2362f;
}
.btn--youtube:hover .icon,
.btn--youtube:focus .icon {
	color: #f2362f;
}

.btn--youtube .icon {
	color: #f2362f;
	top: -1px;
}

.btn--red {
	background-color: #f2362f;
	padding: 0 24px;
}
.btn--red .icon {
	top: -1px;
}

.btn--dark {
	color: #fff;
}
.btn--dark .link-icon {
	border-color: rgba(255, 255, 255, 0.32);
}
.btn--dark .link-icon:hover,
.btn--dark .link-icon:focus {
	color: #fff;
}
.btn--dark i {
	font-size: 10px;
	color: #fff;
}

.btn--competition {
	position: relative;
	height: auto;
	line-height: 22px;
	background-color: #004c93;
	padding: 10px 60px 10px 24px;
	text-align: left;
}
.btn--competition i {
	position: absolute;
	right: 24px;
	top: 50%;
	margin-top: -7px;
	opacity: 0.48;
}

.btn--cheer.btn--white {
	border: inherit;
}
.btn--cheer.btn--white i {
	background: transparent;
}
.btn--cheer.btn--white:hover i {
	background: rgba(42, 155, 255, 0.06);
}

.btn--cheer {
	position: relative;
	-webkit-box-shadow: none;
	box-shadow: none;
	padding-left: 56px;
}
.btn--cheer:hover,
.card:hover .btn--cheer {
	-webkit-box-shadow: none;
	box-shadow: none;
}
.btn--cheer:hover i,
.card:hover .btn--cheer i {
	background-color: rgba(42, 155, 255, 0.32);
}
.btn--cheer:hover .is-dark,
.card:hover .btn--cheer .is-dark {
	background: none;
	border-color: #fff;
}
.btn--cheer span {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	line-height: 20px;
}
.btn--cheer i {
	font-size: 10px;
	color: #2a9bff;
	border-color: #2a9bff;
	border-radius: 100%;
	background-color: rgba(42, 155, 255, 0.06);
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -22px;
	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.btn--cheer i:before {
	margin-left: 1px;
}
.btn--cheer i.is-dark {
	background: none;
}

.btn--transparent {
	white-space: nowrap;
	padding: 0 32px;
	-webkit-box-shadow: 0 0 0 1px inset rgba(255, 255, 255, 0.32);
	box-shadow: 0 0 0 1px inset rgba(255, 255, 255, 0.32);
}
.btn--transparent i {
	opacity: 0.4;
}

.btn--icon {
	width: 32px;
	height: 32px;
	min-width: auto;
	line-height: 28px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 2px solid #ebebeb;
	border-radius: 4px;
	text-align: center;
	background-color: #fff;
	color: #999;
}
.btn--icon i {
	font-size: 10px;
	vertical-align: unset;
}
.btn--icon.is-disabled {
	opacity: 0.24;
	cursor: default;
}
.btn--icon.is-active {
	background-color: rgba(42, 155, 255, 0.12);
}
.btn--icon:hover,
.btn--icon:focus,
.btn--icon.is-active,
.accordion-trigger:hover .btn--icon {
	border-color: #2a9bff;
	color: #2a9bff;
}
.btn--icon.is-dark {
	color: #fff;
	background-color: rgba(255, 255, 255, 0.06);
	border-color: rgba(255, 255, 255, 0.32);
}
.btn--icon.is-dark:hover {
	border-color: #fff;
}
.btn--icon.btn--medium {
	width: 40px;
	height: 40px;
	line-height: 36px;
}
.btn--icon.btn--medium i {
	font-size: 12px;
}

.btn--full {
	width: 100%;
	padding: 0 16px;
}

.btn--xs {
	padding: 0 12px;
	line-height: 28px;
	height: 32px;
	min-height: 32px;
	border: 2px solid #ebebeb;
}
.btn--xs.is-active,
.btn--xs:hover,
.btn--xs:focus {
	outline: 0;
	border-color: #2a9bff;
	color: #2a9bff;
}

.btn--big {
	height: 80px;
	line-height: 80px;
}

.btn--edit {
	border: 1px solid rgba(41, 41, 41, 0.12);
	-webkit-box-shadow: none;
	box-shadow: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#f5f6f7));
	background-image: linear-gradient(180deg, #fff 0%, #f5f6f7 100%);
	white-space: nowrap;
}

@media (min-width: 768px) {
	.btn--competition {
		padding-left: 16px;
		padding-right: 40px;
	}
	.btn--competition i {
		right: 16px;
	}
}

@media (min-width: 1280px) {
	.btn--competition {
		padding-left: 24px;
		padding-right: 60px;
	}
	.btn--competition i {
		right: 24px;
	}
}

@media (max-width: 450px) {
	.btn--primary {
		height: auto;
		white-space: normal;
	}
}

.link-chevron {
	display: block;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
}
.link-chevron:hover,
a.card:hover .link-chevron {
	text-decoration: none;
	color: #2a9bff;
}
.link-chevron:hover i,
a.card:hover .link-chevron i {
	color: #2a9bff;
}
.link-chevron i {
	float: right;
	color: #7d93a8;
	font-size: 10px;
	line-height: 22px;
	-webkit-transition: color 0.2s;
	transition: color 0.2s;
}
.link-chevron .icon-zoom_plus {
	font-size: 8px;
}
.link-chevron .icon-external {
	font-size: 12px;
}
.link-chevron.accordion-trigger:after {
	display: none;
}
.link-chevron.is-open .icon-zoom_plus:before {
	content: '\e921';
}

.link-chevron--right {
	float: right;
}
.link-chevron--right i {
	margin-left: 16px;
}

.link-chevron--left i {
	float: left;
	margin-right: 16px;
}

.link-chevron--block {
	padding: 16px 24px;
}

.link-chevron--inline i {
	float: none;
	margin-left: 8px;
}

.link-chevron--center {
	text-align: center;
}

.link-chevron--alt {
	color: #2a9bff;
}

.link-chevron--white {
	color: #fff;
}
.link-chevron--white:hover,
.card:hover .link-chevron--white {
	color: #fff;
}
.link-chevron--white:hover i,
.card:hover .link-chevron--white i {
	color: #fff;
}

.link-chevron--blue {
	color: #004c93;
}

.link-chevron--din {
	font-family: 'Din';
	font-weight: bold;
}

.link-icon {
	display: inline-block;
	width: 48px;
	height: 48px;
	line-height: 44px;
	text-align: center;
	border: 2px solid #ccc;
	border-radius: 100%;
	color: #999;
	margin-right: 16px;
	cursor: pointer;
}
.link-icon:hover {
	background-color: rgba(204, 204, 204, 0.16);
	color: #999;
}
.link-icon.is-dark {
	border-color: rgba(255, 255, 255, 0.32);
	color: #fff;
}
.link-icon.is-dark:hover,
.news:hover .link-icon.is-dark {
	border-color: #fff;
	background: none;
	color: #fff;
}
.link-icon.link-icon--large {
	width: 60px;
	height: 60px;
	line-height: 56px;
}
.link-icon.link-icon--large i {
	font-size: 12px;
}
.link-icon.link-icon--medium {
	width: 36px;
	height: 36px;
	line-height: 32px;
}
.link-icon.link-icon--medium i {
	font-size: 12px;
}
.link-icon.link-icon--small {
	width: 32px;
	height: 32px;
	line-height: 28px;
	border-width: 1px;
}
.link-icon.link-icon--small i {
	font-size: 12px;
}
.link-icon.link-icon--xsmall {
	width: 24px;
	height: 24px;
	line-height: 20px;
}
.link-icon.link-icon--xsmall i {
	font-size: 10px;
}
.link-icon .icon-facebook {
	top: -1px;
}
.link-icon .icon-twitter {
	top: -1px;
	right: -1px;
}
.link-icon .icon-instagram {
	top: -1px;
}
.link-icon .icon-youtube {
	top: -1px;
}
.link-icon .icon-play {
	left: 1px;
	top: -1px;
}

.link-icon--white {
	background-color: #fff;
	border-color: #fff;
	color: #7d93a8;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
}
.link-icon--white:hover {
	background-color: #fff;
	color: #7d93a8;
}

.link-competition {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	padding-right: 24px;
	margin-bottom: 16px;
}
.link-competition:last-child {
	margin-bottom: 0;
}
.link-competition:hover {
	text-decoration: none;
}
.link-competition:hover .link-competition__title {
	text-decoration: underline;
}
.link-competition:hover i {
	color: #2a9bff;
}
.link-competition i {
	position: absolute;
	right: 16px;
	top: 50%;
	margin-top: -12px;
}
.link-competition img {
	margin-right: 16px;
}

.link-competition__title {
	display: block;
}
.link-competition__title i {
	float: right;
	color: #7d93a8;
	font-size: 10px;
	line-height: 24px;
}

.link-competition__count {
	color: #2a9bff;
}

.link-competition__empty {
	color: #999;
}

.link-competition--card {
	background-color: #fff;
	border-radius: 4px;
	padding: 16px 32px 16px 16px;
	margin-bottom: 8px;
}
.link-competition--card:before {
	content: '';
	position: absolute;
	top: 12px;
	bottom: 12px;
	left: 0;
	border-left: 2px solid #2a9bff;
}

.link-competition--access {
	border: 1px solid #ebebeb;
}
.link-competition--access i {
	font-size: 14px;
}

.link-club {
	display: inline-block;
	background-color: #2077c5;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	border-radius: 4px;
	padding: 8px;
	font-size: 17px;
	font-weight: 700;
	font-family: 'Din';
}
.link-club:hover,
.link-club:focus {
	text-decoration: none;
	color: inherit;
}
.link-club:hover {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}
.link-club img {
	margin-right: 8px;
}

.link-club--blue {
	background-color: #002348;
}

.link-club-alt {
	display: inline-block;
	font-size: 12px;
	font-weight: 700;
	font-family: 'Din';
	text-transform: uppercase;
}
.link-club-alt img {
	border-radius: 100%;
	margin-right: 16px;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	width: 52px;
	height: 52px;
	padding: 12px;
	background-color: #fff;
}
.link-club-alt:hover {
	color: inherit;
}
.link-club-alt:hover img {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}

.link-blur {
	position: relative;
}
.link-blur:before {
	content: '';
	display: block;
	position: absolute;
	bottom: 100%;
	left: 0;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
	height: 104px;
	width: 100%;
}

.link-blur--blueAlt:before {
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 35, 72, 0)), color-stop(69%, #002348));
	background: linear-gradient(to bottom, rgba(0, 35, 72, 0) 0%, #002348 69%);
}

.link-chevron--pagination {
	padding: 16px 0;
}

@media (min-width: 768px) {
	.link-club {
		font-size: 15px;
	}
	.link-chevron--player {
		max-width: 220px;
		display: inline-block;
	}
	.link-chevron--player i {
		top: -10px;
	}
}

@media (min-width: 1280px) {
	.link-chevron--player {
		max-width: initial;
		display: initial;
	}
	.link-chevron--player i {
		top: initial;
	}
}

.tag {
	display: inline-block;
	vertical-align: middle;
	padding: 0 12px;
	height: 32px;
	line-height: 34px;
	font-size: 12px;
	font-family: 'Din';
	font-weight: 700;
	color: #7d93a8;
	-webkit-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
	max-width: 400px;
	border-radius: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-shadow: 0 0 0 2px #7d93a8;
	box-shadow: 0 0 0 2px #7d93a8;
	text-transform: uppercase;
}
.tag.is-active {
	text-decoration: none;
	background-color: #7d93a8;
	color: #fff;
}

a.tag:hover,
a.tag:focus {
	text-decoration: none;
	background-color: #7d93a8;
	color: #fff;
}

.tag--blue {
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #fff;
}
.tag--blue.is-active,
.tag--blue:hover,
.tag--blue:focus {
	background-color: #002348;
}

.label {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 0 8px;
	height: 24px;
	line-height: 26px;
	font-family: 'Din';
	font-size: 12px;
	font-weight: 700;
	color: #fff;
	background-color: #2a9bff;
	max-width: 200px;
	border-radius: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: uppercase;
}
.label i {
	font-size: 12px;
	line-height: 2;
	vertical-align: baseline;
	margin-right: 6px;
	opacity: 0.4;
}
.label .icon-youtube {
	font-size: 14px;
	line-height: 24px;
}

a.label:hover,
a.label:focus {
	color: rgba(255, 255, 255, 0.8);
	text-decoration: none;
}

a.label:hover {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}

a.label--blue-dark:hover,
a.label--blue-dark:focus {
	background-color: #001831;
	color: #fff;
}

.label--rounded {
	border-radius: 12px;
}

.label--icon {
	padding: 0;
	width: 24px;
	text-align: center;
	font-weight: 400;
}
.label--icon i {
	margin-right: 0;
	opacity: 1;
}

.label--khaki {
	background-color: #2077c5;
}

.label--small {
	height: 15px;
	line-height: 17px;
	font-size: 10px;
	padding: 0 4px;
}

.label--grey {
	background-color: #ebebeb;
	color: #999;
}

.label--silver {
	background-color: #ccc;
	color: #fff;
}

.label--blue {
	background-color: #7d93a8;
}

.label--blue-alt {
	background-color: #3d5975;
	color: rgba(255, 255, 255, 0.8);
}

.label--blue-dark {
	background-color: #002348;
	color: #fff;
}

.label--azure {
	background-color: #33a5dd;
}

.label--red {
	background-color: #f2362f;
	color: #fff;
}

.label--transparent {
	border-radius: 4px;
	background-color: rgba(255, 255, 255, 0.32);
	border: 2px solid #fff;
	height: 24px;
	line-height: 22px;
	font-size: 10px;
}

.label--circle {
	border-radius: 50%;
	width: 32px;
	height: 32px;
	padding: 0;
	position: relative;
}
@media (min-width: 768px) {
	.label--circle {
		width: 28px;
		height: 28px;
	}
}
.label--circle i {
	opacity: 1;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 13px;
}
@media (min-width: 768px) {
	.label--circle i {
		font-size: 13px;
	}
}

.box {
	overflow: hidden;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	border-radius: 4px;
	-webkit-transition: -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background-color: #fff;
}
.box.events--game {
	overflow: initial;
}
.box .card__thumbnail:before,
.box .card__thumbnail:after {
	border-radius: 4px;
	-webkit-border-radius: 4px;
}

a.box:hover {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	text-decoration: none;
}

.box--white {
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
}

a.box--white:hover {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
}

.box--content {
	padding: 24px;
}

.box--grey {
	background-color: #f7f7f7;
	-webkit-box-shadow: 0 0 0 1px #ebebeb;
	box-shadow: 0 0 0 1px #ebebeb;
}

.box--smoke {
	background-color: #fcfcfc;
	-webkit-box-shadow: 0 0 0 1px #ebebeb;
	box-shadow: 0 0 0 1px #ebebeb;
}

.box--blue-light {
	background-color: #f4f9fe;
	border: 1px solid #e3ecf4;
}
.box--blue-light .link-icon {
	color: #002348;
	border-color: #002348;
}

.box--green {
	background-color: rgba(80, 183, 72, 0.1);
	border: 1px solid rgba(80, 183, 72, 0.1);
}
.box--green p,
.box--green span {
	color: #32812c;
}
.box--green .link-icon {
	color: #32812c;
	border-color: #32812c;
}

a.box--grey:hover,
a.box--smoke:hover {
	-webkit-box-shadow: 0 0 0 1px #ebebeb, 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 0 0 1px #ebebeb, 0 10px 16px rgba(51, 51, 51, 0.08);
}

.accordion-trigger:after {
	font-family: 'icon';
	content: '\e928';
	float: right;
	font-size: 6px;
	line-height: 32px;
}

.accordion-trigger.is-selected:after {
	content: '\e929';
}

.accordion-trigger.is-selected .icon-zoom_plus:before {
	content: '\e921';
}

.accordion-trigger:hover,
.accordion-trigger:focus {
	text-decoration: none;
}

.accordion-panel {
	opacity: 1;
	-webkit-transition: opacity ease 0.5s, max-height ease 0.5s, visibility ease 0.5s;
	transition: opacity ease 0.5s, max-height ease 0.5s, visibility ease 0.5s;
	visibility: visible;
}
.accordion-panel.is-hidden {
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	visibility: hidden;
}

.section {
	position: relative;
	padding-top: 48px;
	padding-bottom: 48px;
}
.section .section__link:first-child {
	display: none;
}
.section .section__link--zIndex {
	position: relative;
	z-index: 2;
}

.section-blue {
	background-color: #013974;
	color: #fff;
}

.section-blueAlt {
	background-color: #002348;
	color: #fff;
}

.section-white {
	background-color: #fff;
}

.section-black {
	background-color: #04182d;
	color: #fff;
}

.section--bg {
	color: #fff;
}

.section__thumbnail {
	position: relative;
	top: 48px;
	width: calc(100% + 40px);
	max-width: none;
	margin-left: -20px;
}

@media (min-width: 768px) {
	.section {
		padding-bottom: 56px;
	}
	.section .section__link:first-child {
		display: block;
	}
	.section .section__link:last-child {
		display: none;
	}
	.section__thumbnail {
		top: 56px;
	}
}

@media (min-width: 1280px) {
	.section {
		padding-top: 80px;
		padding-bottom: 80px;
	}
	.section__thumbnail {
		position: absolute;
		top: -105px;
		right: 0;
		width: 100%;
		max-width: 600px;
	}
}

.news {
	position: relative;
	display: block;
	margin-bottom: 24px;
	font-family: 'Din';
}
.news:hover,
.news:focus {
	text-decoration: none;
}
.news:hover .news__title,
.news:focus .news__title {
	text-decoration: underline;
}
.news:hover .img-blue:before,
.news:focus .img-blue:before {
	opacity: 0.4;
}
.news img {
	display: block;
}
.news p {
	margin: 0;
}
.news .img-blue:before {
	opacity: 0;
}

.news__cat {
	color: #f2362f;
	margin-right: 8px;
	text-decoration: none;
	display: inline-block;
}

.news__title {
	display: block;
	margin-bottom: 4px;
}

.news__date {
	color: #ccc;
	display: inline-block;
}

.news__thumbnail {
	position: relative;
	height: 144px;
	background: no-repeat center center;
	background-size: cover;
	margin-bottom: 8px;
}
.news__thumbnail img {
	display: none;
}

.news--row article {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.news--row .news__thumbnail {
	position: relative;
	width: 80px;
	height: 80px;
	margin-left: 20px;
	margin-bottom: 0;
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.news--list {
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 24px;
}
.news--list:after {
	content: ' ';
	display: table;
	clear: both;
}
.news--list .news__thumbnail {
	position: relative;
	width: 111px;
	height: 76px;
	margin-right: 12px;
	float: left;
}
.news--list .news__title {
	margin-left: 123px;
}
.news--list .news__desc {
	color: #999;
	clear: both;
	padding-bottom: 24px;
	margin-top: 8px;
}
.news--list .news__date {
	position: absolute;
	bottom: 16px;
	left: 0;
}
.news--list .label {
	margin-bottom: 4px;
}

.news--search .news__desc {
	padding-bottom: 0;
}

.news--search .news__date {
	position: static;
	margin-left: 123px;
	margin-top: 8px;
}

.news--big {
	margin-bottom: 24px;
}
.news--big .news__thumbnail {
	position: relative;
	width: 100%;
	height: 224px;
	margin-bottom: 16px;
}

.news--listSmall {
	border-bottom: 0;
	padding-bottom: 0;
}
.news--listSmall .news__title,
.news--listSmall .news__date {
	margin-left: 0;
}
.news--listSmall .news__date {
	position: inherit;
}
.news--listSmall .news__thumbnail {
	width: 90px;
	height: 60px;
}
.news--listSmall article:hover .img-blue:before {
	opacity: 0.4;
}
.news--listSmall div:last-child {
	display: inline-block;
	width: calc(100% - 122px);
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 15px;
}

.news--video .news__thumbnail {
	-webkit-box-ordinal-group: 1;
	-ms-flex-order: 0;
	order: 0;
	margin-left: 0;
	margin-right: 16px;
	width: 112px;
	height: 84px;
}

.news--video article {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.news--video .link-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
	z-index: 1;
}

.news--video .img-blue-dark:before {
	opacity: 0;
}

.news--video .img-blue-dark:after {
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(1, 57, 116, 0)), to(#013974));
	background: linear-gradient(to bottom, rgba(1, 57, 116, 0) 0%, #013974 100%);
	background-repeat: repeat-y;
	opacity: 0.82;
}

@media (min-width: 768px) {
	.news--list .news__desc {
		padding-bottom: 0;
	}
	.news--list .news__date {
		position: static;
		margin-top: 4px;
		margin-left: 5px;
	}
	.news--list .news__thumbnail {
		margin-right: 16px;
	}
	.news--list .news__title {
		margin-left: 132px;
	}
	.news--search {
		border-bottom: 0;
		padding-bottom: 0;
	}
	.news--search .news__content {
		min-height: 120px;
		margin-left: 196px;
		border-bottom: 1px solid #ebebeb;
		padding-bottom: 24px;
		padding-top: 16px;
	}
	.news--search .news__title,
	.news--search .news__date {
		margin-left: 0;
	}
	.news--search .news__date {
		margin-top: 8px;
	}
	.news--search .news__desc {
		clear: none;
	}
	.news--search .news__thumbnail {
		width: 180px;
		height: 120px;
		margin-bottom: 0;
	}
	.news--search .label {
		margin-bottom: 8px;
	}
	.news--big {
		margin-bottom: 40px;
	}
	.news--big .news__thumbnail {
		height: 380px;
	}
	.news--listSmall {
		border-bottom: 0;
	}
	.news--listSmall .news__title,
	.news--listSmall .news__date {
		margin-left: 0;
	}
	.news--video .news__thumbnail {
		width: 64px;
		height: 48px;
	}
}

@media (min-width: 1280px) {
	.news__thumbnail {
		height: 168px;
	}
	.news--row-lg article {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.news--row-lg .news__thumbnail {
		width: 80px;
		height: 80px;
		margin-left: 20px;
		margin-bottom: 0;
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.news--list {
		border-bottom: 0;
		padding-bottom: 0;
	}
	.news--list .news__thumbnail {
		width: 180px;
		height: 120px;
		margin-right: 32px;
		margin-bottom: 0;
	}
	.news--list .news__desc {
		margin-top: 4px;
		clear: none;
	}
	.news--list .news__content {
		min-height: 120px;
		margin-left: 212px;
		padding-bottom: 24px;
		border-bottom: 1px solid #ebebeb;
	}
	.news--list .news__title,
	.news--list .news__date {
		margin-left: 0;
	}
	.news--big {
		overflow: hidden;
	}
	.news--big .news__thumbnail {
		width: 498px;
		height: 336px;
		float: left;
		margin-bottom: 0;
		margin-right: 32px;
	}
	.news--big .news__title {
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 16px;
		padding-top: 160px;
	}
	.news--big div:last-child {
		min-height: 336px;
		margin-left: 530px;
		border-bottom: 1px solid #ebebeb;
	}
	.news--listSmall .news__thumbnail {
		width: 90px;
		height: 60px;
	}
	.news--video .news__thumbnail {
		margin-right: 16px;
		width: 122px;
		height: 90px;
	}
	.news--video .link-icon {
		width: 36px;
		height: 36px;
		line-height: 32px;
		margin: -18px 0 0 -18px;
	}
	.news--video .link-icon i {
		font-size: 14px;
	}
}

.bg-overflow {
	margin-bottom: 48px;
	padding-bottom: 48px;
}
.bg-overflow:before,
.bg-overflow:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
	width: 300%;
	height: 100%;
	background-color: #fff;
}
.bg-overflow:after {
	right: auto;
	left: 0;
}

.bg-overflow--dark:before,
.bg-overflow--dark:after {
	background-color: #002348;
}

@media (min-width: 768px) {
	.bg-overflow {
		padding-bottom: 32px;
		padding-right: 16px;
	}
	.bg-overflow:after {
		display: none;
	}
	.bg-overflow--dark {
		margin-bottom: 0;
	}
}

@media (min-width: 1280px) {
	.bg-overflow {
		padding-bottom: 80px;
		padding-right: 0;
	}
	.bg-overflow--dark {
		padding-right: 40px;
	}
}

.img-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: no-repeat center center;
	background-size: cover;
}

.img-bg--left {
	background-position: top left;
}

.img-circle {
	position: relative;
	overflow: hidden;
	width: 52px;
	height: 52px;
	border-radius: 100%;
	border: 3px solid #fff;
	background: #fff;
}
.img-circle img {
	position: absolute;
	top: 51%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 30px;
	height: 30px;
}

.img-blue:before,
.img-blue:after,
.img-blue-alt:before,
.img-blue-alt:after,
.img-blue-100:before,
.img-blue-100:after,
.img-blue-dark:before,
.img-blue-dark:after,
.img-blue-dark-98:before,
.img-blue-dark-98:after,
.img-blue-dark-60:before,
.img-blue-dark-60:after,
.img-green:before,
.img-green:after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	will-change: opacity;
}

.img-blue:before {
	opacity: 0.6;
	background-color: #091f39;
}

.img-blue-100:before {
	opacity: 1;
	background-color: #002348;
}

.img-blue-alt:before {
	opacity: 0.92;
	background-color: #002348;
}

.img-blue-dark:before {
	opacity: 0.88;
	background-color: #013974;
}

.img-blue-dark-60:before {
	opacity: 0.6;
	background-color: #013974;
}

.img-blue-dark-98:before {
	opacity: 0.98;
	background-color: #013974;
}

.img-green:before {
	opacity: 0.98;
	background-color: #2a9bff;
}

.quote {
	font-size: 24px;
	font-style: italic;
	font-weight: 300;
	line-height: 32px;
	padding: 8px 16px;
	border-left: 4px solid #2a9bff;
}
.quote .ft-h6 {
	display: block;
	font-style: normal;
	color: #999;
	margin-top: 16px;
}

@media (min-width: 1280px) {
	.quote {
		font-size: 40px;
		line-height: 48px;
		padding-left: 32px;
	}
}

.member-group .member {
	margin-bottom: 16px;
}
.member-group .member:last-child {
	margin-bottom: 0;
}

.member {
	position: relative;
	z-index: 1;
}
.member .btn--icon {
	position: absolute;
	right: 24px;
	top: 50%;
	margin-top: -16px;
}

.member--area .member__info a {
	margin-bottom: 4px;
}
.member--area .member__info a:last-child {
	margin-bottom: 0;
}

.member--area .member__title {
	margin-bottom: 4px;
}

.member__title {
	margin-bottom: 0;
}

.member__role {
	color: #999;
	margin-bottom: 4px;
}

.member__info {
	color: #999;
	margin-bottom: 0;
}
.member__info a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
	text-decoration: none;
}
.member__info a:hover span {
	text-decoration: underline;
}
.member__info i {
	width: 14px;
	font-size: 12px;
	color: #7d93a8;
}

.member--small {
	position: relative;
}
.member--small.is-selected,
.member--small:hover {
	border: 2px solid #2a9bff !important;
}
.member--small.is-selected .member__tag,
.member--small:hover .member__tag {
	color: #fff;
	background-color: #2a9bff;
}
.member--small .member__container {
	padding: 24px;
}
.member--small .member__title {
	margin-bottom: 8px;
}
.member--small .member__tag {
	position: absolute;
	top: 14px;
	right: 14px;
	display: block;
	text-align: center;
	width: 20px;
	height: 20px;
	line-height: 20px;
	border-radius: 50%;
	background-color: #ebebeb;
	font-size: 10px;
	color: #7d93a8;
	font-weight: 500;
}
.member--small i {
	color: #7d93a8;
	margin-right: 10px;
}
.member--small .member__info div {
	display: inline-block;
	vertical-align: top;
}
.member--small .member__info div:first-child {
	position: relative;
	padding-right: 36px;
}
.member--small .member__info div:first-child::after {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 0;
	content: '';
	display: block;
	width: 1px;
	height: 34px;
	background-color: #ebebeb;
}
.member--small .member__info div:last-child {
	padding-left: 36px;
}
.member--small .accordion-trigger {
	cursor: pointer;
	text-align: center;
	height: 56px;
	line-height: 56px;
	border-top: 1px solid #ebebeb;
	font-weight: 500;
	-webkit-transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.member--small .accordion-trigger:hover {
	color: #2a9bff;
}
.member--small .accordion-trigger:hover i {
	color: #2a9bff;
}
.member--small .accordion-trigger i {
	font-style: initial;
	margin-left: 10px;
}
.member--small .accordion-trigger span:last-child {
	display: none;
}
.member--small .accordion-trigger.is-selected span:first-child {
	display: none;
}
.member--small .accordion-trigger.is-selected span:last-child {
	display: block;
}
.member--small .accordion-trigger::after {
	content: '';
}

.member--small + .directory__members {
	background-color: #fff;
}
.member--small + .directory__members .member {
	border-bottom: 1px solid #ebebeb;
}

@media (min-width: 768px) {
	.member-group {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.member-group .member {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		width: 33%;
		margin-bottom: 0;
		padding-right: 40px;
	}
	.member--area .member__info a {
		margin-bottom: 8px;
	}
	.member__info a {
		margin-bottom: 4px;
	}
	.member__info a:last-child {
		margin-bottom: 0;
	}
	.accordion .member--row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.accordion .member--row .member__info {
		width: 250px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.accordion .member--row .member__role {
		margin-bottom: 0;
	}
}

@media (min-width: 1280px) {
	.member--row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.member--row .member__info {
		width: 250px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.member--row .member__role {
		margin-bottom: 0;
	}
	.member--area .member__info a {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	.member--area .member__info a:after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 12px;
		width: 2px;
		background: #ebebeb;
		margin: 0 24px;
	}
	.member--area .member__info a:last-child:after {
		display: none;
	}
}

.overlay {
	content: '';
	z-index: 200;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0;
	-webkit-transition: opacity 0.2s ease-out;
	transition: opacity 0.2s ease-out;
	pointer-events: none;
}
.overlay.is-visible {
	cursor: pointer;
	pointer-events: initial;
	opacity: 0.6;
}

.has-overlay {
	z-index: 201;
}

.list {
	list-style: none;
	margin-left: 32px;
	padding-left: 24px;
}
.list span {
	position: relative;
}
.list li {
	position: relative;
}
.list li span:before {
	color: #ccc;
	position: absolute;
	left: -24px;
}

ul.list li span:before {
	content: '\2014';
}

ul.list--bullet li span:before {
	content: '•';
	font-family: serif;
}

ul.list--check li span:before {
	content: '\e944';
	padding-top: 4px;
	font-family: 'icon';
	font-size: 10px;
	color: #2ecc71;
}

ol.list {
	counter-reset: number-counter;
}
ol.list li span:before {
	content: counter(number-counter) '.';
	counter-increment: number-counter;
}

.list-type {
	color: #999;
}
.list-type li {
	display: inline-block;
}
.list-type li:after {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 12px;
	width: 2px;
	background: #ebebeb;
	margin: 0 24px;
}
.list-type li:last-child {
	padding-right: 0;
	margin-right: 0;
}
.list-type li:last-child:after {
	display: none;
}
.list-type i {
	font-size: 12px;
	color: #7d93a8;
}

.wp-block-table {
	border: 1px solid #ebebeb;
	border-radius: 4px;
}
.wp-block-table .table {
	border: none;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.table {
	display: block;
	overflow-x: auto;
	border: 1px solid #ebebeb;
	color: #999;
	font-weight: 700;
	width: 100%;
	border-radius: 4px;
	border-collapse: collapse;
}
.table thead {
	color: #ccc;
	text-align: left;
}
.table tbody td:first-child {
	color: #292929;
	font-weight: 400;
}
.table tbody tr {
	background: #fcfcfc;
}
.table tbody tr:nth-child(odd) {
	background: #f7f7f7;
}
.table td,
.table th {
	padding: 16px 24px;
}
.table th {
	color: #999;
}
.table td.cell--inline {
	white-space: nowrap;
}

@media (min-width: 1280px) {
	.table {
		display: table;
	}
}

.bullet {
	font-size: 20px;
	font-weight: 400;
	margin: 0 5px;
}

@media (min-width: 768px) {
	.narrow-1 {
		padding-left: 8px;
		padding-right: 8px;
	}
	.narrow-2 {
		padding-left: 16px;
		padding-right: 16px;
	}
	.narrow-3 {
		padding-left: 24px;
		padding-right: 24px;
	}
	.narrow-4 {
		padding-left: 32px;
		padding-right: 32px;
	}
}

.separator {
	border: 0;
	border-bottom: 1px solid #ebebeb;
}
.separator.is-dark {
	opacity: 0.1;
}

.tooltip {
	position: relative;
	display: inline;
	text-align: left;
}
.tooltip:hover .tooltip__content {
	display: block;
}
.tooltip i {
	cursor: pointer;
}

.tooltip__content {
	display: none;
	position: absolute;
	bottom: 150%;
	left: -50px;
	white-space: nowrap;
	padding: 16px 24px;
	background-color: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}
.tooltip__content:after {
	content: '';
	position: absolute;
	left: 52px;
	bottom: -8px;
	border: 4px solid transparent;
	border-top-color: #fff;
}
.tooltip__content p {
	margin: 0;
}

.tooltip--site-club i {
	font-size: 16px;
	color: rgba(43, 65, 112, 0.2);
}

.tooltip__content--site-club {
	display: none;
}

.tooltip__content--site-club {
	position: fixed;
	min-width: 342px;
	left: 50%;
	top: 50%;
	bottom: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: 201;
	padding: 0;
	background: #fff;
	padding: 24px;
	padding-bottom: 0;
	white-space: inherit;
	overflow: hidden;
	border: 1px solid #eeeeee;
	border-radius: 6px;
}
.tooltip__content--site-club::after {
	display: none;
}
.tooltip__content--site-club > i {
	position: absolute;
	top: 21px;
	right: 21px;
	font-size: 16px;
	color: #002348;
	opacity: 0.4;
}
.tooltip__content--site-club > p {
	font-size: 14px;
	line-height: 22px;
	padding-right: 32px;
	margin-bottom: 16px;
	font-weight: 400;
}
.tooltip__content--site-club.is-open {
	display: block;
}

@media (min-width: 1280px) {
	.tooltip__content--site-club {
		position: absolute;
		width: 306px;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		top: 85%;
		bottom: inherit;
	}
	.tooltip__content--site-club > i {
		display: none;
	}
	.tooltip--site-club:hover + .tooltip__content--site-club {
		display: block;
	}
}

.input {
	position: relative;
	margin-bottom: 16px;
}
.input label {
	display: none;
}
.input input,
.input textarea {
	display: block;
	width: 100%;
	height: 56px;
	padding: 8px 22px 8px 16px;
	background: #fff;
	color: #292929;
	border: 1px solid #ccc;
	border-radius: 4px;
	-webkit-appearance: none;
}
.input input:focus,
.input input:hover,
.input textarea:focus,
.input textarea:hover {
	border-color: #999;
	outline: 0;
}
.input input:disabled,
.input textarea:disabled {
	background-color: #ccc;
}
.input input::-ms-clear,
.input textarea::-ms-clear {
	display: none;
}
.input textarea {
	height: auto;
	min-height: 100px;
}
.input.is-dark input,
.input.is-dark textarea {
	border-color: #344f6b;
	color: #fff;
	background: none;
}
.input.is-dark input:focus,
.input.is-dark textarea:focus {
	border-color: #fff;
}
.input.is-dark ::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}
.input.is-dark ::-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
}
.input.is-dark :-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.8) !important;
}
.input.is-dark ::-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}
.input.has-error input {
	border-color: #f2362f;
}
.input.has-error ~ .input__error {
	display: block;
}
.input.has-error .icon-info {
	display: block;
}
.input .icon-info {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 56px;
	line-height: 56px;
	font-size: 20px;
	display: none;
	color: #f2362f;
	border-radius: 0 4px 4px 0;
}
.input.has-check .icon-check {
	width: 20px;
	line-height: 20px;
	position: absolute;
	right: 20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background: #2a9bff;
	border-radius: 50%;
	color: #fff;
	font-size: 7px;
}

.input--search:before,
.input--dropdown:before {
	content: '\e918';
	font-family: 'icon';
	font-size: 16px;
	line-height: 16px;
	color: #ccc;
	position: absolute;
	left: 16px;
	top: 50%;
	margin-top: -8px;
}

.input--search input {
	padding-left: 40px;
	padding-right: 16px;
	font-size: 18px;
}

.input--dropdown:before {
	margin-top: -6px;
	font-size: 12px;
	line-height: 12px;
}

.input--dropdown input {
	height: 48px;
	line-height: 48px;
	padding-right: 16px;
	padding-left: 40px;
	border: 1px solid transparent;
}
.input--dropdown input:hover {
	border-color: transparent;
	cursor: pointer;
}
.input--dropdown input:focus {
	border-color: #ebebeb;
	border-bottom-color: transparent;
}
.input--dropdown input:focus ~ .dropdown__list {
	visibility: visible;
	opacity: 1;
	max-height: 300px;
}

.input--dropdown .dropdown__list {
	position: absolute;
	text-align: left;
	padding: 0 14px 0 0;
	border: 1px solid #ebebeb;
	border-top: 0;
	margin-top: -2px;
}
.input--dropdown .dropdown__list .dropdown__wrapper {
	padding: 8px 0 13px 16px;
	overflow-y: scroll;
	max-height: 200px;
}
.input--dropdown .dropdown__list p {
	display: block;
	line-height: 32px;
	padding: 0 16px 0 0;
}
.input--dropdown .dropdown__list p:hover {
	color: #999;
	cursor: pointer;
}

.input--transparent {
	margin-bottom: 24px;
}
.input--transparent:before {
	left: 0;
	color: #fff;
	opacity: 0.4;
	font-size: 20px;
	margin-top: -4px;
}
.input--transparent.is-dark input {
	border-color: rgba(0, 0, 0, 0.1);
}
.input--transparent.is-dark input:focus {
	border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.input--transparent input {
	border: 0;
	border-bottom: 2px solid #999;
	border-radius: 0;
	font-size: 40px;
	font-weight: 700;
	font-family: 'Din';
	height: 68px;
	padding: 16px 0 16px 32px;
}

.input--expand {
	display: block;
	-webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	overflow: hidden;
	border-radius: 4px;
	margin-bottom: 0;
}
.input--expand:before {
	pointer-events: none;
}
.input--expand.is-open {
	width: 100%;
}
.input--expand.is-open input {
	cursor: text;
}
.input--expand input {
	height: 100%;
	border: 0;
	cursor: pointer;
	padding-left: 56px;
}

.input--file {
	position: relative;
	width: 100%;
	height: 54px;
	line-height: 54px;
	padding-left: 16px;
	background-color: #fcfcfc;
	-webkit-box-shadow: 0 0 0 2px #ebebeb;
	box-shadow: 0 0 0 2px #ebebeb;
	border-radius: 4px;
}
.input--file:hover {
	-webkit-box-shadow: 0 0 0 2px #2a9bff;
	box-shadow: 0 0 0 2px #2a9bff;
}
.input--file:hover span {
	text-decoration: underline;
}
.input--file.has-file {
	-webkit-box-shadow: 0 0 0 2px #ccc;
	box-shadow: 0 0 0 2px #ccc;
}
.input--file.has-file:hover span {
	text-decoration: none;
}
.input--file.has-file label span {
	font-weight: 400;
}
.input--file.has-file .icon-delete {
	display: block;
}
.input--file.has-file input {
	cursor: default;
}
.input--file.has-error {
	-webkit-box-shadow: 0 0 0 2px #f2362f;
	box-shadow: 0 0 0 2px #f2362f;
}
.input--file.has-error ~ .input__error {
	display: block;
}
.input--file.has-error .icon-info {
	display: block;
}
.input--file i {
	margin-top: -2px;
	color: #999;
	margin-right: 8px;
}
.input--file label {
	height: 56px;
	font-size: 14px;
}
.input--file label span {
	color: #2a9bff;
	font-weight: 500;
}
.input--file input {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}
.input--file .icon-delete {
	position: absolute;
	right: 0;
	top: 2px;
	height: 100%;
	width: 54px;
	line-height: 54px;
	font-size: 14px;
	display: none;
	cursor: pointer;
}

.input--file-centenaire {
	background-color: #e8a801;
	color: #fff;
	text-align: center;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	font-weight: 500;
}
.input--file-centenaire:hover {
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
}
.input--file-centenaire.has-file {
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	background: #fff;
	border: 1px solid #eeeeee;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	color: #292929;
}
.input--file-centenaire.has-file + p {
	color: #292929;
}
.input--file-centenaire.has-file + p .icon-valid {
	position: relative;
	top: -1px;
	margin-right: 11px;
	color: #e8a801;
}
.input--file-centenaire > p {
	font-family: 'Roboto';
}

.input__error {
	display: none;
	margin-top: -8px;
	margin-bottom: 16px;
	padding-left: 16px;
	color: #f2362f;
}

.input--material label {
	display: block;
	position: absolute;
	top: 8px;
	left: 16px;
	color: #999;
	font-size: 10px;
	line-height: 20px;
	font-weight: 700;
	text-transform: uppercase;
	-webkit-transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
	transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
	cursor: text;
	pointer-events: none;
}
.input--material label:after {
	content: attr(data-label);
	color: #999;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	letter-spacing: -0.2px;
	text-transform: none;
	position: absolute;
	width: 200%;
	left: 0;
	opacity: 0;
	-webkit-transition: opacity cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
	transition: opacity cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
}

.input--material input,
.input--material textarea {
	padding-top: 24px;
	line-height: 22px;
}
.input--material input.is-empty ~ label,
.input--material textarea.is-empty ~ label {
	-webkit-transform: translate(0, 8px);
	transform: translate(0, 8px);
	color: rgba(0, 0, 0, 0);
}
.input--material input.is-empty ~ label:after,
.input--material textarea.is-empty ~ label:after {
	opacity: 1;
}
.input--material input.is-empty:-webkit-autofill ~ label,
.input--material textarea.is-empty:-webkit-autofill ~ label {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.input--material input.is-empty:-webkit-autofill ~ label:after,
.input--material textarea.is-empty:-webkit-autofill ~ label:after {
	opacity: 0;
}

.input--no-edit input {
	background-color: #fafafa;
	pointer-events: none;
}

.input--picture {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.input--picture .file-input {
	position: relative;
	width: 128px;
	height: 128px;
	border-radius: 50%;
	background-color: #999;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
.input--picture input {
	position: absolute;
	display: block;
	left: 50%;
	height: 100%;
	top: 0;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	opacity: 0;
	cursor: pointer;
	z-index: 1;
}
.input--picture label {
	position: relative;
	display: block;
	padding-top: 8px;
	color: #999;
	font-weight: 500;
	cursor: pointer;
}
.input--picture label:hover {
	color: #2a9bff;
}

.form-separator {
	display: block;
	width: 100%;
	height: 1px;
	background-color: #ebebeb;
}

.input-group.input-group--edit {
	margin-bottom: 24px;
}
.input-group.input-group--edit .input {
	margin-bottom: 8px;
}
.input-group.input-group--edit a {
	font-size: 14px;
	color: #999;
	cursor: pointer;
}
.input-group.input-group--edit a:hover {
	color: #2a9bff;
}

.input-edit {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 56px;
	background-color: #fff;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #ccc;
	padding: 0 12px;
	border-radius: 4px;
	overflow: hidden;
}
.input-edit.text .input-edit__placeholder {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.input-edit.text .label {
	position: absolute;
	display: block;
	top: 10px;
	left: 16px;
	font-size: 10px;
	line-height: 14px;
}
.input-edit.text p {
	padding-top: 12px;
	padding-left: 2px;
	margin-bottom: 0;
	font-size: 14px;
}
.input-edit.image-big {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	min-height: 120px;
}
.input-edit.image-big .input-edit__placeholder {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding-top: 12px;
	padding-left: 12px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.input-edit.image-big .label {
	padding-top: 5px;
}
.input-edit.image-big .btn {
	margin-top: 12px;
	z-index: 2;
}
.input-edit.color-picker {
	padding: 0 12px;
}
.input-edit.color-picker .input-edit__placeholder {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.input-edit.color-picker .input-edit__placeholder div {
	display: inline-block;
}
.input-edit.color-picker .input-edit__placeholder div span {
	display: inline-block;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	overflow: hidden;
	border-width: 2px;
	border-style: solid;
}
.input-edit.color-picker .input-edit__placeholder div span:nth-child(n + 2) {
	margin-left: -9px;
}
.input-edit.image .input-edit__placeholder img {
	height: 25px;
}
.input-edit .label {
	margin-right: 16px;
	padding: 0;
	color: #999;
	font-size: 12px;
	height: auto;
	background-color: transparent;
	pointer-events: none;
}

.input--placeholder {
	height: 56px;
	border-radius: 4px;
	border: 2px dashed rgba(0, 35, 72, 0.2);
}

@media (min-width: 768px) {
	.input--search:before {
		left: 24px;
	}
	.input--search input {
		padding-left: 56px;
		padding-right: 22px;
		font-size: 17px;
	}
	.input--transparent:before {
		left: 0;
	}
	.input--transparent input {
		font-size: 40px;
		padding-right: 0;
		padding-left: 32px;
	}
	.input--dropdown:before {
		left: 16px;
	}
	.input--dropdown input:focus ~ .dropdown__list {
		padding-bottom: 16px;
		top: 46px;
		width: 100%;
		left: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.input--dropdown .dropdown__list {
		top: 46px;
	}
	.input-edit.image-big {
		min-height: 170px;
	}
	.input-edit.text .input-edit__placeholder .label {
		display: inline-block;
		position: static;
		font-size: 12px;
	}
	.input-edit.text .input-edit__placeholder p {
		display: inline-block;
		padding-top: 0;
		padding-left: 0;
	}
}

@media (min-width: 1280px) {
	.input-edit.image-big {
		min-height: 230px;
	}
}

.input--dropdown.input--material::before {
	content: '';
}

.input--dropdown.input--material input {
	display: block;
	width: 100%;
	height: 56px;
	border: 1px solid #ccc;
	padding-left: 16px;
	position: relative;
	z-index: 3;
}

.input--dropdown.input--material i {
	display: none;
}

.input--dropdown.input--material label {
	z-index: 4;
}

.input--dropdown.input--material .dropdown__list {
	border: 0;
	-webkit-transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
	transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s;
	-webkit-box-shadow: 0 10px 16px 0 rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px 0 rgba(51, 51, 51, 0.08);
}
.input--dropdown.input--material .dropdown__list p {
	color: #999;
}
.input--dropdown.input--material .dropdown__list li img {
	margin-right: 16px;
}
.input--dropdown.input--material .dropdown__list li a:hover {
	color: #e8a801;
}

.input--dropdown.input--material input:focus {
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
}
.input--dropdown.input--material input:focus ~ .dropdown__list {
	top: 56px;
	z-index: 2;
}

.input-custom.input-custom--guest {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.input-custom.input-custom--guest span {
	color: #7d93a8;
	font-size: 20px;
	cursor: pointer;
}
.input-custom.input-custom--guest span:first-child {
	margin-right: 16px;
}
.input-custom.input-custom--guest span:last-child {
	margin-left: 16px;
}
.input-custom.input-custom--guest input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 64px;
	height: 56px;
	line-height: 56px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 4px;
	color: #999;
}
.input-custom.input-custom--guest input::-webkit-inner-spin-button,
.input-custom.input-custom--guest input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	appearance: none;
	margin: 0;
}

.input-social {
	position: relative;
}
.input-social > i {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 20px;
	font-size: 16px;
	color: #7d93a8;
}
.input-social > i:after {
	content: '';
	position: absolute;
	right: -19px;
	top: -4px;
	display: block;
	width: 1px;
	height: 24px;
	background-color: #002348;
	opacity: 0.08;
}
.input-social input {
	padding-left: 75px;
}
.input-social--site-club {
	width: 100%;
}
@media (min-width: 1280px) {
	.input-social--site-club {
		width: calc(50% - 16px);
		display: inline-block;
	}
}
@media (min-width: 1280px) {
	.input-social--site-club:nth-child(2n) {
		margin-left: 32px;
	}
}

.checkbox,
.radio,
.switch {
	position: relative;
	margin-top: 16px;
	margin-bottom: 16px;
	color: #999;
}
.checkbox label,
.radio label,
.switch label {
	position: relative;
	display: inline-block;
	padding-left: 24px;
	line-height: 16px;
}
.checkbox label:before,
.radio label:before,
.switch label:before {
	content: '';
	display: inline-block;
	height: 14px;
	width: 14px;
	vertical-align: middle;
	position: absolute;
	top: 1px;
	left: 0;
	border: solid 2px #7d93a8;
}
.checkbox label:after,
.radio label:after,
.switch label:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 0;
}
.checkbox input,
.radio input,
.switch input {
	position: absolute;
	opacity: 0;
}
.checkbox input:disabled ~ label,
.radio input:disabled ~ label,
.switch input:disabled ~ label {
	color: #ccc;
}
.checkbox input:focus ~ label:before,
.radio input:focus ~ label:before,
.switch input:focus ~ label:before {
	border-color: #7d93a8;
}
.checkbox.is-dark,
.radio.is-dark,
.switch.is-dark {
	color: rgba(255, 255, 255, 0.32);
}
.checkbox.is-dark label:before,
.radio.is-dark label:before,
.switch.is-dark label:before {
	border: solid 2px #344f6b;
}
.checkbox.is-dark input:focus ~ label:before,
.radio.is-dark input:focus ~ label:before,
.switch.is-dark input:focus ~ label:before {
	border-color: #fff;
}

.checkbox label:before {
	border-radius: 4px;
}

.checkbox label:after {
	opacity: 0;
	-webkit-transition: opacity ease-out 0.2s;
	transition: opacity ease-out 0.2s;
	will-change: opacity;
}

.checkbox input:checked ~ label:after {
	content: '';
	width: 8px;
	height: 8px;
	margin-left: 3px;
	opacity: 1;
	background-color: #2a9bff;
}

.checkbox:hover label {
	cursor: pointer;
}

.checkbox.is-dark input:checked ~ label:after {
	background-color: #fff;
}

.checkbox.check-right input {
	position: relative;
	float: right;
}
.checkbox.check-right input:checked ~ label {
	color: #292929;
}

.checkbox.check-right label {
	display: block;
	padding-left: 0;
	padding-right: 24px;
}
.checkbox.check-right label:before {
	border-radius: 2px;
	left: auto;
	right: 0;
}
.checkbox.check-right label:after {
	left: auto;
	right: 3px;
}

.checkbox--gold label {
	color: #292929;
	line-height: 20px;
}
.checkbox--gold label:before {
	border-radius: 2px;
}

.checkbox--gold a {
	color: #e8a801;
	text-decoration: underline;
}

.checkbox--gold input:checked ~ label:after {
	background-color: #e8a801;
}

.radio label:before {
	border-radius: 100%;
	background-color: #fff;
	-webkit-transition: border-color ease-out 0.2s;
	transition: border-color ease-out 0.2s;
	will-change: border-color;
}

.radio label:after {
	opacity: 0;
	-webkit-transition: opacity ease-out 0.2s;
	transition: opacity ease-out 0.2s;
	will-change: opacity;
}

.radio input:checked ~ label:after {
	background-color: #2a9bff;
	width: 8px;
	height: 8px;
	left: 3px;
	border-radius: 100%;
	opacity: 1;
}

.radio.is-dark label:before {
	background-color: transparent;
}

.radio.is-dark input:checked ~ label:after {
	background-color: #fff;
}

.switch label {
	padding-left: 48px;
}
.switch label:before {
	border-radius: 40px;
	height: 24px;
	width: 40px;
	background-color: #ccc;
	border: 0;
	-webkit-transition: background-color ease-out 0.2s;
	transition: background-color ease-out 0.2s;
	will-change: background-color;
}
.switch label:after {
	background-color: #fff;
	width: 20px;
	height: 20px;
	left: 2px;
	top: 3px;
	border-radius: 100%;
	-webkit-transition: left ease-out 0.2s;
	transition: left ease-out 0.2s;
	will-change: left;
}

.switch input:checked ~ label:before {
	background-color: #2a9bff;
}

.switch input:checked ~ label:after {
	top: 3px;
	left: 18px;
}

.switch input:focus ~ label:after {
	background-color: #2a9bff;
	-webkit-box-shadow: #fff 0 0 0 8px inset, #999 0 3px 8px;
	box-shadow: #fff 0 0 0 8px inset, #999 0 3px 8px;
}

.switch input.has-error ~ label:before {
	background-color: #f2362f;
}

.switch__info {
	display: block;
	margin-top: 1px;
	padding-left: 48px;
}

.switch__error {
	color: #f2362f;
}

.select {
	position: relative;
	overflow: hidden;
	color: #292929;
	border-radius: 4px;
	background: #fff;
}
.select:after {
	content: '\e906';
	position: absolute;
	right: 24px;
	top: 50%;
	font-family: 'icon';
	font-size: 8px;
	color: #7d93a8;
	pointer-events: none;
	margin-top: -5px;
	text-align: center;
}
.select:hover:after,
.select:focus:after {
	color: #2a9bff;
}
.select.has-error select {
	border-color: #f2362f;
}
.select.is-selected select {
	color: #292929;
}
.select select {
	display: block;
	width: 100%;
	height: 56px;
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding-left: 16px;
	padding-right: 48px;
	color: #999;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.select select:hover,
.select select:focus {
	outline: 0;
	border-color: #999;
}
.select select:hover:after,
.select select:focus:after {
	color: #2a9bff;
}
.select select::-ms-expand {
	display: none;
}
.select select:focus::-ms-value {
	background-color: transparent;
	color: #292929;
}
.select select:disabled {
	background-color: #fcfcfc;
	pointer-events: none;
}
.select.is-dark {
	background: none;
}
.select.is-dark.has-error select {
	border-color: #f2362f;
}
.select.is-dark.is-selected select {
	color: #fff;
}
.select.is-dark:after {
	color: #7d93a8;
}
.select.is-dark select {
	color: hsla(0,0%,100%,.8) !important;
	height: 56px !important;
	border-color: #344f6b;
}
.select.is-dark select:focus::-ms-value {
	color: #fff;
}
.select.is-dark option {
	color: #292929;
}

.select--material label {
	display: block;
	position: absolute;
	top: 8px;
	left: 16px;
	color: #999;
	font-size: 10px;
	line-height: 20px;
	font-weight: 700;
	text-transform: uppercase;
	-webkit-transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
	transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
	cursor: text;
	pointer-events: none;
}
.select--material label:after {
	content: attr(data-label);
	color: #999;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;
	letter-spacing: -0.2px;
	text-transform: none;
	position: absolute;
	width: 200%;
	left: 0;
	opacity: 0;
	-webkit-transition: opacity cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
	transition: opacity cubic-bezier(0.25, 0.1, 0.25, 1) 0.15s;
}

.select--material select {
	padding-top: 24px;
	padding-bottom: 8px;
}
.select--material select.is-empty ~ label {
	-webkit-transform: translate(0, 8px);
	transform: translate(0, 8px);
	color: rgba(0, 0, 0, 0);
}
.select--material select.is-empty ~ label:after {
	opacity: 1;
}
.select--material select.is-empty:-webkit-autofill ~ label {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.select--material select.is-empty:-webkit-autofill ~ label:after {
	opacity: 0;
}

.topbar {
	position: relative;
	z-index: 3;
	background-color: #002348;
	color: #7d93a8;
	font-weight: 500;
	line-height: 2;
	padding: 32px 24px;
	margin-bottom: 32px;
}
.topbar ul {
	margin-bottom: 0;
}
.topbar li {
	margin-bottom: 16px;
	vertical-align: top;
}
.topbar a {
	display: block;
}
.topbar a:focus,
.topbar a:hover {
	color: #fff;
	text-decoration: none;
}
.topbar .is-active {
	color: #fff;
}

.topbar__sub {
	padding-left: 16px;
}
.topbar__sub li {
	margin-bottom: 0;
}

.topbar__right,
.topbar__news {
	display: none;
}

@media (min-width: 1080px) {
	.topbar {
		font-size: 13px;
		line-height: 40px;
		padding: 0;
		margin-bottom: 0;
	}
	.topbar li {
		display: inline-block;
		margin-bottom: 0;
	}
	.topbar a {
		padding: 0 8px;
	}
	.topbar .accordion-trigger {
		padding: 0 24px;
	}
	.topbar .accordion-trigger:after {
		margin-left: 8px;
		line-height: 40px;
		float: none;
		content: '\e906';
	}
	.topbar .accordion-trigger.is-selected {
		background-color: #004b98;
		color: #fff;
		text-decoration: none;
	}
	.topbar .accordion-trigger.is-selected:after {
		content: '\e914';
	}
	.topbar__sub {
		width: 230px;
		position: absolute;
		background-color: #004b98;
		line-height: 2;
		padding: 8px 24px;
		color: rgba(255, 255, 255, 0.6);
	}
	.topbar__sub li {
		display: block;
	}
	.topbar__sub a {
		padding: 0;
	}
	.topbar__right {
		display: block;
		float: right;
	}
	.topbar__right i {
		top: -2px;
	}
	.topbar__right .topbar__sub {
		padding-bottom: 16px;
	}
	.topbar__user {
		padding: 0 24px;
		color: #fff;
		max-width: 220px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		border-left: 1px solid rgba(255, 255, 255, 0.06);
	}
	.topbar__user img {
		margin-right: 16px;
		border-radius: 100%;
	}
	.topbar__login {
		display: inline-block;
		padding: 0;
		background: #1a395a;
		color: #fff;
		line-height: 32px;
		padding: 0 8px;
		border-radius: 4px;
		margin-right: 4px;
		margin-top: 4px;
	}
	.topbar__login:hover {
		background: #20456e;
	}
	.topbar__news {
		display: inline-block;
		border-left: 1px solid rgba(255, 255, 255, 0.06);
		padding-left: 24px;
		font-weight: 400;
		width: calc(100% - 988px);
		font-size: 13px;
	}
	.topbar__news .label {
		margin-right: 8px;
	}
	.topbar__news a {
		display: inline-block;
		vertical-align: middle;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
		padding-left: 0;
	}
}

.menu {
	position: relative;
	padding: 8px 24px 48px;
	background-color: #fff;
}
.menu.has-accordion-open .menu__link {
	color: #999;
}
.menu h1 {
	display: none;
}
.menu a:hover,
.menu a:focus {
	text-decoration: none;
}

.menu__link {
	font-weight: 500;
	line-height: 2;
	margin-bottom: 0;
}
.menu__link li {
	margin-bottom: 16px;
}
.menu__link a {
	display: block;
	-webkit-transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1),
		-webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1),
		-webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.menu__link .accordion-trigger:after {
	color: #7d93a8;
}
.menu__link .accordion-trigger.is-selected {
	color: #292929;
}
.menu__link .accordion-trigger.is-selected:after {
	color: #2a9bff;
}
.menu__link .accordion-trigger.is-selected + .menu__sub {
	color: #292929;
}

.menu__sub {
	padding-left: 16px;
	margin-bottom: 32px;
}
.menu__sub.is-hidden {
	margin-bottom: 0;
}
.menu__sub li {
	margin-bottom: 0;
}
.menu__sub ul {
	margin-bottom: 16px;
}
.menu__sub .menu__link-all {
	margin: 16px 0;
	color: #2a9bff;
}

.menu__col .is-collapsed {
	margin-top: -16px;
}

.menu__login {
	width: 100%;
	text-align: center;
	margin-bottom: 24px;
}

.menu__social {
	text-align: center;
}
.menu__social a {
	margin: 0 8px;
}

.menu__category {
	color: #999;
	font-family: 'Din';
	font-weight: 700;
}

.menu__user {
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
	padding: 16px 0 24px;
	margin-bottom: 24px;
	font-weight: 500;
}
.menu__user ul {
	color: #7d93a8;
	margin: 8px 0 0 64px;
}
.menu__user li {
	margin-bottom: 8px;
}
.menu__user li:last-child {
	margin-bottom: 0;
}
.menu__user img {
	border-radius: 100%;
	margin-right: 16px;
}
.menu__user i {
	top: -2px;
}

.menu__logo,
.menu__close,
.menu__plus,
.menu .input--expand {
	display: none;
}

@media (max-width: 1079px) {
	.menu__club {
		position: fixed;
		z-index: 4;
		right: 0;
		bottom: 0;
		width: 375px;
		border-radius: 0;
		text-align: center;
	}
}

@media (max-width: 767px) {
	.menu__club {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.menu {
		padding-top: 80px;
	}
	.menu__close {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 70px;
		line-height: 70px;
		color: #7d93a8;
		font-size: 19px;
		cursor: pointer;
	}
	.menu__sub {
		line-height: 32px;
		margin-top: 8px;
	}
	.menu__sub.is-hidden {
		margin-top: 0;
	}
}

@media (min-width: 1080px) {
	.menu {
		height: 104px;
		max-width: 1672px;
		padding: 0 40px;
		margin: 0 auto;
		z-index: 2;
	}
	.menu .input--expand {
		display: block;
		position: absolute;
		top: 32px;
		right: 48px;
		width: 44px;
		height: 44px;
		-webkit-box-shadow: 0 0 0 2px #ebebeb;
		box-shadow: 0 0 0 2px #ebebeb;
		-webkit-transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
	.menu .input--expand:before {
		left: 14px;
	}
	.menu .input--expand:hover {
		-webkit-box-shadow: 0 0 0 2px #999;
		box-shadow: 0 0 0 2px #999;
	}
	.menu .input--expand.is-open {
		height: 100%;
		width: 480px;
		top: 0;
		-webkit-box-shadow: -13px -3px 13px #ebebeb;
		box-shadow: -13px -3px 13px #ebebeb;
	}
	.menu .input--expand.is-open:before {
		left: 24px;
	}
	.menu h1 {
		display: inline-block;
		vertical-align: middle;
	}
	.menu h1 span {
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height: 1px;
		width: 1px;
		margin: -1px;
	}
	.menu__link {
		display: inline-block;
		line-height: 104px;
		font-size: 0;
	}
	.menu__link > li > a {
		color: #999;
	}
	.menu__link > li:hover > a {
		color: #292929;
		text-decoration: none;
		-webkit-box-shadow: 0 -4px 0 #2a9bff inset;
		box-shadow: 0 -4px 0 #2a9bff inset;
	}
	.menu__link > li:hover > .accordion-trigger {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.menu__link > li:hover .menu__sub {
		opacity: 1;
		visibility: visible;
	}
	.menu__link .is-active {
		-webkit-box-shadow: 0 -4px 0 #2a9bff inset;
		box-shadow: 0 -4px 0 #2a9bff inset;
		color: #292929;
	}
	.menu__link li {
		display: inline-block;
		margin-bottom: 0;
		font-size: 14px;
	}
	.menu__link a {
		padding: 0 20px;
	}
	.menu__link .accordion-trigger:after,
	.menu__link .menu__link-all {
		display: none;
	}
	.menu__sub {
		position: absolute;
		top: 84%;
		left: 30%;
		padding: 24px 32px;
		background-color: #fff;
		border-bottom: 3px solid #2a9bff;
		-webkit-box-shadow: 0 4px 25px rgba(13, 24, 33, 0.15);
		box-shadow: 0 4px 25px rgba(13, 24, 33, 0.15);
		opacity: 0;
		visibility: hidden;
		margin-top: 0;
	}
	.menu__sub ul {
		margin-bottom: 0;
	}
	.menu__sub li {
		display: block;
		line-height: 40px;
	}
	.menu__sub a {
		padding: 0;
	}
	.menu__col {
		padding: 32px 40px;
	}
	.menu__col > li {
		display: inline-block;
		vertical-align: top;
		margin-right: 48px;
	}
	.menu__col > li:last-child {
		margin-right: 0;
	}
	.menu__col .is-collapsed {
		margin-top: 40px;
	}
	.menu__logo {
		max-height: 56px;
		padding: 0 10px;
		margin-right: 16px;
	}
	.menu__right {
		float: right;
		padding-right: 74px;
		line-height: 104px;
	}
	.menu__club {
		position: relative;
	}
	.menu__club:after {
		content: '';
		position: absolute;
		right: -11px;
		top: 8px;
		display: block;
		width: 2px;
		height: 32px;
		background-color: #ebebeb;
	}
	.menu__club,
	.menu__logo {
		display: inline-block;
	}
	.menu__login,
	.menu__social,
	.menu__close,
	.menu__user,
	.logo-white {
		display: none;
	}
}

@media (min-width: 1080px) and (hover: none) {
	.menu__link .menu__link-all {
		display: block;
	}
	.menu__link .accordion-trigger {
		pointer-events: none;
	}
}

@media (min-width: 1680px) {
	.menu__link > li > a {
		padding: 0 26px;
	}
}

.header {
	position: relative;
	z-index: 10;
	background-color: #fff;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	width: 100%;
}

.header--over {
	position: absolute;
}

.header--fixed {
	position: fixed;
}

.header--dashboard .header__mobile {
	text-align: inherit;
}
.header--dashboard .header__mobile h1 {
	display: inline-block;
	color: #004c99;
}
.header--dashboard .header__mobile img {
	height: 28px;
	margin-left: 16px;
	margin-right: 16px;
}

.header--dashboard .topbar {
	margin-bottom: 0;
}

.header--dashboard .dashboard-menu__header {
	display: none;
}

.header--transparent {
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.header--transparent .input--search:before {
	color: #fff;
}
.header--transparent .input--search input {
	background-color: transparent;
}
.header--transparent .menu .input--search.is-open {
	color: #fff;
	background-color: #013974;
	-webkit-box-shadow: -13px 0px 5px #013974, 13px 0px 5px #013974;
	box-shadow: -13px 0px 5px #013974, 13px 0px 5px #013974;
}
.header--transparent .menu .input--search input {
	color: #fff;
}
.header--transparent .header__mobile {
	background-color: transparent;
}
.header--transparent .header__mobile i {
	color: #fff;
}
.header--transparent .header__mobile .logo-white {
	display: inline;
}
.header--transparent .header__mobile .logo-blue {
	display: none;
}
.header--transparent .header__mobile .input--search:before {
	color: #fff;
}
.header--transparent .header__mobile .input--search.is-open {
	background-color: #fff;
}
.header--transparent .header__mobile .input--search.is-open:before {
	color: #ccc;
}

.header__wrapper {
	display: none;
}

.header__mobile {
	position: relative;
	height: 72px;
	line-height: 72px;
	text-align: center;
	width: 100%;
	background: #fff;
}
.header__mobile .logo-white {
	display: none;
}
.header__mobile img {
	height: 36px;
}
.header__mobile > i {
	position: absolute;
	right: 0;
	width: 72px;
	line-height: 72px;
	font-size: 20px;
	color: #7d93a8;
	cursor: pointer;
}
.header__mobile .input--expand {
	position: absolute;
	width: 72px;
	height: 100%;
	border-radius: 0;
}
.header__mobile .input--expand input {
	border-radius: 0;
}
.header__mobile .input--expand:before {
	left: 24px;
	font-size: 20px;
	color: #7d93a8;
}
.header__mobile .input--expand.is-open {
	width: 100%;
}

.header--site-club {
	max-height: 146px;
}
.header--site-club .header__wrapper.is-visible + .header__mobile {
	border-bottom: 1px solid #ccc;
}
.header--site-club .menu {
	padding-top: 16px;
	padding-bottom: 24px;
}
.header--site-club .menu__right .menu__link {
	display: none;
}

@media (max-width: 1079px) {
	.header__wrapper.is-visible {
		z-index: 201;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		position: fixed;
		top: 0;
		right: 0;
		height: 100%;
		width: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		background-color: #fff;
	}
	.header__wrapper.is-visible ~ .overlay {
		display: block;
	}
	.header__wrapper .topbar {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
	}
}

@media (max-width: 767px) {
	.header__wrapper.is-visible {
		top: 72px;
		height: calc(100% - 72px);
	}
	.header__wrapper.is-visible ~ .overlay {
		display: none;
	}
}

@media (min-width: 768px) {
	.header__wrapper {
		max-width: 375px;
	}
	.header--site-club .menu {
		padding-top: 56px;
	}
}

@media (min-width: 1080px) {
	.header__wrapper {
		display: block;
		max-width: none;
	}
	.header__mobile {
		display: none;
	}
	.header--transparent .menu {
		background: none;
	}
	.header--transparent .menu__link:hover > li > a {
		color: rgba(255, 255, 255, 0.6);
	}
	.header--transparent .menu__link > li > a {
		color: #fff;
	}
	.header--transparent .menu__link > li:hover > a {
		color: #fff;
		-webkit-box-shadow: 0 -4px 0 rgba(255, 255, 255, 0.32) inset;
		box-shadow: 0 -4px 0 rgba(255, 255, 255, 0.32) inset;
	}
	.header--transparent .menu__link > li:hover > .accordion-trigger {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.header--transparent .menu__link .is-active {
		-webkit-box-shadow: 0 -4px 0 rgba(255, 255, 255, 0.32) inset;
		box-shadow: 0 -4px 0 rgba(255, 255, 255, 0.32) inset;
	}
	.header--transparent .menu__club:after {
		background-color: rgba(255, 255, 255, 0.06);
	}
	.header--transparent .input--expand {
		-webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.32);
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.32);
	}
	.header--transparent .input--expand:hover {
		-webkit-box-shadow: 0 0 0 2px #fff;
		box-shadow: 0 0 0 2px #fff;
	}
	.header--transparent .logo-white {
		display: inline-block;
	}
	.header--transparent .logo-blue {
		display: none;
	}
	.header--dashboard .dashboard-menu {
		display: none;
	}
	.header--site-club {
		max-height: 146px;
	}
	.header--site-club .menu {
		padding-bottom: 0;
		padding-top: 0;
	}
	.header--site-club .menu > a {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.header--site-club .menu__link li a {
		margin: 0 20px;
	}
	.header--site-club .menu__club:after {
		display: none;
	}
	.header--site-club .menu__right {
		padding-right: 20px;
	}
	.header--site-club .menu__right .menu__link {
		display: inline-block;
	}
	.header--site-club .menu__right .menu__link--only {
		position: relative;
		left: 20px;
	}
	.header--site-club .menu__right .menu__link--only a {
		margin: 0;
	}
}

.footer {
	position: relative;
	color: rgba(255, 255, 255, 0.8);
	background-color: #002348;
	padding-top: 40px;
}
.footer h5 {
	color: #fff;
}
.footer ul {
	margin-bottom: 0;
}
.footer .checkbox {
	font-size: 12px;
	line-height: 20px;
}

.footer__top .btn--primary {
	width: 100%;
	height: 56px;
	line-height: 56px;
}

.footer__top .select {
	margin-bottom: 8px;
}

.footer__top hr {
	border: 0;
	border-bottom: 1px solid rgba(255, 255, 255, 0.06);
	margin: 40px 0;
}

.footer__links {
	padding-bottom: 40px;
}
.footer__links .col {
	margin-bottom: 12px;
}
.footer__links ul a:hover,
.footer__links ul a:focus {
	text-decoration: none;
}
.footer__links li {
	line-height: 32px;
	color: rgba(255, 255, 255, 0.6);
}
.footer__links a:hover,
.footer__links a:focus {
	color: #fff;
}

.footer__category {
	display: block;
	font-weight: 500;
	line-height: 2;
	color: #fff;
}
.footer__category.is-selected {
	margin-bottom: 8px;
}
.footer__category.is-selected + ul {
	margin-bottom: 16px;
}
.footer__category.is-selected:after {
	color: #2a9bff;
}
.footer__category:after {
	float: right;
	color: #7d93a8;
}

.footer__corporate {
	background-color: #002042;
	padding: 24px 0;
	color: #fff;
}

.footer__corporate-legal {
	margin-top: 16px;
	margin-bottom: 0;
	color: rgba(255, 255, 255, 0.32);
}

.footer__corporate-links a {
	color: rgba(255, 255, 255, 0.6);
}
.footer__corporate-links a:hover,
.footer__corporate-links a:focus {
	color: #fff;
	opacity: 1;
}

.footer__logo {
	text-align: center;
}
.footer__logo img {
	height: 69px;
}
.footer__logo img:last-of-type:not(:only-of-type) {
	display: none;
}
.footer__logo img.ligue {
	opacity: 0.4;
	max-width: 175px;
}

@media (min-width: 768px) {
	.footer__top form {
		position: relative;
	}
	.footer__top .input,
	.footer__top .select {
		display: inline-block;
		vertical-align: top;
		margin-bottom: 0;
	}
	.footer__top .select {
		width: 206px;
		margin-right: 16px;
	}
	.footer__top .input {
		width: 300px;
		margin-right: 32px;
	}
	.footer__top .btn--primary {
		position: absolute;
		top: 0;
		left: 555px;
		width: 180px;
	}
	.footer__category {
		font-size: 15px;
		font-weight: 700;
	}
	.footer__corporate {
		text-align: center;
	}
	.footer__corporate-links li {
		display: inline-block;
		margin: 0 16px;
	}
}

@media (min-width: 1280px) {
	.footer {
		padding-top: 48px;
	}
	.footer h5 {
		font-size: 20px;
		font-weight: 400;
	}
	.footer__top .select {
		width: 286px;
	}
	.footer__top .input {
		width: 390px;
	}
	.footer__top .btn--primary {
		left: 725px;
	}
	.footer__links {
		position: relative;
	}
	.footer__links .col:first-child {
		margin-left: 25%;
	}
	.footer__links .row {
		margin-top: 24px;
	}
	.footer__links .row:first-child {
		margin-top: 0;
	}
	.footer__links ul {
		padding: 0;
	}
	.footer__links .accordion-trigger:after {
		display: none;
	}
	.footer__links .accordion-panel {
		max-height: none;
		opacity: 1;
		visibility: visible;
	}
	.footer__logo {
		position: absolute;
		top: 0;
		left: 60px;
		text-align: left;
	}
	.footer__logo img {
		height: 110px;
	}
	.footer__logo img:first-child() {
		display: block;
	}
	.footer__logo img:nth-child(2) {
		display: none;
	}
	.footer__category {
		margin-bottom: 16px;
	}
	.footer__category i {
		display: none;
	}
	.footer__corporate {
		text-align: right;
		padding: 20px 0;
	}
	.footer__corporate .col {
		float: right;
	}
	.footer__corporate-legal {
		margin: 0;
		text-align: left;
	}
}

.pagination {
	font-weight: 400;
	text-align: center;
	margin-bottom: 0;
}
.pagination li {
	display: inline-block;
}
.pagination a {
	display: inline-block;
	vertical-align: middle;
	color: #ccc;
	width: 48px;
	height: 48px;
	line-height: 48px;
	border-radius: 4px;
	font-weight: 500;
	-webkit-transition: all 0.15s ease-out;
	transition: all 0.15s ease-out;
}
.pagination a:hover {
	color: #2a9bff;
	text-decoration: none;
}
.pagination a.is-active {
	background-color: #fff;
	color: #292929;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	pointer-events: none;
}
.pagination i {
	font-size: 10px;
}

.pagination__next a,
.pagination__prev a {
	color: #7d93a8;
}

.pagination__next.is-disabled,
.pagination__prev.is-disabled {
	opacity: 0.32;
	pointer-events: none;
}

.banner {
	position: relative;
	min-height: 300px;
	color: #fff;
	padding-top: 16px;
	padding-bottom: 96px;
}
.banner h1 {
	margin-top: 48px;
}
.banner h1.has-score {
	margin-top: 48px;
}
.banner h1 span {
	color: rgba(255, 255, 255, 0.6);
}
.banner .img-green:before {
	opacity: 0.95;
}
.banner .img-blue-dark:after,
.banner .img-blue-dark-60:after {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(5%, #003974),
		color-stop(38%, rgba(0, 57, 116, 0))
	);
	background: linear-gradient(-180deg, #003974 5%, rgba(0, 57, 116, 0) 38%);
	opacity: 0.7;
}
.banner .navbar {
	position: absolute;
	z-index: 4;
	bottom: 0;
	left: 0;
}

.banner--big {
	height: 560px;
	margin-bottom: -310px;
	padding-bottom: 0;
}
.banner--big:after {
	content: '';
	position: absolute;
	bottom: -1px;
	left: 0;
	width: 100%;
	height: 200px;
	background: url(../img/banner/mask.svg) no-repeat bottom center;
	background-size: cover;
}
.banner--big .img-blue-alt:before {
	opacity: 0.6;
}

.banner--faq {
	margin-bottom: -370px;
}

.banner--searchClub {
	height: 656px;
	margin-bottom: -48px;
	padding-bottom: 0;
}

.banner--search {
	min-height: 248px;
	padding-bottom: 24px;
}
.banner--search h1 {
	display: none;
}
.banner--search .input {
	margin-top: 48px;
}
.banner--search .input + p {
	color: #2077c5;
}
.banner--search .img-green:before {
	opacity: 1;
}
.banner--search ::-webkit-search-cancel-button {
	background-image: url(../img/icon-delete-black.svg);
	opacity: 0.4;
}

.banner--404 {
	margin-top: -129px;
	margin-bottom: -390px;
}

.banner--match {
	position: relative;
	padding-top: 86px;
	padding-bottom: 0;
	height: initial;
	min-height: 460px;
}
.banner--match.banner--bigNoAfter {
	height: 540px;
	margin-bottom: 0;
}
.banner--match .banner__button {
	display: inline-table;
	position: absolute;
	bottom: 86px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 2;
	padding: 0 22px;
}
.banner--match .banner__button .icon {
	margin-top: -1px;
}
.banner--match .banner__button--score {
	bottom: 110px;
}
.banner--match .banner__button--score.is-live {
	bottom: 86px;
}
.banner--match .breadcrumb {
	text-align: left;
}
.banner--match h1,
.banner--match h2 {
	font-size: 14px;
	line-height: 20px;
}
.banner--match h1 {
	font-weight: 800;
}
.banner--match h2 {
	opacity: 0.6;
	font-weight: 500;
}
.banner--match .bannner__infos {
	padding-bottom: 32px;
}
.banner--match .banner__infos span {
	display: block;
	margin-bottom: 8px;
	opacity: 0.6;
	font-weight: 800;
	text-align: center;
}
.banner--match .banner__infos span:last-child {
	margin-bottom: 0;
}
.banner--match hr {
	border: 1px solid #fff;
	border-bottom: 0;
	opacity: 0.2;
}
.banner--match .banner__scoreboard {
	display: block;
	text-align: center;
}
.banner--match .banner__scoreboard__image {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 0 auto;
	width: 70px;
	height: 70px;
	-webkit-box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	border: 4px solid #ebebeb;
	background-color: #fff;
}
.banner--match .banner__scoreboard__image img {
	max-height: 43px;
	max-width: 41px;
}
.banner--match .banner__scoreboard > div {
	display: inline-block;
	padding-top: 20px;
	vertical-align: top;
	width: 100px;
}
.banner--match .banner__scoreboard > div:nth-child(2) {
	width: 125px;
	padding-top: 32px;
	text-align: center;
}
.banner--match .banner__scoreboard > div:nth-child(2) > p {
	font-size: 20px;
	line-height: 22px;
}
.banner--match .banner__scoreboard .banner__scoreboard__score p {
	display: inline-block;
}
.banner--match .banner__scoreboard .banner__scoreboard__score p:nth-child(2) {
	font-size: 36px;
	line-height: 36px;
	font-weight: normal;
	margin-left: 8px;
	margin-right: 8px;
}
.banner--match .banner__scoreboard h2 {
	display: block;
	font-weight: bold;
}
.banner--match .banner__scoreboard h2:before {
	display: none;
}
.banner--match .banner__scoreboard .label--long {
	margin-top: 16px;
	padding-top: 8px;
	padding-bottom: 8px;
	max-width: 80px;
	white-space: normal;
	height: auto;
	line-height: 15px;
}

.banner--wButton {
	min-height: 530px;
}
.banner--wButton hr {
	margin-top: 72px;
	margin-bottom: 48px;
}
.banner--wButton hr.has-score {
	margin-top: 56px;
	margin-bottom: 40px;
}
.banner--wButton hr.is-live {
	margin-top: 56px;
	margin-bottom: 48px;
}

.banner__thumbnail {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	background: no-repeat center center;
	background-size: cover;
}

.banner__desc {
	font-size: 18px;
	line-height: 28px;
	color: rgba(255, 255, 255, 0.8);
	margin-top: 8px;
	max-width: 710px;
}

.banner-over.banner-over--themes .card,
.banner-over.banner-over--themes .news {
	margin-top: -84px;
}

.banner-over .card,
.banner-over .news {
	margin-top: -119px;
}

.banner--enterprise {
	height: 588px;
	margin-bottom: -215px;
}
.banner--enterprise h1 {
	margin-top: 56px;
}

@media (min-width: 768px) {
	.banner {
		min-height: auto;
		height: 300px;
		padding-bottom: 0;
		padding-top: 24px;
	}
	.banner h1 {
		font-size: 40px;
		line-height: 56px;
	}
	.banner__desc {
		font-size: 17px;
		line-height: 24px;
	}
	.banner--big {
		height: 572px;
		margin-bottom: -342px;
	}
	.banner--faq {
		margin-bottom: -358px;
	}
	.banner--searchClub {
		height: 740px;
		margin-bottom: -80px;
	}
	.banner--search {
		height: 248px;
	}
	.banner--center h1,
	.banner--center p {
		margin-left: 0;
	}
	.banner-over .card,
	.banner-over .news {
		margin-top: -135px;
	}
	.banner--wButton {
		min-height: inherit;
	}
	.banner--wButton hr {
		margin-top: 56px;
		margin-bottom: 32px;
	}
	.banner--wButton hr.is-live {
		margin-top: 56px;
		margin-bottom: 32px;
	}
	.banner--wButton hr.has-score {
		margin-top: 56px;
		margin-bottom: 32px;
	}
	.banner--match {
		text-align: center;
		height: 460px;
		padding-top: 86px;
	}
	.banner--match.banner--bigNoAfter {
		height: 460px;
		margin-bottom: 0;
	}
	.banner--match .banner__button,
	.banner--match .banner__button--score.is-live {
		bottom: 52px;
	}
	.banner--match h1 {
		margin-top: 48px;
	}
	.banner--match .banner__infos {
		position: relative;
	}
	.banner--match .banner__infos span {
		display: inline-block;
		margin-bottom: 0;
	}
	.banner--match .banner__infos span:first-child {
		text-align: left;
		position: absolute;
		left: 0;
	}
	.banner--match .banner__infos span:last-child {
		text-align: right;
		position: absolute;
		right: 0;
	}
	.banner--match h1,
	.banner--match h2 {
		display: inline-block;
		font-size: 14px;
		line-height: 20px;
	}
	.banner--match h2:before {
		content: '·';
		margin: 0 3px;
		font-size: 22px;
	}
	.banner--match .banner__scoreboard__image {
		width: 90px;
		height: 90px;
	}
	.banner--match .banner__scoreboard__image img {
		max-height: 55px;
		max-width: 52px;
	}
	.banner--match .banner__scoreboard > div {
		width: inherit;
	}
	.banner--match .banner__scoreboard > div h3 {
		margin-top: 40px;
	}
	.banner--match .banner__scoreboard > div:first-child {
		width: 200px;
		padding-top: 0;
	}
	.banner--match .banner__scoreboard > div:nth-child(2) {
		width: inherit;
		padding-top: 16px;
		margin-left: 48px;
		margin-right: 48px;
	}
	.banner--match .banner__scoreboard > div:nth-child(2) p {
		font-size: 30px;
		line-height: 32px;
	}
	.banner--match .banner__scoreboard > div:nth-child(2) .label {
		margin-top: 16px;
	}
	.banner--match .banner__scoreboard > div:last-child {
		width: 200px;
		padding-top: 0;
	}
	.banner--match .banner__scoreboard > div .banner__scoreboard__score p {
		font-size: 40px;
		line-height: 48px;
	}
	.banner--match .banner__scoreboard > div .banner__scoreboard__score p:nth-child(2) {
		margin-left: 24px;
		margin-right: 24px;
	}
	.banner--match .banner__scoreboard h2 + .label.label--long {
		margin-top: 8px;
	}
	.banner--match .banner__scoreboard .label--long {
		white-space: nowrap;
		overflow: auto;
		max-width: 100%;
	}
	.banner--enterprise {
		height: inherit;
		margin-bottom: -43px;
	}
	.banner--enterprise h1 {
		margin-top: 48px;
	}
}

@media (min-width: 1080px) {
	.banner .search-club {
		margin-left: 8.33333%;
	}
	.banner .search-club .btn {
		left: 726px;
		right: 0;
		width: 270px;
		padding: 0 32px;
		background-color: #fff;
		border-radius: 4px;
	}
	.banner .search-club .btn span {
		display: inline;
	}
	.banner .search-club .btn i {
		margin-right: 8px;
	}
	.banner .search-club .input input {
		padding-right: 24px;
	}
	.banner .search-club__autocompletion {
		width: 100%;
	}
	.banner--big {
		margin-bottom: -326px;
	}
	.banner--big:after {
		background-size: 100% 200px;
	}
	.banner--searchClub {
		margin-bottom: -56px;
	}
	.banner--faq {
		margin-bottom: -348px;
	}
	.banner--center h1,
	.banner--center p {
		margin-left: 16.66667%;
	}
	.banner--match {
		padding-top: 170px;
		height: 540px;
	}
	.banner--match.banner--bigNoAfter {
		height: 540px;
		margin-bottom: 0;
	}
	.banner--match hr {
		margin-bottom: 32px;
	}
	.banner--match h1 {
		margin-top: 48px;
	}
	.banner--match .banner__scoreboard__image {
		width: 110px;
		height: 110px;
	}
	.banner--match .banner__scoreboard__image img {
		max-height: 67px;
		max-width: 63px;
	}
	.banner--match .banner__scoreboard > div:nth-child(2) {
		margin-left: 100px;
		margin-right: 100px;
	}
	.banner--match .banner__scoreboard > div:first-child,
	.banner--match .banner__scoreboard > div:last-child {
		width: 244px;
	}
	.banner--match .banner__scoreboard > div h3 {
		margin-top: 24px;
		font-size: 30px;
	}
	.banner--match .banner__scoreboard > div .banner__scoreboard__score p {
		font-size: 48px;
		line-height: 56px;
	}
	.banner--match .banner__scoreboard > div .banner__scoreboard__score p:nth-child(2) {
		margin-left: 32px;
		margin-right: 32px;
	}
	.banner--match .banner__button,
	.banner--match .banner__button--score.is-live {
		bottom: 52px;
	}
	.banner--wButton hr {
		margin-top: 48px;
		margin-bottom: 32px;
	}
	.banner--wButton hr.is-live {
		margin-top: 48px;
		margin-bottom: 32px;
	}
	.banner--wButton hr.has-score {
		margin-top: 48px;
		margin-bottom: 32px;
	}
}

@media (min-width: 1280px) {
	.banner-over .card {
		left: -40px;
		width: calc(100% + 40px);
	}
}

.breadcrumb {
	list-style: none;
	margin-bottom: 0;
	color: #999;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.breadcrumb li {
	display: inline-block;
}
.breadcrumb li:after {
	content: '\e924';
	font-family: 'icon';
	font-size: 6px;
	position: relative;
	top: -1px;
	margin: 0 8px;
}
.breadcrumb li:last-child {
	color: #292929;
}
.breadcrumb li:last-child:after {
	display: none;
}
.breadcrumb a {
	display: inline-block;
}
.breadcrumb a:hover {
	color: #292929;
}
.breadcrumb.is-dark {
	color: rgba(255, 255, 255, 0.6);
}
.breadcrumb.is-dark li:last-child {
	color: #fff;
}
.breadcrumb.is-dark a:hover {
	color: rgba(255, 255, 255, 0.8);
}

.card {
	display: block;
	position: relative;
	width: 100%;
	height: 424px;
	overflow: hidden;
}
.card:hover {
	color: inherit;
}
.card:hover .img-blue:before {
	opacity: 0.7;
}
.card .icon-play--circle {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -24px;
	margin-top: -24px;
}
.card hr {
	border: 0;
	border-bottom: 1px solid #ebebeb;
	margin: 24px 0;
}

.card__thumbnail {
	position: absolute;
	top: 0;
	height: 240px;
	width: 100%;
	background: no-repeat center center;
	background-size: cover;
}
.card__thumbnail img {
	display: none;
}

.card__body,
.card__footer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 16px 24px;
}

.card__body {
	height: 184px;
}

.card__flex-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.card__title {
	margin-bottom: 8px;
}

.card__desc {
	color: #999;
}

.card__date {
	color: rgba(255, 255, 255, 0.8);
}

.card__meta {
	color: #7d93a8;
}
.card__meta i {
	width: 20px;
	font-size: 12px;
	top: -1px;
}

.card__url {
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 800;
	color: #ccc;
}

.card__type {
	position: absolute;
	top: 24px;
	left: 16px;
}

.card__social {
	position: absolute;
	top: 24px;
	right: 16px;
}
.card__social .label {
	margin-left: 4px;
}

.card__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.card--content:hover .card__body {
	height: 204px;
}

.card--content:hover .card__thumbnail {
	-webkit-transform: scale(1.02);
	transform: scale(1.02);
}

.card--content:hover .img-blue:before {
	opacity: 0.3;
}

.card--content .card__thumbnail {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.card--content .img-blue:before {
	opacity: 0;
}

.card--content .card__body {
	background-color: #fff;
	border-top: 4px solid #2a9bff;
	-webkit-transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.card--picture.card-player--compact .card__thumbnail--empty span {
	width: 56px;
	height: 50px;
}

.card--picture .img-blue:before {
	display: none;
}

.card--picture .card__thumbnail {
	height: 304px;
	background: no-repeat top center;
	background-size: cover;
}

.card--picture .card__thumbnail--empty {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #ebebeb;
}
.card--picture .card__thumbnail--empty span {
	display: block;
	width: 204px;
	height: 168px;
	background-size: 100%;
	background-repeat: no-repeat;
}

.card--picture .card__body {
	height: 120px;
}

.card--picture .card__title {
	color: #292929;
}

.card--pictureFavorite {
	height: 388px;
}
.card--pictureFavorite .card__body {
	height: 100px;
	background: #fff;
}
.card--pictureFavorite .card__footer .btn {
	position: absolute;
	top: 17px;
	right: 24px;
}

.card--compact {
	height: 353px;
}
.card--compact:hover .card__body {
	height: 133px;
}
.card--compact .card__body {
	height: 113px;
}

.card--simple {
	height: auto;
}
.card--simple .card__body,
.card--simple .card__footer {
	position: static;
	height: auto;
	padding: 24px;
}
.card--simple .card__body {
	padding-bottom: 16px;
}
.card--simple .card__footer {
	padding-top: 0;
}

.card--date {
	height: 324px;
}
.card--date .card__label {
	position: absolute;
	top: 16px;
	right: 16px;
}
.card--date:hover .card__body {
	height: 113px;
}
.card--date .card__body {
	padding: 20px 24px;
	height: 84px;
}
.card--date .card__title {
	margin-bottom: 4px;
	color: #292929;
}

.card--hub:hover .card__thumbnail,
.card--hub-alt:hover .card__thumbnail,
.card--billboard:hover .card__thumbnail,
.card--channel:hover .card__thumbnail {
	-webkit-transform: scale(1.02);
	transform: scale(1.02);
}

.card--hub:hover .img-blue-dark:before,
.card--hub-alt:hover .img-blue-dark:before,
.card--billboard:hover .img-blue-dark:before,
.card--channel:hover .img-blue-dark:before {
	opacity: 0.7;
}

.card--hub .card__thumbnail,
.card--hub-alt .card__thumbnail,
.card--billboard .card__thumbnail,
.card--channel .card__thumbnail {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	will-change: transform;
	border-radius: 4px;
}

.card--hub .img-blue,
.card--hub .img-blue:before,
.card--hub .img-blue:after,
.card--hub-alt .img-blue,
.card--hub-alt .img-blue:before,
.card--hub-alt .img-blue:after,
.card--billboard .img-blue,
.card--billboard .img-blue:before,
.card--billboard .img-blue:after,
.card--channel .img-blue,
.card--channel .img-blue:before,
.card--channel .img-blue:after {
	width: 100%;
	border-radius: 4px;
}

.card--hub .img-blue-dark:before,
.card--hub-alt .img-blue-dark:before,
.card--billboard .img-blue-dark:before,
.card--channel .img-blue-dark:before {
	opacity: 0.5;
}

.card--hub .img-blue-dark:after,
.card--hub-alt .img-blue-dark:after,
.card--billboard .img-blue-dark:after,
.card--channel .img-blue-dark:after {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(14%, rgba(0, 57, 116, 0)),
		color-stop(79%, #003974)
	);
	background: linear-gradient(-180deg, rgba(0, 57, 116, 0) 14%, #003974 79%);
	opacity: 0.72;
}

.card--hub .card__thumbnail {
	height: 100%;
}

.card--hub .card__body {
	position: relative;
	max-width: 300px;
	height: auto;
	padding-top: 32px;
	padding-bottom: 96px;
	color: #fff;
}

.card--hub .card__footer {
	padding: 24px;
}

.card--hub .card__desc {
	color: rgba(255, 255, 255, 0.8);
}

.card--hub.card--small {
	height: auto;
	min-height: 288px;
}

.card--billboard {
	height: 368px;
}
.card--billboard .card__thumbnail {
	height: 100%;
}
.card--billboard .card__body {
	height: auto;
	color: #fff;
	padding: 24px 16px;
}
.card--billboard .card__title {
	font-size: 28px;
	line-height: 32px;
	margin-bottom: 0;
}
.card--billboard .card__footer {
	display: none;
}

.card--hub-alt .card__thumbnail {
	height: 100%;
}

.card--hub-alt .card__body {
	height: auto;
	padding: 32px 32px 130px;
	color: #fff;
}

.card--hub-alt .card__footer {
	padding: 32px;
}

.card--hub-alt .card__desc {
	color: rgba(255, 255, 255, 0.8);
}

.card--hub-alt.card--small {
	height: auto;
	min-height: 288px;
}
.card--hub-alt.card--small .card__body {
	position: relative;
	padding: 32px;
}

.card--hub-alt.card--xSmall {
	min-height: 242px;
}

.card--channel {
	height: 516px;
}
.card--channel .card__thumbnail {
	height: 100%;
}
.card--channel .card__body {
	height: auto;
	padding: 24px 24px 32px;
	color: #fff;
}
.card--channel .card__title {
	min-height: 72px;
	margin-bottom: 0;
}
.card--channel .card__desc {
	color: rgba(255, 255, 255, 0.8);
}

.card--social {
	height: 376px;
	background-color: #002752;
}
.card--social .card__thumbnail {
	height: 180px;
}
.card--social .card__thumbnail + .card__body {
	height: 196px;
	padding: 16px 24px;
}
.card--social .card__body {
	height: 100%;
	padding: 24px 32px;
}
.card--social .card__desc {
	color: rgba(255, 255, 255, 0.8);
}
.card--social .card__desc strong {
	font-weight: 500;
	color: #fff;
}
.card--social .card__footer {
	background-color: #002348;
	color: #7d93a8;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 16px;
}
.card--social .card__social {
	top: 12px;
	right: 12px;
}

.card__stat {
	float: right;
	color: #fff;
}
.card__stat i {
	font-size: 12px;
	color: #7d93a8;
	margin-right: 8px;
}
.card__stat span {
	margin-left: 16px;
}

.card--user {
	height: 504px;
}
.card--user .card__thumbnail {
	height: 364px;
	background-position: top center;
}
.card--user .card__body {
	height: 140px;
	padding: 12px 16px 16px;
}
.card--user .card__desc {
	font-size: 10px;
	line-height: 14px;
}
.card--user .card__title {
	margin-bottom: 4px;
}
.card--user .card__footer {
	font-size: 12px;
	font-weight: 500;
	padding: 16px;
}
.card--user .card__footer i {
	color: #7d93a8;
}

a.card.card--competition:hover .link-chevron {
	color: #fff;
}
a.card.card--competition:hover .link-chevron i {
	color: #fff;
}

.card--competition {
	height: 432px;
}
.card--competition .card__thumbnail {
	height: 100%;
}
.card--competition .card__body {
	height: 100%;
	padding: 56px 24px 56px;
	color: #fff;
	text-align: center;
}
.card--competition .card__body > img {
	margin-bottom: 16px;
}
.card--competition .card__title {
	margin-bottom: 90px;
}
.card--competition .card__desc {
	color: rgba(255, 255, 255, 0.6);
}
.card--competition .icon-shield-alt {
	font-size: 56px;
	display: block;
	margin-bottom: 16px;
}
.card--competition ul {
	text-align: left;
}
.card--competition ul img {
	margin-right: 8px;
	border-radius: 2px;
}
.card--competition li {
	margin-bottom: 16px;
}
.card--competition.is-logged .card__thumbnail {
	background-position: center top;
}
.card--competition.is-logged .card__body {
	padding-top: 24px;
}
.card--competition.is-logged .card__body > img {
	float: left;
}
.card--competition.is-logged .card__title {
	float: left;
	margin-bottom: 109px;
	margin-left: 16px;
}
.card--competition.is-logged .card__desc {
	clear: both;
}
.card--competition.is-logged .card__footer {
	padding-top: 80px;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 57, 116, 0)), to(#003974));
	background: linear-gradient(to bottom, rgba(0, 57, 116, 0) 0%, #003974 100%);
}
.card--competition.has-club .card__title {
	margin-bottom: 70px;
}
.card--competition.has-club .card__desc {
	margin-bottom: 32px;
}
.card--competition.has-club .card__footer {
	padding-top: 70px;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 57, 116, 0.48)), to(#003974));
	background: linear-gradient(to bottom, rgba(0, 57, 116, 0.48) 0%, #003974 100%);
}
.card--competition.has-club ul {
	height: 80px;
}
.card--competition.card--small {
	height: auto;
}
.card--competition.card--small .card__body {
	position: relative;
}
.card--competition.card--competition--dashboard .card__body {
	text-align: center;
}
.card--competition.card--competition--dashboard .card__body > div img {
	float: left;
	margin-top: -2px;
}
.card--competition.card--competition--dashboard .card__title {
	float: none;
	margin-left: 0;
}
.card--competition.card--competition--dashboard .card__desc {
	text-align: center;
}
.card--competition.card--competition--dashboard ul {
	height: auto;
}
.card--competition.card--competition--dashboard li {
	font-size: 15px;
}
.card--competition.card--competition--dashboard li:nth-child(n + 5) {
	display: none;
}
.card--competition.card--competition--dashboard li:last-child {
	margin-bottom: 0;
}
.card--competition.card--competition--dashboard .card__footer {
	background: none;
	padding-top: 24px;
}
.card--competition.card--competition--dashboard.has-club .card__body {
	text-align: left;
}
.card--competition.card--competition--dashboard.has-club .card__body > img {
	margin-top: -2px;
}
.card--competition.card--competition--dashboard.has-club .card__title {
	padding-left: calc(32px + 8px);
}

.card--game {
	height: 432px;
}
.card--game .card__thumbnail {
	height: 100%;
}
.card--game .card__body {
	position: relative;
	height: 100%;
	padding: 32px 24px 88px;
	color: #fff;
	text-align: center;
}
.card--game .card__footer {
	padding-bottom: 24px;
}
.card--game .card__desc {
	color: #292929;
}
.card--game.card--smallBlue .card__desc {
	color: rgba(255, 255, 255, 0.6);
}
.card--game.card--small {
	height: auto;
}
.card--game.card--small .card__body {
	padding-top: 24px;
}
.card--game.card--small .card__team p {
	line-height: 22px;
	margin-bottom: 0;
}

.card__team {
	color: #fff;
	font-weight: 700;
	text-align: left;
	font-family: 'Din';
	margin-top: 8px;
}
.card__team p {
	width: 50%;
	min-height: 44px;
	margin-bottom: 24px;
	color: #fff;
}
.card__team p:last-child {
	text-align: right;
}

.card__status {
	color: rgba(255, 255, 255, 0.6);
}

.card--nextgame {
	height: auto;
}
.card--nextgame .card__thumbnail {
	height: 100%;
}
.card--nextgame .card__body {
	position: relative;
	height: 100%;
	padding: 32px 32px 32px;
	color: #fff;
	text-align: center;
}
.card--nextgame .card__footer {
	padding-bottom: 32px;
	position: relative;
	padding-top: 0;
}
.card--nextgame .card__title {
	display: none;
}
.card--nextgame .card__desc {
	color: rgba(255, 255, 255, 0.6);
}
.card--nextgame .match {
	position: relative;
	padding: 0;
	min-height: 40px;
	margin-bottom: 64px;
}
.card--nextgame .match__info {
	position: absolute;
	top: 100%;
	width: 100%;
	margin-top: 16px;
}
.card--nextgame .match__time {
	color: #fff;
}
.card--nextgame .match__club:first-child {
	margin-right: 20px;
	padding-right: 56px;
}
.card--nextgame .match__club:last-child {
	padding-left: 56px;
}
.card--nextgame .match__club img {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	border-radius: 100%;
	padding: 8px;
	background: #fff;
}

.card--club {
	min-height: 140px;
	height: auto;
}
.card--club:hover,
.card--club:focus {
	text-decoration: none;
}
.card--club:hover .card__title,
.card--club:focus .card__title {
	text-decoration: underline;
}
.card--club img {
	position: absolute;
	left: 16px;
	top: 50%;
	margin-top: -24px;
}
.card--club .card__body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: static;
	height: 100%;
	width: calc(100% - 80px);
	margin-left: 80px;
	border-left: 1px solid #ebebeb;
}
.card--club .card__body > div {
	width: 100%;
}
.card--club .card__title {
	margin-bottom: 2px;
	padding-right: 24px;
	font-size: 15px;
}
.card--club .label {
	margin: 8px 8px 0 0;
}
.card--club .card__position {
	display: none;
}
.card--club.card--small {
	height: 72px;
}
.card--club.card--small .label {
	display: none;
}
.card--club.card--small .card__position {
	display: block;
}
.card--club.card--small img {
	width: 32px;
	height: 32px;
	margin-top: -16px;
	left: 20px;
}
.card--club.card--small .card__body {
	width: calc(100% - 72px);
	margin-left: 72px;
}

.card--clubSmall .card__title {
	font-size: inherit;
	line-height: inherit;
}

.card--clubSmall .card__body {
	padding: 30px;
	padding-bottom: 22px;
	margin-left: 0;
	border-left: 0;
}
@media (max-width: 768px) {
	.card--clubSmall .card__body {
		width: 100%;
	}
}

.card--clubSmall .card__desc i {
	color: #7d93a8;
}

.card--clubSmall .card__desc--address i {
	position: relative;
	vertical-align: top;
	top: 5px;
}

.card--clubSmall .card__desc--address span {
	display: inline-block;
}

.card--clubSmall.box:hover {
	-webkit-box-shadow: 0 0 0 2px inset #2a9bff, 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 0 0 2px inset #2a9bff, 0 10px 16px rgba(51, 51, 51, 0.08);
}
.card--clubSmall.box:hover h4 {
	text-decoration: none;
}
.card--clubSmall.box:hover .card__position {
	background: #2a9bff;
	color: #fff;
}

.card__position {
	position: absolute;
	right: 16px;
	top: 16px;
	min-width: 24px;
	height: 24px;
	line-height: 26px;
	text-align: center;
	border-radius: 100%;
	background: #f0f2f5;
	color: #999;
}

.card--download {
	height: auto;
}
.card--download .card__body {
	position: relative;
	height: auto;
	padding: 24px 24px 88px;
}
.card--download .card__title {
	margin-bottom: 0;
}
.card--download .card__title a {
	margin-right: 8px;
}
.card--download .card__desc {
	color: #ccc;
}
.card--download .card__footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 16px 24px 24px;
}
.card--download .card__category {
	margin-bottom: 0;
	color: #2a9bff;
}
.card--download .label {
	top: -2px;
}
.card--download .share {
	margin-right: 8px;
}

.card--data {
	height: auto;
}
.card--data .card__thumbnail {
	height: 100%;
}
.card--data p {
	position: relative;
	width: 216px;
	height: 216px;
	margin: 24px auto;
	padding-top: 70px;
	border: 6px solid #fff;
	border-radius: 100%;
	color: rgba(255, 255, 255, 0.8);
	text-align: center;
}
.card--data p span {
	max-width: 170px;
	display: block;
	margin: 0 auto;
}
.card--data strong {
	display: block;
	font-size: 48px;
	line-height: 1;
	font-family: 'Din';
	color: #fff;
}

.card--find {
	height: auto;
}
.card--find .card__thumbnail {
	height: 100%;
}
.card--find .card__body {
	height: 100%;
	color: #fff;
	position: relative;
	padding: 24px 24px 96px;
}
.card--find .card__desc {
	color: #fff;
}
.card--find .card__footer {
	padding: 24px;
}

.card--product {
	height: 444px;
}
.card--product .card__thumbnail {
	height: 100%;
}
.card--product .card__thumbnail:before {
	content: '';
	position: absolute;
	display: block;
	bottom: 0;
	width: 100%;
	height: 300px;
	opacity: 0.48;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 35, 72, 0)), to(#002348));
	background: linear-gradient(to bottom, rgba(0, 35, 72, 0) 0%, #002348 100%);
}
.card--product .card__body {
	color: #fff;
	text-align: center;
}
.card--product .card__desc {
	color: #fff;
}
.card--product .card__title {
	font-size: 16px;
	padding: 0 16px;
}

.card--job {
	height: 284px;
}
.card--job .card__body {
	height: 100%;
}
.card--job .card__title,
.card--job .card__meta {
	margin-bottom: 16px;
}
.card--job .card__footer {
	background-color: #fff;
	border-top: 1px solid #ebebeb;
}
.card--job .card__footer:before {
	content: '';
	position: absolute;
	height: 60px;
	width: 100%;
	bottom: 101%;
	left: 0;
	opacity: 0.88;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
}

.card--job-alt {
	height: 270px;
}
.card--job-alt .card__title {
	height: 72px;
	overflow: hidden;
	word-wrap: initial;
}
.card--job-alt .card__body {
	padding: 24px;
}
.card--job-alt .card__meta {
	color: #999;
}
.card--job-alt i {
	color: #7d93a8;
}

.card--address {
	height: 188px;
	border-top: 4px solid #2a9bff;
	border-radius: 0 0 4px 4px;
}
.card--address .card__body {
	height: 100%;
}

.card--my-club {
	height: 212px;
}
.card--my-club .card__header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 86px;
	padding: 0 24px;
	border-bottom: 1px solid #ebebeb;
}
.card--my-club .card__header h3 {
	margin-left: 16px;
	margin-bottom: 0;
}
.card--my-club .card__body {
	padding: 16px 24px 24px 24px;
	height: auto;
}
.card--my-club .card__body span {
	margin-left: 20px;
}
.card--my-club .card__body p {
	display: block;
	font-size: 14px;
	line-height: 28px;
	margin-bottom: 0;
	color: #999;
}
.card--my-club .card__body p:last-child {
	margin-bottom: 0;
}
.card--my-club .card__body p i {
	width: 20px;
	color: #7d93a8;
	margin-right: 20px;
	font-size: 12px;
}
.card--my-club .card__body p a {
	display: inline-block;
}

.card--ticket {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: auto;
}
.card--ticket:not(.box) {
	border: 1px solid #ebebeb;
	background-color: #fff;
	border-radius: 4px;
}
.card--ticket .card__header {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 120px;
	padding: 24px 16px;
}
.card--ticket .transport {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: -40px;
}
.card--ticket .transport img:first-child {
	margin-right: 4px;
}
.card--ticket .transport span {
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	bottom: 24px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	font-size: 12px;
}
.card--ticket .transport i {
	color: #fff;
}
.card--ticket .formule .icon {
	display: block;
	margin-bottom: 16px;
	color: #2a9bff;
}
.card--ticket .formule .icon:last-child {
	margin-bottom: 0;
}
.card--ticket .formule[data-level='1'] .icon:nth-child(n + 2) {
	color: rgba(42, 155, 255, 0.24);
}
.card--ticket .formule[data-level='2'] .icon:nth-child(n + 3) {
	color: rgba(42, 155, 255, 0.24);
}
.card--ticket .card__body {
	position: relative;
	width: calc(100% - 120px);
	height: auto;
	padding: 24px 24px 24px 16px;
}
.card--ticket ul li:last-child {
	margin-bottom: 0;
}
.card--ticket ul li {
	position: relative;
	padding-left: 12px;
	margin-bottom: 8px;
}
.card--ticket ul li:before {
	position: absolute;
	content: '';
	left: 0;
	top: 12px;
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: #999;
}

.card--ticket--nolicense {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.card--ticket--nolicense .card__header {
	height: 180px;
	width: 100%;
	background-color: rgba(42, 155, 255, 0.08);
}
.card--ticket--nolicense .card__header i {
	color: #2a9bff;
	font-size: 80px;
}
.card--ticket--nolicense .card__body {
	width: 100%;
	padding: 16px;
}
.card--ticket--nolicense .card__body p {
	max-width: 548px;
}
.card--ticket--nolicense li {
	display: block;
}
.card--ticket--nolicense li:before {
	content: initial !important;
}
.card--ticket--nolicense li i {
	float: left;
	margin-right: 8px;
	margin-top: 4px;
	left: -11px;
}
.card--ticket--nolicense li p {
	padding-left: 16px;
}
.card--ticket--nolicense .btn {
	height: 56px;
	line-height: 56px;
}
.card--ticket--nolicense .input {
	margin-bottom: 8px;
}
.card--ticket--nolicense .icon-close {
	position: absolute;
	top: 16px;
	right: 16px;
	display: block;
	width: 16px;
	line-height: 16px;
	color: #999;
	font-size: 12px;
	cursor: pointer;
}

.card--partner {
	display: block;
	height: 330px;
	margin-bottom: 16px;
}
.card--partner .card__header {
	height: 150px;
	width: 100%;
}
.card--partner .card__header img {
	height: 55px;
}
.card--partner .card__body {
	width: 100%;
}

.card--offer {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.card--offer:hover .card--offer__buttons .btn--white {
	color: inherit;
}
.card--offer .card__header {
	height: 236px;
	width: 100%;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	padding: 0;
}
.carousel .card--offer .card__header {
	float: left;
	height: 224px;
}
.card--offer .card__thumbnail {
	position: static;
}
.card--offer .card__body {
	padding: 24px;
	width: 100%;
}
.card--offer li {
	margin-bottom: 24px;
	line-height: 16px;
}
.card--offer li i {
	float: left;
}
.card--offer li p {
	padding-left: 24px;
}
.card--offer i {
	color: #7d93a8;
}
.card--offer .card--offer__price {
	display: block;
	margin-bottom: 12px;
}
.card--offer .card--offer__price > p:first-child {
	margin-bottom: 3px;
}
.card--offer .card__footer {
	position: relative;
	padding: 24px;
	border-top: 1px solid #ebebeb;
	width: auto;
}
.card--offer .card--offer__buttons {
	float: left;
}
.card--offer .btn {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.card--offer .btn:last-child {
	margin-left: 8px;
}
.card--offer .btn.btn--white:hover {
	color: #2a9bff;
}
.card--offer.card--small {
	height: 224px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
.card--offer.card--small .card__header {
	width: 132px;
}
.card--offer.card--small .card__footer {
	position: absolute;
	border-top: 0;
	padding-bottom: 32px;
	left: 132px;
}
.card--offer.card--small .card__body {
	padding: 32px 24px 80px;
}
.carousel .card--offer .card__body {
	margin-left: 132px;
	width: calc(100% - 132px);
}

.card--offer--option.has-formule .card__footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 0;
}

.card--offer--option.has-formule .card--offer__price {
	padding: 16px 8px;
}
.card--offer--option.has-formule .card--offer__price:nth-child(2) {
	border-left: 1px solid #ebebeb;
}

.card--offer--option .card--offer--option__duration {
	position: absolute;
	right: 24px;
	top: calc(24px + 3px);
}
.card--offer--option .card--offer--option__duration i {
	float: left;
	margin-top: 2px;
	font-size: 11px;
}
.card--offer--option .card--offer--option__duration p {
	display: inline;
}

.card--offer--option .card__footer {
	padding: 16px 24px;
}

.card--offer--option .card--offer__price {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	width: 100%;
}
.card--offer--option .card--offer__price > p:first-child {
	margin-bottom: 0;
}

.card--video {
	height: 216px;
}
.card--video:hover .img-blue-dark:before {
	opacity: 0.4;
}
.card--video p,
.card--video h5 {
	margin: 0;
}
.card--video h5 {
	margin-top: 8px;
}
.card--video .img-blue-dark:before {
	opacity: 0;
}
.card--video .img-blue-dark:after {
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(1, 57, 116, 0)), to(#013974));
	background: linear-gradient(to bottom, rgba(1, 57, 116, 0) 0%, #013974 100%);
	background-repeat: repeat-y;
	opacity: 0.82;
}
.card--video .card__thumbnail {
	height: 100%;
	border-radius: 4px;
}
.card--video .card__footer {
	padding: 16px;
	color: #fff;
}
.card--video .link-icon {
	float: left;
}
.card--video .card__type {
	top: 16px;
}

.card--result .card__header {
	padding: 16px 24px;
	border-bottom: 1px solid #ebebeb;
}

.card--result .card__body {
	position: relative;
	height: auto;
	padding-top: 24px;
}
.card--result .card__body > p {
	margin-bottom: 0;
}

.card--result .card__footer {
	padding: 0;
	border-top: 1px solid #ebebeb;
}

.card--result .match {
	padding: 0;
	height: auto;
	min-height: 40px;
	margin-bottom: 8px;
	font-size: 15px;
}

.card--result .match__score {
	font-size: 20px;
}

.card--result .ft-h6 {
	color: #f2362f;
}

.card--result .ft-center {
	color: #999;
}

.card--resultSmall {
	height: auto;
	padding-bottom: 56px;
}
.card--resultSmall .ft-h6 {
	color: #6bad46;
}
.card--resultSmall .ft-center {
	color: #999;
}

.card--competitionInfos {
	height: inherit;
	min-height: 285px;
	max-height: 390px;
}
.card--competitionInfos:hover {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}
.card--competitionInfos .card__thumbnail {
	position: inherit;
	height: 230px;
}
.card--competitionInfos .card__thumbnail__content {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	width: 75%;
}
.card--competitionInfos .card__thumbnail__content h4 {
	margin-bottom: 8px;
	color: #fff;
}
.card--competitionInfos .card__footer {
	position: inherit;
}
.card--competitionInfos .card__body {
	position: inherit;
	height: 103px;
}

.card--license {
	height: auto;
	width: 100%;
	max-width: 376px;
}
.card--license .card__header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 65px;
	padding: 0 16px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.card--license .card__header > div {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 16px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.card--license .card__header .ft-h5 {
	font-size: 14px;
}
.card--license .card__body {
	position: relative;
	height: auto;
	padding: 8px 16px;
}
.card--license .card__body > div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 29px;
}
.card--license .card__body > div img {
	margin-right: 8px;
	width: 20px;
	height: 20px;
	border-radius: 2px;
	overflow: hidden;
}
.card--license .card__body > div p {
	font-family: 'Din';
	font-size: 12px;
}
.card--license .card__body > hr {
	margin: 8px 0;
}

.card--nextGameWhite .card__body {
	top: 0;
	height: calc(100% - 54px);
	padding: 24px;
}
.card--nextGameWhite .card__body h3 {
	margin-bottom: 24px;
}
.card--nextGameWhite .card__body p:nth-child(1) {
	color: #f2362f;
	margin-bottom: 5px;
}
.card--nextGameWhite .card__body p:nth-child(2) {
	color: #999;
	line-height: inherit;
}
.card--nextGameWhite .card__body p:nth-child(5) {
	color: #999;
}
.card--nextGameWhite .card__body img {
	margin-right: 18px;
	height: 24px;
	width: 24px;
}
.card--nextGameWhite .card__body a:not(.btn):hover {
	color: inherit;
	text-decoration: none;
}
.card--nextGameWhite .card__body .btn {
	margin-top: auto;
}

.card--nextGameWhite .card__footer {
	border-top: 1px solid #ebebeb;
}

.card--nextGameFull {
	text-decoration: none;
	height: inherit;
}
.card--nextGameFull:hover {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
}
.card--nextGameFull:hover .card__body > a {
	color: inherit;
}
.card--nextGameFull:focus {
	text-decoration: none;
}
.card--nextGameFull a:hover {
	text-decoration: none;
}
.card--nextGameFull .card__header {
	padding: 16px;
	padding-left: 24px;
	border-bottom: 1px solid #ebebeb;
}
.card--nextGameFull .card__header span:first-child {
	color: #f2362f;
	margin-right: 8px;
}
.card--nextGameFull .card__header span:last-child {
	color: #999;
}
.card--nextGameFull .card__body {
	height: 100%;
	position: inherit;
	padding: 24px;
}
.card--nextGameFull .card__body p:first-child {
	margin-bottom: 24px;
}
.card--nextGameFull .card__body .ft-h6 {
	color: #999;
}
.card--nextGameFull .card__body img {
	margin-right: 24px;
	height: 40px;
	width: 40px;
}
.card--nextGameFull .btn--white {
	white-space: nowrap;
}
.card--nextGameFull .icon-place {
	top: -2px;
}
.card--nextGameFull__button {
	width: calc(100% - 48px);
	margin: 0 0 24px 24px;
}
.card--nextGameFull__address {
	margin-bottom: 0;
}
.card--nextGameFull__address .icon {
	height: 30px;
	top: 2px;
}
.card--nextGameFull__address span {
	display: inline-block;
	vertical-align: top;
}

.card--nextGameFull--smallBody .card__body {
	padding: 32px 24px;
}
.card--nextGameFull--smallBody .card__body p:first-child {
	margin-bottom: 0;
}

.card--noHover {
	-webkit-box-shadow: 0 0 0 1px #ebebeb;
	box-shadow: 0 0 0 1px #ebebeb;
}
.card--noHover:hover {
	-webkit-box-shadow: 0 0 0 1px #ebebeb;
	box-shadow: 0 0 0 1px #ebebeb;
}

.card--teamStats {
	height: auto;
}
.card--teamStats .card__header {
	position: relative;
	padding: 16px 24px;
	border-bottom: 1px solid #ebebeb;
	font-family: 'Din';
}
.card--teamStats .card__header h3 {
	font-size: 14px;
}
.card--teamStats .card__header span {
	position: absolute;
	right: 24px;
	top: 19px;
	font-size: 12px;
	color: #999;
}
.card--teamStats .card__body {
	height: auto;
	position: inherit;
}
.card--teamStats .card__body > div {
	display: block;
	width: 100%;
}
.card--teamStats .card__body > div .ft-up {
	color: #999;
}
.card--teamStats .card__body > div span {
	display: inline-block;
	width: 51px;
	font-size: 27px;
	margin-right: 20px;
	font-family: 'Din';
}
.card--teamStats .card__body > div div {
	position: relative;
	display: inline-block;
	width: 72%;
	height: 8px;
	background: #ccc;
	vertical-align: bottom;
	margin-bottom: 17px;
	border-radius: 4px;
}
.card--teamStats .card__body > div div span {
	width: 40%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #2a9bff;
	border-radius: 4px;
}

.card--smallBlue .card__team p {
	color: #fff;
}

.card--smallBlue p {
	color: rgba(255, 255, 255, 0.6);
}

.card--actus {
	height: auto;
}
.card--actus:hover .img-blue:before {
	opacity: 0;
}
.card--actus .news:last-of-type div:last-child {
	border-bottom: 1px;
}
.card--actus.has-accordion-open .news:last-of-type div:last-child {
	border-bottom: 1px solid #ebebeb;
}
.card--actus .card__body {
	position: inherit;
	height: auto;
	padding: 24px;
	padding-bottom: 0;
}
.card--actus .card__body .news:last-child div:last-child {
	border-bottom: 0;
}
.card--actus .card__footer {
	position: inherit;
	border-top: 1px solid #ebebeb;
	padding: 0;
}
.card--actus .card__footer a {
	display: block;
	height: 58px;
	line-height: 58px;
}
.card--actus .card__footer a:hover {
	text-decoration: none;
}
.card--actus .card__footer a:hover span:nth-child(2),
.card--actus .card__footer a:hover span:nth-child(3) {
	color: #2a9bff;
}
.card--actus .card__footer a span:nth-child(2),
.card--actus .card__footer a span:nth-child(3) {
	margin-left: 5px;
	color: #7d93a8;
}
.card--actus .card__footer a span:nth-child(3) {
	display: none;
}
.card--actus .card__footer a:after {
	display: none;
}
.card--actus .card__footer a.is-selected span:nth-child(2) {
	display: none;
}
.card--actus .card__footer a.is-selected span:nth-child(3) {
	display: inline-block;
}

.card--match {
	height: auto;
	padding: 24px;
	background-color: #fff;
	border: 1px solid #ebebeb;
	border-top: 0;
	cursor: pointer;
}
.card--match div {
	font-family: 'Din';
	display: inline-block;
}
.card--match div:last-child {
	display: none;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 24px;
}
.card--match div img:nth-child(1) {
	margin-right: 16px;
}
.card--match div img:last-child {
	margin-left: 16px;
}

.card--exp {
	padding: 24px;
	border: 1px solid #ebebeb;
	border-radius: 4px;
	background: #fcfcfc;
	height: auto;
}
.card--exp i {
	margin-bottom: 16px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 2px solid #999;
	background: rgba(153, 153, 153, 0.08);
	color: #999;
	line-height: 38px;
	font-size: 12px;
}
.card--exp p {
	color: #999;
	margin-bottom: 0;
	font-size: 14px;
}
.card--exp p a {
	text-decoration: underline;
}
.card--exp p br {
	display: none;
}

.card--sidebar {
	height: 316px;
}
.card--sidebar .card__thumbnail {
	height: 136px;
}
.card--sidebar .card__body {
	height: 180px;
}

.card--link.card--content:hover .card__body {
	height: 258px;
}

.card--link.card--content:hover .card__thumbnail {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.card--link .card__thumbnail {
	height: 166px;
}

.card--link .card__body {
	border: 0;
	height: 258px;
	padding-top: 24px;
}

.card--link .card__footer {
	padding-bottom: 24px;
}

.card--linkSidebar {
	height: 258px;
}
.card--linkSidebar .card__thumbnail {
	display: none;
}
.card--linkSidebar .card__body {
	height: 258px;
}

.card--news .card__body {
	top: 0;
	bottom: 54px;
	height: auto;
}

.card--news hr {
	margin: 16px 0;
}

.card--news .news {
	margin: 0;
	padding: 16px 0;
	border-bottom: 1px solid #ebebeb;
}
.card--news .news:last-child {
	border-bottom: none;
}
.card--news .news span {
	display: inline-block;
}
.card--news .news p {
	display: inline-block;
	margin-left: 8px;
	color: #999;
}

.card--news .card__footer {
	height: 54px;
	padding: 0;
	border-top: 1px solid #ebebeb;
}

.card--news-direct {
	background-color: #fff;
}
.card--news-direct .card__head {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 16px 0;
}
.card--news-direct .dropdown {
	margin-top: 8px;
}
.card--news-direct .dropdown .dropdown__head {
	margin-top: 8px;
	padding-right: 0;
}
.card--news-direct .box {
	padding: 16px;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
}
.card--news-direct .link-chevron {
	display: none;
}

.card--news-direct-container {
	z-index: 2;
}
.card--news-direct-container .container {
	padding: 0;
}
.card--news-direct-container .card--news-direct .dropdown {
	margin-top: 0px;
}
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance: none) {
		.card--news-direct-container .card--news-direct .dropdown .dropdown__head {
			margin-top: 0;
		}
	}
}

.card--site-choice {
	position: relative;
	height: 320px;
	text-align: center;
}
.card--site-choice .card__body {
	height: 100%;
	padding-top: 24px;
	padding-bottom: 96px;
}
.card--site-choice .card__footer {
	padding-bottom: 32px;
}
.card--site-choice p {
	max-width: 75%;
	margin-left: auto;
	margin-right: auto;
	line-height: 28px;
}
.card--site-choice img {
	height: 72px;
	width: 72px;
}

.card--centenaire {
	position: relative;
	background: transparent;
}
.card--centenaire .card__social {
	z-index: 10;
	position: absolute;
	top: 18px;
	right: 18px;
	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	opacity: 0;
	-webkit-transform: translateY(3px);
	transform: translateY(3px);
}
.card--centenaire:hover .card__social {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.card--centenaire .card__video {
	position: absolute;
	top: 0;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 57, 116, 0)), to(#003974));
	background-image: linear-gradient(180deg, rgba(0, 57, 116, 0) 0%, #003974 100%);
	width: 100%;
	height: calc(100% - 90px);
	z-index: 2;
}
.card--centenaire .card__video .link-icon {
	position: relative;
	z-index: 2;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	margin-right: 0;
	width: 60px;
	height: 60px;
}
.card--centenaire .card__video .link-icon i {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.card--centenaire .card__container {
	position: static;
	height: 100%;
	text-decoration: none;
}
.card--centenaire .card__thumbnail {
	display: block;
	position: inherit;
	height: auto;
}
.card--centenaire .card__club {
	overflow: hidden;
	position: relative;
	background-color: #002752;
	height: 40px;
	line-height: 40px;
	color: #fff;
	padding-left: 24px;
	text-transform: uppercase;
	font-size: 12px;
	z-index: 3;
}
.card--centenaire .card__club i {
	position: relative;
	top: -1px;
	color: #7d93a8;
	margin-right: 8px;
}
.card--centenaire .card__club span {
	position: relative;
	top: 2px;
}
.card--centenaire .card__club span:first-child {
	margin-right: 24px;
}
.card--centenaire .card__footer {
	position: inherit;
	background-color: #002348;
	color: #7d93a8;
	z-index: 3;
}
.card--centenaire .card__footer .card__stat i {
	font-size: 9px;
	position: relative;
	top: -1px;
}

.card--newsletter {
	height: auto;
}
.card--newsletter .card__thumbnail {
	height: 100%;
}
.card--newsletter .card__body {
	height: 100%;
	color: #fff;
	position: relative;
	padding: 24px;
}
.card--newsletter .card__desc {
	font-size: 16px;
	margin-bottom: 24px;
	color: rgba(255, 255, 255, 0.72);
}
.card--newsletter .select {
	margin-bottom: 8px;
}
.card--newsletter .is-dark {
	border-radius: 4px;
	background-color: #002348;
}
.card--newsletter .input input {
	color: rgba(255, 255, 255, 0.56);
}

@media (min-width: 768px) {
	.card--hub .card__body {
		padding-top: 48px;
	}
	.card--hub.card--small .card__body {
		padding-top: 32px;
		max-width: 480px;
	}
	.card--billboard {
		height: 380px;
	}
	.card--billboard .card__title {
		max-width: 400px;
		font-size: 30px;
		line-height: 36px;
	}
	.card--billboard .card__body {
		bottom: 87px;
		padding: 24px;
		padding-bottom: 0;
	}
	.card--billboard .card__type {
		left: 24px;
	}
	.card--billboard .card__social {
		right: 24px;
	}
	.card--billboard .card__footer {
		display: block;
		padding: 24px;
	}
	.card--club {
		min-height: 132px;
		height: auto;
	}
	.card--club .card__title {
		font-size: 17px;
		line-height: 22px;
	}
	.card--club .card__position {
		display: block;
	}
	.card--club.card--small .card__title {
		font-size: 15px;
		margin-bottom: 0;
	}
	.card--picture .card__thumbnail--empty span {
		width: 204px;
		height: 168px;
		max-width: 80%;
	}
	.card--pictureFavorite .card__body {
		height: 84px;
		-webkit-transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
	.card--pictureFavorite .card__footer {
		-webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
	.card--pictureFavorite .card__footer .btn {
		opacity: 0;
	}
	.card--pictureFavorite:hover .card__body {
		height: 100px;
	}
	.card--pictureFavorite:hover .card__footer .btn {
		opacity: 1;
	}
	.card--user {
		height: 444px;
	}
	.card--user .card__thumbnail {
		height: 304px;
	}
	.card--user .card__body {
		padding-top: 16px;
	}
	.card--user .card__footer {
		font-size: 14px;
	}
}

@media all and (min-width: 768px) and (-ms-high-contrast: none), (min-width: 768px) and (-ms-high-contrast: active) {
	.card--find .card__thumbnail.img-green {
		width: 99%;
		height: 99%;
		margin-top: 1px;
	}
	.card--find .card__thumbnail.img-green:before {
		width: 101%;
		height: 101%;
	}
}

@media (min-width: 768px) {
	.card--find.card--large .card__body {
		width: 60%;
		padding-bottom: 32px;
	}
	.card--find.card--large .card__footer {
		left: auto;
		right: 0;
		width: 40%;
		height: 100%;
		padding-top: 32px;
		text-align: right;
	}
	.card--find.card--large .btn {
		width: auto;
		padding: 0 32px;
	}
	.card--download.card--large {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		min-height: 104px;
	}
	.card--download.card--large .card__footer,
	.card--download.card--large .card__body {
		-ms-flex-item-align: center;
		align-self: center;
		padding: 24px;
	}
	.card--download.card--large .card__footer {
		position: relative;
		width: auto;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.card--download.card--large .btn--full {
		width: auto;
		padding: 0 32px;
	}
	.card--download.card--large .share__wrapper {
		left: auto;
		right: 0;
	}
	.card--download.card--large .share__wrapper .icon-delete {
		float: right;
	}
	.card--license .card__header {
		height: 80px;
	}
	.card--license .card__header > div {
		width: 40px;
		height: 40px;
	}
	.card--license .card__header .ft-h5 {
		font-size: 15px;
	}
	.card--license .card__body > div {
		height: 39px;
	}
	.card--license .card__body > div img {
		width: 24px;
		height: 24px;
	}
	.card--license .card__body > div p {
		font-size: 15px;
	}
	.card--nextgame {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		height: auto;
	}
	.card--nextgame .match {
		margin-bottom: 8px;
		height: auto;
		font-size: 15px;
	}
	.card--nextgame .match__info {
		position: static;
		margin: 0 16px;
		min-width: 120px;
		width: inherit;
	}
	.card--nextgame .match__club:first-child {
		margin-right: 0;
	}
	.card--nextgame .card__footer {
		padding-bottom: 0;
	}
	.card--nextgame.card--large .card__body {
		padding: 32px 16px;
	}
	.card--nextgame.card--large .card__footer {
		position: relative;
		width: auto;
		padding: 16px;
	}
	.card--nextgame.card--large .btn--full {
		width: auto;
		padding: 0 32px;
	}
	.card--nextGameFull__button:not(.prevent-float) {
		position: absolute;
		right: 24px;
		bottom: 24px;
		max-width: 50%;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0;
		width: auto;
	}
	.card--competition.card--competition--dashboard {
		height: 164px;
	}
	.card--competition.card--competition--dashboard .card__thumbnail {
		background-image: url('/src/img/card/bg-competition-tablet.jpg') !important;
		background-position: center center;
	}
	.card--competition.card--competition--dashboard .card__body {
		text-align: left;
		padding: 24px;
	}
	.card--competition.card--competition--dashboard .card__body h3 {
		margin-top: 16px;
	}
	.card--competition.card--competition--dashboard .card__body > img {
		display: block;
		float: none;
		margin-top: -4px;
		margin-bottom: 0;
		margin-left: 96px;
	}
	.card--competition.card--competition--dashboard .card__title {
		display: inline-block;
		margin-left: calc(8px * 8);
		margin-bottom: 32px;
	}
	.card--competition.card--competition--dashboard .card__desc {
		position: absolute;
		width: 46%;
		right: 0;
		top: 24px;
		text-align: left;
		padding-right: 24px;
	}
	.card--competition.card--competition--dashboard ul {
		position: absolute;
		right: 0;
		top: 24px;
		width: 45%;
	}
	.card--competition.card--competition--dashboard .card__footer {
		left: auto;
		right: 0;
		width: 46%;
		padding-left: 0;
		padding-bottom: 24px;
	}
	.card--competition.card--competition--dashboard.has-club {
		height: 236px;
	}
	.card--competition.card--competition--dashboard.has-club .card__body > img {
		display: inline-block;
		margin-left: calc(8px * 7);
	}
	.card--competition.card--competition--dashboard.has-club .card__desc {
		position: relative;
		top: 0;
		width: 27%;
		min-width: 270px;
		padding-right: 0;
		font-size: 17px;
		text-align: center;
	}
	.card--competition.card--competition--dashboard.has-club .card__title {
		padding-left: 0;
		margin-left: 8px;
	}
	.card--competition.card--competition--dashboard.has-club .card__footer {
		width: 45%;
		padding-bottom: 16px;
	}
	.card--hub-alt.card--xSmall {
		min-height: 212px;
	}
	.card--teamStats .card__header h3 {
		font-size: inherit;
	}
	.card--teamStats .card__body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.card--teamStats .card__body > div {
		max-width: 222px;
	}
	.card--teamStats .card__body > div span {
		width: auto;
	}
	.card--teamStats .card__body > div div {
		width: 120px;
	}
	.card--ticket .card__body {
		padding: 24px;
	}
	.card--partner {
		height: 370px;
		margin-bottom: 0;
	}
	.card--partner .card__header img {
		height: 65px;
	}
	.card--ticket--nolicense {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.card--ticket--nolicense .card__header {
		width: 180px;
		height: auto;
	}
	.card--ticket--nolicense .card__body {
		width: calc(100% - 180px);
		padding: 32px;
	}
	.card--ticket--nolicense .card__body > div {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.card--ticket--nolicense .input {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		margin-bottom: 0;
		margin-right: 8px;
	}
	.card--ticket--nolicense .btn {
		width: auto;
	}
	.card--offer {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.card--offer:hover .btn {
		visibility: visible;
		opacity: 1;
	}
	.card--offer .card__header {
		position: relative;
		height: auto;
		width: 236px;
	}
	.card--offer .card__thumbnail {
		height: 100%;
		width: 100%;
	}
	.card--offer .card__body {
		padding: 32px;
		padding-bottom: calc(108px + 24px);
	}
	.card--offer ul {
		overflow: auto;
	}
	.card--offer li {
		float: left;
		width: 50%;
	}
	.card--offer .card--offer__price {
		display: inline-block;
	}
	.card--offer .card__footer {
		position: absolute;
		left: 236px;
		right: 0;
		height: 108px;
		padding: 32px;
	}
	.card--offer .card--offer__buttons {
		float: right;
	}
	.card--offer .card--offer__buttons .btn {
		-webkit-transition: opacity 0.2s;
		transition: opacity 0.2s;
	}
	.card--offer.card--small .card__header {
		width: 248px;
	}
	.card--offer.card--small .card__footer {
		left: 248px;
	}
	.card--offer.card--small .card__body {
		padding: 32px 32px 80px;
	}
	.carousel .card--offer .card__body {
		margin-left: 248px;
		width: calc(100% - 248px);
	}
	.card--offer--option {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.card--offer--option.has-formule .card--offer__price {
		padding: 16px;
	}
	.card--offer--option .card__body {
		padding: 24px;
	}
	.card--offer--option .card__footer {
		position: relative;
		height: auto;
		padding: 16px 24px;
		left: 0;
	}
	.card--exp {
		position: relative;
	}
	.card--exp i {
		position: absolute;
		left: 24px;
		top: 50%;
		margin: -20px 0 0;
	}
	.card--exp p {
		max-width: 900px;
		padding-left: 64px;
	}
	.card--match div:last-child {
		display: block;
	}
	.card--news-direct-container .container {
		padding: 0;
	}
	.sidebar .card--news-direct {
		background-color: transparent;
	}
	.sidebar .card--news-direct:first-child {
		margin-top: -20px;
	}
	.sidebar .card--news-direct h3 {
		font-weight: 400;
	}
	.sidebar .card--news-direct .card__head {
		padding: 0;
	}
	.sidebar .card--news-direct .dropdown__head {
		color: #999;
	}
	.sidebar .card--news-direct .news {
		margin-bottom: 16px;
	}
	.sidebar .card--news-direct .news__thumbnail {
		width: 56px;
		height: 56px;
	}
	.sidebar .card--news-direct .news__title {
		word-wrap: break-word;
		max-height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.home-slider + .card--news-direct-container {
		position: absolute;
		top: 72px;
		right: 0;
		height: 485px;
	}
	.home-slider + .card--news-direct-container > .container {
		height: 100%;
	}
	.home-slider + .card--news-direct-container .card--news-direct {
		position: relative;
		float: right;
		width: 35vw;
		height: 100%;
		max-width: 600px;
		background-color: rgba(0, 35, 72, 0.48);
	}
	.home-slider + .card--news-direct-container .card--news-direct .box {
		-webkit-box-shadow: none;
		box-shadow: none;
		height: 435px;
		background-color: transparent;
		padding-bottom: 0;
	}
	.home-slider + .card--news-direct-container .card--news-direct .card__head {
		padding-top: 16px;
		color: #fff;
	}
	.home-slider + .card--news-direct-container .card--news-direct .dropdown.is-open {
		color: #292929;
	}
	.home-slider + .card--news-direct-container .card--news-direct .dropdown .dropdown__head {
		height: 20px;
		line-height: 20px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .dropdown .dropdown__head:after {
		margin-left: 8px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .news {
		color: #fff;
		margin-bottom: 8px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .news__title {
		word-wrap: break-word;
		max-height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.home-slider + .card--news-direct-container .card--news-direct .news__thumbnail {
		margin-left: 8px;
		width: 56px;
		height: 56px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .btn--primary {
		display: none;
	}
	.home-slider + .card--news-direct-container .card--news-direct .link-chevron {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: block;
		color: #fff;
		line-height: 26px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .link-chevron i {
		line-height: 26px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .link-chevron:hover {
		color: #2a9bff;
	}
	.card--site-choice p {
		max-width: 100%;
	}
	.card--site-choice .card__footer {
		padding-bottom: 24px;
	}
	.card--site-choice img {
		height: 76px;
		width: 76px;
	}
}

@media (min-width: 1080px) {
	.home-slider + .card--news-direct-container {
		top: 146px;
		height: 634px;
	}
	.home-slider + .card--news-direct-container .card--news-direct {
		height: 634px;
		width: 386px;
		background-color: rgba(0, 35, 72, 0.48);
	}
	.home-slider + .card--news-direct-container .card--news-direct .box {
		height: 564px;
		padding-top: 8px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .card__head {
		padding-top: 8px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .dropdown .dropdown__head {
		height: 48px;
		line-height: 50px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .news {
		margin-bottom: 20px;
	}
	.home-slider + .card--news-direct-container .card--news-direct .news__thumbnail {
		width: 80px;
		height: 80px;
	}
}

@media (min-width: 1280px) {
	.card--nextGameFull .card__body .ft-h6 {
		margin-bottom: 0;
	}
	.card--nextGameFull .btn--full {
		width: auto;
		position: absolute;
		right: 24px;
		bottom: 24px;
	}
	.card--hub .card__title {
		font-size: 40px;
		line-height: 48px;
	}
	.card--hub .card__body {
		padding-left: 32px;
	}
	.card--hub .card__footer {
		padding: 32px;
	}
	.card--hub.card--small .card__title {
		font-size: 30px;
		line-height: 36px;
	}
	.card--hub.card--small .card__body {
		padding-left: 24px;
	}
	.card--billboard {
		height: 504px;
	}
	.card--billboard .card__body,
	.card--billboard .card__footer {
		padding: 40px;
	}
	.card--billboard .card__body {
		padding-bottom: 0;
		bottom: 100px;
	}
	.card--billboard .card__title {
		max-width: 610px;
	}
	.card--billboard .card__type {
		top: 40px;
		left: 40px;
	}
	.card--billboard .card__social {
		top: 40px;
		right: 40px;
	}
	.card--billboard.is-small-desktop {
		height: 292px;
	}
	.card--billboard.is-small-desktop .card__body,
	.card--billboard.is-small-desktop .card__footer {
		padding: 24px;
	}
	.card--billboard.is-small-desktop .card__body {
		padding-bottom: 0;
		bottom: 88px;
	}
	.card--billboard.is-small-desktop .card__title {
		font-size: 20px;
		line-height: 24px;
		max-width: 400px;
	}
	.card--billboard.is-small-desktop .card__type {
		top: 24px;
		left: 24px;
	}
	.card--billboard.is-small-desktop .card__social {
		top: 24px;
		right: 24px;
	}
	.card--channel .card__body {
		padding-left: 32px;
		padding-right: 32px;
	}
	.card--partner {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		height: 216px;
	}
	.card--partner .card__header {
		width: 135px;
		height: auto;
	}
	.card--partner .card__header img {
		height: auto;
		max-width: 94px;
		max-height: 94px;
	}
	.card--partner .card__body,
	.card--partner .card__footer {
		width: calc(100% - 135px);
	}
	.card--partner .card__footer {
		left: auto;
		right: 0;
	}
	.card--date {
		height: 324px;
	}
	.card--date .card__thumbnail {
		height: inherit;
	}
	.card--nextgame .match {
		margin-bottom: 16px;
	}
	.card--nextgame.card--large-desktop .card__footer {
		position: relative;
		width: auto;
		padding: 16px;
	}
	.card--nextgame.card--large-desktop .btn--full {
		width: auto;
		padding: 0 32px;
	}
	.card--nextgame.card--large .card__title,
	.card--nextgame.card--large-desktop .card__title {
		display: block;
		position: relative;
		white-space: nowrap;
		padding: 40px;
		color: #fff;
		margin: 0;
	}
	.card--nextgame.card--large .card__body,
	.card--nextgame.card--large-desktop .card__body {
		padding: 24px 16px;
	}
	.card--nextgame.card--large .card__footer,
	.card--nextgame.card--large-desktop .card__footer {
		padding: 40px;
	}
	.card--teamStats .card__body > div div {
		width: 145px;
	}
	.card.card--nextGameWhite div:first-child h3 {
		margin-bottom: 16px;
	}
	.card.card--nextGameWhite div:first-child p:nth-child(1) {
		margin-bottom: 1px;
	}
	.card.card--nextGameWhite div:first-child img {
		height: 40px;
		width: 40px;
	}
	.card--offer .card__header {
		width: 498px;
	}
	.card--offer .card__footer {
		left: 498px;
	}
	.card--offer .btn {
		visibility: hidden;
		opacity: 0;
	}
	.card--offer--option .card__footer {
		left: 0;
	}
	.card--news-direct-container .container {
		max-width: calc(1680px - 90px);
	}
	.card--site-choice {
		height: 430px;
	}
	.card--site-choice .card__body {
		padding-top: 48px;
	}
	.card--site-choice p {
		max-width: 75%;
	}
	.card--site-choice img {
		height: 136px;
		width: 136px;
	}
}

@media (max-width: 1590px) {
	.home-slider + .card--news-direct-container .dropdown__list {
		left: auto;
		right: -16px;
	}
}

@media (min-width: 1590px) {
	.home-slider + .card--news-direct-container {
		right: calc((100vw - 1590px) / 2);
	}
	.home-slider + .card--news-direct-container:after {
		position: absolute;
		content: '';
		top: 0;
		left: 100%;
		width: calc((100vw - 1590px) / 2);
		height: 100%;
		background-color: rgba(0, 35, 72, 0.48);
	}
}

.card-site-club {
	cursor: pointer;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	height: 64px;
	background-color: #f4f9fe;
	border: 2px solid #e3ecf4;
	border-radius: 4px;
	padding: 0 24px;
	font-size: 14px;
	font-weight: 500;
}
@media (min-width: 1280px) {
	.card-site-club {
		font-size: 16px;
	}
}
.card-site-club > i {
	color: #7d93a8;
	margin-right: 16px;
}
.card-site-club > span {
	margin-right: 12px;
}
.card-site-club .card-site-club__check {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	width: 16px;
	height: 16px;
	background-color: #2a9bff;
	border-radius: 50%;
	margin-left: auto;
	position: relative;
}
.card-site-club .card-site-club__check i {
	color: #fff;
	font-size: 7px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.card-site-club--large {
	display: inline-block;
	width: calc(50% - 10px);
	height: 148px;
	vertical-align: top;
	line-height: 20px;
	text-align: center;
	padding: 0 24px;
}
@media (min-width: 768px) {
	.card-site-club--large {
		height: 116px;
		width: calc(50% - 7px);
	}
}
@media (min-width: 1280px) {
	.card-site-club--large {
		height: 80px;
		line-height: 80px;
		width: calc(50% - 16px);
		text-align: left;
	}
}
.card-site-club--large:nth-child(2n) {
	margin-left: 20px;
}
@media (min-width: 768px) {
	.card-site-club--large:nth-child(2n) {
		margin-left: 14px;
	}
}
@media (min-width: 1280px) {
	.card-site-club--large:nth-child(2n) {
		margin-left: 32px;
	}
}
.card-site-club--large > i {
	display: none;
}
.card-site-club--large .card-site-club__image {
	display: inline-block;
}
@media (min-width: 1280px) {
	.card-site-club--large .card-site-club__image {
		width: 60px;
		text-align: center;
		margin-right: 8px;
	}
}
.card-site-club--large .card-site-club__image img {
	margin-top: 36px;
}
@media (min-width: 768px) {
	.card-site-club--large .card-site-club__image img {
		margin-top: 20px;
	}
}
@media (min-width: 1280px) {
	.card-site-club--large .card-site-club__image img {
		margin-top: 0;
	}
}
.card-site-club--large > span {
	width: 100%;
	position: absolute;
	top: 90px;
	left: 0;
	display: block;
	margin-right: 0;
	margin-top: auto;
	padding: 0 8px;
}
@media (min-width: 768px) {
	.card-site-club--large > span {
		top: 68px;
		padding: 0 24px;
	}
}
@media (min-width: 1280px) {
	.card-site-club--large > span {
		position: static;
		display: inline-block;
		width: auto;
		padding-right: 8px;
		padding-left: 0;
		line-height: 20px;
		max-width: 75%;
		vertical-align: middle;
	}
}
.card-site-club--large .card-site-club__check {
	position: absolute;
	top: 10px;
	right: 10px;
}
@media (min-width: 1280px) {
	.card-site-club--large .card-site-club__check {
		top: calc(50% + 3px);
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		right: 30px;
	}
}
.card-site-club--large .tooltip {
	position: absolute;
	top: 5px;
	left: 10px;
}
@media (min-width: 1280px) {
	.card-site-club--large .tooltip {
		position: static;
		top: inherit;
		left: inherit;
	}
}
.card-site-club--no-selected {
	border: 1px solid #eeeeee;
	background-color: #fff;
	background: #ffffff;
	-webkit-box-shadow: 0 3px 8px 0 rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px 0 rgba(51, 51, 51, 0.08);
}
.card-site-club--no-selected .card-site-club__check {
	background-color: #fff;
	border: 2px solid #ebebeb;
}
.card-site-club--no-selected .card-site-club__check i {
	display: none;
}
@media (min-width: 1280px) {
	.card-site-club .card-site-club--large.card-site-club--no-selected .card-site-club__check {
		top: calc(50% + 1px);
	}
}
.card-site-club--edit {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: #fff;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 1px solid #ccc;
	cursor: default;
}
.card-site-club--edit .card-site-club__check {
	display: none;
}

.instagram-media,
.fb_iframe_widget,
.twitter-tweet {
	margin-bottom: 16px !important;
}

.instagram-media {
	min-width: auto !important;
	width: 100% !important;
}

.fb_iframe_widget,
.fb_iframe_widget > span,
.fb_iframe_widget iframe {
	min-width: auto !important;
	width: 100% !important;
}

.fb_iframe_widget iframe {
	background-color: #fff;
}

.event-top:hover,
.event-row:hover {
	color: inherit;
	text-decoration: none;
}
.event-top:hover .event__title,
.event-row:hover .event__title {
	color: #2a9bff;
	text-decoration: underline;
}

.event-top {
	display: block;
	overflow: hidden;
	position: relative;
	text-align: center;
	padding: 56px 16px 32px;
	height: 184px;
}
.event-top:before {
	content: attr(data-day);
	position: absolute;
	top: 0;
	font-size: 360px;
	font-weight: 700;
	line-height: 0.7;
	right: 50%;
	margin-right: -200px;
	pointer-events: none;
	opacity: 0.05;
}
.event-top:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	height: 135px;
	width: 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(white));
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 100%);
	pointer-events: none;
}
.event-top .label {
	position: absolute;
	top: 16px;
	left: 16px;
}
.event-top + .event-row {
	border-top: 1px solid #ebebeb;
}
.event-top .event__title {
	padding: 0 20px;
}

.event-row {
	display: table;
	min-height: 93px;
	width: 100%;
	border-bottom: 1px solid #ebebeb;
}
.event-row:last-child {
	border-bottom: none;
}
.event-row p {
	margin-bottom: 0;
}
.event-row .event__date,
.event-row .event__content {
	display: table-cell;
	vertical-align: middle;
}
.event-row .event__title {
	margin-bottom: 4px;
}
.event-row .event__date {
	width: 80px;
	border-right: 1px solid #ebebeb;
	text-align: center;
}
.event-row .event__content {
	padding: 8px 16px;
}
.event-row .btn {
	display: none;
}
.event-row .card--nextGameFull {
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
}
.event-row .card--nextGameFull .card__header {
	border: 0;
}
.event-row .card--nextGameFull .card__body {
	padding-top: 0;
}
.event-row .card--nextGameFull .card__body .card--nextGameFull__address {
	margin-bottom: 0;
}

.event-row--large .event__content {
	padding: 24px 16px 16px;
}

.event-row--delete .btn {
	display: block;
}

.event__content {
	position: relative;
	z-index: 1;
}

.event__date {
	line-height: 1;
}

.event__month,
.event__info {
	color: #999;
}
.event__month i,
.event__info i {
	font-size: 12px;
	color: #7d93a8;
	top: -1px;
}

.event__type {
	color: #f2362f;
}

.event__type,
.event__info {
	display: block;
	margin-bottom: 8px;
}
.event__type:last-child,
.event__info:last-child {
	margin-bottom: 0;
}

.event-single__info li {
	display: block;
	color: #999;
}
.event-single__info li:after {
	content: '';
	display: none;
	vertical-align: middle;
	height: 12px;
	width: 2px;
	background: #ebebeb;
	margin: 0 24px;
}
.event-single__info li:last-child {
	padding-right: 0;
	margin-right: 0;
}
.event-single__info li:last-child:after {
	display: none;
}

.event-single__info i {
	font-size: 12px;
	color: #7d93a8;
}

.event-single__content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.event-single__content img {
	margin-bottom: 32px;
}
.event-single__content .ft-secondary {
	line-height: 32px;
}
.event-single__content .btn {
	margin-top: 16px;
}
.event-single__content > div {
	width: 100%;
}

@media (min-width: 768px) {
	.event-row--large {
		min-height: 116px;
	}
	.event-row--large .event__content {
		padding: 24px 32px;
	}
	.event-row--large .event__title {
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 8px;
	}
	.event__type,
	.event__info {
		display: inline-block;
		margin-bottom: 0;
		vertical-align: top;
		line-height: 22px;
	}
	.event__type:after,
	.event__info:after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		height: 12px;
		width: 2px;
		background: #ebebeb;
		margin: 0 16px;
	}
	.event__type:last-child:after,
	.event__info:last-child:after {
		display: none;
	}
	.event-single__content .btn {
		width: auto;
		padding: 0 16px;
		margin-top: 16px;
		margin-right: 16px;
	}
	.event-single__content .btn:last-child {
		margin-right: 0;
	}
	.event-single__info li {
		display: inline-block;
	}
	.event-single__info li:after {
		display: inline-block;
	}
}

@media (min-width: 1280px) {
	.event-row .card--nextGameFull .btn {
		right: 24px;
		bottom: 24px;
		top: auto;
	}
	.event-row--large .event__content {
		padding: 24px 300px 24px 40px;
	}
	.event-row--large .event__date {
		width: 120px;
	}
	.event-row--large .btn {
		display: inline-block;
		position: absolute;
		right: 40px;
		top: 50%;
		margin-top: -24px;
	}
	.event__type:after,
	.event__info:after {
		margin: 0 24px;
	}
	.event-single__content {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
	.event-single__content img {
		margin-bottom: 0;
		margin-right: 32px;
	}
	.event-single__content > div {
		margin-top: 16px;
	}
	.event-single__content .btn {
		padding: 0 32px;
	}
}

.carousel .card {
	display: inline-block;
	vertical-align: top;
	width: 286px;
	margin-right: 8px;
	margin-bottom: -4px;
	white-space: normal;
}

.carousel .card--club {
	width: 303px;
	height: 72px;
}
.carousel .card--club .label {
	display: none;
}

.carousel .card--offer {
	width: 330px;
}

.carousel .card--channel {
	width: 320px;
}

.carousel .card--date {
	width: 316px;
}

.carousel__wrapper {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	white-space: nowrap;
	margin-left: -16px;
	margin-right: -16px;
	padding-left: 16px;
	padding-bottom: 16px;
	padding-top: 1px;
}

.carousel__nav {
	display: none;
}

.carousel-photo figure img {
	width: 100%;
}

@media (min-width: 768px) {
	.carousel .card {
		margin-right: 10px;
	}
	.carousel .card--club {
		width: 498px;
		height: 132px;
	}
	.carousel .card--club .label {
		display: inline-block;
	}
	.carousel .card--offer {
		width: 500px;
		margin-right: 32px;
	}
	.carousel .card--video {
		width: 320px;
		height: 224px;
		opacity: 1;
		-webkit-animation: fadeInCard 0.3s both cubic-bezier(0.25, 0.1, 0.25, 1);
		animation: fadeInCard 0.3s both cubic-bezier(0.25, 0.1, 0.25, 1);
	}
	@-webkit-keyframes fadeInCard {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	@keyframes fadeInCard {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	.carousel__nav {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
	}
	.carousel__nav a {
		margin-left: 8px;
	}
	.carousel__wrapper {
		overflow: initial;
		margin-left: 0;
		padding-left: 0;
		padding-bottom: 0;
	}
	.carousel--tv {
		position: relative;
	}
	.carousel--tv:hover .carousel__prev,
	.carousel--tv:hover .carousel__next {
		opacity: 1;
	}
	.carousel--tv .carousel__nav {
		position: static;
	}
	.carousel--tv .carousel__prev,
	.carousel--tv .carousel__next {
		position: absolute;
		z-index: 1;
		top: 50%;
		margin-top: -30px;
		margin-right: 0;
		opacity: 0;
		-webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		-webkit-transform-origin: center center;
		transform-origin: center center;
	}
	.carousel--tv .carousel__prev.is-disabled,
	.carousel--tv .carousel__next.is-disabled {
		display: none;
	}
	.carousel--tv .carousel__prev:hover,
	.carousel--tv .carousel__next:hover {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}
	.carousel--tv .carousel__prev:hover i,
	.carousel--tv .carousel__next:hover i {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	.carousel--tv .carousel__prev i,
	.carousel--tv .carousel__next i {
		-webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
	.carousel--tv .carousel__prev {
		left: 0;
	}
	.carousel--tv .carousel__next {
		right: 0;
	}
	.carousel--tv .carousel__wrapper {
		-ms-overflow-x: hidden;
	}
}

@media (min-width: 768px) and (hover: none) {
	.carousel .carousel__wrapper {
		overflow: auto;
		padding-bottom: 16px;
		margin-left: -16px;
		padding-left: 16px;
	}
	.carousel--tv .carousel__nav {
		display: none;
	}
}

@media (min-width: 1280px) {
	.carousel .card {
		margin-right: 32px;
	}
	.carousel .card--club {
		width: 498px;
		height: 132px;
	}
	.carousel .card--video {
		width: 392px;
		height: 292px;
	}
	.carousel .card--channel,
	.carousel .card--date {
		width: 392px;
	}
	.carousel__wrapper {
		margin-right: 0;
	}
	.carousel--tv .carousel__prev {
		left: -30px;
	}
	.carousel--tv .carousel__next {
		right: -30px;
	}
	.carousel-photo .news-slider__wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}

@media (min-width: 1280px) and (hover: none) {
	.carousel .carousel__wrapper {
		margin-left: 0;
		padding-left: 0;
	}
}

.live-feed .btn--primary {
	width: 100%;
	margin-top: 24px;
}

.live-feed .carousel__wrapper {
	padding-bottom: 0;
	-ms-overflow-x: hidden;
}

.live-feed__tag a {
	margin-right: 8px;
}

.live-feed__desc {
	color: rgba(255, 255, 255, 0.48);
}

@media (min-width: 768px) {
	.live-feed .btn--primary {
		width: auto;
		position: absolute;
		bottom: 4px;
	}
	.live-feed .link-icon {
		width: 40px;
		height: 40px;
		line-height: 36px;
	}
	.live-feed .link-icon i {
		font-size: 11px;
	}
	.live-feed__title {
		width: 60%;
	}
	.live-feed__desc {
		width: 95%;
	}
}

@media (min-width: 1280px) {
	.live-feed .link-icon {
		width: 48px;
		height: 48px;
		line-height: 44px;
	}
	.live-feed .link-icon i {
		font-size: 14px;
	}
	.live-feed__title {
		width: 100%;
		padding-right: 40px;
	}
	.live-feed__desc {
		width: 100%;
	}
}

.tab__links,
.tab-links {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.tab__links:after,
.tab-links:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	border-bottom: 4px solid #ebebeb;
}
.tab__links a,
.tab-links a {
	cursor: pointer;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	line-height: 52px;
	text-align: center;
	color: #999;
}
.tab__links a.is-active,
.tab__links a:focus,
.tab-links a.is-active,
.tab-links a:focus {
	color: #292929;
	text-decoration: none;
}
.tab__links a:hover,
.tab-links a:hover {
	text-decoration: none;
	color: #2a9bff;
}

.tab-links:after {
	border-radius: 4px;
}

.tab-links.is-dark:after {
	border-color: rgba(255, 255, 255, 0.32);
}

.tab-links.is-dark a {
	color: rgba(255, 255, 255, 0.6);
}
.tab-links.is-dark a.is-active,
.tab-links.is-dark a:focus,
.tab-links.is-dark a:hover {
	color: #fff;
}

.tab-links.is-dark .tab__indicator {
	border-radius: 4px;
	border-bottom: 0;
}

.tab__indicator {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	height: 4px;
	width: 50%;
	background-color: #2a9bff;
	border-radius: 0 4px 4px 0;
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.tab__indicator.on-right {
	border-radius: 4px 0 0 4px;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
}

.tab__wrapper {
	width: 200%;
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	-webkit-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}
.tab__wrapper.on-right {
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
}

.tab__content {
	width: 50%;
	float: left;
	padding-left: 48px;
	counter-reset: c;
	opacity: 0;
	-webkit-transition: opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: opacity 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.tab__content.is-active {
	opacity: 1;
}
.tab__content .news {
	counter-increment: c;
	margin-bottom: 0;
	padding: 16px 16px 16px 0;
	border-bottom: 1px solid #ebebeb;
}
.tab__content .news:before {
	content: counter(c, decimal-leading-zero) '.';
	margin-left: -22px;
	font-size: 12px;
	font-family: 'Din';
	font-weight: 700;
	line-height: 2;
	float: left;
	color: #999;
}
.tab__content .news:last-child {
	border-bottom: 0;
}

.partner,
.partenaire {
	position: relative;
	height: 144px;
	padding: 40px 0;
	margin-bottom: 0;
	background-color: #fff;
	text-align: center;
}

.partner--ligue li {
	display: inline-block;
	height: 100%;
	vertical-align: top;
	margin: 0 16px;
}
.partner--ligue li img {
	height: 100%;
}

.partner:not(.partner--ligue) li,
.partenaire:not(.partner--ligue) li {
	display: none;
	width: 64px;
	height: 64px;
	overflow: hidden;
	background: url(../img/partners/partner.png) no-repeat left center;
	background-size: auto 64px;
	margin: 0 16px;
	text-indent: -200%;
}
.partner:not(.partner--ligue) li:nth-child(1),
.partner:not(.partner--ligue) li:nth-child(2),
.partner:not(.partner--ligue) li:nth-child(3),
.partenaire:not(.partner--ligue) li:nth-child(1),
.partenaire:not(.partner--ligue) li:nth-child(2),
.partenaire:not(.partner--ligue) li:nth-child(3) {
	display: inline-block;
}
.partner:not(.partner--ligue) li:nth-child(1),
.partenaire:not(.partner--ligue) li:nth-child(1) {
	background-position: 0 0;
}
.partner:not(.partner--ligue) li:nth-child(2),
.partenaire:not(.partner--ligue) li:nth-child(2) {
	background-position: -64px 0;
}
.partner:not(.partner--ligue) li:nth-child(3),
.partenaire:not(.partner--ligue) li:nth-child(3) {
	background-position: -128px 0;
}
.partner:not(.partner--ligue) li:nth-child(4),
.partenaire:not(.partner--ligue) li:nth-child(4) {
	background-position: -192px 0;
}
.partner:not(.partner--ligue) li:nth-child(5),
.partenaire:not(.partner--ligue) li:nth-child(5) {
	background-position: -256px 0;
}
.partner:not(.partner--ligue) li:nth-child(6),
.partenaire:not(.partner--ligue) li:nth-child(6) {
	background-position: -320px 0;
}

.partner:not(.partner--ligue) a,
.partenaire:not(.partner--ligue) a {
	display: block;
	height: 100%;
	text-indent: -200%;
}

@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
	.partner:not(.partner--ligue) li {
		background-image: url(../img/partners/partner.png);
	}
}

@media (min-width: 768px) {
	.partner:not(.partner--ligue) li,
	.partenaire:not(.partner--ligue) li {
		margin: 0 32px;
		width: 48px;
		height: 48px;
		background-size: auto 48px;
	}
	.partner:not(.partner--ligue) li:nth-child(4),
	.partner:not(.partner--ligue) li:nth-child(5),
	.partner:not(.partner--ligue) li:nth-child(6),
	.partenaire:not(.partner--ligue) li:nth-child(4),
	.partenaire:not(.partner--ligue) li:nth-child(5),
	.partenaire:not(.partner--ligue) li:nth-child(6) {
		display: inline-block;
	}
	.partner:not(.partner--ligue) li:nth-child(2),
	.partenaire:not(.partner--ligue) li:nth-child(2) {
		background-position: -48px 0;
	}
	.partner:not(.partner--ligue) li:nth-child(3),
	.partenaire:not(.partner--ligue) li:nth-child(3) {
		background-position: -96px 0;
	}
	.partner:not(.partner--ligue) li:nth-child(4),
	.partenaire:not(.partner--ligue) li:nth-child(4) {
		background-position: -144px 0;
	}
	.partner:not(.partner--ligue) li:nth-child(5),
	.partenaire:not(.partner--ligue) li:nth-child(5) {
		background-position: -192px 0;
	}
	.partner:not(.partner--ligue) li:nth-child(6),
	.partenaire:not(.partner--ligue) li:nth-child(6) {
		background-position: -240px 0;
	}
}

@media (min-width: 1080px) {
	.partner:not(.partner--ligue) li,
	.partenaire:not(.partner--ligue) li {
		width: 64px;
		height: 64px;
		background-size: auto 64px;
	}
	.partner:not(.partner--ligue) li:nth-child(7),
	.partner:not(.partner--ligue) li:nth-child(8),
	.partenaire:not(.partner--ligue) li:nth-child(7),
	.partenaire:not(.partner--ligue) li:nth-child(8) {
		display: inline-block;
	}
	.partner:not(.partner--ligue) li:nth-child(2),
	.partenaire:not(.partner--ligue) li:nth-child(2) {
		background-position: -64px 0;
	}
	.partner:not(.partner--ligue) li:nth-child(3),
	.partenaire:not(.partner--ligue) li:nth-child(3) {
		background-position: -128px 0;
	}
	.partner:not(.partner--ligue) li:nth-child(4),
	.partenaire:not(.partner--ligue) li:nth-child(4) {
		background-position: -192px 0;
	}
	.partner:not(.partner--ligue) li:nth-child(5),
	.partenaire:not(.partner--ligue) li:nth-child(5) {
		background-position: -256px 0;
	}
	.partner:not(.partner--ligue) li:nth-child(6),
	.partenaire:not(.partner--ligue) li:nth-child(6) {
		background-position: -320px 0;
	}
}

.block-partner {
	display: block;
	width: 100%;
	border: 1px solid #ebebeb;
	-webkit-box-shadow: 0 3px 8px 0 rgba(41, 41, 41, 0.08);
	box-shadow: 0 3px 8px 0 rgba(41, 41, 41, 0.08);
	border-radius: 4px;
}
@media (min-width: 1280px) {
	.block-partner {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		min-height: 276px;
	}
}
.block-partner .block-partner__left {
	position: relative;
	width: 100%;
	height: 150px;
	background: rgba(15, 40, 66, 0.08);
}
@media (min-width: 1280px) {
	.block-partner .block-partner__left {
		width: 180px;
		height: initial;
		border-right: 1px solid #ebebeb;
		border-bottom: 0;
	}
}
.block-partner .block-partner__left img {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	height: 55px;
}
@media (min-width: 768px) {
	.block-partner .block-partner__left img {
		height: 65px;
	}
}
@media (min-width: 1280px) {
	.block-partner .block-partner__left img {
		height: auto;
		max-width: 112px;
		max-height: 112px;
	}
}
.block-partner.block-partner--detail .block-partner__left {
	width: 100%;
}
@media (min-width: 1280px) {
	.block-partner.block-partner--detail .block-partner__left {
		height: 278px;
	}
}
@media (min-width: 1280px) {
	.block-partner.has-address .block-partner__right .link-chevron:last-child {
		margin-left: 32px;
	}
}
.block-partner .block-partner__right {
	position: relative;
	width: 100%;
	padding: 24px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
@media (min-width: 1280px) {
	.block-partner .block-partner__right {
		width: calc(100% - 180px);
		padding: 24px 32px;
	}
}
.block-partner .block-partner__right h2 {
	font-size: 20px;
	line-height: 24px;
}
.block-partner .block-partner__right p,
.block-partner .block-partner__right .block-partner__right___address {
	color: #999;
	font-size: 14px;
	line-height: 22px;
	margin-bottom: 0;
}
@media (min-width: 768px) {
	.block-partner .block-partner__right .block-partner__right___address {
		margin-top: 16px;
	}
}
@media (min-width: 1280px) {
	.block-partner .block-partner__right .block-partner__right___address {
		margin-top: 8px;
	}
}
.block-partner .block-partner__right .link-chevron {
	cursor: pointer;
}
@media (min-width: 1280px) {
	.block-partner .block-partner__right .link-chevron {
		display: inline-block;
	}
}
.block-partner .block-partner__right .link-chevron .icon {
	margin-left: 16px;
}
@media (min-width: 1280px) {
	.block-partner .block-partner__right .block-partner__right__footer {
		margin-top: auto;
	}
}
.block-partner .block-partner__right .social {
	position: absolute;
	top: 24px;
	right: 24px;
}
@media (min-width: 768px) {
	.block-partner .block-partner__right .social {
		bottom: 24px;
		top: inherit;
	}
}
@media (min-width: 1280px) {
	.block-partner .block-partner__right .social {
		right: 32px;
	}
}
.block-partner .block-partner__right .social a {
	margin-left: 10px;
}
.block-partner .block-partner__right .social a:first-child {
	margin-left: 0;
}
.block-partner .block-partner__right .social a .label {
	-webkit-transition: background 0.2s;
	transition: background 0.2s;
}
.block-partner .block-partner__right .social a:hover .label {
	background: #5b6d7e;
}

.search-club.has-autocompletion .search-club__autocompletion,
.search-club.has-autocompletion .search-faq__autocompletion,
.search-faq.has-autocompletion .search-club__autocompletion,
.search-faq.has-autocompletion .search-faq__autocompletion {
	display: block;
}

.search-club .input,
.search-faq .input {
	z-index: 2;
}
.search-club .input input,
.search-faq .input input {
	border-radius: 4px;
	border-color: #ebebeb;
	padding-right: 72px;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
}
.search-club .input input:hover,
.search-club .input input:focus,
.search-faq .input input:hover,
.search-faq .input input:focus {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}
.search-club .input .icon-info,
.search-faq .input .icon-info {
	right: 56px;
}

.search-club .btn,
.search-faq .btn {
	position: absolute;
	z-index: 3;
	right: 0;
	top: 0;
	width: 56px;
	height: 56px;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.06);
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0 4px 4px 0;
}
.search-club .btn span,
.search-faq .btn span {
	display: none;
}

.search-club .search-club__club .list-label li {
	margin-right: 32px;
}

.search-club .input input {
	font-size: 16px;
}

.search-club .btn {
	padding: 0 8px;
	border-left-color: #e0e0e0;
}

.search-club__wrapper,
.search-faq__wrapper {
	position: relative;
	z-index: 5;
}

.search-club__city {
	display: none;
}

.search-club__autocompletion {
	top: 54px;
	padding: 24px;
}
.search-club__autocompletion div:first-of-type {
	border-bottom: 2px solid #ebebeb;
	margin-bottom: 16px;
	padding-bottom: 16px;
}

.search-faq__autocompletion {
	top: 53px;
	padding: 24px 16px 8px 16px;
}
.search-faq__autocompletion ul li {
	font-family: 'Roboto';
}
.search-faq__autocompletion ul li span {
	color: #2a9bff;
	font-weight: bold;
}

.search-club__autocompletion,
.search-faq__autocompletion {
	position: absolute;
	display: none;
	z-index: 1;
	left: 0;
	width: 100%;
	background-color: #fff;
	color: #292929;
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	border-radius: 0 0 4px 4px;
}
.search-club__autocompletion p,
.search-faq__autocompletion p {
	color: #999;
}
.search-club__autocompletion img,
.search-faq__autocompletion img {
	margin-right: 8px;
}
.search-club__autocompletion ul,
.search-faq__autocompletion ul {
	line-height: 32px;
}

@media (min-width: 768px) {
	.search-club.has-autocompletion .search-club__autocompletion,
	.search-club.has-autocompletion .search-faq__autocompletion,
	.search-faq.has-autocompletion .search-club__autocompletion,
	.search-faq.has-autocompletion .search-faq__autocompletion {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.search-club .input input,
	.search-faq .input input {
		height: 72px;
		padding-right: 96px;
	}
	.search-club .input .icon-info,
	.search-faq .input .icon-info {
		line-height: 72px;
		right: 72px;
	}
	.search-club .btn,
	.search-faq .btn {
		height: 72px;
		width: 72px;
	}
	.search-club__autocompletion {
		width: calc(100% - 72px);
		top: 70px;
	}
	.search-club__autocompletion div {
		width: calc(50% - 14px);
	}
	.search-club__autocompletion div:first-child {
		border-right: 2px solid #ebebeb;
	}
	.search-faq__autocompletion {
		top: 69px;
		padding: 24px 0 16px 48px;
	}
	.search-club__autocompletion div,
	.search-faq__autocompletion div {
		display: inline-block;
		vertical-align: top;
		min-height: 130px;
	}
	.search-club__autocompletion div:first-of-type,
	.search-faq__autocompletion div:first-of-type {
		margin: 0 24px 0 0;
		padding: 0 24px 0 0;
		border-bottom: 0;
	}
}

@media (min-width: 1280px) {
	.search-club .input input {
		border-width: 2px;
	}
	.search-club .input .icon-info {
		right: 0;
	}
	.search-club .col-lg-4 li {
		display: block;
		margin: 0 0 16px;
	}
	.search-club .col-lg-4 li:nth-child(n + 5) {
		display: none;
	}
	.search-club__wrapper {
		width: 710px;
	}
	.search-club__city {
		display: block;
	}
}

.block-label {
	padding: 16px 16px 8px;
}
.block-label .label {
	margin-right: 8px;
	margin-bottom: 8px;
}
.block-label p {
	color: #999;
	display: inline-block;
	margin-right: 16px;
}
.block-label ul {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 0;
}
.block-label li {
	display: inline-block;
}

@media (min-width: 768px) {
	.block-label {
		padding: 24px 24px 16px;
	}
}

.block-social {
	float: right;
}
.block-social a {
	margin-left: 6px;
}

.block-share {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.block-share .btn {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	margin-left: 16px;
}
.block-share .btn:first-child {
	margin-left: 0;
}
.block-share .icon {
	opacity: 1;
	margin-right: 0;
}
.block-share span {
	display: none;
}

.block-share--white .btn--mail i {
	font-size: 11px;
}

.block-share--white .icon {
	opacity: 1;
}

.block-share--stack {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.block-share--stack .btn {
	width: 100%;
	margin: 0 0 8px;
}
.block-share--stack .icon {
	margin-right: 8px;
	opacity: 0.48;
}
.block-share--stack span {
	display: inline;
}

@media (min-width: 768px) {
	.block-share--stack {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.block-share--stack .btn {
		margin-left: 16px;
	}
}

@media (min-width: 1280px) {
	.block-share .btn {
		width: 33%;
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
		margin-left: 24px;
	}
	.block-share .icon {
		margin-right: 8px;
		opacity: 0.48;
	}
	.block-share span {
		display: inline;
	}
	.block-share--white .icon {
		opacity: 1;
	}
}

.block-news {
	position: relative;
	padding: 24px 16px 64px;
}
.block-news .link-chevron {
	position: absolute;
	left: 16px;
	bottom: 24px;
}
.block-news .news:last-child {
	margin-bottom: 0;
}
.block-news .news article {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

@media (min-width: 768px) {
	.block-news {
		padding: 24px 24px 8px;
	}
	.block-news .link-chevron {
		position: static;
	}
	.block-news .news {
		display: inline-block;
		vertical-align: top;
		width: calc(50% - 8px);
		margin-bottom: 16px;
	}
	.block-news .news:nth-child(even) {
		margin-left: 16px;
	}
	.block-news .news article {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.block-news .news__thumbnail {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0;
		margin-left: 0;
		margin-right: 16px;
	}
}

@media (min-width: 1280px) {
	.block-news {
		padding: 32px 40px 40px;
	}
	.block-news .news {
		width: calc(25% - 30px);
		margin-bottom: 0;
		margin-right: 40px;
	}
	.block-news .news:nth-child(even) {
		margin-left: 0;
	}
	.block-news .news:last-child {
		margin-right: 0;
	}
}

.block-carousel {
	position: relative;
	padding: 16px 24px 24px;
}
.block-carousel .carousel__nav {
	top: 32px;
	right: 40px;
}

@media (min-width: 768px) {
	.block-carousel {
		padding: 40px;
	}
}

.block-competition {
	position: relative;
}
.block-competition .link-chevron--blue {
	margin-top: 24px;
}

.block-competition__head {
	color: #999;
}
.block-competition__head span {
	color: #ccc;
}

.block-competition__counter {
	float: right;
}

@media (min-width: 768px) {
	.block-competition .link-chevron--blue {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 0;
	}
}

.players-list .card-player--compact {
	height: 84px;
}
.players-list .card-player--compact .card__thumbnail {
	position: relative;
	float: left;
	height: 100%;
	width: 84px;
}
.players-list .card-player--compact .card__title {
	margin-bottom: calc(8px / 2);
}
.players-list .card-player--compact .card__desc {
	line-height: 18px;
}
.players-list .card-player--compact .card__body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	left: 84px;
	width: auto;
	padding: 16px 16px;
}
.players-list .card-player--compact h3 {
	font-size: 15px;
}

.players-list .col.mb-1:last-child {
	margin-bottom: 0;
}

.players-list--compact .card-player--compact {
	height: 84px;
}
.players-list--compact .card-player--compact .card__thumbnail {
	position: relative;
	float: left;
	height: 100%;
	width: 84px;
}
.players-list--compact .card-player--compact .card__body {
	height: 100%;
	left: 84px;
	width: auto;
	padding: 16px 16px;
}
.players-list--compact .card-player--compact h3 {
	font-size: 14px;
}

@media (min-width: 768px) {
	.players-list:not(.players-list--compact) .card-player--compact {
		height: 388px;
		border-radius: 4px;
	}
	.players-list:not(.players-list--compact) .card-player--compact .card__thumbnail {
		position: absolute;
		height: 304px;
		width: 100%;
	}
	.players-list:not(.players-list--compact) .card-player--compact .card__body {
		height: 100%;
		left: 0;
	}
	.players-list:not(.players-list--compact) .card-player--compact .card__title {
		margin-bottom: 8px;
	}
	.players-list:not(.players-list--compact) .card-player--compact h3 {
		font-size: 17px;
	}
	.players-list:not(.players-list--compact) .card-player--compact .card__body {
		height: 84px;
	}
}

@media (min-width: 1280px) {
	.players-list--compact .card-player--compact .card__body {
		padding: 16px 24px;
	}
}

.block-faq {
	width: 100%;
}
.block-faq__header div:first-child {
	display: none;
}
.block-faq__header .dropdown__head {
	height: 54px;
	line-height: 54px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom: 2px solid #ebebeb;
	padding: 0 16px;
}
.block-faq__header .dropdown__head:after {
	right: 16px;
}
.block-faq__header .dropdown {
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
}
.block-faq__header .dropdown.is-open .dropdown__head {
	border-bottom: 0;
}
.block-faq__content {
	padding: 24px 16px 8px 16px;
}
.block-faq__content h2 {
	font-size: 20px;
	line-height: 24px;
}
.block-faq__content p {
	line-height: 32px;
}
.block-faq__content p.is-grey {
	color: #999;
}
.block-faq__content p span {
	display: block;
}
.block-faq__content p span a:first-child {
	margin-right: 8px;
}
.block-faq__content p span a:last-child {
	margin-left: 8px;
}
.block-faq__links {
	border-top: 2px solid #ebebeb;
}
.block-faq__links a {
	margin-bottom: 24px;
}
.block-faq__links a:first-child {
	margin-top: 24px;
}
.block-faq__links a i {
	display: none;
}
.block-faq__write {
	padding: 24px 16px 24px 16px;
	border-top: 2px solid #ebebeb;
	background-color: #f7f7f7;
}
.block-faq__write h3 {
	font-weight: 500;
}
.block-faq__write p {
	color: #999;
}
.block-faq__write a {
	width: 100%;
}

@media (min-width: 768px) {
	.block-faq {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	.block-faq__header {
		display: inline-block;
		vertical-align: top;
		border-bottom: 0;
		padding: 0 48px;
		height: auto;
		margin-top: 48px;
	}
	.block-faq__header div:first-child {
		display: block;
	}
	.block-faq__header .dropdown {
		display: none;
	}
	.block-faq__header a {
		display: block;
		text-transform: inherit;
		line-height: inherit;
		height: 40px;
		line-height: 40px;
		color: #999;
		font-size: 14px;
		margin-bottom: 0;
	}
	.block-faq__header a.is-active {
		color: #292929;
		position: relative;
	}
	.block-faq__header a.is-active::before {
		position: absolute;
		content: '';
		display: inline-block;
		width: 4px;
		height: 30px;
		background: #2a9bff;
		border-radius: 2.5px;
		top: 5px;
		left: -16px;
	}
	.block-faq__header i {
		display: none;
	}
	.block-faq__content {
		padding: 40px 40px 40px 0;
	}
	.block-faq__content h2 {
		font-size: 30px;
		line-height: 36px;
	}
	.block-faq__content p span {
		display: inline-block;
	}
	.block-faq__content p span:first-child {
		margin-left: 16px;
	}
	.block-faq__links a:last-child {
		margin-bottom: 0;
	}
	.block-faq__links a i {
		display: inline-block;
	}
	.block-faq__write {
		padding: 24px;
		margin: 0 40px 40px 0;
		border: 1px solid #ebebeb;
		border-radius: 4px;
	}
	.block-faq__write a {
		width: inherit;
	}
}

@media (min-width: 1280px) {
	.block-faq__content {
		padding: 48px 0 32px 0;
		margin-right: 106px;
	}
	.block-faq__content p {
		padding-right: 170px;
	}
	.block-faq__write {
		position: relative;
		margin-right: 106px;
	}
	.block-faq__write a {
		position: absolute;
		top: 50%;
		right: 24px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.block-faq__write p {
		margin-bottom: 0;
	}
}

.player-stats {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 24px 0;
	margin-top: 24px;
	border: 1px solid #ebebeb;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: #fcfcfc;
}
.player-stats__item {
	display: inline-block;
	width: 50%;
	padding: 16px 24px 0 24px;
}
.player-stats__item:nth-child(odd) {
	border-right: 1px solid #ebebeb;
}
.player-stats__item p {
	display: block;
	font-size: 12px;
	font-family: 'Din';
	font-weight: bold;
	color: #999;
	text-transform: uppercase;
}
@media (min-width: 768px) {
	.player-stats__item p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.player-stats__item span {
	font-family: 'Din';
	font-size: 40px;
	line-height: 51px;
	letter-spacing: -0.83px;
	font-weight: 500;
}
@media (min-width: 768px) {
	.player-stats__item {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		width: auto;
		padding: 16px 8px 8px 8px;
		overflow: hidden;
		border-right: 1px solid #ebebeb;
	}
	.player-stats__item:last-child {
		border-right: 0;
	}
}
@media (min-width: 1280px) {
	.player-stats__item {
		padding: 16px 24px 8px 24px;
	}
}

.block-auteur {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.block-auteur.block-auteur--empty .block-auteur__profile {
	background: none;
}
.block-auteur.block-auteur--empty .block-auteur__profile span {
	display: block;
}
.block-auteur .block-auteur__profile {
	width: 56px;
	height: 56px;
	border-radius: 50%;
	margin-right: 12px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.block-auteur .block-auteur__profile span {
	display: none;
	width: 56px;
	height: 56px;
	border-radius: 50%;
	background: #f0f2f5;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.block-auteur .link-competition__empty {
	font-weight: 500;
}

.not-found {
	margin-bottom: 40px;
	text-align: center;
	padding: 56px 24px 48px;
}
.not-found img {
	margin-bottom: 32px;
}
.not-found h1 {
	margin-bottom: 16px;
}
.not-found p {
	margin-bottom: 32px;
	color: #999;
}
.not-found .btn {
	color: #292929;
}

.modal {
	position: relative;
	display: none;
}
.modal.visible {
	display: block;
}
.modal .container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	z-index: 150;
}
.modal__overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(41, 41, 41, 0.6);
	z-index: 1;
}
.modal .icon-close {
	position: absolute;
	top: calc(16px - 2px);
	right: calc(16px - 2px);
	display: block;
	width: 16px;
	line-height: 16px;
	color: #999;
	font-size: 12px;
	cursor: pointer;
}
.modal .icon-arrow-left {
	position: absolute;
	top: calc(16px - 2px);
	left: calc(16px - 2px);
	display: block;
	width: 16px;
	line-height: 16px;
	color: #999;
	font-size: 12px;
	cursor: pointer;
}

.newsletter-subscription .interests {
	margin-bottom: 40px;
}

.newsletter-subscription .newsletter-subscription__success {
	display: none;
	padding: 16px 0;
}

.newsletter-input {
	max-width: 492px;
	padding-bottom: 8px;
}
.newsletter-input .icon-close {
	color: #fff;
	z-index: 2;
	opacity: 0.6;
}
.newsletter-input .modal__wrapper {
	padding-top: 24px !important;
}
@media (min-width: 768px) {
	.newsletter-input .modal__wrapper {
		padding-top: 32px;
	}
}
.newsletter-input .modal__header {
	position: relative;
	height: 114px;
	text-align: center;
	background: blue;
	color: #fff;
	-webkit-box-shadow: 0 3px 8px 0 rgba(51, 51, 51, 0.12);
	box-shadow: 0 3px 8px 0 rgba(51, 51, 51, 0.12);
	background-image: url('../img/banner/newsletter.jpg');
	background-size: cover;
}
@media (min-width: 768px) {
	.newsletter-input .modal__header {
		height: 214px;
	}
}
.newsletter-input .modal__header::before {
	content: '';
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background: #013974;
	opacity: 0.88;
}
.newsletter-input .modal__header h3 {
	width: 100%;
	max-width: 320px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.newsletter-input .modal__header h3 br {
	display: none;
}
@media (min-width: 768px) {
	.newsletter-input .modal__header h3 br {
		display: block;
	}
}

.newsletter-valid {
	max-width: 492px;
	padding-top: 24px;
}
.newsletter-valid .modal__body {
	padding: 0;
}
.newsletter-valid .modal__wrapper {
	padding-top: 24px;
	padding-bottom: 32px;
}
.newsletter-valid .list--check span {
	font-size: 14px;
}
.newsletter-valid h3,
.newsletter-valid .btn--cheer,
.newsletter-valid .list--check span {
	color: #292929;
}

.modal-centenaire .icon-close {
	top: 24px;
	right: 24px;
}

.modal-centenaire .btn--white:hover {
	color: #292929;
}

.modal-centenaire .btn--white:focus {
	background: #fff;
}

.modal-centenaire.modal-centenaire--with-bg {
	max-width: 345px;
}
.modal-centenaire.modal-centenaire--with-bg .icon-close {
	color: #fff;
}
.modal-centenaire.modal-centenaire--with-bg .modal__header {
	background-size: cover;
	height: 337px;
	color: #fff;
	text-align: center;
}
.modal-centenaire.modal-centenaire--with-bg .modal__header img {
	max-width: 155px;
	margin-top: 67px;
}
.modal-centenaire.modal-centenaire--with-bg .modal__header h3 {
	margin-top: 38px;
	font-size: 24px;
}
.modal-centenaire.modal-centenaire--with-bg .modal__header p {
	font-size: 17px;
	opacity: 0.8;
	line-height: 28px;
}

.modal-centenaire.modal-centenaire--intro .modal__header {
	height: 368px;
	background-image: url('../img/centenaire/modal-intro.jpg');
}
.modal-centenaire.modal-centenaire--intro .modal__header p {
	margin-bottom: 13px;
}
.modal-centenaire.modal-centenaire--intro .modal__header a {
	font-size: 17px;
	opacity: 0.8;
	text-decoration: underline;
}

.modal-centenaire.modal-centenaire--intro .modal__wrapper {
	padding: 24px 24px 38px 24px;
}

.modal-centenaire.modal-centenaire--end .modal__header {
	background-image: url('../img/centenaire/modal-end.jpg');
	height: 541px;
	padding: 0 24px;
}
.modal-centenaire.modal-centenaire--end .modal__header p {
	margin-bottom: 64px;
}
.modal-centenaire.modal-centenaire--end .modal__header .btn--white {
	margin-bottom: 12px;
}

.modal-centenaire.modal-centenaire--form {
	width: 345px;
}
.modal-centenaire.modal-centenaire--form .modal__header {
	padding-top: 19px;
	padding-left: 24px;
}
.modal-centenaire.modal-centenaire--form .modal__wrapper {
	padding: 32px 24px 24px 24px;
}

.modal-centenaire.modal-centenaire--download {
	width: 345px;
}
.modal-centenaire.modal-centenaire--download .icon-close {
	color: #fff;
	background: #7d93a8;
	width: 22px;
	height: 22px;
	border-radius: 2px;
	font-size: 10px;
	line-height: 23px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.modal-centenaire.modal-centenaire--download .icon-close {
		line-height: 20px;
	}
}
.modal-centenaire.modal-centenaire--download .icon-close:before {
	position: relative;
	top: 0.5px;
	left: 0.5px;
}
.modal-centenaire.modal-centenaire--download .modal__header {
	background: #f3f3f3;
}
.modal-centenaire.modal-centenaire--download .modal__header img {
	display: block;
	margin: 0 auto;
	max-height: 276px;
}
.modal-centenaire.modal-centenaire--download .modal__header p {
	color: #292929;
	opacity: 0.6;
	padding: 16px 24px 16px 24px;
	margin-bottom: 0;
	font-size: 12px;
}
.modal-centenaire.modal-centenaire--download .modal__wrapper {
	padding: 0 24px 24px 24px;
}
.modal-centenaire.modal-centenaire--download .modal__wrapper .checkbox  {
	margin-top: 0;
}

.modal-content {
	position: relative;
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	z-index: 20;
	max-height: calc(100% - 32px);
	overflow-y: auto;
	background-color: #fff;
	-webkit-overflow-scrolling: touch;
}
.modal-content .modal__wrapper {
	padding-top: 40px;
	padding-bottom: 16px;
	padding-left: 24px;
	padding-right: 24px;
}
.modal-content .modal__wrapper.modal__wrapper--footer {
	border-top: 1px solid #ebebeb;
	background-color: #fafafa;
	color: #999;
}
.modal-content.has-bg .icon-close {
	color: #fff;
}
.modal-content .input.mb-3,
.modal-content .radio.mb-3,
.modal-content .checkbox.mb-3 {
	margin-bottom: 24px;
}
.modal-content .input.mb-4,
.modal-content .radio.mb-4,
.modal-content .checkbox.mb-4 {
	margin-bottom: 32px;
}
.modal-content strong {
	color: #292929;
	font-weight: 500;
}
.modal-content .modal__body {
	color: #999;
}
.modal-content .modal__body > * {
	max-width: 100%;
}
.modal-content .card {
	color: #292929;
}
.modal-content .modal__body--medium {
	font-size: 17px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.modal-content .modal__body--medium .circle-icon {
	margin-bottom: 16px;
}
.modal-content.modal-content--stripe {
	width: 392px;
}
.modal-content.modal-content--stripe h3 {
	text-align: center;
}
.modal-content.modal-content--stripe .modal__body {
	text-align: center;
}
.modal-content .modal-or-separator {
	margin: 16px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 27px;
}
.modal-content .modal-or-separator .modal-or-separator__line {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 0px;
	flex: 1 0 0;
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.modal-content .modal-or-separator .modal-or-separator__line:before {
	display: block;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 0px;
	flex: 1 0 0;
	height: 1px;
	background-color: #ebebeb;
	content: '';
}
.modal-content .modal-or-separator .modal-or-separator__text {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 4px 16px 0 16px;
	text-transform: uppercase;
	font-family: 'Din';
	font-size: 12px;
	font-weight: bold;
	color: #999;
}
.modal-content .list {
	margin-left: 0;
}
.modal-content .list li + li {
	margin-top: 8px;
}
.modal-content .modal__footer-option {
	margin: 0;
	font-size: 14px;
	color: #999;
	text-align: center;
}
.modal-content .modal__footer-option a {
	font-weight: 500;
}
.modal-content .modal__footer-option a.footer-option__link {
	color: #2a9bff;
	font-weight: 700;
}
.modal-content .modal__snack-bar {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	pointer-events: none;
}
.modal-content .modal__snack-bar > .snack-bar__content {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 48px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #2ecc71;
	color: white;
	font-weight: 500;
	-webkit-transition: -webkit-transform 0.5s ease-out;
	transition: -webkit-transform 0.5s ease-out;
	transition: transform 0.5s ease-out;
	transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
	-webkit-transition: opacity 0.5 ease-out;
	transition: opacity 0.5 ease-out;
	-webkit-transition-delay: 0.75s, 0.75s;
	transition-delay: 0.75s, 0.75s;
}
.modal-content .modal__snack-bar > .snack-bar__content .icon {
	margin-right: 8px;
	opacity: 0.6;
}
.modal-content .modal__snack-bar > .snack-bar__content:before {
	content: '';
	position: absolute;
	top: -64px;
	width: 100%;
	height: 64px;
	background: -webkit-gradient(linear, left top, left bottom, from(#fff0), to(#fffc));
	background: linear-gradient(#fff0, #fffc);
	-webkit-transition: opacity 0.5s ease-out;
	transition: opacity 0.5s ease-out;
	-webkit-transition-delay: 1s;
	transition-delay: 1s;
}
.modal-content .modal__snack-bar:not(.visible) > .snack-bar__content {
	pointer-events: none;
	-webkit-transition-delay: 0s, 0s;
	transition-delay: 0s, 0s;
	-webkit-transition-duration: 0s, 0s;
	transition-duration: 0s, 0s;
	-webkit-transform: translateY(48px);
	transform: translateY(48px);
	opacity: 0;
}
.modal-content .modal__snack-bar:not(.visible) > .snack-bar__content:before {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
	opacity: 0;
}

.newsletter-unsubscribe .radio {
	color: #292929;
}

.creation-account .checkbox {
	font-size: 12px;
	margin: 0;
}
.creation-account .checkbox label {
	line-height: 18px;
}

.creation-account .checkbox + .checkbox {
	margin-top: 8px;
}

.keep-in-touch .checkbox {
	color: #292929;
}

.keep-in-touch .modal__event-abstract {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border: 2px solid #ebebeb;
	border-radius: 4px;
	background-color: #fbfbfb;
}
.keep-in-touch .modal__event-abstract > div {
	padding: 8px 16px;
}
.keep-in-touch .modal__event-abstract h6 {
	font-size: 10px;
	font-weight: 700;
	color: #999;
	text-transform: uppercase;
}
.keep-in-touch .modal__event-abstract p {
	font-size: 17px;
}

.keep-in-touch .modal__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.keep-in-touch .modal__row > * {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 0px;
	flex: 1 0 0;
}

@media (max-width: 767px) {
	.keep-in-touch {
		-webkit-box-flex: 0;
		-ms-flex: 0 1 604px;
		flex: 0 1 604px;
	}
	.keep-in-touch .modal__event-abstract {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.keep-in-touch .modal__event-abstract > div + div {
		border-top: 1px solid #ebebeb;
	}
	.keep-in-touch .modal__row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@media (min-width: 768px) {
	.keep-in-touch {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 604px;
		flex: 0 0 604px;
	}
	.keep-in-touch .modal__event-abstract {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.keep-in-touch .modal__event-abstract > div + div {
		border-left: 1px solid #ebebeb;
	}
	.keep-in-touch .modal__row {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.keep-in-touch .modal__row > * + * {
		margin-left: 16px;
	}
}

@media (min-width: 768px) {
	.modal-content {
		max-height: calc(100% - 64px);
	}
	.modal-content .modal__wrapper {
		padding-top: 32px;
		padding-left: 32px;
		padding-right: 32px;
		padding-bottom: 24px;
	}
}

@media (min-width: 1280px) {
	.modal-content.has-bg .icon-close {
		color: #999;
	}
}

.site-club-creation-club,
.site-club-creation-comite,
.site-club-add-admin {
	max-width: 492px;
}
.site-club-creation-club .icon-close,
.site-club-creation-comite .icon-close,
.site-club-add-admin .icon-close {
	color: #fff;
	z-index: 2;
	opacity: 0.6;
}
.site-club-creation-club .modal__wrapper,
.site-club-creation-comite .modal__wrapper,
.site-club-add-admin .modal__wrapper {
	padding: 24px;
}
@media (min-width: 768px) {
	.site-club-creation-club .modal__wrapper,
	.site-club-creation-comite .modal__wrapper,
	.site-club-add-admin .modal__wrapper {
		padding: 32px;
	}
}
.site-club-creation-club .modal__header,
.site-club-creation-comite .modal__header,
.site-club-add-admin .modal__header {
	position: relative;
	height: 214px;
	text-align: center;
	color: #fff;
	-webkit-box-shadow: 0 3px 8px 0 rgba(51, 51, 51, 0.12);
	box-shadow: 0 3px 8px 0 rgba(51, 51, 51, 0.12);
	background-size: cover;
}
@media (min-width: 768px) {
	.site-club-creation-club .modal__header,
	.site-club-creation-comite .modal__header,
	.site-club-add-admin .modal__header {
		height: 214px;
	}
}
.site-club-creation-club .modal__header h3,
.site-club-creation-comite .modal__header h3,
.site-club-add-admin .modal__header h3 {
	width: 100%;
	max-width: 320px;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.site-club-creation-club p,
.site-club-creation-comite p,
.site-club-add-admin p {
	color: #999999;
	margin-right: auto;
	margin-left: 0;
	font-size: 14px;
}
@media (min-width: 768px) {
	.site-club-creation-club p,
	.site-club-creation-comite p,
	.site-club-add-admin p {
		font-size: 17px;
	}
	.site-club-creation-club p:first-child,
	.site-club-creation-comite p:first-child,
	.site-club-add-admin p:first-child {
		padding-right: 76px;
	}
	.site-club-creation-club p:last-child,
	.site-club-creation-comite p:last-child,
	.site-club-add-admin p:last-child {
		padding-right: 11px;
	}
}
.site-club-creation-club .ft-700,
.site-club-creation-comite .ft-700,
.site-club-add-admin .ft-700 {
	color: #292929;
}

@media (min-width: 768px) {
	.site-club-reminder,
	.site-club-revoke {
		width: 409px;
	}
}

.site-club-reminder p:not(.ft-secondary),
.site-club-revoke p:not(.ft-secondary) {
	color: #999999;
}

.site-club-reminder p,
.site-club-revoke p {
	font-size: 14px;
	line-height: 22px;
}
@media (min-width: 768px) {
	.site-club-reminder p,
	.site-club-revoke p {
		font-size: 17px;
		line-height: 24px;
	}
}

.modal-access-dashboard {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 345px;
}

.modal-access-dashboard__left {
	display: none;
}

.modal-access-dashboard__right h3 {
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
}

.modal-access-dashboard__right .modal__wrapper {
	padding-top: 32px;
	padding-bottom: 32px;
}
.modal-access-dashboard__right .modal__wrapper li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.modal-access-dashboard__right .modal__wrapper li span {
	margin-right: 16px;
}
.modal-access-dashboard__right .modal__wrapper .icon {
	width: 15px;
	height: 15px;
	line-height: 15px;
	border-radius: 50%;
	color: #2a9bff;
	background: rgba(42, 155, 255, 0.1);
	font-size: 5px;
}

.modal-access-dashboard:not(.modal-access-dashboard--has-img) .modal__header {
	padding-top: 32px;
	padding-left: 24px;
	padding-right: 24px;
}
.modal-access-dashboard:not(.modal-access-dashboard--has-img) .modal__header h3 {
	font-size: 20px;
}

.modal-access-dashboard--has-img .modal__header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-size: cover;
	min-height: 206px;
	padding: 0 24px;
}
.modal-access-dashboard--has-img .modal__header h3 {
	color: #fff;
	text-align: center;
}

@media (min-width: 1280px) {
	.modal-access-dashboard {
		max-width: 880px;
	}
	.modal-access-dashboard__left {
		display: block;
		background-size: cover;
		width: 424px;
	}
	.modal-access-dashboard__right {
		width: 456px;
		height: 100%;
		padding: 56px 32px 40px;
	}
	.modal-access-dashboard__right li {
		padding-right: 30px;
	}
	.modal-access-dashboard__right .modal__header {
		background-image: inherit !important;
		padding: 0;
		min-height: inherit;
	}
	.modal-access-dashboard__right .modal__header h3 {
		color: #292929;
		text-align: left;
	}
	.modal-access-dashboard__right .modal__wrapper {
		padding: 0;
	}
	.modal-access-dashboard:not(.modal-access-dashboard--has-img) .modal__header {
		padding: 0;
	}
	.modal-access-dashboard:not(.modal-access-dashboard--has-img) .modal__header h3 {
		font-size: 24px;
	}
	.modal-access-dashboard:not(.modal-access-dashboard--has-img) .modal-access-dashboard__left {
		display: none;
	}
}

.confirm-success {
	text-align: center;
	padding: 70px 0;
}
.confirm-success div {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	width: 80px;
	height: 80px;
	border: 4px solid #2ecc71;
	border-radius: 50%;
	color: #2ecc71;
	margin-bottom: 28px;
}
.confirm-success div i {
	position: absolute;
	top: 51%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 17px;
}
.confirm-success p {
	color: #999;
	margin-bottom: 56px;
}

.placeholder {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 208px;
	text-align: center;
	background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.02)));
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.02) 100%);
}
.placeholder p {
	color: #ccc;
	margin-bottom: 0;
	width: 100%;
}
.placeholder i {
	font-size: 32px;
	font-weight: 400;
	color: #7d93a8;
	margin-bottom: 8px;
}

.share {
	display: inline-block;
	position: relative;
	background-color: #fff;
}
.share .icon-delete {
	cursor: pointer;
	width: 32px;
	line-height: 32px;
	color: #7d93a8;
}
.share .btn--white {
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 2px solid #ebebeb;
	line-height: 42px;
	padding: 0;
}
.share .btn--white:focus {
	outline: 0;
}
.share .btn--white:focus i {
	color: #2a9bff;
}
.share.is-open .share__wrapper {
	display: block;
}

.share--left .share__wrapper {
	left: auto;
	right: 0;
}
.share--left .share__wrapper .btn {
	float: left;
}

.share__wrapper {
	display: none;
	width: 172px;
	border: 2px solid #ebebeb;
	border-radius: 4px;
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	padding: 6px;
}
.share__wrapper .btn {
	margin-left: 8px;
}

.share--transparent {
	background-color: transparent;
}
.share--transparent .btn--white {
	border: 2px solid rgba(255, 255, 255, 0.32);
	background: rgba(80, 183, 72, 0.06);
	border-radius: 4px;
}
.share--transparent .btn--white i {
	color: #fff;
}

.practice {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background: #fcfcfc;
	border: 1px solid #ebebeb;
	border-radius: 4px;
}
.practice div {
	padding: 16px;
}

.practice__title,
.practice__desc {
	border-bottom: 1px solid #ebebeb;
}

.practice__desc {
	color: #999;
}

.practice__place i {
	font-size: 12px;
	color: #7d93a8;
}

@media (min-width: 1280px) {
	.practice {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.practice__title,
	.practice__desc {
		border-bottom: 0;
		border-right: 1px solid #ebebeb;
	}
	.practice__title {
		width: 180px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.practice__place {
		width: 280px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		-ms-flex-item-align: center;
		align-self: center;
	}
}

.practices-table {
	padding-left: 24px;
	padding-right: 32px;
	background-color: #fff;
}
.practices-table table {
	border: none;
	width: 100%;
	border-collapse: collapse;
}
.practices-table th {
	font-family: 'Din';
	color: #999;
	text-transform: uppercase;
	font-size: 12px;
	border-bottom: 1px solid #ebebeb;
	padding-left: 0;
}
.practices-table th:first-child {
	font-family: 'Din';
	color: #292929;
	text-transform: none;
	font-size: 15px;
}
.practices-table tbody tr,
.practices-table tbody tr:nth-child(odd) {
	background-color: transparent;
}
.practices-table tbody tr:last-child td {
	border-bottom: none;
}
.practices-table tbody tr > td:first-child {
	font-family: 'Din';
	color: #292929;
	font-size: 15px;
}
.practices-table tbody tr > td:last-child {
	color: #292929;
}
.practices-table tbody tr > td:last-child i {
	color: #7d93a8;
}
.practices-table td {
	font-weight: 400;
	color: #999;
	font-size: 14px;
	padding-left: 0;
	border-bottom: 1px solid #ebebeb;
}
.practices-table td.no-border {
	border-bottom: none;
}

@media (max-width: 1080px) {
	.practices-table {
		padding-left: 16px;
		padding-right: 16px;
	}
	.practices-table td:before {
		content: attr(data-title);
	}
	.practices-table table,
	.practices-table thead,
	.practices-table tbody,
	.practices-table th,
	.practices-table td,
	.practices-table tr {
		display: block;
	}
	.practices-table thead tr {
		display: none;
	}
	.practices-table tbody tr:last-child td:not(:last-child) {
		border-bottom: 1px solid #ebebeb;
	}
	.practices-table td {
		position: relative;
		min-height: 55px;
		border: 0px solid transparent;
		padding-left: 45% !important;
		white-space: normal;
		text-align: right;
		border-bottom: 1px solid #ebebeb;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-bottom: 0;
	}
	.practices-table td.no-border {
		border-bottom: 1px solid #ebebeb;
	}
	.practices-table td.mobile-hidden {
		display: none;
	}
	.practices-table td:last-child:before {
		color: #999;
	}
	.practices-table td:before {
		position: absolute;
		top: 50%;
		left: 0px;
		width: 42%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		padding-right: 15px;
		white-space: nowrap;
		text-overflow: ellipsis !important;
		overflow: hidden !important;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		text-align: left;
	}
}

.list-label {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	white-space: nowrap;
	margin-left: -16px;
	margin-right: -16px;
	padding-left: 16px;
	padding-bottom: 12px;
}
.list-label li {
	display: inline-block;
	margin-right: 6px;
}

.list-tag li {
	display: inline-block;
	margin-right: 8px;
	margin-bottom: 8px;
}

@media (min-width: 768px) {
	.list-label {
		overflow: visible;
		white-space: normal;
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}
	.list-label li {
		margin-bottom: 8px;
	}
}

.itw {
	position: relative;
	overflow: hidden;
	font-size: 18px;
	line-height: 32px;
	padding: 16px 20px;
	border-left: 3px solid #2a9bff;
	background-color: #fafbfb;
}
.itw:before {
	content: '”';
	position: absolute;
	top: 0;
	font-size: 800px;
	font-weight: 700;
	line-height: 600px;
	right: -80px;
	pointer-events: none;
	opacity: 0.03;
}
.itw p {
	margin-bottom: 0;
}

.itw__author {
	color: #2a9bff;
	margin-top: 16px;
}
.itw__author:first-child {
	margin-top: 0;
}

@media (min-width: 768px) {
	.itw {
		font-size: 17px;
	}
}

@media (min-width: 1280px) {
	.itw {
		padding: 32px 40px;
	}
}

.filter {
	z-index: 1;
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
	height: 56px;
}
.filter .input {
	margin: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	border-right: 1px solid #ebebeb;
}
.filter .input input {
	border: none;
	height: 54px;
}
.filter.mobile-only {
	margin-left: -16px;
	margin-right: -16px;
}
.filter .filter-option__cta {
	width: 110px;
	line-height: 54px;
	height: 54px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 0;
}
.filter .filter-option__cta:hover {
	color: #2a9bff;
}

.filter__wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.filter--centenaire {
	border-color: #345374;
	border-bottom: 0;
}
.filter--centenaire input {
	color: #fff;
}
.filter--centenaire .input {
	border-color: #345374;
}
@media (min-width: 768px) {
	.filter--centenaire .dropdown {
		background-color: transparent;
		color: #fff;
	}
}
.filter--centenaire .dropdown.is-open {
	color: #292929;
}
.filter--centenaire .dropdown .dropdown__list {
	border-radius: 0;
}
@media (min-width: 768px) {
	.filter--centenaire .filter-option__list {
		border-right: 1px solid #345374;
		height: 60px;
	}
}
.filter--centenaire .filter-option__cta {
	background: transparent;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	height: calc(100% + 1px);
	color: #fff;
}
@media (min-width: 768px) {
	.filter--centenaire .filter-option__cta {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		height: 100%;
	}
}
.filter--centenaire .btn--primary {
	background-color: #e8a801;
}

@media (min-width: 768px) {
	.filter {
		height: 64px;
	}
	.filter .input {
		height: 64px;
	}
	.filter .input input {
		height: 62px;
	}
	.filter .dropdown {
		display: inline-block;
		margin: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.filter .dropdown__head {
		height: 62px;
		line-height: 62px;
		padding: 0 16px;
	}
	.filter .dropdown__head:after {
		margin-left: 8px;
		position: static;
	}
	.filter .dropdown__list {
		width: auto;
	}
}

@media (min-width: 1280px) {
	.filter .input {
		border-left: 1px solid #ebebeb;
	}
	.filter .filter__options {
		border-right: 1px solid #ebebeb;
	}
	.filter--centenaire .input {
		border-left: 1px solid #345374;
	}
	.filter__wrapper {
		max-width: 1680px;
		padding-left: 60px;
		padding-right: 60px;
		margin: 0 auto;
	}
}

.filter-option__list {
	display: none;
	overflow: auto;
	position: fixed;
	background: #f0f2f5;
	left: 0;
	top: 127px;
	width: 100%;
	height: calc(100% - 127px);
	padding: 24px 24px 120px;
}
.mobile-only .filter-option__list {
	top: 112px;
	height: calc(100% - 112px);
}

.filter-option__action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 40px 24px 24px;
	z-index: 2;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(rgba(240, 242, 245, 0)),
		color-stop(55%, #f0f2f5)
	);
	background-image: linear-gradient(-180deg, rgba(240, 242, 245, 0) 0%, #f0f2f5 55%);
}
.filter-option__action .btn--white {
	border: 2px solid #ebebeb;
	margin-right: 16px;
	line-height: 42px;
}
.filter-option__action .btn--primary {
	width: 100%;
}

.filter-option__cta.is-active {
	background-color: #f0f2f5;
	color: inherit;
}
.filter-option__cta.is-active + .filter-option__list {
	display: block;
	z-index: 100;
}

@media (min-width: 768px) {
	.filter-option--mobile .filter-option__list {
		display: block;
		position: static;
		padding: 0;
		height: auto;
		overflow: visible;
		background-color: transparent;
	}
	.filter-option--mobile .filter-option__cta,
	.filter-option--mobile .filter-option__action {
		display: none;
	}
	.filter--centenaire .filter-option__list {
		height: 63px;
	}
}

@media (min-width: 1280px) {
	.filter-option__list {
		display: block;
		position: static;
		padding: 0;
		height: auto;
		overflow: visible;
		background-color: transparent;
	}
	.filter-option__cta,
	.filter-option__action {
		display: none;
	}
}

.dropdown {
	display: block;
	position: relative;
	background-color: #fff;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	border-radius: 4px;
}
.dropdown.is-open {
	z-index: 2;
}
.dropdown.is-open .dropdown__list {
	visibility: visible;
	opacity: 1;
	max-height: 80vh;
	overflow: auto;
}
.dropdown.is-open .dropdown__head:after {
	content: '\e914';
	color: #2a9bff;
}
.dropdown.has-counter .dropdown__counter {
	opacity: 1;
	display: initial;
}
.dropdown.is-dark {
	background: none;
	color: rgba(255, 255, 255, 0.6);
	border-color: #344f6b;
}
.dropdown.is-dark .dropdown__head {
	color: rgba(255, 255, 255, 0.6);
	text-transform: none;
	font-size: 14px;
	font-family: 'Roboto';
	font-weight: 400;
}
.dropdown.is-dark .dropdown__label {
	color: rgba(255, 255, 255, 0.6);
}
.dropdown.is-dark .dropdown__list {
	background-color: #002348;
	-webkit-box-shadow: 0 0 0 1px #344f6b;
	box-shadow: 0 0 0 1px #344f6b;
}
.dropdown.is-dark .radio {
	color: #fff;
}
.dropdown ::-webkit-scrollbar {
	position: relative;
	-webkit-appearance: none;
	appearance: none;
	width: 4px;
}
.dropdown ::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: #7d93a8;
}
.dropdown ::-webkit-scrollbar-track {
	background-color: rgba(125, 147, 168, 0.32);
	border-radius: 5px;
}
.dropdown .radio {
	color: #292929;
}
.dropdown .radio label {
	padding-left: 0;
}
.dropdown .radio label:before,
.dropdown .radio label:after {
	display: none;
}
.dropdown .radio input[checked] + label {
	font-weight: 700;
}

.dropdown__head {
	display: block;
	cursor: pointer;
	height: 48px;
	line-height: 50px;
	padding: 0 48px 0 24px;
	border-radius: 4px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.dropdown__head:after {
	content: '\e906';
	font-family: 'icon';
	font-size: 8px;
	position: absolute;
	right: 24px;
	margin-left: 16px;
	color: #7d93a8;
}
.dropdown__head:hover:after {
	color: #2a9bff;
}
.dropdown__head i {
	font-size: 12px;
	color: #7d93a8;
}

.dropdown__counter {
	opacity: 0;
	display: none;
	color: #2a9bff;
	margin-left: 4px;
}

.dropdown__list {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 20;
	max-height: 0;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	background-color: #fff;
	-webkit-transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s;
	transition: all cubic-bezier(0.25, 0.1, 0.25, 1) 0.5s;
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
	width: 100%;
	padding: 0 16px 16px 0;
	border-radius: 4px;
}
.dropdown__list .checkbox,
.dropdown__list .radio,
.dropdown__list .switch {
	margin-bottom: 0;
}
.dropdown__list .checkbox:first-child,
.dropdown__list .radio:first-child,
.dropdown__list .switch:first-child {
	margin-top: 0;
}
.dropdown__list .checkbox label {
	width: 100%;
}
.dropdown__list label:hover {
	cursor: pointer;
}
.dropdown__list .dropdown__head {
	-webkit-box-shadow: none;
	box-shadow: none;
	padding-right: 32px;
}

.dropdown__wrapper {
	overflow-y: auto;
	max-height: 220px;
	-webkit-overflow-scrolling: touch;
	padding: 0 24px 0;
	font-size: 14px;
}
.dropdown__wrapper > p:hover,
.dropdown__wrapper > div:hover {
	font-weight: 500;
	color: #292929;
}

.dropdown--checkbox input:checked ~ label {
	color: #292929;
	font-weight: 500;
}

.dropdown--accordion .dropdown__wrapper {
	max-height: none;
	padding: 0 24px 16px;
}

.dropdown--accordion .dropdown__list {
	position: static;
	padding: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.dropdown--accordion .dropdown__list .dropdown__head {
	display: none;
}

.dropdown--select {
	border: 1px solid #ccc;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: 56px;
}
.dropdown--select .dropdown__head {
	height: 56px;
	line-height: 56px;
	padding-left: 16px;
}
.dropdown--select .dropdown__label {
	text-transform: none;
	color: #999;
	font-size: 14px;
	font-family: 'Roboto';
	font-weight: 400;
}
.dropdown--select .dropdown__subhead {
	opacity: 0;
	line-height: normal;
	font-size: 11px;
	margin-top: 14px;
	color: #999;
}
.dropdown--select .dropdown__list {
	padding-right: 0;
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ccc;
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ccc;
}
.dropdown--select .dropdown__wrapper {
	padding-left: 16px;
	margin-right: 16px;
}
.dropdown--select.is-selected .dropdown__label {
	color: #292929;
}
.dropdown--select.is-open .dropdown__head {
	position: relative;
	z-index: 1;
}
.dropdown--select.is-disabled {
	background-color: #fcfcfc;
	pointer-events: none;
}

.dropdown--match .dropdown__wrapper {
	max-height: 400px;
	padding: 0;
	margin-right: 0;
}
.dropdown--match .dropdown__wrapper .card--match:last-child {
	border-bottom: 0;
}

.dropdown--match .dropdown__head {
	-webkit-box-shadow: initial;
	box-shadow: initial;
}

.dropdown--match.is-open .dropdown__head {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}

.dropdown--match .dropdown__list {
	padding: 0;
}

.dropdown--guest .input-custom:hover {
	font-weight: normal;
}

.dropdown--guest .input-custom.input-custom--guest {
	padding: 20px 0 20px 8px;
	width: 100%;
	height: 100%;
}
.dropdown--guest .input-custom.input-custom--guest input {
	width: 124px;
	outline: none;
}
.dropdown--guest .input-custom.input-custom--guest span:hover {
	color: #2a9bff;
}

.dropdown--guest .dropdown__wrapper {
	padding-right: 0;
}

.dropdown--guest .dropdown__list {
	padding: 0;
	border-top-left-radius: 0;
	margin-top: 1px;
}
.dropdown--guest .dropdown__list .dropdown__head {
	height: 78px;
}

.dropdown--big {
	height: 80px;
}
.dropdown--big .dropdown__head {
	height: 80px;
	line-height: 80px;
	padding-left: 24px;
}
.dropdown--big .dropdown__head .dropdown__subhead {
	position: absolute;
	top: 0;
}
.dropdown--big .dropdown__head .dropdown__label {
	font-size: 17px;
}
.dropdown--big.is-open .dropdown__subhead,
.dropdown--big.is-selected .dropdown__subhead {
	opacity: 1;
}
.dropdown--big.is-open .dropdown__label,
.dropdown--big.is-selected .dropdown__label {
	position: relative;
	top: 6px;
}
.dropdown--big.is-open .dropdown__head,
.dropdown--big.is-selected .dropdown__head {
	position: relative;
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	z-index: 1;
}
.dropdown--big.is-selected .dropdown__head {
	-webkit-box-shadow: initial;
	box-shadow: initial;
}

.dropdown--contact {
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
	font-family: 'Roboto';
}
.dropdown--contact.is-selected .dropdown__label {
	color: #292929;
}
.dropdown--contact .dropdown__head {
	position: relative;
	height: 72px;
	line-height: 68px;
	text-transform: initial;
	padding-left: 48px;
	font-weight: 500;
	font-size: 17px;
	color: #ccc;
	border: 2px solid #ccc;
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
}
.dropdown--contact .dropdown__head::before {
	content: '\e912';
	font-family: 'icon';
	font-size: 16px;
	line-height: 16px;
	color: #ccc;
	position: absolute;
	left: 24px;
	top: 50%;
	margin-top: -8px;
}
.dropdown--contact .dropdown__list {
	padding-right: 0;
}
.dropdown--contact .dropdown__wrapper a {
	display: block;
	color: #292929;
	font-size: 17px;
	line-height: 40px;
}
.dropdown--contact .dropdown__wrapper a:hover {
	text-decoration: none;
	font-weight: 700;
}
.dropdown--contact .dropdown__wrapper a:first-child {
	margin-top: 24px;
}

.dropdown--noBorder {
	display: inline-block;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: transparent;
}
.dropdown--noBorder .dropdown__wrapper {
	padding: 8px 16px 0 16px;
}
.dropdown--noBorder label {
	white-space: nowrap;
}
.dropdown--noBorder .dropdown__head {
	display: inline-block;
	padding-left: 16px;
	padding-right: 16px;
}
.dropdown--noBorder .dropdown__head:after {
	position: static;
}
.dropdown--noBorder .dropdown__list {
	width: auto;
}

.dropdown--margin {
	margin-left: -16px;
}

.multi-dropdown {
	display: inline-block;
}
.multi-dropdown__desktop {
	display: none;
}
.multi-dropdown__mobile {
	display: block;
}
.multi-dropdown .dropdown__wrapper span {
	display: block;
	color: #999;
	font-weight: 500;
}
.multi-dropdown .dropdown__wrapper span:not(:first-child) {
	margin-top: 24px;
}
@media (min-width: 1195px) {
	.multi-dropdown__desktop {
		display: block;
	}
	.multi-dropdown__mobile {
		display: none;
	}
}

@media (min-width: 768px) {
	.dropdown--accordion .dropdown__list {
		min-width: 100%;
		position: absolute;
		padding: 0 16px 16px 0;
		-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
		box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
	}
	.dropdown--accordion .dropdown__list .dropdown__head {
		display: block;
	}
	.dropdown--accordion .dropdown__wrapper {
		padding: 0 0 0 24px;
	}
	.dropdown__wrapper {
		max-height: 360px;
	}
	.dropdown--big {
		height: 80px;
	}
	.dropdown--big .dropdown__head {
		height: 80px;
		line-height: 80px;
	}
}

.cookie {
	position: fixed;
	z-index: 9;
	bottom: 12px;
	left: 12px;
	right: 12px;
	padding: 16px;
	-webkit-box-shadow: 0 0 36px rgba(51, 51, 51, 0.3);
	box-shadow: 0 0 36px rgba(51, 51, 51, 0.3);
}
.cookie p {
	font-size: 11px;
	color: #999;
	max-width: 815px;
	margin: 0;
	padding-right: 24px;
}
.cookie p a {
	color: #2a9bff;
	font-weight: 500;
	text-decoration: underline;
}
.cookie .btn {
	margin-top: 16px;
}
.cookie .icon-close {
	position: absolute;
	top: 16px;
	right: 16px;
	width: 16px;
	height: 16px;
	font-size: 12px;
	cursor: pointer;
	color: #999;
}

@media (min-width: 768px) {
	.cookie {
		padding-right: 260px;
		padding-left: 40px;
	}
	.cookie .btn {
		position: absolute;
		top: 50%;
		right: 48px;
		margin-top: -24px;
		width: 200px;
		padding: 0 32px;
	}
	.cookie .icon-close {
		top: 50%;
		margin-top: -6px;
	}
}

@media (min-width: 1280px) {
	.cookie {
		padding-right: 510px;
	}
	.cookie p {
		max-width: 1015px;
	}
	.cookie__about {
		position: absolute;
		right: 282px;
		top: 50%;
		line-height: 24px;
		margin-top: -12px;
	}
	.cookie__separator {
		display: none;
	}
}

.warning {
	background: #2077c5;
	color: rgba(255, 255, 255, 0.8);
}
.warning p {
	margin-bottom: 0;
}
.warning strong {
	font-weight: 500;
	color: #fff;
}
.warning i {
	color: #fff;
	font-size: 16px;
	margin-right: 8px;
}
.warning .icon-close {
	float: right;
	margin: 6px 0 0 8px;
	cursor: pointer;
	font-size: 12px;
}

.warning__wrapper {
	max-width: 1672px;
	padding: 20px;
	min-height: 64px;
	margin-left: auto;
	margin-right: auto;
}

.sidebar {
	padding-bottom: 48px;
}
.sidebar > h3 {
	margin-top: 32px;
	margin-bottom: 16px;
}
.sidebar > h3:first-child {
	margin-top: 0;
}
.sidebar > h4 {
	color: #999;
	margin-top: 24px;
	margin-bottom: 8px;
}
.sidebar .btn--competition {
	width: 100%;
	padding: 16px 24px 16px 16px;
}

@media (min-width: 768px) {
	.sidebar {
		padding-top: 32px;
		padding-bottom: 0;
		margin-bottom: 48px;
	}
	.sidebar > h3 {
		margin-top: 40px;
	}
	.sidebar--article {
		padding-top: 80px;
	}
	.sidebar--alt {
		padding-top: 110px;
	}
}

@media (min-width: 1280px) {
	.sidebar {
		padding-top: 40px;
	}
	.sidebar--article {
		padding-top: 650px;
	}
	.sidebar--alt {
		padding-top: 110px;
	}
}

@media (max-width: 1279px) {
	.sidebar--form {
		position: fixed;
		z-index: 201;
		bottom: 0;
		left: 0;
		padding: 0;
		margin: 0;
		-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		max-height: 95%;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		-webkit-box-shadow: 0 -3px 8px rgba(51, 51, 51, 0.08);
		box-shadow: 0 -3px 8px rgba(51, 51, 51, 0.08);
		border-top: 1px solid #ebebeb;
	}
	.sidebar--form.is-hidden {
		-webkit-transform: translate(0, calc(100% - 60px));
		transform: translate(0, calc(100% - 60px));
		z-index: 9;
	}
	.sidebar--form.is-hidden h3 {
		font-size: 15px;
		padding: 18px 16px;
	}
	.sidebar--form.is-hidden h3:after {
		content: '\e914';
		color: #7d93a8;
	}
	.sidebar--form .box {
		border-radius: 4px 4px 0 0;
	}
	.sidebar--form h3 {
		cursor: pointer;
	}
	.sidebar--form h3:after {
		font-family: 'icon';
		content: '\e906';
		float: right;
		font-size: 8px;
		color: #2a9bff;
	}
}

.club {
	text-align: center;
}
.club .list-type {
	color: #999;
}
.club .list-type i {
	color: #7d93a8;
}

.club__logo {
	width: 88px;
	height: 88px;
	margin-bottom: 8px;
}

.club__title {
	font-size: 20px;
	line-height: 26px;
	margin-bottom: 24px;
}

.club__label {
	margin-bottom: 8px;
	max-width: 730px;
}
.club__label .label {
	margin: 0 4px 8px;
}

@media (min-width: 768px) {
	.club {
		text-align: left;
	}
	.club__logo {
		float: right;
		width: 64px;
		height: 64px;
		margin: 0 0 0 16px;
	}
	.club__title {
		font-size: 40px;
		line-height: 56px;
		margin-bottom: 16px;
	}
}

@media (min-width: 1280px) {
	.club {
		position: relative;
	}
	.club__title {
		max-width: 730px;
	}
	.club__logo {
		width: 116px;
		height: 116px;
		position: absolute;
		top: calc(50% - 58px);
		right: 0;
	}
}

.address {
	position: relative;
	background-color: #f0f2f5;
	z-index: 0;
}
.address iframe {
	width: 100%;
	height: 456px;
}
.address .card {
	position: absolute;
	bottom: 16px;
	left: 24px;
	right: 24px;
	width: auto;
}

@media (min-width: 768px) {
	.address {
		margin-left: -16px;
	}
	.address iframe {
		width: 100%;
		height: 248px;
	}
	.address .card {
		bottom: inherit;
		top: 32px;
		right: 16px;
		left: auto;
		width: 50%;
		max-width: 300px;
	}
}

@media (min-width: 1280px) {
	.address {
		margin-left: -40px;
	}
	.address iframe {
		width: 100%;
		height: 268px;
	}
	.address .card {
		top: 40px;
		right: 40px;
	}
}

.contact .box {
	position: relative;
	border-top: 4px solid #2a9bff;
	border-radius: 0 0 4px 4px;
}

.contact.is-open .contact__more {
	visibility: visible;
	max-height: 1000px;
}

.contact__club {
	margin-bottom: 0;
}

.contact__item {
	position: relative;
	border-bottom: 1px solid #ebebeb;
	padding: 16px 16px 16px 44px;
}
.contact__item i {
	position: absolute;
	top: 20px;
	left: 16px;
	font-size: 12px;
	color: #7d93a8;
}
.contact__item p:last-child {
	margin-bottom: 0;
	color: #999;
	line-height: 20px;
}

.contact__more {
	border: 1px solid #ebebeb;
	border-top: 0;
	border-radius: 0 0 4px 4px;
	background: #fcfcfc;
	max-height: 0;
	overflow: hidden;
	visibility: hidden;
	-webkit-transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}
.contact__more h3 {
	padding: 0 24px;
}
.contact__more h3:first-child {
	padding-top: 24px;
}
.contact__more .member {
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid #ebebeb;
}
.contact__more .member:last-child {
	margin-bottom: 0;
	border-bottom: 0;
}

.contact__staff {
	padding: 0 24px;
	border-bottom: 1px solid #ebebeb;
	margin-bottom: 32px;
}
.contact__staff:last-of-type {
	border-bottom: 0;
	margin-bottom: 0;
}

@media (min-width: 768px) {
	.contact__item p {
		margin: 0;
	}
}

@media (min-width: 1280px) {
	.contact__club {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.contact__item {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		width: 33%;
		border-right: 1px solid #ebebeb;
		text-align: center;
		padding: 24px;
	}
	.contact__item:last-child {
		border-right: 0;
	}
	.contact__item i {
		position: static;
	}
	.contact__staff {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		padding: 0;
	}
	.contact__more .member {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		width: 33%;
		border-bottom: 0;
		margin-bottom: 0;
		padding: 0 24px 32px;
	}
	.contact__more h3:first-child {
		padding-top: 32px;
	}
}

.navbar {
	position: relative;
	width: 100%;
	font-family: 'Din';
	background-color: #fff;
}
.navbar.has-accordion-open {
	z-index: 201;
}
.navbar .accordion-trigger {
	display: block;
	font-size: 12px;
	font-weight: 700;
	line-height: 56px;
	padding: 0 16px;
	position: relative;
	z-index: 1;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	color: #292929;
}
.navbar .accordion-trigger:after {
	line-height: 56px;
}
.navbar .accordion-trigger:hover {
	color: #292929;
}
.navbar .accordion-trigger.is-selected {
	color: #2a9bff;
}
.navbar.box {
	overflow: initial;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.navbar.box.has-accordion-open {
	-webkit-box-shadow: inherit;
	box-shadow: inherit;
}

.navbar--green .accordion-trigger,
.navbar--blue .accordion-trigger {
	color: #fff;
}
.navbar--green .accordion-trigger:hover,
.navbar--green .accordion-trigger:focus,
.navbar--green .accordion-trigger.is-selected,
.navbar--blue .accordion-trigger:hover,
.navbar--blue .accordion-trigger:focus,
.navbar--blue .accordion-trigger.is-selected {
	color: #fff;
}
.navbar--green .accordion-trigger:hover:after,
.navbar--green .accordion-trigger:focus:after,
.navbar--green .accordion-trigger.is-selected:after,
.navbar--blue .accordion-trigger:hover:after,
.navbar--blue .accordion-trigger:focus:after,
.navbar--blue .accordion-trigger.is-selected:after {
	color: #fff;
}
.navbar--green .accordion-trigger:after,
.navbar--blue .accordion-trigger:after {
	color: rgba(255, 255, 255, 0.6);
}

.navbar--green .navbar__links,
.navbar--blue .navbar__links {
	color: rgba(255, 255, 255, 0.6);
}
.navbar--green .navbar__links a:hover,
.navbar--green .navbar__links a:focus,
.navbar--green .navbar__links a.is-active,
.navbar--blue .navbar__links a:hover,
.navbar--blue .navbar__links a:focus,
.navbar--blue .navbar__links a.is-active {
	color: #fff;
}

.navbar--green .navbar__sub a:hover,
.navbar--blue .navbar__sub a:hover {
	text-decoration: underline;
}

.navbar--green {
	background-color: #2077c5;
}
.navbar--green .navbar__wrapper {
	background-color: #2a9bff;
}

.navbar--blue {
	background-color: #002348;
}
.navbar--blue .navbar__sub,
.navbar--blue .navbar__wrapper,
.navbar--blue .navbar__plus:hover {
	background-color: #002348;
}

.navbar__right {
	display: none;
}

.navbar__wrapper {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	padding: 16px 16px 8px;
	background-color: #fff;
}

.navbar__links {
	font-size: 16px;
	line-height: 32px;
	font-weight: 500;
	margin: 0;
	color: #999;
}
.navbar__links li {
	margin-bottom: 16px;
}
.navbar__links a {
	display: block;
}
.navbar__links a:hover,
.navbar__links a:focus,
.navbar__links a.is-active {
	color: #292929;
	text-decoration: none;
}
.navbar__links .label {
	margin-left: 8px;
}

.navbar__plus {
	display: none;
}

@media (min-width: 768px) {
	.navbar .accordion-trigger {
		display: none;
	}
	.navbar.box {
		overflow: hidden;
		-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
		box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
		border-radius: 4px;
	}
	.navbar--green .navbar__links > li:not(.navbar__plus) > a:after,
	.navbar--blue .navbar__links > li:not(.navbar__plus) > a:after {
		background: #fff;
	}
	.navbar--green .navbar__plus:hover,
	.navbar--blue .navbar__plus:hover {
		color: #fff;
	}
	.navbar--green .navbar__wrapper,
	.navbar--blue .navbar__wrapper {
		background: none;
	}
	.navbar--green .navbar__plus:hover {
		background-color: #2077c5;
	}
	.navbar--green .navbar__sub {
		background-color: #2077c5;
	}
	.navbar--green .navbar__sub li {
		color: #fff;
	}
	.navbar__wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
		padding: 0;
		background: none;
	}
	.navbar__right {
		display: block;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.navbar__links {
		position: relative;
		font-size: 0;
		background: none;
		-webkit-box-flex: 2;
		-ms-flex-positive: 2;
		flex-grow: 2;
	}
	.navbar__links li {
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.navbar__links > li {
		display: inline-block;
		line-height: 56px;
	}
	.navbar__links > li > a {
		padding: 0 16px;
	}
	.navbar__links > li:not(.navbar__plus) {
		position: relative;
	}
	.navbar__links > li:not(.navbar__plus) > a:after {
		content: '';
		display: block;
		position: absolute;
		bottom: -1px;
		left: 2px;
		right: 2px;
		height: 0;
		background: #2a9bff;
		border-radius: 4px;
		-webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		z-index: 1;
	}
	.navbar__links > li:not(.navbar__plus) > a:hover:after,
	.navbar__links > li:not(.navbar__plus) > a.is-active:after {
		height: 4px;
	}
	.navbar__plus:hover {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.navbar__plus:hover > a:after {
		content: '\e914';
	}
	.navbar__plus:hover .navbar__sub {
		opacity: 1;
		visibility: visible;
		z-index: 2;
	}
	.navbar__plus > a:after {
		position: relative;
		top: -2px;
		font-family: 'icon';
		content: '\e906';
		font-size: 6px;
		margin-left: 8px;
	}
	.navbar__sub {
		margin: 0;
		position: absolute;
		top: 100%;
		right: 200px;
		text-align: right;
		opacity: 0;
		visibility: hidden;
		padding: 16px 24px;
		background-color: #fff;
		-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
		box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	}
	.navbar__sub li {
		display: block;
		line-height: 32px;
	}
}

@media (min-width: 1280px) {
	.navbar__wrapper {
		max-width: 1680px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 60px;
		padding-right: 60px;
	}
}

.list-competition .btn--icon {
	position: absolute;
	right: 16px;
	top: 50%;
	margin-top: -16px;
}

.list-competition .link-competition {
	margin-bottom: 0;
	display: block;
}

.list-competition ul {
	margin-bottom: 0;
}

.list-competition__head {
	position: relative;
	display: block;
	padding: 16px 56px 16px 16px;
	border-bottom: 1px solid #ebebeb;
	z-index: 1;
}
.list-competition__head:after {
	display: none;
}
.list-competition__head:focus .ft-h3 {
	color: #292929;
}
.list-competition__head.is-selected {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}

.list-competition__legend {
	display: none;
}

.list-competition__item {
	padding: 16px;
	border-bottom: 1px solid #ebebeb;
	background: #f6f6f6;
}

.list-competition__club {
	display: none;
}

.list-competition__count {
	color: #999;
}

@media (min-width: 768px) {
	.list-competition .btn--icon {
		right: 40px;
	}
	.list-competition .link-competition,
	.list-competition .ft-h3 {
		padding-right: 24px;
	}
	.list-competition__head,
	.list-competition__item,
	.list-competition__legend {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 20px 24px;
	}
	.list-competition__head {
		height: 80px;
	}
	.list-competition__legend {
		line-height: 48px;
		color: #999;
		background: #ebebeb;
		padding: 0 24px;
	}
	.list-competition__legend span:last-child {
		width: 320px;
	}
	.list-competition__count,
	.list-competition__club {
		width: 320px;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.list-competition__count {
		padding-right: 56px;
	}
	.list-competition__club {
		display: block;
		margin: 0;
	}
	.list-competition__club img {
		margin-right: 16px;
	}
}

@media (min-width: 1280px) {
	.list-competition__head,
	.list-competition__item,
	.list-competition__legend {
		padding-left: 40px;
	}
	.list-competition__count,
	.list-competition__club {
		width: 366px;
	}
	.list-competition__legend span:last-child {
		width: 366px;
	}
}

.match {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
	padding: 8px;
	min-height: 80px;
	font-family: 'Din';
	font-size: 15px;
	line-height: 20px;
}
.match:hover {
	color: inherit;
	text-decoration: none;
}
.match p {
	margin: 0;
}

.match--score {
	color: #999;
}
.match--score .ft-700 {
	color: #292929;
}

.match__info {
	width: 88px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.match__score {
	font-size: 20px;
	color: #292929;
}

.match__time {
	color: #999;
}

.match__club {
	position: relative;
	width: 50%;
}
.match__club img {
	position: absolute;
	top: 50%;
	margin-top: -12px;
}
.match__club:first-child {
	padding-right: 32px;
	text-align: right;
}
.match__club:first-child img {
	right: 0;
}
.match__club:last-child {
	padding-left: 32px;
	text-align: left;
}
.match__club:last-child img {
	left: 0;
}

.match__colon {
	font-size: 15px;
	margin: 0 8px;
	line-height: 24px;
	vertical-align: bottom;
}

.list-match .match {
	margin-bottom: 8px;
}

@media (min-width: 768px) {
	.match {
		padding: 16px;
		min-height: auto;
		height: 76px;
	}
	.match__info {
		width: 120px;
	}
	.match__club:first-child {
		padding-right: 36px;
	}
	.match__club:last-child {
		padding-left: 36px;
	}
	.list-match .match {
		margin-bottom: 16px;
	}
}

@media (min-width: 1280px) {
	.match {
		padding: 8px 32px;
		height: 60px;
	}
	.match__club img {
		width: 32px;
		height: 32px;
		margin-top: -16px;
	}
	.match__club:first-child {
		padding-right: 44px;
	}
	.match__club:last-child {
		padding-left: 44px;
	}
}

.ranking {
	padding-left: 16px;
	padding-right: 16px;
	font-weight: 700;
	font-family: 'Din';
	font-size: 15px;
}
.ranking p {
	margin: 0;
}
.ranking img {
	margin-right: 16px;
}
.ranking abbr {
	text-decoration: none;
}
.ranking table {
	width: 100%;
	border-collapse: collapse;
}
.ranking td,
.ranking th {
	padding-top: 16px;
	padding-bottom: 16px;
	display: none;
}
.ranking thead {
	border-bottom: 1px solid #ebebeb;
	color: #ccc;
}
.ranking thead .ranking__point {
	color: #292929;
}
.ranking thead th {
	text-align: left;
}
.ranking tbody td:not(.ranking__count) {
	border-bottom: 1px solid #ebebeb;
}
.ranking tbody .ranking__count {
	font-size: 12px;
	color: #999;
}
.ranking tbody tr:last-child td {
	border-bottom: 0;
}
.ranking .ranking__count,
.ranking .ranking__name,
.ranking .ranking__point {
	display: table-cell;
}
.ranking th.ranking__name {
	padding-left: 48px;
}
.ranking--small {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	overflow: hidden;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	white-space: nowrap;
	text-align: center;
	padding-left: 0;
	padding-right: 0;
}
.ranking--small div {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: left;
}
.ranking--small div:first-child span {
	margin-left: 0;
}
.ranking--small div:nth-child(n + 3) {
	display: none;
}
.ranking--small div span {
	color: #999;
	margin-left: 16px;
}
.ranking--small div img {
	margin-left: 8px;
	margin-right: 8px;
}

.ranking--opposition {
	border: 1px solid #ebebeb;
	border-radius: 4px;
	padding-left: 21px;
	padding-right: 17px;
}
.ranking--opposition td,
.ranking--opposition th {
	display: table-cell;
}
.ranking--opposition tr {
	color: inherit;
}
.ranking--opposition .ranking__count,
.ranking--opposition .ranking__point {
	color: #292929;
}
.ranking--opposition .ranking__name {
	font-weight: 400;
}
.ranking--opposition th.ranking__stat {
	font-size: 12px;
}
.ranking--opposition .ranking__stat {
	color: #999;
	padding: 0 11px;
}
.ranking--opposition .ranking__stat,
.ranking--opposition .ranking__point {
	text-align: center;
	padding: 0 11px;
	white-space: nowrap;
	text-transform: uppercase;
}

.ranking__name p {
	font-weight: 400;
	font-family: 'Roboto';
	font-size: 16px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-right: 24px;
}

.ranking__count {
	width: 24px;
}

.ranking__point {
	width: 40px;
}

.ranking__history {
	font-size: 0;
	text-align: right;
}
.ranking__history li {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #ccc;
	margin-left: 4px;
}
.ranking__history li:first-child {
	margin-left: 0;
}
.ranking__history li.has-win {
	background-color: #2a9bff;
}

@media (min-width: 560px) {
	.ranking.ranking--small div:nth-child(n + 3) {
		display: inline-block;
	}
	.ranking.ranking--small div:nth-child(n + 4) {
		display: none;
	}
}

@media (min-width: 768px) {
	.ranking--opposition {
		padding-left: 22px;
		padding-right: 32px;
	}
	.ranking--opposition .ranking__stat {
		width: 40px;
	}
}

@media (min-width: 1280px) {
	.ranking td,
	.ranking th {
		display: table-cell;
	}
	.ranking th {
		padding-top: 24px;
		padding-bottom: 24px;
	}
	.ranking tbody .ranking__stat {
		color: #999;
	}
	.ranking .ranking__last {
		width: 86px;
		text-align: right;
	}
	.ranking.ranking--small div:nth-child(n + 3),
	.ranking.ranking--small div:nth-child(n + 4) {
		display: inline-block;
	}
	.ranking.ranking--small div:nth-child(n + 5) {
		display: none;
	}
	.ranking.ranking--small div img {
		margin-left: 16px;
		margin-right: 16px;
	}
	.ranking__count {
		width: 40px;
	}
	.ranking__stat {
		width: 56px;
	}
	.ranking__point {
		width: 70px;
	}
	.ranking__name p {
		font-size: 14px;
	}
	.ranking--opposition br {
		display: none;
	}
}

.social-wall {
	position: relative;
	background-color: #033971;
	padding-top: 40px;
	padding-bottom: 40px;
}

.social-wall__more {
	position: absolute;
	width: 100%;
	bottom: 30px;
	left: 0;
	padding-top: 90px;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(3, 57, 113, 0)), color-stop(69%, #033971));
	background: linear-gradient(to bottom, rgba(3, 57, 113, 0) 0%, #033971 69%);
}
.social-wall__more a {
	position: relative;
}

@media (min-width: 768px) {
	.social-wall {
		padding-bottom: 56px;
	}
	.social-wall__more {
		bottom: 40px;
	}
}

@media (min-width: 1280px) {
	.social-wall {
		padding-top: 56px;
		padding-bottom: 80px;
	}
	.social-wall__more {
		bottom: 80px;
	}
}

.form-action div:last-child {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 24px;
}

.form-action button[type='reset'] {
	margin-right: 24px;
}
.form-action button[type='reset']:hover {
	color: #2a9bff;
}

.form-action button[type='submit'] {
	width: 100%;
}

.form-action p {
	margin: 0;
}

@media (min-width: 768px) {
	.form-row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.form-row .input {
		width: 50%;
		margin-left: 16px;
	}
	.form-row .input:first-child {
		margin-left: 0;
	}
	.form-action {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.form-action div:last-child {
		margin-top: 0;
	}
	.form-action button[type='submit'] {
		width: auto;
	}
}

.form-contact {
	padding: 24px 16px 0 16px;
	border-bottom: 2px solid #ebebeb;
}
.form-contact .select {
	margin-bottom: 16px;
}
.form-contact__row {
	display: block;
}
.form-contact__other {
	display: none;
}
.form-contact__other.is-other {
	display: inline-block;
}
.form-contact__select,
.form-contact__input,
.form-contact__other {
	width: 100%;
}
.form-contact__textarea textarea {
	min-height: 168px;
}
.form-contact .input--file {
	margin-top: 24px;
	margin-bottom: 40px;
}
.form-contact .form-action {
	margin-top: 24px;
	margin-bottom: 40px;
}

@media (min-width: 768px) {
	.form-contact {
		padding: 48px 48px 0 48px;
		border-bottom: inherit;
	}
	.form-contact__row {
		width: 100%;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	.form-contact__row > div {
		margin-left: 16px;
	}
	.form-contact__row > div:first-child {
		margin-left: 0;
	}
	.form-contact__row.is-right {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}
	.form-contact__select {
		width: 30%;
	}
	.form-contact__other {
		width: 70%;
	}
	.form-contact__input {
		width: 70%;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.form-contact .form-action {
		margin-bottom: 70px;
	}
}

.block-contact {
	width: 100%;
}
.block-contact p {
	color: #999;
}
.block-contact__faq {
	padding: 24px 16px;
}
.block-contact__faq h3 {
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 2px solid #ebebeb;
}
.block-contact__faq a {
	position: relative;
	margin-bottom: 16px;
}
.block-contact__faq a span {
	display: inline-block;
	max-width: 296px;
}
.block-contact__faq a i {
	top: 50%;
	position: absolute;
	right: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.block-contact__faq a,
.block-contact__faq i {
	line-height: 22px;
}
.block-contact__write {
	position: relative;
	background: #fcfcfc;
	padding: 24px 16px;
	margin: 0;
	border: 2px solid #ebebeb;
	border-left: 0;
	border-right: 0;
}
.block-contact__write h4 {
	margin-bottom: 8px;
}
.block-contact__write p {
	margin-bottom: 16px;
}
.block-contact__write button {
	width: 100%;
}
.block-contact__infos {
	padding: 32px 16px 32px 16px;
}
.block-contact__infos h5 {
	margin-bottom: 4px;
}
.block-contact__infos i {
	color: #7d93a8;
	margin-top: -4px;
	margin-right: 8px;
}
.block-contact__infos div {
	display: inline-block;
	width: 100%;
}
.block-contact__infos div:last-child {
	position: relative;
}
.block-contact__infos div:last-child h5 {
	margin-top: 8px;
}
.block-contact__infos div:last-child p {
	line-height: 22px;
	margin-bottom: 8px;
}
.block-contact__infos div:last-child p:last-child {
	margin-bottom: 0;
}
.block-contact__infos div:last-child p.is-active {
	font-weight: 700;
}
.block-contact__infos div:last-child p span {
	width: 100%;
	display: block;
}

@media (min-width: 768px) {
	.block-contact__form,
	.block-contact__faq {
		padding: 40px 40px 0 40px;
	}
	.block-contact__faq h3 {
		margin-bottom: 16px;
	}
	.block-contact__faq a {
		max-width: inherit;
		margin-bottom: 0;
		height: 52px;
		line-height: 52px;
	}
	.block-contact__faq a span {
		display: inherit;
		max-width: inherit;
	}
	.block-contact__faq a i {
		display: inline-block;
		line-height: 52px;
	}
	.block-contact__write {
		border-radius: 4px;
		border: 1px solid #ebebeb;
		margin: 32px 40px 40px;
	}
	.block-contact__write p {
		max-width: 440px;
	}
	.block-contact__write p {
		margin-bottom: 0;
	}
	.block-contact__write button {
		width: inherit;
		position: absolute;
		top: 24px;
		right: 24px;
	}
	.block-contact__infos {
		border-top: 2px solid #ebebeb;
		padding: 40px 40px 40px 40px;
	}
	.block-contact__infos div {
		width: calc(50% - 2px);
		vertical-align: top;
	}
	.block-contact__infos div:last-child {
		position: relative;
		padding-left: 16px;
		border-left: 2px solid #ebebeb;
		text-align: left;
	}
	.block-contact__infos div:last-child h5 {
		margin-top: 0;
	}
	.block-contact__infos div:last-child p {
		margin-bottom: 0;
	}
	.block-contact__infos div:last-child p span {
		display: inline-block;
		position: absolute;
		right: 20px;
		width: 203px;
	}
}

@media (min-width: 1280px) {
	.block-contact__faq {
		padding: 48px 48px 0 48px;
	}
	.block-contact__faq h3 {
		margin-bottom: 16px;
	}
	.block-contact__faq a {
		height: 54px;
	}
	.block-contact__faq a,
	.block-contact__faq i {
		line-height: 54px;
	}
	.block-contact__write {
		margin: 24px 48px 48px 48px;
	}
	.block-contact__write p {
		max-width: 75%;
	}
	.block-contact__write h4 {
		margin-bottom: 8px;
	}
	.block-contact__write button {
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.block-contact__infos {
		padding: 48px 48px 48px 48px;
	}
	.block-contact__infos div:last-child {
		padding-left: 32px;
	}
}

.mosaic-images {
	width: 100%;
	height: 100%;
}
.mosaic-images__left,
.mosaic-images__right {
	font-size: 0;
}
.mosaic-images__left div,
.mosaic-images__right div {
	display: inline-block;
	width: calc(50% - 8px);
	background-size: cover;
	min-width: 161px;
	min-height: 161px;
	margin-right: 16px;
	margin-bottom: 16px;
	cursor: pointer;
}
.mosaic-images__left div:nth-child(2n),
.mosaic-images__right div:nth-child(2n) {
	margin-right: 0;
}
.mosaic-images__left div img,
.mosaic-images__right div img {
	display: none;
}
.mosaic-images__left div:after,
.mosaic-images__right div:after {
	content: '';
	display: block;
	padding-bottom: 100%;
}

@media (min-width: 768px) {
	.mosaic-images__left div,
	.mosaic-images__right div {
		width: calc(100% / 3 - 11px);
		min-width: inherit;
		min-height: 146px;
		margin-right: 16px;
	}
	.mosaic-images__left div:nth-child(2n),
	.mosaic-images__right div:nth-child(2n) {
		margin-right: 16px;
	}
	.mosaic-images__left div:nth-child(3n),
	.mosaic-images__right div:nth-child(3n) {
		margin-right: 0;
	}
}

@media (min-width: 1280px) {
	.mosaic-images {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: -8px;
	}
	.mosaic-images__left,
	.mosaic-images__right {
		width: 50%;
		max-width: 414px;
	}
	.mosaic-images__left div,
	.mosaic-images__right div {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		width: calc(50% - 16px);
		max-width: 192px;
		min-height: 192px;
		margin-right: 16px !important;
	}
	.mosaic-images__left div:nth-child(2n),
	.mosaic-images__right div:nth-child(2n) {
		margin-right: inherit;
	}
	.mosaic-images__left div:nth-child(9n + 3) {
		width: calc(100% - 16px);
		max-width: 400px;
		height: 400px;
	}
	.mosaic-images__right div:nth-child(9n) {
		width: calc(100% - 16px);
		max-width: 400px;
		height: 400px;
	}
}

.directory .member {
	padding: 24px;
	border-bottom: 2px solid #ebebeb;
}
.directory .member:last-child {
	border-bottom: 0;
}

.directory .member--small {
	padding: 0;
	border: 2px solid transparent;
}

.directory .member--area {
	padding-right: 72px;
}

.directory .col-lg-1on9 {
	clear: both;
}

.directory__ref {
	position: relative;
	top: 10px;
	font-family: 'Din';
	font-size: 17px;
}

.directory__items {
	margin: -20px 0 32px 28px;
}

.directory__members {
	margin: 0 2px;
}
.directory__members.accordion-panel {
	border-radius: 0 0 4px 4px;
}

@media (min-width: 768px) {
	.directory__ref {
		font-size: 40px;
		line-height: 1;
		top: 55px;
	}
	.directory__items {
		margin: -40px 0 24px 57px;
	}
}

@media (min-width: 1280px) {
	.directory__ref {
		top: 25px;
	}
	.directory__items {
		margin: 0 0 32px;
	}
}

.header-filter .link-chevron {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	height: 48px;
	line-height: 48px;
	vertical-align: top;
	margin-bottom: 8px;
	height: 48px;
}
.header-filter .link-chevron i {
	line-height: 48px;
	margin-left: 16px;
}
.header-filter .link-chevron i.icon-external {
	margin-left: 8px;
}

.header-filter .input--dropdown {
	margin-bottom: 8px;
}

.header-filter__sep {
	position: relative;
	border-bottom: 1px solid #ebebeb;
	width: 100%;
	right: 0;
}
.header-filter__sep.mobile-only {
	border-bottom: none;
}

.header-filter .header-filter__left {
	display: inline-block;
	margin-left: -16px;
}

.header-filter--team .header-filter__right {
	min-width: 243px;
}
.header-filter--team .header-filter__right .input--dropdown:before {
	left: 0;
}
.header-filter--team .header-filter__right .input--dropdown input {
	padding-left: 24px;
}

.header-filter--teamWfilter .header-filter__left .dropdown {
	display: inline-block;
}
.header-filter--teamWfilter .header-filter__left .dropdown .dropdown__head {
	height: 40px;
	line-height: 40px;
}

@media (min-width: 475px) {
	.header-filter--teamWfilter .header-filter__left .dropdown .dropdown__head {
		height: 48px;
		line-height: 48px;
	}
}

@media (min-width: 768px) {
	.header-filter .dropdown--noBorder {
		display: inline-block;
	}
	.header-filter .link-chevron {
		width: auto;
	}
	.header-filter .input--dropdown {
		margin-top: 0;
		margin-left: 0;
	}
	.header-filter .header-filter__right {
		float: right;
	}
	.header-filter--year .header-filter__left {
		margin-left: -16px;
	}
	.header-filter--team .header-filter__right,
	.header-filter--competition .header-filter__right {
		float: none;
	}
	.header-filter--team .header-filter__right .input--dropdown:before {
		left: 16px;
	}
	.header-filter--team .header-filter__right .input--dropdown input {
		padding-left: 40px;
	}
}

@media (min-width: 855px) {
	.header-filter--team .header-filter__right {
		position: absolute;
		right: 0;
		top: 0;
		width: auto;
		margin-top: 0;
	}
	.header-filter--team .header-filter__left {
		margin-left: -16px;
	}
}

@media (min-width: 1080px) {
	.header-filter--competition .header-filter__right {
		float: right;
	}
}

.player-detail {
	border-bottom: 1px solid #ebebeb;
	padding-bottom: 48px;
}
.player-detail__head {
	margin-top: -52px;
}
.player-detail__head .link-chevron {
	position: absolute;
	top: 15px;
	left: calc(130px + 24px);
	width: 70px;
	white-space: nowrap;
	overflow: hidden;
	color: #fff;
}
.player-detail__head .link-chevron:hover {
	color: #2a9bff;
}
@media (min-width: 540px) {
	.player-detail__head .link-chevron {
		left: 0;
	}
}
@media (min-width: 768px) {
	.player-detail__head .link-chevron {
		left: 0;
		top: 30px;
		width: auto;
		overflow: visible;
	}
}
.player-detail__head .player-portrait {
	float: left;
	width: 130px;
	height: 175px;
	margin-right: 24px;
	background-repeat: no-repeat center center;
	background-size: cover;
	border-radius: 4px;
}
.player-detail__head .player-portrait img {
	display: none;
}
@media (min-width: 540px) {
	.player-detail__head .player-portrait {
		width: 180px;
		height: 240px;
	}
}
@media (min-width: 768px) {
	.player-detail__head .player-portrait {
		max-width: 48%;
		width: 286px;
		height: 42vw;
		margin-bottom: 0;
		margin-right: 32px;
	}
}
@media (min-width: 940px) {
	.player-detail__head .player-portrait {
		height: 388px;
	}
}
.player-detail__head > div:last-of-type {
	position: relative;
	padding-top: calc(52px + 16px);
}
@media (min-width: 540px) {
	.player-detail__head > div:last-of-type {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		margin-left: calc(180px + 32px);
		min-height: 286px;
		padding-top: calc(52px + 24px);
	}
}
@media (min-width: 768px) {
	.player-detail__head > div:last-of-type {
		margin-left: calc(48% + 32px);
		min-height: 388px;
		padding-top: calc(81px + 32px);
	}
}
@media (min-width: 940px) {
	.player-detail__head > div:last-of-type {
		margin-left: calc(286px + 32px);
	}
}
.player-detail__head h3 {
	font-size: 30px;
}
.player-detail__head ul {
	clear: both;
}
.player-detail__head ul li {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-line-pack: center;
	align-content: center;
}
.player-detail__head ul li span {
	color: #999;
	width: calc(130px + 24px);
}
@media (min-width: 768px) {
	.player-detail__head ul li span {
		width: calc(100px + 24px);
	}
}
.player-detail__head ul li p {
	display: inline-block;
	font-size: 15px;
	line-height: 20px;
}
.player-detail__head .role {
	display: block;
	font-weight: 500;
	color: #999;
}
@media (min-width: 768px) {
	.player-detail__head .role {
		font-size: 17px;
	}
}
@media (min-width: 540px) {
	.player-detail__head .btn {
		width: auto;
	}
}
@media (min-width: 768px) {
	.player-detail__head .btn {
		margin-top: auto;
	}
}
@media (min-width: 768px) {
	.player-detail__head {
		margin-top: -81px;
	}
}
.player-detail h2 {
	line-height: 48px;
}
.player-detail > div > p:not(.ft-h6) {
	font-size: 18px;
	line-height: 32px;
	margin-bottom: 16px;
}
.player-detail .card--club {
	margin-bottom: 16px;
}
.player-detail .section {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}
.player-detail .menu-team {
	display: inline-block;
}
.player-detail .menu-team .accordion-panel {
	height: inherit;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 24px;
}
@media (min-width: 768px) {
	.player-detail .menu-team .accordion-panel {
		padding: 32px 40px 32px 40px;
	}
}
.player-detail .menu-team .accordion-panel > li {
	display: block;
	width: 100%;
	margin-top: 16px;
}
.player-detail .menu-team .accordion-panel > li:first-child {
	margin-top: 0;
}
.player-detail .news-slider__wrapper--video {
	width: 604px;
}
@media (min-width: 768px) {
	.player-detail .news-slider__wrapper--video .card--video {
		width: calc(50% - 8px);
	}
}
@media (min-width: 1280px) {
	.player-detail .news-slider__wrapper--video .card--video {
		width: calc(50% - 12px);
	}
}
@media (min-width: 768px) {
	.player-detail .news-slider__wrapper--video {
		width: auto;
	}
}

.auteur-detail h1 {
	font-size: 30px;
	line-height: 37px;
}

.auteur-detail .role {
	font-size: 18px;
}

.auteur-detail .player-detail__head p {
	font-size: 17px;
	line-height: 32px;
}

.auteur-detail .player-detail__head h3 {
	font-size: 20px;
}
@media (min-width: 540px) {
	.auteur-detail .player-detail__head h3 {
		margin-top: 32px;
	}
}

.auteur-detail .player-portrait span {
	display: none;
	height: 100%;
	background-repeat: no-repeat;
	background-position: center;
}

.auteur-detail .player-portrait.player-portrait--empty {
	background: none;
	background-color: #ebebeb;
}
.auteur-detail .player-portrait.player-portrait--empty span {
	display: block;
}

.properties {
	border: 1px solid #ebebeb;
	border-radius: 4px;
}

.properties__title {
	padding: 0 24px;
	line-height: 48px;
	color: #2a9bff;
	border-bottom: 1px solid #ebebeb;
}

.properties__items {
	padding: 24px;
	margin: 0;
	font-size: 17px;
	line-height: 24px;
	color: #999;
}
.properties__items li {
	position: relative;
	margin-bottom: 16px;
	padding-left: 26px;
}
.properties__items li:last-child {
	margin-bottom: 0;
}
.properties__items .icon {
	position: absolute;
	left: 0;
	top: 5px;
	color: #7d93a8;
}

.video-player-top {
	background-color: #002348;
	color: #fff;
	padding: 20px 24px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	cursor: pointer;
}
.video-player-top:hover .icon-close {
	opacity: 1;
}
.video-player-top .icon-close {
	float: right;
	line-height: 22px;
	opacity: 0.64;
}
.video-player-top .link-chevron--left {
	float: none;
}

.video-player {
	z-index: 210;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #002348;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	display: none;
}
.video-player iframe {
	width: 100%;
	height: 100%;
}
.video-player.is-visible {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.video-player__wrapper {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.video-thumbnail {
	position: relative;
	cursor: pointer;
}
.video-thumbnail:hover .link-icon {
	border-color: #fff;
}
.video-thumbnail .link-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -30px 0 0 -30px;
}
.video-thumbnail img {
	width: 100%;
}

.page-maintenance .topbar {
	padding-left: 8px;
}
.page-maintenance .topbar .topbar__right {
	display: none;
}
@media (min-width: 768px) {
	.page-maintenance .topbar .topbar__right {
		display: inline-block !important;
	}
}
.page-maintenance .topbar li:nth-child(5),
.page-maintenance .topbar li:nth-child(6) {
	display: none;
}

.page-maintenance--ffr .topbar li:first-child {
	display: none;
}

.page-maintenance--ffr .topbar li:nth-child(2) a {
	padding-left: 24px;
}

.page-maintenance--ligue .topbar li:nth-child(4) {
	display: none;
}

@media (min-width: 0) and (max-width: 1080px) {
	.page-maintenance .topbar {
		font-size: 13px;
		line-height: 40px;
		padding: 0;
		margin-bottom: 0;
	}
	.page-maintenance .topbar a {
		padding: 0 8px;
	}
	.page-maintenance .topbar li {
		display: inline-block;
		margin-bottom: 0;
	}
	.page-maintenance .topbar li:first-child a {
		padding-left: 24px;
	}
	.page-maintenance .topbar li:nth-child(7) {
		display: none;
	}
	.page-maintenance .topbar .accordion-trigger {
		padding: 0 24px;
	}
	.page-maintenance .topbar .accordion-trigger:after {
		margin-left: 8px;
		line-height: 40px;
		float: none;
		content: '\e906';
	}
	.page-maintenance .topbar .accordion-trigger.is-selected {
		background-color: #004b98;
		color: #fff;
		text-decoration: none;
	}
	.page-maintenance .topbar .accordion-trigger.is-selected:after {
		content: '\e914';
	}
	.page-maintenance .topbar .topbar__sub {
		width: 230px;
		position: absolute;
		background-color: #004b98;
		line-height: 2;
		padding: 8px 24px;
		color: rgba(255, 255, 255, 0.6);
	}
	.page-maintenance .topbar .topbar__sub li {
		display: block;
	}
	.page-maintenance .topbar .topbar__sub a {
		padding: 0;
	}
	.page-maintenance .topbar .topbar__right {
		float: right;
	}
}
@media (min-width: 0) and (max-width: 1080px) and (min-width: 768px) {
	.page-maintenance .topbar .topbar__right {
		display: inline-block;
	}
}

@media (min-width: 0) and (max-width: 1080px) {
	.page-maintenance .topbar .topbar__right i {
		top: -2px;
	}
	.page-maintenance .topbar .topbar__right .topbar__sub {
		padding-bottom: 16px;
	}
	.page-maintenance .topbar .topbar__user {
		padding: 0 24px;
		color: #fff;
		max-width: 220px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		border-left: 1px solid rgba(255, 255, 255, 0.06);
	}
	.page-maintenance .topbar .topbar__user img {
		margin-right: 8px;
		border-radius: 100%;
	}
	.page-maintenance .topbar .topbar__login {
		padding: 0;
		background: #1a395a;
		color: #fff;
		line-height: 32px;
		padding: 0 8px;
		border-radius: 4px;
		margin-right: 4px;
		margin-top: 4px;
	}
}
@media (min-width: 0) and (max-width: 1080px) and (min-width: 768px) {
	.page-maintenance .topbar .topbar__login {
		display: inline-block;
	}
}

@media (min-width: 0) and (max-width: 1080px) {
	.page-maintenance .topbar .topbar__login:hover {
		background: #20456e;
	}
	.page-maintenance .topbar .topbar__news {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.page-maintenance .topbar li:nth-child(5),
	.page-maintenance .topbar li:nth-child(6) {
		display: inline-block;
	}
	.page-maintenance .topbar li:first-child {
		display: inline-block;
	}
	.page-maintenance .topbar li:nth-child(2) a {
		padding-left: 8px;
	}
	.page-maintenance .topbar li:nth-child(4) {
		display: inline-block;
	}
	.page-maintenance .topbar li:nth-child(7) {
		display: inline-block;
	}
}

@media (min-width: 768px) and (max-width: 1080px) {
	.page-maintenance .topbar li:nth-child(5),
	.page-maintenance .topbar li:nth-child(6) {
		display: inline-block;
	}
	.page-maintenance .topbar li:nth-child(7) {
		display: none;
	}
}

.page-maintenance {
	margin-bottom: 24px;
}
.page-maintenance .container {
	padding: 0;
}
.page-maintenance .copyright {
	margin-top: 24px;
	text-align: center;
	opacity: 0.2;
	font-size: 14px;
}

.block-maintenance {
	background: #fff;
	padding: 48px 24px;
	-webkit-box-shadow: 0 4px 10px 0 rgba(13, 24, 33, 0.1);
	box-shadow: 0 4px 10px 0 rgba(13, 24, 33, 0.1);
}
@media (min-width: 768px) {
	.block-maintenance {
		width: 704px;
		margin: 0 auto;
		border-radius: 2px;
		border: 1px #ebebeb solid;
		border-radius: 4px;
		margin-top: 112px;
		padding: 96px 54px;
	}
}
@media (min-width: 1280px) {
	.block-maintenance {
		width: 816px;
	}
}
.block-maintenance > div {
	max-width: 327px;
	margin: 0 auto;
}
@media (min-width: 768px) {
	.block-maintenance > div {
		max-width: 614px;
	}
}
.block-maintenance > div img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 43px;
	width: 76px;
	height: auto;
}
.block-maintenance > div h2 {
	margin-bottom: 16px;
	text-align: center;
}
.block-maintenance > div h2 br {
	display: none;
}
@media (min-width: 768px) {
	.block-maintenance > div h2 br {
		display: block;
	}
}
@media (min-width: 1280px) {
	.block-maintenance > div h2 br {
		display: none;
	}
}
.block-maintenance > div p {
	text-align: center;
	color: #999;
	margin-bottom: 32px;
}
@media (min-width: 768px) {
	.block-maintenance > div p {
		max-width: 538px;
		margin-left: auto;
		margin-right: auto;
	}
}
.block-maintenance > div a {
	display: block;
	width: 100%;
}
@media (min-width: 768px) {
	.block-maintenance > div a {
		display: inline-block;
		max-width: 291px;
	}
}
@media (min-width: 1280px) {
	.block-maintenance > div a {
		max-width: 301px;
	}
}
.block-maintenance > div a.btn--facebook {
	margin-bottom: 12px;
}
@media (min-width: 768px) {
	.block-maintenance > div a.btn--facebook {
		margin-bottom: 0;
	}
}
@media (min-width: 768px) {
	.block-maintenance > div a.btn--twitter {
		margin-left: 12px;
	}
}
.block-maintenance--ligue > div h2 br {
	display: none;
}
@media (min-width: 768px) {
	.block-maintenance--ligue > div h2 br {
		display: block;
	}
}
.block-maintenance--ligue > div img {
	width: 300px;
}

.accordion {
	border-radius: 0px;
}
.accordion.accordion--mini .accordion-trigger {
	border-radius: 4px;
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}
.accordion.accordion--mini .accordion-trigger.is-selected {
	border-bottom: 0;
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
}
.accordion.accordion--mini .btn--icon {
	position: absolute;
	right: 16px;
	top: 50%;
	margin-top: -16px;
}
.accordion.accordion--mini .accordion-panel {
	border: 1px solid #ebebeb;
	border-radius: 4px;
	border-top: 0;
}
.accordion.accordion--mini .accordion-panel:last-child {
	border-bottom: 1px solid #ebebeb;
}
.accordion .accordion-trigger {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 73px;
	width: 100%;
	border-bottom: 1px solid #ebebeb;
	background-color: #fff;
}
.accordion .accordion-trigger:hover {
	cursor: pointer;
}
.accordion .accordion-trigger:nth-last-child(2) {
	border-bottom: 0;
}
.accordion .accordion-trigger.is-selected {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-bottom: 1px solid #ebebeb;
}
.accordion .accordion-trigger .btn--icon i {
	font-weight: bold;
}
.accordion .accordion-panel {
	border-bottom: 1px solid #ebebeb;
	margin-bottom: 0;
	padding-bottom: 0;
	background-color: #fcfcfc;
	overflow: hidden;
}
.accordion .accordion-panel.is-hidden {
	padding: 0;
	max-height: 0;
}
.accordion .accordion-panel:last-child {
	border-bottom: 0;
}
.accordion .accordion-panel .content {
	padding: 24px 16px;
}
.accordion .accordion-panel .content p,
.accordion .accordion-panel .content .list {
	font-size: 14px;
	line-height: 22px;
}
.accordion .accordion-panel .card:last-child {
	margin-bottom: 8px;
}
.accordion .directory .member {
	border-bottom-width: 1px;
}
.accordion .ft-h4 {
	font-size: 15px;
}

@media (min-width: 768px) {
	.accordion .accordion-trigger {
		padding: 16px 20px 16px 40px;
	}
	.accordion .accordion-trigger h4 {
		width: calc(100% - 56px);
	}
	.accordion .accordion-trigger .btn--icon {
		right: 20px;
	}
	.accordion .accordion-panel .content {
		padding: 32px 40px;
	}
}

@media (min-width: 1280px) {
	.accordion .content {
		padding: 32px 48px 40px 40px;
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.block-contact-persons .contact__staff {
	padding: 0;
}

.block-contact-persons .card__body > p {
	font-size: 16px;
}

.block-contact-persons .member {
	display: block;
	padding: 15px 0 10px 0;
	border-bottom: 1px solid #ebebeb;
}
.block-contact-persons .member:first-child {
	padding-top: 5px;
}
.block-contact-persons .member:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}

@media (min-width: 768px) {
	.block-contact-persons .contact__staff {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.block-contact-persons .member {
		display: inline-block;
		width: 25%;
		border-bottom: 0;
		padding-top: 0;
	}
	.block-contact-persons .member:first-child {
		padding-top: 0px;
	}
}

.color-picker {
	padding: 16px;
}
.color-picker[data-type='secondary'] .color {
	position: relative;
}
.color-picker[data-type='secondary'] .color:before {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	pointer-events: none;
}
.color-picker .colors {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-left: -4px;
}
.color-picker .colors--secondary {
	display: none;
}
.color-picker .color {
	border-radius: 4px;
	height: 38px;
	width: calc(20% - 4px);
	margin-left: 4px;
	margin-bottom: 4px;
	overflow: hidden;
}
.color-picker .color:hover i {
	opacity: 1;
}
.color-picker .color label {
	display: block;
	height: 100%;
	text-align: center;
	line-height: 32px;
	font-size: 16px;
	border: 2px solid transparent;
	cursor: pointer;
}
.color-picker .color label i {
	color: #fff;
	opacity: 0;
}
.color-picker .color input {
	position: absolute;
	visibility: hidden;
}
.color-picker .color input:checked + label {
	border-color: rgba(41, 41, 41, 0.2);
}
.color-picker .color input:checked + label i {
	opacity: 1;
}

.color-picker-primary-1 {
	background-color: #b02f38;
}

.color-picker-primary-2 {
	background-color: #cb5e37;
}

.color-picker-primary-3 {
	background-color: #d88c3b;
}

.color-picker-primary-4 {
	background-color: #d7b116;
}

.color-picker-primary-5 {
	background-color: #80ab30;
}

.color-picker-primary-6 {
	background-color: #25834e;
}

.color-picker-primary-7 {
	background-color: #2d9e93;
}

.color-picker-primary-8 {
	background-color: #3485b7;
}

.color-picker-primary-9 {
	background-color: #3c5ec9;
}

.color-picker-primary-10 {
	background-color: #813dd1;
}

.color-picker-primary-11 {
	background-color: #b7367c;
}

.color-picker-primary-12 {
	background-color: #cc3555;
}

.color-picker-secondary-1 {
	background-color: #b02f38;
}

.color-picker-secondary-2 {
	background-color: #cb5e37;
}

.color-picker-secondary-3 {
	background-color: #d88c3b;
}

.color-picker-secondary-4 {
	background-color: #d7b116;
}

.color-picker-secondary-5 {
	background-color: #80ab30;
}

.color-picker-secondary-6 {
	background-color: #25834e;
}

.color-picker-secondary-7 {
	background-color: #2d9e93;
}

.color-picker-secondary-8 {
	background-color: #3485b7;
}

.color-picker-secondary-9 {
	background-color: #3c5ec9;
}

.color-picker-secondary-10 {
	background-color: #813dd1;
}

.color-picker-secondary-11 {
	background-color: #b7367c;
}

.color-picker-secondary-12 {
	background-color: #cc3555;
}

.color-picker-secondary-13 {
	background-color: #1a1a1a;
}

@media (min-width: 768px) {
	.color-picker .colors {
		margin-left: -1px;
	}
	.color-picker .colors--secondary {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.color-picker .color {
		width: calc(14% - 4px);
		height: 23px;
		margin-left: 1px;
		margin-bottom: 1px;
	}
	.color-picker .color:nth-child(n + 9) {
		display: block;
	}
	.color-picker .color label {
		line-height: 17px;
	}
	.color-picker .color span {
		position: relative;
		display: block;
		height: 100%;
		text-align: center;
		font-size: 16px;
		border-width: 0;
	}
	.color-picker[data-type='primary'] .color span::before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.45);
	}
	.color-picker[data-type='secondary'] .color span::before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.15);
	}
}

@media (min-width: 1280px) {
	.color-picker .color {
		height: 32px;
	}
	.color-picker .color label {
		line-height: 26px;
	}
}

.bloc-user-manage {
	padding: 32px 24px;
	background-color: #fcfcfc;
	border: 1px solid #ebebeb;
	border-radius: 4px;
}
.bloc-user-manage .buttons .btn {
	width: 100%;
	height: 48px;
	line-height: 48px;
}
.bloc-user-manage .icon {
	margin-right: 16px;
	color: #7d93a8;
	font-size: 16px;
}
.bloc-user-manage p {
	display: inline-block;
	font-size: 17px;
	line-height: 20px;
	margin-bottom: 0;
}
@media (min-width: 768px) {
	.bloc-user-manage {
		padding: 24px;
	}
	.bloc-user-manage .buttons {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.bloc-user-manage .buttons .btn {
		width: calc(50% - 8px);
	}
	.bloc-user-manage .icon {
		font-size: 20px;
		margin-right: 24px;
	}
}
@media (min-width: 1280px) {
	.bloc-user-manage {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.bloc-user-manage .icon {
		font-size: 24px;
	}
	.bloc-user-manage .buttons .btn {
		width: auto;
	}
	.bloc-user-manage .buttons .btn:first-child {
		margin-right: 16px;
	}
}

.news-slider {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	margin-left: -16px;
	margin-right: -16px;
	padding-left: 16px;
}
.news-slider .news,
.news-slider .card--video {
	vertical-align: top;
	display: inline-block;
	width: 220px;
	margin-left: 16px;
	margin-bottom: 0;
}
.news-slider .news:first-child,
.news-slider .card--video:first-child {
	margin-left: 0;
}
.news-slider .card--video {
	width: 286px;
}
.news-slider .picture {
	position: relative;
	width: 120px;
	height: 76px;
	margin-left: 8px;
}
.news-slider .picture:first-child {
	margin-left: 0;
}
.news-slider .picture:before,
.news-slider .picture:after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.news-slider .picture:before {
	background-color: rgba(42, 155, 255, 0.5);
	opacity: 0;
	-webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	z-index: 2;
}
.news-slider .picture .see-more {
	position: absolute;
	display: none;
	top: 50%;
	left: 0;
	width: 100%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	padding: 0 8px;
	font-size: 12px;
	font-family: 'Din';
	font-weight: bold;
	text-align: center;
	color: #fff;
	z-index: 3;
}
.news-slider .picture--last .see-more {
	display: block;
}
.news-slider .picture--last:before {
	background-color: rgba(1, 57, 116, 0.88);
	opacity: 1;
}
.news-slider .picture:hover:not(.picture--active) {
	cursor: pointer;
}
.news-slider .picture:hover:not(.picture--active):before {
	opacity: 1;
}
.news-slider .picture.picture--active:after {
	border: 4px solid #2a9bff;
	z-index: 1;
}
.news-slider .picture .picture__thumbnail {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: no-repeat center center;
	background-size: cover;
	z-index: 1;
}
.news-slider .picture img {
	display: none;
}

.news-slider__wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 708px;
}
.news-slider__wrapper--video {
	width: 908px;
}
.news-slider__wrapper--picture {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 786px;
}

@media (min-width: 768px) {
	.news-slider {
		overflow-x: auto;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
	}
	.news-slider .news,
	.news-slider .card--video {
		width: calc(50% - 8px);
		margin-left: 16px;
	}
	.news-slider .news:nth-child(3),
	.news-slider .card--video:nth-child(3) {
		display: none;
	}
	.news-slider .picture {
		width: 160px;
		height: 88px;
		margin-left: 16px;
	}
	.card--video {
		height: 269px;
	}
	.news-slider__wrapper {
		width: auto;
	}
	.news-slider__wrapper--picture {
		width: 1068px;
	}
}

@media (min-width: 1280px) {
	.news-slider .news,
	.news-slider .card--video {
		width: calc(33.3% - 16px);
		margin-left: 24px;
	}
	.news-slider .news:nth-child(3),
	.news-slider .card--video:nth-child(3) {
		display: inline-block;
	}
	.news-slider .card--video {
		height: 292px;
	}
	.news-slider .picture {
		width: 184px;
		height: 100px;
	}
	.news-slider .news--row-lg {
		display: block;
		width: 100%;
		margin-left: 0;
		margin-bottom: 24px;
	}
	.news-slider .news--row-lg:last-child {
		margin-bottom: 0;
	}
	.news-slider__wrapper {
		display: block;
	}
}

.result {
	position: relative;
}
.result .result-club__maps {
	position: absolute;
	top: 0;
	padding-top: 0;
	height: 100%;
}

.result-club {
	position: relative;
	width: 100%;
	height: calc(100vh - 128px);
}
.result-club.has-map .result-club__maps {
	display: block;
}
.result-club.has-map .result-club__list {
	display: none;
}
.result-club .card--club.box:hover,
.result-club .card--club.is-selected {
	-webkit-box-shadow: 0 0 0 2px inset #2a9bff, 0 10px 16px rgba(51, 51, 51, 0.08);
	box-shadow: 0 0 0 2px inset #2a9bff, 0 10px 16px rgba(51, 51, 51, 0.08);
}
.result-club .card--club:hover .card__title,
.result-club .card--club:focus .card__title {
	color: #292929;
	text-decoration: none;
}
.result-club .card--club:hover .card__position,
.result-club .card--club:focus .card__position {
	color: #fff;
	background: #2a9bff;
}

.result-club__maps {
	display: none;
	position: fixed;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	padding-top: 129px;
	height: 100%;
	background-color: #f0f2f5;
	z-index: 0;
}
.result-club__maps iframe {
	width: 100%;
	height: 100%;
}
.result-club__maps .btn {
	position: absolute;
	top: 144px;
	right: 16px;
	width: 128px;
}

.result-club__slider {
	position: absolute;
	bottom: 40px;
	left: 0;
	width: 100%;
}
.result-club__slider .card {
	width: 303px;
	margin: 0 auto;
}

.result-club__header .btn {
	float: right;
	width: 128px;
}

.result-club__header p {
	margin: 0;
	line-height: 48px;
	color: #999;
}

.result-academies .result-club__maps {
	z-index: 0;
	height: 100vh;
}
.result-academies .result-club__maps.sticky {
	position: fixed;
	height: 100vh;
}
.result-academies .result-club__maps.fullheight {
	bottom: 0;
	top: auto;
}
.result-academies .result-club__maps.fullheight iframe {
	position: absolute;
	height: 100vh;
	bottom: 0;
}

@media (min-width: 768px) {
	.result-club {
		height: calc(100vh - 136px);
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
	}
	.result-club__maps {
		display: block;
		right: 0;
		width: 41%;
	}
	.result-club__maps .btn {
		display: none;
	}
	.result-club__slider {
		display: none;
	}
	.result-club__header .btn {
		display: none;
	}
	.result-club__header .dropdown {
		float: right;
		width: 255px;
	}
	.result-club__header p {
		float: left;
		line-height: 56px;
	}
}

@media (min-width: 1280px) {
	.result-club {
		height: calc(100vh - 210px);
	}
	.result-club__maps {
		width: 50%;
		width: calc(50% + 60px);
		padding-top: 210px;
	}
}

.article {
	border-bottom: 1px solid #ebebeb;
	margin-bottom: 40px;
	padding-bottom: 24px;
}
.article > div > p,
.article .list {
	margin-bottom: 16px;
	font-size: 18px;
	line-height: 32px;
}
.article > div > p a {
	color: #2a9bff;
	font-weight: 700;
}
.article > div > p a.btn--primary {
	color: #fff;
}
.article .card--user {
	width: calc(33.3% - 21px);
	max-width: 200px;
}
.article .card,
.article .event {
	margin-bottom: 40px;
	margin-top: 32px;
}
.article .card--download + .card--download {
	margin-top: -32px;
}

.article figure:not(.article__cover),
.article table,
.page figure:not(.article__cover),
.page table {
	margin-top: 32px;
	margin-bottom: 40px;
}

.article .carousel-photo,
.page .carousel-photo {
	margin-bottom: 40px;
}
.article .carousel-photo figure,
.page .carousel-photo figure {
	margin-bottom: 8px;
}

.article .quote,
.page .quote {
	margin-top: 32px;
	margin-bottom: 32px;
}

.article .twitter-tweet,
.page .twitter-tweet {
	margin-top: 24px !important;
	margin-bottom: 32px !important;
}

.article .iframe-youtube,
.page .iframe-youtube {
	width: 100%;
	height: 208px;
}

.article__title {
	margin-bottom: 32px;
}

.article__meta {
	margin-bottom: 16px;
}
.article__meta .label {
	margin-right: 8px;
}

.article__date {
	color: #ccc;
}

.article__cover {
	margin-bottom: 24px;
	background-color: transparent;
	-webkit-transition: background 0.3s;
	transition: background 0.3s;
	z-index: 1;
}
.article__cover figcaption {
	margin-bottom: 0;
}
.article__cover .iframe-close {
	display: none;
}

#stickyVideoWidget {
	position: fixed;
	bottom: 16px;
	right: 16px;
	width: 204px;
	height: 100px;
	padding: 0;
	margin: 0;
	z-index: 10;
	background-color: #f0f2f5;
	-webkit-transform: translateY(110%);
	transform: translateY(110%);
	opacity: 0;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}
#stickyVideoWidget.is-active {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
#stickyVideoWidget .iframe-container {
	height: 100%;
}
#stickyVideoWidget .iframe-container iframe {
	width: 151px !important;
}
@media (min-width: 768px) {
	#stickyVideoWidget .iframe-container iframe {
		width: 100% !important;
	}
}
#stickyVideoWidget .iframe-container #iframe-widget__close {
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 54px;
	right: 0;
	top: 0;
	height: 100%;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	background-color: #fff;
	color: #999;
}
@media (min-width: 768px) {
	#stickyVideoWidget .iframe-container #iframe-widget__close {
		right: 10px;
		top: 10px;
		width: 24px;
		height: 24px;
		background-color: transparent;
		color: #fff;
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
		z-index: 2;
	}
}
@media (min-width: 768px) {
	#stickyVideoWidget {
		width: 28rem;
		height: 15.75rem;
		max-width: 390px;
		max-height: 260px;
	}
}
@media (min-width: 1280px) {
	#stickyVideoWidget {
		bottom: 56px;
		right: 60px;
	}
}

.article__thumbnail {
	height: 280px;
	background: no-repeat center center;
	background-size: cover;
}
.article__thumbnail img {
	display: none;
}

.article__thumbnail--iframe {
	position: relative;
	padding-bottom: 47.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}
@media (min-width: 1680px) {
	.article__thumbnail--iframe {
		max-width: 950px;
	}
}
@media (min-width: 768px) {
	.article__thumbnail--iframe {
		padding-bottom: 50.25%;
	}
}
@media (min-width: 1280px) {
	.article__thumbnail--iframe {
		padding: 0;
	}
}
.article__thumbnail--iframe iframe,
.article__thumbnail--iframe object,
.article__thumbnail--iframe embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}
@media (min-width: 1280px) {
	.article__thumbnail--iframe iframe,
	.article__thumbnail--iframe object,
	.article__thumbnail--iframe embed {
		position: initial;
		width: initial;
		height: initial;
	}
}

.article__social {
	padding: 16px 0;
	margin: 24px 0 32px;
	border-top: 2px solid #ebebeb;
	border-bottom: 2px solid #ebebeb;
	text-align: center;
}
.article__social .btn {
	margin: 0 20px;
}

.article__headline {
	font-size: 20px;
	line-height: 32px;
	font-weight: 300;
}

.article__topbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 64px;
	z-index: 20;
	background-color: #fff;
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	display: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.article__topbar p {
	margin: 0;
	line-height: 32px;
}
.article__topbar > div {
	padding-left: 16px;
	padding-right: 16px;
	margin: 0 auto;
	width: 100%;
	max-width: 1680px;
}
.article__topbar .label {
	float: left;
	margin-right: 8px;
	margin-top: 4px;
}
.article__topbar .btn--icon {
	display: none;
}
.article__topbar.is-visible {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.article__topbar-social {
	float: right;
}
.article__topbar-social .btn {
	margin-left: 8px;
}

@media (min-width: 768px) {
	.article > div > p,
	.article .list {
		font-size: 17px;
	}
	.article .iframe-youtube,
	.page .iframe-youtube {
		height: 288px;
	}
	.article .carousel-photo,
	.page .carousel-photo {
		margin-bottom: 56px;
	}
	.article .card:not(.card--large),
	.page > .card:not(.card--large, .card--ticket) {
		width: 66.6%;
	}
	.article__cover {
		margin-bottom: 32px;
	}
	.article__social {
		margin-bottom: 40px;
	}
}

@media (min-width: 1080px) {
	.article .card + .card + p,
	.article .card + .card + h2,
	.article .card + .card + h3,
	.page .card + .card + p,
	.page .card + .card + h2,
	.page .card + .card + h3 {
		clear: both;
	}
	.article .card + p + .card,
	.page .card + p + .card {
		clear: left;
	}
	.article .card:not(.card--large),
	.page > .card:not(.card--large, .card--ticket) {
		width: 41.66%;
		margin-right: 32px;
		margin-top: 0;
		float: left;
	}
}

@media (min-width: 1280px) {
	.article {
		padding-bottom: 56px;
	}
	.article .article__headline {
		margin-bottom: 40px;
	}
	.article .event,
	.page .event {
		max-width: 498px;
		margin-left: auto;
		margin-right: auto;
	}
	.article .iframe-youtube,
	.page .iframe-youtube {
		height: 432px;
	}
	.article__title {
		width: 48.5%;
		height: 363px;
		border-bottom: 1px solid #ebebeb;
		margin-bottom: 80px;
		line-height: 48px;
	}
	.article__cover {
		position: absolute;
		top: 0;
		left: 53%;
		margin: 0;
		width: 100%;
	}
	.article__cover figcaption {
		width: 50%;
	}
	.article__thumbnail {
		height: 460px;
	}
	.article__sidebar {
		padding-top: 610px;
	}
	.article__social {
		position: absolute;
		left: -10%;
		top: 0;
		border: 0;
		margin: 0;
		padding-top: 8px;
	}
	.article__social .btn {
		display: block;
		margin-bottom: 12px;
	}
	.article__headline {
		font-size: 28px;
		line-height: 40px;
	}
	.article__topbar .btn--icon {
		float: right;
		display: block;
	}
	.article__topbar p {
		line-height: 40px;
	}
	.article__topbar .label {
		margin-top: 8px;
	}
	.article__topbar-social {
		display: none;
	}
}

.page {
	padding-top: 32px;
}
.page > p,
.page .list {
	font-size: 18px;
	line-height: 32px;
}
.page > h6 {
	color: #999;
	text-transform: uppercase;
	margin-top: 24px;
	margin-bottom: 8px;
}
.page .list {
	margin-left: 0;
}
.page .article__headline {
	margin-bottom: 40px;
}

.page--full {
	padding-bottom: 40px;
}

.page__cards {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.page__cards .card {
	width: 100%;
	margin-bottom: 16px;
}

.page__head h3 {
	margin-bottom: 24px;
}

.page__head .input--search {
	margin-bottom: 0;
}
.page__head .input--search:before {
	font-size: 12px;
	margin-top: -6px;
}
.page__head .input--search input {
	font-size: 16px;
}

@media (min-width: 768px) {
	.page {
		padding-top: 40px;
	}
	.page > p,
	.page .list {
		font-size: 17px;
	}
	.page .card--data {
		width: 286px;
		margin-left: auto;
		margin-right: auto;
	}
	.page .article__headline {
		margin-bottom: 56px;
	}
	.page--full {
		padding-top: 56px;
		padding-bottom: 56px;
	}
	.page__head {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.page__head h3,
	.page__head .dropdown {
		margin-bottom: 0;
	}
	.page__head .dropdown,
	.page__head .input--search {
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.page__head .dropdown {
		width: 240px;
	}
	.page__head .input--search {
		width: 275px;
	}
	.page__head .input--search:before {
		left: 16px;
	}
	.page__head .input--search input {
		font-size: 14px;
		padding-left: 40px;
		padding-right: 16px;
	}
	.page__cards .card {
		width: 48%;
		width: calc(50% - 8px);
	}
}

@media (min-width: 1280px) {
	.page .card--data,
	.page .card--job,
	.page .card--game {
		width: 286px;
		margin-right: 32px;
		margin-top: 0;
		float: left;
	}
	.page--full {
		padding-bottom: 80px;
	}
	.page__cards:after {
		content: '';
		width: 33%;
		width: calc(33.3% - 21px);
	}
	.page__cards .card {
		width: 33%;
		width: calc(33.3% - 21px);
		margin-bottom: 32px;
	}
	.page__head .input--search {
		width: 390px;
	}
}

.events .navbar__sub {
	top: 95%;
}

.events__filter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	height: 62px;
	border-bottom: 2px solid #ebebeb;
}
.events__filter .dropdown,
.events__filter .filter-option__cta {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
	border: none;
}
.events__filter .dropdown__head,
.events__filter .filter-option__cta {
	height: 100%;
	line-height: 62px;
}
.events__filter .dropdown {
	-webkit-box-flex: 2;
	-ms-flex-positive: 2;
	flex-grow: 2;
	border-right: 1px solid #ebebeb;
	min-width: 0;
}
.events__filter .filter-option__cta {
	padding: 0 32px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	font-family: 'Din';
	font-size: 12px;
	font-weight: 700;
}
.events__filter .navbar {
	display: none;
}

.events__pagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 72px;
	padding: 0 16px;
}
.events__pagination .is-inline {
	margin-left: 0;
}

.events__group {
	border-bottom: 1px solid #ebebeb;
}
.events__group > .link-chevron {
	margin-left: 80px;
	border-left: 1px solid #ebebeb;
	border-bottom: 3px solid #2a9bff;
}
.events__group > .link-chevron.is-selected {
	border-top: 1px solid #ebebeb;
}
.events__group .event-row {
	border-bottom: 0;
}
.events__group .event-row .event__content,
.events__group .event-row .card--nextGameFull {
	border-bottom: 1px solid #ebebeb;
}
.events__group .event-row:last-child .event__content,
.events__group .event-row:last-child .card--nextGameFull {
	border-bottom: 0;
}

.events__filter--ffr {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	height: auto;
}
.events__filter--ffr .dropdown {
	border-right: 0;
	border-bottom: 1px solid #ebebeb;
}
.events__filter--ffr .dropdown:last-child {
	border-bottom: 0;
}
.events__filter--ffr .filter-option .btn--white {
	border-left: 1px solid #ebebeb;
}

.events__filter--ligue {
	display: initial;
	height: 124px;
}
.events__filter--ligue .dropdown {
	border-bottom: 1px solid #ebebeb;
}

.events__filter-group {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.events__filter-group .dropdown {
	border-bottom: 0;
}

@media (min-width: 768px) {
	.events .event__content {
		-webkit-transition: -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: -webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1),
			-webkit-box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
	.events .event__content:hover {
		-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 40px rgba(51, 51, 51, 0.08);
		box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 40px rgba(51, 51, 51, 0.08);
	}
	.events .card--nextGameFull:hover {
		-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 40px rgba(51, 51, 51, 0.08);
		box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 40px rgba(51, 51, 51, 0.08);
	}
	.events .card--nextGameFull.has-training:hover {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.events__filter .dropdown {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		border-left: 1px solid #ebebeb;
		border-right: 0;
	}
	.events__filter .navbar {
		display: block;
		z-index: 2;
	}
	.events__filter .filter-option__cta {
		display: none;
	}
	.events__filter .navbar__links > li {
		line-height: 62px;
	}
	.events__filter .navbar__links > li > a {
		padding: 0 12px;
	}
	.events__pagination {
		padding: 0 24px;
	}
	.events__filter--ffr {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.events__filter--ffr .navbar {
		display: none;
	}
	.events__filter--ffr .dropdown {
		border-bottom: 0;
	}
	.events__filter--ffr .dropdown--navbar {
		width: 60%;
		-ms-flex-negative: 1;
		flex-shrink: 1;
	}
	.events__filter--ffr .filter-option .btn--white {
		display: block;
	}
	.events__filter-group {
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}
	.events__filter--ligue {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		height: 64px;
	}
	.events__filter--ligue .dropdown {
		border-bottom: initial;
	}
	.events__filter--ligue .dropdown.dropdown--navbar {
		display: none;
	}
}

@media (min-width: 1280px) {
	.events__group > .link-chevron {
		margin-left: 119px;
	}
	.events__filter {
		padding-left: 40px;
	}
	.events__filter .navbar__links > li > a {
		padding: 0 16px;
	}
	.events__pagination {
		padding: 0 40px;
	}
	.events__filter--ffr .dropdown--navbar {
		display: none;
	}
	.events__filter--ffr .navbar {
		display: block;
	}
	.events__filter--ffr .filter-option .btn--white {
		display: none;
	}
	.events__filter--ffr .filter-option .dropdown {
		margin-bottom: 0;
	}
	.events__filter--ffr .filter-option__list {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.events__filter-group {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
	}
	.events__filter-group .dropdown {
		border-left: 1px solid #ebebeb;
	}
}

.search {
	background-color: #fff;
	padding-top: 24px;
	padding-bottom: 48px;
}
.search .select {
	margin-bottom: 24px;
}
.search .link-blur:before {
	height: 160px;
}
.search .navbar {
	display: none;
}
.search h3 .label {
	margin-left: 8px;
}

@media (min-width: 768px) {
	.search {
		padding-top: 40px;
		padding-bottom: 56px;
	}
	.search .select {
		display: none;
	}
	.search .navbar {
		display: block;
		position: relative;
	}
	.search .navbar:after {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 4px;
		background: #ebebeb;
		border-radius: 4px;
	}
}

@media (min-width: 1280px) {
	.search {
		padding-bottom: 80px;
	}
	.search .col-lg-8,
	.search .col-lg-4 {
		float: right;
	}
}

.news:hover,
.news:focus {
	color: inherit;
}

.rugby-type {
	font-family: 'Din';
	height: 106px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 24px;
	border-bottom: 2px dashed #ebebeb;
}
.rugby-type:hover {
	text-decoration: none;
}
.rugby-type:hover .link-icon {
	color: #2a9bff;
}
.rugby-type:last-child {
	border-bottom: 0;
}
.rugby-type .link-icon {
	margin: 0;
	border-color: transparent;
}
.rugby-type .link-icon i {
	font-size: 10px;
	top: -1px;
}

.rugby-type__logo {
	font-size: 48px;
	font-weight: 700;
	color: #002348;
	text-shadow: 0 3px #ccc;
	width: 80px;
	text-align: center;
	margin-right: 32px;
}

.rugby-type__name {
	font-size: 15px;
	font-weight: 700;
	color: #292929;
}

@media (min-width: 768px) {
	.rugby-type {
		padding: 0 32px;
	}
	.rugby-type__logo {
		font-size: 64px;
		margin-right: 40px;
	}
}

@media (min-width: 1280px) {
	.rugby-type {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		height: auto;
		width: 25%;
		border-bottom: 0;
		border-right: 1px solid #ebebeb;
		float: left;
		height: 424px;
		padding: 80px 0 32px;
	}
	.rugby-type:hover .rugby-type__logo {
		color: #002348;
		text-shadow: 0 7px #ccc;
	}
	.rugby-type:nth-child(3) .rugby-type__logo {
		letter-spacing: -14px;
		text-indent: -14px;
	}
	.rugby-type .link-icon {
		border-color: #ebebeb;
	}
	.rugby-type__logo {
		font-size: 120px;
		margin-right: 0;
		width: auto;
		color: #ccc;
		text-shadow: none;
		-webkit-transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
		transition: all 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	}
	.rugby-type__name {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
}

@media (min-width: 1080px) {
	.topbar {
		padding-left: 8px;
	}
	.topbar .accordion-trigger {
		border-left: 1px solid rgba(255, 255, 255, 0.06);
		margin-left: 16px;
	}
	.topbar__sub {
		margin-left: 16px;
	}
}

.match {
	height: 80px;
}

@media (min-width: 1280px) {
	.match {
		font-size: 20px;
	}
	.match__score {
		font-size: 25px;
	}
}

.my-news {
	background-color: rgba(0, 0, 0, 0.4);
	color: #fff;
	text-align: center;
	padding: 16px;
	border-radius: 4px;
}
.my-news .ft-h6 {
	margin-bottom: 8px;
}
.my-news a {
	margin-left: 8px;
	text-decoration: underline;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.my-news a:hover {
	color: #fff;
}
.my-news .icon-record {
	font-size: 10px;
}

.my-news__content {
	margin-bottom: 0;
	color: rgba(255, 255, 255, 0.6);
}

.my-news-container {
	position: absolute;
	bottom: calc(100vh - 495px);
	width: 100%;
	padding: 0;
	z-index: 1;
}
.my-news-container .container {
	padding: 0;
}

.my-news--home {
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 0;
}

.my-news--transparent {
	padding-top: 8px;
}
.my-news--transparent .ft-h6 {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	margin-left: -16px;
	margin-right: -16px;
	padding-bottom: 8px;
}
.my-news--transparent .my-news__content {
	text-align: left;
}

@media (min-width: 768px) {
	.my-news {
		background-color: rgba(0, 0, 0, 0.4);
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0;
		height: 40px;
		line-height: 20px;
		text-align: left;
	}
	.my-news p {
		padding: 0 16px;
	}
	.my-news .ft-h6 {
		-ms-flex-negative: 0;
		flex-shrink: 0;
		margin: 0;
		line-height: 40px;
		border-right: 1px solid rgba(255, 255, 255, 0.06);
		border-bottom: 0;
	}
	.my-news span {
		display: inline;
		vertical-align: bottom;
		margin-right: 4px;
	}
	.my-news__content {
		overflow: hidden;
		width: 100%;
	}
	.my-news-container {
		position: absolute;
		top: calc(485px - 90px);
		left: 0px;
		padding: 0 60px;
	}
	.my-news--home {
		background-color: rgba(0, 0, 0, 0.4);
		width: 51vw;
		height: 60px;
		border-radius: 4px;
	}
	.my-news--home span {
		height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@media (min-width: 1080px) {
	.my-news-container {
		top: calc(780px - 160px);
		height: 40px;
		width: 100vw;
	}
	.my-news--home {
		width: 43vw;
		height: 40px;
		background-color: rgba(0, 0, 0, 0.4);
		margin-left: 0;
	}
	.my-news--home span {
		height: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.my-news__content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.my-news__content span {
		display: inline-block;
		height: auto;
		white-space: nowrap;
	}
	.my-news__content a {
		display: inline-block;
	}
}

@media (min-width: 1280px) {
	.my-news {
		max-width: 630px;
		margin-left: auto;
		margin-right: auto;
	}
	.my-news-container {
		top: 625px;
	}
	.my-news-container .container {
		width: calc(1680px - 120px);
	}
	.my-news--home {
		margin-left: 0;
		border-radius: 4px;
	}
}

.composition .composition__hoverCard {
	position: absolute;
	top: 0;
	left: 0;
	width: 345px;
	height: 84px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: white;
	border-radius: 4px;
	-webkit-box-shadow: 1px 1px 8px 2px rgba(51, 51, 51, 0.08);
	box-shadow: 1px 1px 8px 2px rgba(51, 51, 51, 0.08);
	z-index: 200;
	overflow: hidden;
	pointer-events: none;
	opacity: 0;
	-webkit-transition: opacity 1s cubic-bezier(0.2, 1, 0.5, 1), -webkit-transform 0.2s cubic-bezier(0.2, 0, 0.3, 1);
	transition: opacity 1s cubic-bezier(0.2, 1, 0.5, 1), -webkit-transform 0.2s cubic-bezier(0.2, 0, 0.3, 1);
	transition: opacity 1s cubic-bezier(0.2, 1, 0.5, 1), transform 0.2s cubic-bezier(0.2, 0, 0.3, 1);
	transition: opacity 1s cubic-bezier(0.2, 1, 0.5, 1), transform 0.2s cubic-bezier(0.2, 0, 0.3, 1),
		-webkit-transform 0.2s cubic-bezier(0.2, 0, 0.3, 1);
}
.composition .composition__hoverCard.active {
	opacity: 1;
}
.composition .composition__hoverCard .hoverCard__portrait {
	width: 84px;
	height: 84px;
	background-image: url(/src/img/trash/home-ffr/Joueur-1-HD.jpg);
	background-size: cover;
}
.composition .composition__hoverCard .hoverCard__desc {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 20px 24px;
}
.composition .composition__hoverCard .hoverCard__desc h6 {
	color: #999;
}

.composition__schema {
	height: 681px;
}
.composition__schema .schema {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 346px;
	height: 681px;
	background-image: url('../img/bg-schema.png');
	background-size: 100% 100%;
}
.composition__schema .schema__player {
	display: inline-block;
	text-align: center;
	font-family: 'Din';
	font-weight: 700;
	max-width: 48px;
}
.composition__schema .schema__player span {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: #2a9bff;
	color: #fff;
	text-align: center;
	line-height: 33px;
	font-size: 14px;
	margin-bottom: 5px;
}
.composition__schema .schema__player p {
	width: 200%;
	position: relative;
	left: -50%;
	font-size: 12px;
	white-space: nowrap;
}
.composition__schema .schema__firstLine {
	width: 100%;
	padding: 0 47px;
	padding-top: 78px;
	text-align: center;
}
.composition__schema .schema__firstLine .schema__player {
	margin-right: 46px;
}
.composition__schema .schema__firstLine .schema__player:last-child {
	margin-right: 0;
}
.composition__schema .schema__twoPlayer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 32px;
	padding: 0 90px;
}
.composition__schema .schema__twoPlayer .schema__player:last-child {
	margin-left: auto;
}
.composition__schema .schema__twoPlayer--second {
	margin-top: 46px;
}
.composition__schema .schema__thirdLine {
	margin-top: 47px;
	padding: 0 24px;
}
.composition__schema .schema__thirdLine .schema__player {
	margin-right: 70px;
}
.composition__schema .schema__thirdLine .schema__player:last-child {
	margin-right: 0;
}
.composition__schema .schema__backLine {
	margin-top: 22px;
	padding: 0 9px;
}
.composition__schema .schema__backLine .schema__player {
	margin-right: 40px;
}
.composition__schema .schema__backLine .schema__player:last-child {
	margin-right: 0;
}
.composition__schema .schema__oneLine {
	margin-top: 20px;
}
.composition__schema .schema__oneLine .schema__player {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.composition__team .team__header {
	padding-bottom: 16px;
	border-bottom: 1px solid #ebebeb;
}
.composition__team .team__header i {
	width: 36px;
	height: 36px;
	color: #2a9bff;
	border: 2px solid #ebebeb;
	border-radius: 50%;
	line-height: 32px;
	margin-right: 16px;
	padding-left: 1px;
}
.composition__team .team__header i.icon-shirt {
	font-size: 12px;
}

.composition__team .team__body ol {
	width: 100%;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 0;
}

.composition__team .team__body ol li,
.composition__team .team__body ul li {
	position: relative;
	list-style-type: none;
	font-family: 'Din';
	line-height: 29px;
	font-size: 15px;
}
.composition__team .team__body ol li p,
.composition__team .team__body ul li p {
	display: inline-block;
	text-align: left;
	margin-bottom: 0;
	margin-left: 5px;
}
.composition__team .team__body ol li span:nth-child(2),
.composition__team .team__body ul li span:nth-child(2) {
	display: block;
	color: #999;
	font-size: 13px;
	line-height: 9px;
	margin-bottom: 5px;
}

.composition__team .team__body ol li:before {
	vertical-align: top;
	color: #999;
	content: attr(data-num) '.';
}

.composition__team .team__body .substitution::after {
	display: inline-block;
	position: relative;
	top: 8px;
	vertical-align: top;
}

.composition--ext .icon-plus,
.composition--ext .icon-less {
	font-size: 15px;
	font-weight: 600;
	vertical-align: 1px;
}

.captain.yellow-card::before,
.captain.red-card::before,
.captain.substitution::before {
	float: right;
	content: '';
	width: 9px;
	height: 14px;
	margin-left: 4px;
	margin-top: 7px;
}

.captain.yellow-card::after,
.captain.red-card::after,
.captain.substitution::after {
	display: inline-block;
	content: 'C';
	margin-left: 4px;
	width: auto;
	height: auto;
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
	background-color: #2a9bff;
	border-radius: 2px;
	padding: 3px 4px 2px 4px;
	color: #fff;
	line-height: 12px;
	font-size: 11px;
	background-image: none;
}

.captain.yellow-card::before {
	background-color: #f7c019;
	border-radius: 2px;
}

.captain.red-card::before {
	background-color: #f2362f;
	border-radius: 2px;
}

.captain.substitution::before {
	width: 11px;
	height: 11px;
	background-image: url('/src/img/icon-substitution.svg');
	background-size: cover;
}

.captain::after {
	display: inline-block;
	content: 'C';
	margin-left: 4px;
	background-color: #2a9bff;
	border-radius: 2px;
	padding: 3px 4px 2px 4px;
	color: #fff;
	line-height: 12px;
	font-size: 11px;
}

.yellow-card::after {
	display: inline-block;
	content: '';
	width: 9px;
	height: 14px;
	margin-left: 4px;
	background-color: #f7c019;
	border-radius: 2px;
}

.red-card::after {
	display: inline-block;
	content: '';
	width: 9px;
	height: 14px;
	margin-left: 4px;
	background-color: #f2362f;
	border-radius: 2px;
}

.substitution::after {
	display: inline-block;
	content: '';
	width: 11px;
	height: 11px;
	margin-left: 4px;
	background-image: url('/src/img/icon-substitution.svg');
	background-size: cover;
}

@media (min-width: 768px) {
	.composition__schema {
		height: 700px;
	}
	.composition__schema .schema {
		width: 355px;
	}
	.composition__schema .schema__thirdLine {
		padding: 0 30px;
	}
	.composition__schema .schema__backLine {
		padding: 0 14px;
	}
	.composition__team .team__body ol {
		width: inherit;
	}
	.composition__team .team__body ol:first-child {
		margin-right: 109px;
	}
}

@media (min-width: 1280px) {
	.composition__schema .schema {
		margin-right: 37px;
		margin-left: 0;
	}
	.composition__team {
		padding-left: 32px;
	}
	.composition--ext .composition__team:last-child {
		padding-left: 105px;
	}
}

.menu-team {
	position: relative;
	font-weight: 500;
	min-width: 250px;
}
.banner .menu-team li {
	color: #292929;
}
.menu-team .accordion-trigger:after {
	float: none;
	margin-left: 8px;
	display: inline-block;
	vertical-align: middle;
	content: '\e906';
	font-size: 10px;
}
.menu-team .accordion-trigger.is-selected:after {
	content: '\e914';
}
.menu-team .accordion-panel {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 300px;
	max-width: 788px;
	background: #fff;
	margin-top: 8px;
	border-bottom: 3px solid #2a9bff;
	padding: 16px 16px 32px;
	overflow: auto;
	-webkit-transition: opacity ease 0.3s, max-height ease 0.3s, visibility ease 0.3s;
	transition: opacity ease 0.3s, max-height ease 0.3s, visibility ease 0.3s;
}
.menu-team .accordion-panel ul {
	font-size: 14px;
	margin-bottom: 0;
}
.menu-team .accordion-panel ul li {
	margin-top: 16px;
}
.menu-team .accordion-panel a:hover {
	text-decoration: none;
}
.menu-team .accordion-panel .is-collapsed {
	margin-bottom: 24px;
}

.menu-team__category {
	display: block;
	font-family: 'Din';
	font-size: 15px;
	font-weight: 700;
	color: #999;
}

@media (min-width: 768px) {
	.menu-team .accordion-panel {
		height: inherit;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.menu-team .accordion-panel > li {
		display: inline-block;
		vertical-align: top;
		padding-right: 24px;
		width: 25%;
	}
	.menu-team .accordion-panel .is-collapsed {
		padding-top: 20px;
	}
}

@media (min-width: 1280px) {
	.menu-team .accordion-panel {
		padding: 32px 40px;
	}
}

.stats-match {
	background: #fafbfb;
	padding: 20px 20px 36px 20px;
	border-radius: 4px;
	border: 1px solid #e1e5ea;
}
.stats-match__header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-weight: 700;
	font-family: 'Din';
}
.stats-match hr {
	margin-top: 11px;
	margin-bottom: 0;
	border: 0;
	border-top: 1px solid #e1e5ea;
}
.stats-match .score {
	font-family: 'Din';
}
.stats-match .score__head {
	padding-top: 25px;
}
.stats-match .score__head a {
	display: block;
	width: 100%;
	text-transform: uppercase;
	color: #999;
	text-align: center;
	font-size: 12px;
	font-weight: 700;
	cursor: pointer;
	text-decoration: none;
}
.stats-match .score__head a::after {
	position: relative;
	display: inline-block;
	font-family: 'icon';
	font-size: 8px;
	margin-left: 13px;
	top: -2px;
}
.stats-match .score__head a.is-open::after {
	content: '\e923';
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}
.stats-match .score__head a.is-close::after {
	content: '\e923';
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
.stats-match .score__head .score__head__int,
.stats-match .score__head .score__head__ext {
	font-size: 20px;
	width: 30px;
	display: inline-block;
}
.stats-match .score__head .score__head__ext {
	text-align: right;
}
.stats-match .score__head span.is-superior {
	font-weight: 700;
}
.stats-match .score__head div {
	position: relative;
	display: inline-block;
	width: calc(100% - 60px);
	height: 8px;
	background: #ebebeb;
	border-radius: 4px;
}
.stats-match .score__head div span {
	position: absolute;
	top: 0;
	display: block;
	width: 80%;
	height: 8px;
	border-radius: 4px;
	background: #2a9bff;
}
.stats-match .score__head div span.is-right {
	right: 0;
}
.stats-match .score.is-disabled .score__head__int {
	color: #ccc;
}
.stats-match .score.is-disabled .score__head__ext {
	color: #ccc;
}
.stats-match .score.is-disabled .score__head div span {
	width: 0;
}
.stats-match .score__body {
	margin-top: 10px;
	font-size: 0;
}
.stats-match .score__body.is-hidden {
	display: none;
}
.stats-match .score__body div {
	width: calc(50% - 4px);
	display: inline-block;
	vertical-align: top;
	font-weight: 700;
	font-size: 15px;
	color: #292929;
}
.stats-match .score__body div span {
	color: #999;
	padding: 0 2px;
}
.stats-match .score__body div:first-child {
	text-align: right;
	border-right: 1px solid #ebebeb;
	padding-right: 30px;
}
.stats-match .score__body div:last-child {
	padding-left: 30px;
}

@media (min-width: 768px) {
	.stats-match {
		padding: 30px;
	}
	.stats-match .score .score__head__int,
	.stats-match .score .score__head__ext {
		font-size: 30px;
		width: 50px;
	}
	.stats-match .score__head a {
		position: relative;
		top: 6px;
	}
	.stats-match .score__head div {
		top: -7px;
		width: calc(100% - 100px);
	}
}

@media (min-width: 1280px) {
	.stats-match {
		padding: 40px 50px 50px 50px;
	}
}

.highlight {
	font-family: 'Din';
	display: block;
	margin: 0 auto;
	width: 343px;
	text-align: center;
}
.highlight .highlight__halfTime {
	margin-bottom: 65px;
}
.highlight .highlight__halfTime p {
	font-size: 30px;
	color: #292929;
	margin-bottom: 0;
}
.highlight .highlight__halfTime p span.is-winner {
	font-weight: 700;
}
.highlight .highlight__title,
.highlight .highlight__halfTime div {
	font-family: 'Din';
	font-size: 14px;
	text-transform: uppercase;
	color: #999;
	text-align: center;
	font-weight: 700;
}
.highlight .highlight__title--end {
	margin-bottom: 60px;
}
.highlight .highlight__circle {
	position: relative;
}
.highlight .highlight__circle .circle__content {
	position: absolute;
	margin-bottom: 0;
	line-height: 20px;
	color: #292929;
	font-weight: 700;
	font-size: 15px;
}
.highlight .highlight__circle .circle__content span {
	display: block;
	opacity: 0.4;
	font-weight: 400;
}
.highlight .highlight__circle .circle__content--left {
	text-align: right;
}
.highlight .highlight__circle .circle__content--left.circle__content--info {
	top: 0;
	right: 217px;
}
.highlight .highlight__circle .circle__content--left.circle__content--time {
	top: 12px;
	right: 220px;
}
.highlight .highlight__circle .circle__content--right.circle__content--info {
	top: 0;
	left: 212px;
	text-align: left;
}
.highlight .highlight__circle .circle__content--right.circle__content--time {
	top: 12px;
	left: 214px;
}
.highlight .highlight__circle .circle__content--time b {
	color: #292929;
	opacity: 0.4;
}
.highlight .highlight__circle .circle__icon {
	position: relative;
	display: block;
	margin: 0 auto;
	width: 40px;
	height: 40px;
	background: #fff;
	border-radius: 50%;
	text-align: center;
	line-height: 40px;
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
	margin-bottom: 41px;
}
.highlight .highlight__circle .circle__icon::before {
	position: relative;
	top: -40px;
	display: block;
	margin: 0 auto;
	content: '';
	width: 1px;
	height: 40px;
	border: 1px dashed #ccc;
}
.highlight .highlight__circle .circle__icon i {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.highlight .highlight__circle .circle__icon .icon-sub,
.highlight .highlight__circle .circle__icon .icon-red-card,
.highlight .highlight__circle .circle__icon .icon-yellow-card {
	display: inline-block;
	width: 16px;
	height: 18px;
	background-size: cover;
}
.highlight .highlight__circle .circle__icon .icon-sub {
	background-image: url('/src/img/icon-substitution.svg');
}
.highlight .highlight__circle .circle__icon .icon-red-card {
	background-image: url('/src/img/icon-red-card.svg');
	margin-left: 2px;
}
.highlight .highlight__circle .circle__icon .icon-yellow-card {
	background-image: url('/src/img/icon-yellow-card.svg');
	margin-left: 2px;
}
.highlight .highlight__circle .circle__icon .icon-ball,
.highlight .highlight__circle .circle__icon .icon-cage,
.highlight .highlight__circle .circle__icon .icon-drop {
	color: #002348;
}
.highlight .highlight__circle .circle__icon .icon-cage {
	font-size: 20px;
}
.highlight .highlight__circle .circle__icon .icon-drop {
	font-size: 17px;
}
.highlight .highlight__circle--first::before {
	position: absolute;
	left: 50%;
	top: 42px;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	display: block;
	content: '';
	width: 1px;
	height: 40px;
	border: 1px dashed #ccc;
}
.highlight .highlight__circle--first::after {
	position: absolute;
	left: 50%;
	top: 82px;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	display: block;
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #ccc;
}
.highlight .highlight__circle--first .circle__icon {
	margin-bottom: 82px;
}
.highlight .highlight__circle--last::after {
	position: absolute;
	left: 50%;
	bottom: 82px;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	display: block;
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #ccc;
}

@media (min-width: 768px) {
	.highlight {
		width: inherit;
		max-width: 427px;
	}
	.highlight .highlight__circle .circle__content--left.circle__content--info {
		top: 9px;
		right: 287px;
	}
	.highlight .highlight__circle .circle__content--left.circle__content--time {
		top: 17px;
		right: 290px;
	}
	.highlight .highlight__circle .circle__content--right.circle__content--info {
		top: 9px;
		left: 289px;
	}
	.highlight .highlight__circle .circle__content--right.circle__content--time {
		top: 17px;
		left: 290px;
	}
	.highlight .highlight__circle .circle__icon {
		width: 56px;
		height: 56px;
		line-height: 56px;
	}
	.highlight .highlight__circle--first::before {
		top: 57px;
	}
	.highlight .highlight__circle--first::after {
		top: 99px;
	}
	.highlight .highlight__circle--last::after {
		bottom: 93px;
	}
}

.banner {
	min-height: 352px;
	padding-top: 86px;
	padding-bottom: 60px;
}
.banner .img-green:before {
	background-color: #013974;
}
.banner .navbar {
	-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
	box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
}

.banner--search .input + p {
	color: rgba(255, 255, 255, 0.6);
}

.banner--map {
	z-index: 2;
}

.banner--searchClub {
	height: 728px;
}

.banner--big,
.banner--bigNoAfter {
	height: 544px;
}

.banner--big {
	margin-bottom: -260px;
}
.banner--big.banner--faq {
	margin-bottom: -342px;
}
.banner--big:after {
	background: inherit;
}

.banner--bigNoAfter {
	margin-bottom: -150px;
}
.banner--bigNoAfter h1 {
	margin-top: 112px;
}

.banner--compact {
	min-height: 206px;
}

.banner--lounge {
	min-height: 684px;
}
.banner--lounge h1 {
	margin-top: 100px;
}
.banner--lounge .video-thumbnail {
	margin-top: 24px;
	margin-bottom: 40px;
	max-width: 550px;
}
.banner--lounge .video-thumbnail img {
	border-radius: 4px;
}

.banner--tv {
	height: 692px;
}
.banner--tv .row {
	margin-top: 32px;
	margin-bottom: 24px;
}
.banner--tv .link-icon {
	margin: 0 auto 32px;
}
.banner--tv .link-icon i {
	font-size: 12px;
}
.banner--tv h1 {
	margin-top: 8px;
}
.banner--tv h1 a {
	color: #fff;
	text-decoration: none;
}

.banner--back h1 {
	margin-top: 24px;
}

.banner--themes .home-slider__links {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin-top: 8px;
}
.banner--themes .home-slider__links .billboard__sub {
	top: 150%;
	opacity: 0;
	visibility: hidden;
	color: #292929;
}
.banner--themes .home-slider__links .billboard__sub li {
	display: block;
}
.banner--themes .home-slider__links .billboard__sub li:first-child {
	opacity: 1;
}
.banner--themes .home-slider__links li {
	margin-right: 8px;
}
.banner--themes .home-slider__links li:nth-child(n + 2) {
	display: none;
}
.banner--themes .home-slider__links li:hover .billboard__sub {
	opacity: 1;
	visibility: visible;
}
.banner--themes .home-slider__links li a:focus {
	color: inherit;
}
.banner--themes .home-slider__links li.is-active {
	display: inline-block;
}
.banner--themes .home-slider__links li.is-active a {
	text-decoration: underline;
}
.banner--themes .home-slider__links li.see-all {
	display: inline-block;
}
.banner--themes .home-slider__links li.see-all:after {
	position: absolute;
	content: '\e906';
	line-height: 22px;
	margin-left: 4px;
	color: #7d93a8;
	font-family: 'icon';
	float: right;
	font-size: 6px;
}
@media (min-width: 768px) {
	.banner--themes .home-slider__links li.see-all:after {
		display: none;
	}
}

.banner--events {
	padding-top: 92px;
	margin-bottom: -250px;
}
.banner--events h1 {
	margin-top: 47px;
}

.banner--searchCentenaire .banner__img {
	display: block;
	margin: 0 auto;
	margin-top: 40px;
}
@media (min-width: 768px) {
	.banner--searchCentenaire .banner__img {
		margin-top: 0;
	}
}

.banner--searchCentenaire h1 {
	text-align: center;
	margin-top: 24px;
	line-height: 56px;
}
@media (min-width: 768px) {
	.banner--searchCentenaire h1 {
		line-height: inherit;
	}
}

.banner--searchCentenaire .banner__thumbnail::after {
	display: block;
	content: '';
	position: absolute;
	bottom: -1px;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(65%, rgba(0, 39, 82, 0)), to(#033971));
	background-image: linear-gradient(180deg, rgba(0, 39, 82, 0) 65%, #033971 100%);
	width: 100%;
	height: 300px;
	pointer-events: none;
}

.banner--searchCentenaire .btn--primary {
	display: block;
	max-width: 162px;
	position: fixed;
	z-index: 8;
	bottom: 20px;
	left: 50%;
	-webkit-transform: translatex(-50%);
	transform: translatex(-50%);
	right: 56px;
}
@media (min-width: 768px) {
	.banner--searchCentenaire .btn--primary {
		position: relative;
		top: -20px;
		bottom: inherit;
		left: inherit;
		-webkit-transform: inherit;
		transform: inherit;
		right: 8px;
	}
}
@media (min-width: 1080px) {
	.banner--searchCentenaire .btn--primary {
		right: 98px;
	}
}
@media (min-width: 1280px) {
	.banner--searchCentenaire .btn--primary {
		right: 53px;
	}
}

.banner--searchCentenaire .banner__desc {
	text-align: center;
	max-width: 683px;
	margin: 0 auto;
	margin-bottom: 40px;
}
@media (min-width: 768px) {
	.banner--searchCentenaire .banner__desc {
		margin-bottom: 56px;
	}
}

.banner--searchCentenaire .search-club {
	margin: 0 auto;
}
.banner--searchCentenaire .search-club .btn {
	background-color: #e8a801;
	border-color: #e8a801;
	color: #fff;
	left: 756px;
}
.banner--searchCentenaire .search-club .btn i {
	color: #fff;
}
.banner--searchCentenaire .search-club .search-club__club {
	max-width: 1028px;
	margin: 0 auto;
	margin-top: 24px;
}
@media (min-width: 768px) {
	.banner--searchCentenaire .search-club .search-club__club {
		margin-top: 16px;
	}
}
.banner--searchCentenaire .search-club .search-club__club .list-label li {
	margin-right: 12px;
}
.banner--searchCentenaire .search-club .search-club__wrapper {
	width: 100%;
	max-width: 1028px;
	margin: 0 auto;
}
.banner--searchCentenaire .search-club .search-club__wrapper .search-club__autocompletion,
.banner--searchCentenaire .search-club .search-club__wrapper .input {
	width: calc(100% - 56px);
}
@media (min-width: 1080px) {
	.banner--searchCentenaire .search-club .search-club__wrapper .search-club__autocompletion,
	.banner--searchCentenaire .search-club .search-club__wrapper .input {
		width: calc(100% - 302px - 16px);
	}
}
@media (min-width: 1280px) {
	.banner--searchCentenaire .search-club .search-club__wrapper .search-club__autocompletion,
	.banner--searchCentenaire .search-club .search-club__wrapper .input {
		width: 69%;
		max-width: 740px;
	}
}
.banner--searchCentenaire .search-club .search-club__wrapper .input {
	display: inline-block;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
@media (min-width: 1080px) {
	.banner--searchCentenaire .search-club .search-club__wrapper .input {
		margin-right: 16px;
	}
}
.banner--searchCentenaire .search-club .search-club__wrapper .input input {
	padding-right: 10px;
}
.banner--searchCentenaire .search-club .search-club__wrapper .btn {
	display: inline-block;
	left: -2px;
	width: 56px;
	position: inherit;
	top: -2px;
	vertical-align: top;
	margin-top: 2px;
}
@media (min-width: 1080px) {
	.banner--searchCentenaire .search-club .search-club__wrapper .btn {
		width: 302px;
		left: 0;
	}
}

.banner--site-club {
	min-height: 324px;
	padding-top: 72px;
	padding-bottom: 60px;
}
.banner--site-club > .container {
	margin-top: 32px;
}
@media (min-width: 768px) {
	.banner--site-club > .container {
		margin-top: 48px;
	}
}
@media (min-width: 1280px) {
	.banner--site-club > .container {
		margin-top: 70px;
	}
}
@media (min-width: 1680px) {
	.banner--site-club > .container {
		padding-left: 40px;
		padding-right: 40px;
	}
}
.banner--site-club > .container .row {
	position: relative;
}
.banner--site-club .ft-secondary {
	opacity: 0.72;
	max-width: 337px;
}
@media (min-width: 768px) {
	.banner--site-club .ft-secondary {
		max-width: inherit;
	}
}
@media (min-width: 768px) {
	.banner--site-club .ft-h2 {
		line-height: 60px;
	}
}
@media (min-width: 1280px) {
	.banner--site-club .ft-h2 {
		font-size: 40px;
	}
}
.banner--site-club .ft-h2 span {
	display: block;
	opacity: 0.72;
	font-size: 17px;
	font-weight: normal;
	line-height: 28px;
	margin-bottom: 4px;
}
@media (min-width: 768px) {
	.banner--site-club .ft-h2 span {
		position: relative;
		display: inline;
		font-size: 30px;
		line-height: 60px;
		font-weight: 700;
		opacity: 1;
		padding-right: 26px;
	}
	.banner--site-club .ft-h2 span:after {
		position: absolute;
		content: '-';
		right: 4px;
	}
}
@media (min-width: 1280px) {
	.banner--site-club .ft-h2 span {
		font-size: 40px;
		padding-right: 32px;
	}
}
@media (min-width: 1280px) and (max-width: 1679px) {
	.banner--site-club .ft-h2,
	.banner--site-club .ft-secondary {
		margin-left: 40px;
	}
}
@media (min-width: 768px) {
	.banner--site-club .banner__pres {
		float: right;
	}
}
@media (min-width: 1280px) {
	.banner--site-club .banner__pres {
		padding-left: calc(16.66667% - 32px);
		padding-right: 8.33333%;
	}
}
@media (min-width: 1280px) {
	.banner--site-club .banner__pres > div {
		max-width: 391px;
	}
}
.banner--site-club .banner__pres p {
	overflow: hidden;
	font-size: 14px;
	line-height: 22px;
	color: rgba(255, 255, 255, 0.8);
	padding-bottom: 32px;
	margin-bottom: 0;
}
.banner--site-club .banner__pres p span {
	display: inline-block;
}
.banner--site-club .banner__pres p span:first-child {
	position: relative;
	top: 4px;
	-webkit-box-shadow: 0 6px 17px 0 rgba(51, 51, 51, 0.2);
	box-shadow: 0 6px 17px 0 rgba(51, 51, 51, 0.2);
}
.banner--site-club .banner__pres p span:last-child {
	padding-left: 20px;
	width: calc(100% - 32px);
	float: right;
}
@media (min-width: 768px) {
	.banner--site-club .banner__pres p span:last-child {
		width: calc(100% - 28px);
	}
}
@media (min-width: 1280px) {
	.banner--site-club .banner__pres .btn--full {
		width: auto;
		padding: 0 24px;
	}
}
.banner--site-club .banner__video {
	position: relative;
	z-index: 2;
}
@media (min-width: 768px) {
	.banner--site-club .banner__video {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		padding-right: 41px;
	}
}
@media (min-width: 1280px) {
	.banner--site-club .banner__video {
		padding-right: 0;
		position: static;
		-webkit-transform: inherit;
		transform: inherit;
	}
}
.banner--site-club .banner__video img {
	pointer-events: none;
	-webkit-box-shadow: 0 6px 17px 0 rgba(51, 51, 51, 0.2);
	box-shadow: 0 6px 17px 0 rgba(51, 51, 51, 0.2);
}

.banner--site-club-index {
	height: 732px;
	margin-bottom: 40px;
}
@media (min-width: 768px) {
	.banner--site-club-index {
		margin-bottom: 0;
	}
}

@media (min-width: 768px) {
	.banner {
		height: 392px;
		min-height: auto;
	}
	.banner--big,
	.banner--bigNoAfter {
		height: 544px;
		margin-bottom: -150px;
	}
	.banner--compact {
		height: 300px;
	}
	.banner--searchClub {
		height: 812px;
	}
	.banner--searchCentenaire {
		height: 812px;
	}
	.banner--searchCentenaire .btn--primary {
		margin-left: auto;
	}
	.banner--searchCentenaire .search-club__wrapper .input input {
		padding-right: 72px;
	}
	.banner--lounge {
		height: 600px;
	}
	.banner--lounge .row {
		margin-top: 80px;
	}
	.banner--lounge h1 {
		margin-top: 40px;
	}
	.banner--lounge .video-thumbnail {
		margin: 0;
		width: 360px;
		float: right;
	}
	.banner--tv {
		height: 672px;
	}
	.banner--tv h1 {
		font-size: 48px;
		line-height: 60px;
	}
	.banner--tv .link-icon {
		width: 60px;
		height: 60px;
		line-height: 56px;
		position: absolute;
		margin-bottom: 0;
		left: 16px;
		top: 32px;
	}
	.banner--tv .row {
		margin-top: 100px;
		margin-bottom: 56px;
	}
	.banner--tv .col {
		padding-left: 108px;
	}
	.banner--back h1 {
		margin-top: 40px;
	}
	.banner--themes .home-slider__links li {
		margin-right: 24px;
	}
	.banner--themes .home-slider__links li:nth-child(n + 2) {
		display: inline-block;
	}
	.banner--themes .home-slider__links .billboard__sub li {
		display: block;
	}
	.banner--events {
		padding-top: 86px;
		margin-bottom: -150px;
	}
	.banner--events h1 {
		margin-top: 112px;
	}
	.banner--site-club {
		height: 288px;
	}
	.banner--site-club.banner--site-club-index {
		height: 608px;
	}
}

@media (min-width: 1080px) {
	.banner {
		padding-top: 170px;
		height: 448px;
	}
	.banner--big,
	.banner--bigNoAfter,
	.banner--lounge {
		height: 688px;
	}
	.banner--big {
		margin-bottom: -350px;
	}
	.banner--compact {
		height: 356px;
	}
	.banner--searchClub {
		height: 885px;
	}
	.banner--searchCentenaire {
		height: 874px;
	}
	.banner--lounge .row {
		margin-top: 48px;
	}
	.banner--lounge .video-thumbnail {
		width: 500px;
	}
	.banner--lounge h1 {
		margin-top: 56px;
	}
	.banner--tv {
		height: 816px;
	}
	.banner--tv .row {
		margin-top: 240px;
		margin-bottom: 70px;
	}
	.banner--tv .col {
		padding-left: 140px;
	}
	.banner--tv .link-icon {
		left: 32px;
	}
	.banner--back {
		height: 472px;
	}
	.banner--back h1 {
		margin-top: 48px;
	}
	.banner--back .banner__desc {
		max-width: none;
	}
	.banner--site-club {
		margin-bottom: -461px;
		height: 816px;
		padding-top: 156px;
	}
	.banner--site-club.banner--site-club-index {
		margin-bottom: -89px;
		height: 816px;
	}
	.banner--site-club .banner__video {
		padding-left: 64px;
	}
}

@media (min-width: 1680px) {
	.banner--site-club .container {
		max-width: 1560px;
	}
}

@media (max-width: 767px) {
	.banner--site-club-mobile {
		position: relative;
		min-height: 160px;
		padding-bottom: 16px;
	}
	.banner--site-club-mobile p {
		display: none;
	}
	.banner--site-club-mobile > .container {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
		margin-top: 35px;
	}
	.banner--site-club-mobile > .container .ft-h2 {
		font-size: 17px;
		line-height: 28px;
	}
}

.dashboard-menu .dashboard-menu__header {
	padding: 24px;
}
.dashboard-menu .dashboard-menu__header a:hover {
	text-decoration: none;
}
.dashboard-menu .dashboard-menu__header img {
	display: inline-block;
	margin-right: 16px;
	vertical-align: top;
	width: 28px;
	height: 28px;
}
.dashboard-menu .dashboard-menu__header h1 {
	display: inline-block;
	color: #004c99;
	line-height: 32px;
}

.dashboard-menu__profil {
	position: relative;
	width: 100%;
	height: 219px;
	background: #fcfcfc;
	border-bottom: 1px solid #ebebeb;
	padding: 24px 0;
}
.dashboard-menu__profil > div {
	border: 2px solid #ebebeb;
	width: 128px;
	height: 128px;
	border-radius: 50%;
	margin: 0 auto;
}
.dashboard-menu__profil > div img {
	width: 100%;
	height: 100%;
}
.dashboard-menu__profil p {
	width: 100%;
	text-align: center;
}

.dashboard-menu__nav {
	padding: 24px 0;
	font-family: 'Din';
}
.dashboard-menu__nav p {
	margin-left: 32px;
}
.dashboard-menu__nav a {
	position: relative;
	display: block;
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding-left: 32px;
	color: #999;
	font-size: 12px;
}
.dashboard-menu__nav a:before {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	-webkit-transform: translateX(-2px);
	transform: translateX(-2px);
	position: absolute;
	left: 0;
	content: '';
	width: 2px;
	height: 100%;
	background: #2a9bff;
}
.dashboard-menu__nav a i {
	margin-top: -2px;
}
.dashboard-menu__nav a:hover,
.dashboard-menu__nav a.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(42, 155, 255, 0.08);
	text-decoration: none;
	color: #292929;
}
.dashboard-menu__nav a:hover i,
.dashboard-menu__nav a.active i {
	color: #2a9bff;
}
.dashboard-menu__nav a.active:before {
	-webkit-transform: translateX(0px);
	transform: translateX(0px);
}

.dashboard-menu__settings {
	padding: 24px 0;
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
}
.dashboard-menu__settings p {
	padding: 0 32px;
}
.dashboard-menu__settings p:first-child {
	margin-bottom: 8px;
}
.dashboard-menu__settings a {
	display: block;
	height: 40px;
	line-height: 40px;
	font-size: 12px;
	padding-left: 32px;
	text-transform: uppercase;
	font-family: 'Din';
}
.dashboard-menu__settings a:hover,
.dashboard-menu__settings a.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(42, 155, 255, 0.08);
	text-decoration: none;
	color: #292929;
}
.dashboard-menu__settings a:hover i,
.dashboard-menu__settings a.active i {
	color: #2a9bff;
}
.dashboard-menu__settings a.active:before {
	-webkit-transform: translateX(0px);
	transform: translateX(0px);
}

.dashboard-menu__access {
	padding: 24px 32px 40px 32px;
}

.is-mobile-dashboard {
	display: none;
}

@media (min-width: 1080px) {
	.is-mobile-dashboard {
		display: block;
	}
	.dashboard-menu {
		border: 1px solid #ebebeb;
	}
	.dashboard-menu__profil {
		border-top: 1px solid #ebebeb;
	}
}

.espace-perso-title {
	color: #292929;
}
.espace-perso-title h1 {
	font-weight: 300;
	line-height: 48px;
}
.espace-perso-title p {
	color: #999;
	margin-bottom: 0;
}

.block-infos-generales {
	position: relative;
}
.block-infos-generales .profile-picture {
	text-align: center;
	margin-bottom: 24px;
}
.block-infos-generales .profile-picture > p {
	color: #ccc;
	font-weight: 500;
}
.block-infos-generales .form-action-success {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 48px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #2ecc71;
	color: #fff;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
}
.block-infos-generales .form-action-success.is-valid {
	visibility: visible;
	opacity: 1;
}
.block-infos-generales .form-action-success i {
	margin-right: 8px;
}

.block-infos-generales__header {
	padding: 16px 24px;
	border-bottom: 1px solid #ebebeb;
}

.block-infos-generales__body {
	padding: 24px;
}

.block-infos-generales__footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 24px;
	border-top: 1px solid #ebebeb;
}
.block-infos-generales__footer .ft-black-secondary {
	font-size: 15px;
	padding: 0;
}
.block-infos-generales__footer .btn--primary {
	padding: 0 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@media (min-width: 768px) {
	.block-infos-generales__body {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.block-infos-generales__body .content {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		padding-left: 24px;
	}
	.block-infos-generales__footer .btn--primary {
		padding: 0 24px;
	}
}

.event-row:hover .event__title {
	color: inherit;
	text-decoration: none;
}

.event-row:hover a {
	text-decoration: none;
}

.event__title a:hover {
	color: #292929;
	text-decoration: underline;
}

@media (min-width: 1280px) {
	.event-row--large:hover .btn {
		opacity: 1;
	}
	.event-row--large .btn {
		opacity: 0;
	}
}

.estimation .is-disabled {
	background-color: #fcfcfc;
}
.estimation .is-disabled p {
	color: #ccc;
}
.estimation .is-disabled .select {
	opacity: 0.6;
	pointer-events: none;
}

.estimation .dropdown .dropdown__wrapper {
	max-height: 110px;
}

.estimation .dropdown--match .dropdown__wrapper {
	max-height: 200px;
}

.estimation .card--match div:last-child {
	display: none;
}

.estimation .dropdown--guest .dropdown__head {
	height: 56px;
}

.estimation .dropdown--guest .dropdown__list {
	margin-top: 0;
	border-top-left-radius: 4px;
}

.estimation__price,
.estimation__guest {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.estimation__price {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
	margin-bottom: 24px;
}

.estimation__guest {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media (min-width: 768px) and (max-width: 1279px) {
	.estimation .btn {
		width: 236px;
	}
	.estimation .card--match div:last-child {
		display: block;
	}
	.estimation__price {
		margin-bottom: 0;
	}
	.estimation__price p {
		display: inline-block;
		margin-right: 4px;
	}
	.estimation__footer {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.estimation__grid {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: end;
		-ms-flex-align: end;
		align-items: flex-end;
	}
	.estimation__grid > div {
		width: calc(50% - 12px);
	}
}

@media (min-width: 768px) {
	.header-tv {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.header-tv .tab-links {
		display: block;
	}
	.header-tv .tab-links a {
		width: 144px;
		display: inline-block;
	}
	.header-tv .tab__indicator {
		border-radius: 4px;
	}
	.header-tv .dropdown {
		width: 286px;
	}
}

.tv-player .card--picture {
	margin-bottom: 8px;
}

.tv-player .card:hover .card__title {
	text-decoration: underline;
}

.tv-player__video {
	width: 100%;
	height: 215px;
	margin-left: -16px;
	width: calc(100% + 32px);
}

.tv-player__social .btn--youtube {
	width: 100%;
}

.tv-player__share {
	margin-top: 24px;
}
.tv-player__share p {
	display: inline-block;
}
.tv-player__share .btn {
	margin-left: 8px;
}

@media (min-width: 768px) {
	.tv-player__video {
		height: 300px;
	}
	.tv-player .card--picture {
		width: 49%;
		vertical-align: top;
		display: inline-block;
		width: calc(50% - 8px);
		margin-left: 16px;
		margin-bottom: 16px;
	}
	.tv-player .card--picture:nth-child(odd) {
		margin-left: 0;
	}
	.tv-player__social {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.tv-player__social .btn--youtube {
		width: auto;
	}
	.tv-player__share {
		margin-top: 0;
	}
}

@media (min-width: 1280px) {
	.tv-player__video {
		height: 600px;
		width: calc(100% + 80px);
		margin-left: -40px;
	}
	.tv-player .card--picture {
		width: calc(33.3% - 16px);
		margin-left: 24px;
		margin-bottom: 24px;
	}
	.tv-player .card--picture:nth-child(odd) {
		margin-left: 24px;
	}
	.tv-player .card--picture:nth-child(3n + 1) {
		margin-left: 0;
	}
}

.page-site-club > .container {
	padding: 0;
}
@media (min-width: 1280px) {
	.page-site-club > .container {
		padding-left: 60px;
		padding-right: 60px;
	}
}

@media (min-width: 768px) {
	.page-site-club {
		position: relative;
	}
	.page-site-club:before {
		/*position: absolute;
		content: '';
		top: 445px;*/
		background-color: #002348;
		left: 0;
		right: 0;
		height: 100%;
		z-index: -1;
	}
}

.page-site-club--index:before {
	top: 88px;
}

.site-club-container {
	width: 100%;
	background-color: #fff;
	z-index: 2;
}
@media (min-width: 1280px) {
	.site-club-container {
		position: relative;
	}
}
.site-club-container .site-club__content {
	display: none;
	padding-top: 40px;
	padding-bottom: 48px;
}
.site-club-container .site-club__content.is-active {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.site-club-container .site-club__content .link-back .icon {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
@media (min-width: 768px) {
	.site-club-container .site-club__content .site-club__content__title {
		font-size: 17px;
		line-height: 24px;
	}
}
@media (min-width: 1280px) {
	.site-club-container .site-club__content .site-club__content__title {
		font-size: 20px;
	}
}
.site-club-container .site-club__content .site-club__content__paragraph {
	font-size: 14px;
	color: #999999;
	line-height: 22px;
}
@media (min-width: 1280px) {
	.site-club-container .site-club__content .site-club__content__paragraph {
		font-size: 17px;
		line-height: 28px;
	}
}
.site-club-container .site-club__head {
	position: relative;
	display: block;
	height: 60px;
	border-bottom: 1px solid #ebebeb;
}
@media (min-width: 1280px) {
	.site-club-container .site-club__head {
		height: 80px;
	}
}
.site-club-container .site-club__head p {
	margin-bottom: 0;
}
.site-club-container .steps {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
}
.site-club-container .steps[data-step='1'] .steps-switcher--prev {
	display: none;
}
.site-club-container .steps .step {
	display: none;
	font-size: 12px;
	color: #999;
}
.site-club-container .steps .step.is-active {
	display: block;
	color: #2a9bff;
}
.site-club-container .steps .steps-switcher {
	position: absolute;
	height: 100%;
	width: 48px;
}
.site-club-container .steps .steps-switcher::before {
	position: absolute;
	content: '\e906';
	top: 23px;
	font-family: 'icon';
	font-size: 8px;
	color: #7d93a8;
}
.site-club-container .steps .steps-switcher--prev {
	left: 0;
	padding-left: 16px;
}
.site-club-container .steps .steps-switcher--prev::before {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}
.site-club-container .steps .steps-switcher--next {
	right: 0;
	padding-right: 16px;
}
.site-club-container .steps .steps-switcher--next::before {
	left: 24px;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
.site-club-container .steps-indicator {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 2px;
	background-color: #2a9bff;
	width: 25%;
	-webkit-transition: width 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: width 0.6s cubic-bezier(0.25, 0.1, 0.25, 1);
}
@media (min-width: 768px) {
	.site-club-container .steps-indicator {
		width: calc(25% - 12%);
	}
}
.site-club-container .site-club__intro h2 {
	font-size: 20px;
	line-height: 24px;
}
.site-club-container .site-club__intro p {
	font-size: 17px;
	line-height: 28px;
}
.site-club-container .site-club__intro .site-club__intro__bottom {
	text-align: center;
}
@media (min-width: 1280px) {
	.site-club-container .site-club__intro .site-club__intro__bottom {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
@media (min-width: 1280px) {
	.site-club-container .site-club__intro .site-club__intro__bottom .btn {
		margin-right: 32px;
		padding: 0 32px;
	}
}
.site-club-container .site-club__intro .site-club__intro__bottom p {
	color: #cccccc;
	font-size: 14px;
	white-space: nowrap;
}
@media (min-width: 1280px) {
	.site-club-container .site-club__intro .site-club__intro__bottom p {
		margin-right: 16px;
	}
}
.site-club-container .site-club__intro .site-club__intro__bottom a:last-child {
	color: #999999;
	font-size: 14px;
	text-decoration: underline;
}
@media (min-width: 1280px) {
	.site-club-container .site-club__intro .site-club__intro__bottom a:last-child {
		white-space: nowrap;
	}
}
.site-club-container .site-club__intro .site-club__intro__list {
	border: 1px solid #eeeeee;
	padding: 24px;
	padding-bottom: 24px;
	color: #999999;
	font-size: 14px;
	border-radius: 2px;
	background: #fff;
}
.site-club-container .site-club__intro .site-club__intro__list li {
	display: inline-block;
	line-height: 28px;
	width: 100%;
}
@media (min-width: 768px) {
	.site-club-container .site-club__intro .site-club__intro__list li {
		line-height: 18px;
		margin-bottom: 8px;
	}
	.site-club-container .site-club__intro .site-club__intro__list li span {
		width: calc(100% - 29px);
		float: right;
	}
}
@media (min-width: 1280px) {
	.site-club-container .site-club__intro .site-club__intro__list li {
		line-height: 28px;
	}
}
.site-club-container .site-club__intro .site-club__intro__list li::before {
	content: '•';
	margin-right: 24px;
}
@media (min-width: 768px) {
	.site-club-container .site-club__intro .site-club__intro__list ul {
		display: inline-block;
		width: calc(50% - 4px);
		vertical-align: top;
	}
}
.site-club-container .site-club__intro .site-club__intro__inputs label {
	text-transform: uppercase;
	color: #999999;
	font-size: 12px;
	font-weight: 700;
}
.site-club-container .site-club__intro .site-club__intro__inputs p {
	line-height: 20px;
	color: #999999;
	font-size: 14px;
}
.site-club-container .simple-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #002348;
	font-size: 14px;
	line-height: 20px;
	padding: 24px;
}
.site-club-container .simple-info img {
	float: left;
	margin-right: 16px;
}
.site-club-container .simple-info p {
	margin-bottom: 0;
}
.site-club-container .simple-info .link-icon {
	float: left;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	cursor: default;
	border-color: #002348;
	background: none;
}
.site-club-container .simple-info .icon {
	color: #002348;
}
.site-club-container .btn {
	height: 56px;
	line-height: 56px;
}
.site-club-container .btn--edit {
	height: 32px;
	line-height: 32px;
}
.site-club-container .title-with-tooltip {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-top: 24px;
	margin-bottom: 16px;
}
.site-club-container .title-with-tooltip h3,
.site-club-container .title-with-tooltip h4 {
	display: inline-block;
	margin-right: 8px;
}
.site-club-container .title-with-tooltip .tooltip {
	-webkit-box-flex: 1;
	-ms-flex: auto;
	flex: auto;
}
.site-club-container .title-with-tooltip .tooltip i {
	color: rgba(43, 65, 112, 0.2);
	font-size: 16px;
}
.site-club-container .title-with-tooltip > p {
	margin-bottom: 0;
}
.site-club-container hr {
	margin: 0;
	border: 0;
	border-top: 1px solid #ebebeb;
}
.site-club-container .input-with-edit {
	position: relative;
}
.site-club-container .input-with-edit .btn {
	position: absolute;
	top: 12px;
	right: 12px;
}
.site-club-container .step-confirmation {
	padding-top: 16px;
}
.site-club-container .step-confirmation .simple-info {
	line-height: 24px;
}
.site-club-container .step-confirmation .simple-info img {
	float: none;
	width: 48px;
	height: 48px;
}
@media (min-width: 1280px) {
	.site-club-container .step-confirmation .simple-info > div {
		display: inline-block;
		width: calc(100% - 64px);
	}
}
.site-club-container .step-confirmation .btn {
	height: 56px;
	line-height: 56px;
}
.site-club-container .step-confirmation .btn:hover {
	color: #fff;
}
.site-club-container .step-confirmation .btn-with-link {
	text-align: center;
}
.site-club-container .step-confirmation .btn-with-link a:last-child {
	text-decoration: underline;
}
.site-club-container .step-confirmation .link-icon {
	float: none;
}
@media (min-width: 1280px) {
	.site-club-container .step-confirmation .link-icon {
		float: left;
	}
}
.site-club-container .step-confirmation .box--green .link-icon {
	border-color: #32812c;
	background-color: rgba(46, 204, 113, 0.08);
}
.site-club-container .step-confirmation .box--green .link-icon .icon {
	color: #32812c;
}

.step-delegate .btn {
	width: 100%;
}

.step-delegate-success {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.step-delegate-success .site-club__intro {
	text-align: center;
}

.step-startup .box {
	padding: 24px 16px;
	background-color: inherit;
}
.step-startup .box > div {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.step-startup .box > div img {
	margin-right: 16px;
}
.step-startup .box > div p {
	color: #999;
}

.step-customization .site-club__main {
	width: 100%;
	padding: 0 16px;
	border-right: 1px solid #e3ecf4;
}

.step-customization .site-club__sidebar {
	display: none;
	background-color: #eef5fd;
	text-align: right;
}
.step-customization .site-club__sidebar.show-preview {
	display: block;
	position: fixed;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 10;
	overflow-y: auto;
	padding: 60px 16px 16px;
}
.step-customization .site-club__sidebar.show-preview .toggle-preview {
	display: none;
}
.step-customization .site-club__sidebar.show-preview > .icon-close {
	position: absolute;
	right: 8px;
	top: 8px;
	padding: 16px;
	color: rgba(0, 35, 72, 0.4);
	font-size: 16px;
}

.step-customization .custom-url::after {
	position: absolute;
	content: '.ffr.fr';
	right: 16px;
	top: 18px;
	color: #292929;
}

.step-customization .custom-url input {
	padding-right: 60px;
}

.step-customization .input {
	margin-bottom: 0;
}

.step-customization .custom-logo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.step-customization .custom-logo .logo {
	display: inline-block;
	width: 90px;
	height: 90px;
	margin-right: 16px;
	border-radius: 50%;
	-webkit-box-shadow: 0 10px 16px 0 rgba(41, 41, 41, 0.08);
	box-shadow: 0 10px 16px 0 rgba(41, 41, 41, 0.08);
	overflow: hidden;
	background-size: 80%;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: #fff;
}
.step-customization .custom-logo .message {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	max-width: 280px;
}
.step-customization .custom-logo .message a {
	text-decoration: underline;
}

.step-customization .input--file {
	height: 116px;
	line-height: 116px;
	text-align: center;
}

.step-customization .btn--primary {
	margin-top: 32px;
}

.step-checking .administrators {
	border-radius: 6px;
	padding: 24px;
}
.step-checking .administrators p {
	color: #002348;
}

.step-checking .checkbox {
	color: #292929;
}
.step-checking .checkbox label {
	line-height: 20px;
}
.step-checking .checkbox a {
	text-decoration: underline;
}

.step-monitoring {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.step-monitoring .monitoring {
	position: relative;
	padding: 24px;
	width: calc(100% - 32px);
	margin: -112px auto 0;
	background-color: #2a9bff;
	border-radius: 6px;
	background-image: url('/src/img/banner/placeholder-banner.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	color: #fff;
	overflow: hidden;
}
.step-monitoring .monitoring::after {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: -webkit-gradient(linear, left bottom, left top, from(#002348), to(rgba(0, 35, 72, 0.6)));
	background-image: linear-gradient(0deg, #002348 0%, rgba(0, 35, 72, 0.6) 100%);
	z-index: 1;
}
.step-monitoring .monitoring > div {
	position: relative;
	z-index: 2;
}
.step-monitoring .monitoring .monitoring__head {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.step-monitoring .monitoring .monitoring__head a {
	position: relative;
	top: 3px;
	color: #f0f2f5;
	text-decoration: underline;
	opacity: 0.6;
	font-size: 12px;
}
.step-monitoring .monitoring .monitoring__status {
	display: block;
	min-height: 72px;
	padding: 16px;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 4px;
}
.step-monitoring .monitoring .monitoring__status.is-hidden {
	display: none;
}
.step-monitoring .monitoring .monitoring__status.status--online .link-icon {
	color: #50b748;
	border-color: #50b748;
}
.step-monitoring .monitoring .monitoring__status.status--offline .link-icon {
	color: #f2362f;
	border-color: #f2362f;
}
.step-monitoring .monitoring .monitoring__status.status--offline .link-icon i {
	font-size: 13px;
}
.step-monitoring .monitoring .monitoring__status.status--maintenance .link-icon {
	color: #f2932f;
	border-color: #f2932f;
}
.step-monitoring .monitoring .monitoring__status.status--maintenance .link-icon i {
	font-size: 13px;
}
.step-monitoring .monitoring .monitoring__status img {
	float: left;
	margin-right: 16px;
}
.step-monitoring .monitoring .monitoring__status .link-icon {
	width: 35px;
	height: 35px;
	line-height: 29px;
	float: left;
	margin-right: 16px;
}
.step-monitoring .monitoring .monitoring__status .link-icon i {
	font-size: 9px;
}
.step-monitoring .monitoring .monitoring__status > div {
	display: inline-block;
	width: calc(100% - 51px);
}
.step-monitoring .monitoring .monitoring__status span {
	font-size: 20px;
}
.step-monitoring .monitoring .monitoring__status p {
	color: #f0f2f5;
	margin-bottom: 0;
	opacity: 0.6;
	font-size: 12px;
}
.step-monitoring .monitoring .btn {
	width: 100%;
}

@media (min-width: 768px) {
	.site-club__content {
		padding-bottom: 56px;
	}
	.site-club__content > .container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.site-club__content > .col {
		float: none;
	}
	.site-club__content > .col:nth-child(2) {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	.site-club-container .step-customization {
		padding-top: 0;
		padding-bottom: 0;
	}
	.site-club-container .step-customization .site-club__sidebar {
		display: inline-block;
		padding: 16px 24px 24px;
	}
	.site-club-container .step-customization .site-club__sidebar > .icon-close {
		display: none;
	}
	.site-club-container .step-customization .site-club__main {
		width: 50%;
		padding: 40px 16px 40px;
	}
	.site-club-container .step-customization .btn-preview {
		display: none;
	}
	.site-club-container .input-with-edit .btn {
		height: 40px;
		line-height: 40px;
		top: 8px;
		right: 8px;
	}
	.site-club__head .steps {
		-ms-flex-pack: distribute;
		justify-content: space-around;
	}
	.site-club__head .steps .step {
		display: inline-block;
	}
	.site-club__head .steps .steps-switcher {
		display: none;
	}
	.simple-info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.simple-info .link-icon {
		float: none;
	}
	.site-club__sidebar {
		position: relative;
	}
	.step-delegate {
		height: 420px;
	}
	.step-delegate form {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.step-delegate form .input {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		margin-bottom: 0;
		margin-right: 16px;
	}
	.step-delegate .btn {
		width: auto;
	}
	.step-delegate-success .site-club__intro {
		max-width: 50%;
	}
	.step-delegate-success img {
		width: 140px;
		height: 140px;
	}
	.step-delegate-success .btn {
		width: auto;
	}
	.step-confirmation .simple-info {
		display: block;
	}
	.step-confirmation .row-input {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.step-confirmation .row-input .input {
		width: 55%;
		margin-bottom: 0;
		margin-right: 16px;
	}
	.step-confirmation .row-input .btn {
		width: calc(45% - 16px);
	}
	.step-monitoring > .container {
		display: block;
	}
	.step-monitoring .monitoring {
		padding: 32px 24px;
		background-position: 60% 50%;
	}
	.step-monitoring .monitoring .monitoring__head {
		display: inline-block;
		margin-right: 24px;
	}
	.step-monitoring .monitoring .monitoring__head a {
		position: static;
		top: 0;
	}
	.step-monitoring .monitoring .monitoring__status {
		display: inline-block;
		vertical-align: top;
		min-width: 310px;
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
	}
	.step-monitoring .monitoring .monitoring__status p,
	.step-monitoring .monitoring .monitoring__status span {
		white-space: nowrap;
	}
	.step-monitoring .monitoring .buttons {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.step-monitoring .monitoring .buttons .btn {
		width: calc(50% - 8px);
	}
	.step-monitoring > .container {
		position: relative;
	}
	.step-monitoring .btn--primary {
		position: absolute;
		top: 32px;
		right: 16px;
		width: auto;
		padding: 0 32px;
	}
	.step-checking .administrators .btn {
		min-width: 130px;
	}
	.step-startup .box > div {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
}

@media (min-width: 1280px) {
	.site-club-container {
		-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
		box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
		border-radius: 6px;
	}
	.site-club-container .site-club__intro h2 {
		font-size: 30px;
		line-height: 36px;
	}
	.site-club-container .step-delegate-success {
		padding-top: 48px;
	}
	.site-club-container .step-delegate-success img {
		width: 176px;
		height: 176px;
	}
	.site-club-container .container {
		padding-left: 40px;
		padding-right: 40px;
	}
	.site-club-container .title-with-tooltip {
		margin-top: 40px;
	}
	.site-club-container .step-customization .site-club__sidebar {
		padding: 24px 32px 32px;
	}
	.site-club-container .step-customization .site-club__main {
		padding-left: 40px;
		padding-right: 48px;
	}
	.site-club-container .step-customization .site-club__main form {
		max-width: 500px;
	}
	.site-club-container .step-customization .title-with-tooltip {
		margin-top: 32px;
	}
	.site-club-container .step-customization .custom-logo .logo {
		margin-right: 24px;
	}
	.site-club-container .step-customization .btn--primary {
		width: auto;
		margin-top: 48px;
	}
	.site-club-container .step-customization .btn--full {
		padding: 0 32px;
	}
	.site-club-container .step-confirmation {
		padding-top: 40px;
	}
	.site-club-container .step-confirmation .simple-info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.site-club-container .step-confirmation .simple-info img,
	.site-club-container .step-confirmation .simple-info .link-icon {
		width: 56px;
		height: 56px;
		line-height: 53px;
		margin-right: 24px;
	}
	.site-club-container .step-confirmation .simple-info img + div,
	.site-club-container .step-confirmation .simple-info .link-icon + div {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	.site-club-container .step-confirmation .row-input .input {
		width: 100%;
	}
	.site-club-container .step-confirmation .row-input .btn {
		width: calc(40% - 16px);
		max-width: 200px;
	}
	.site-club-container .step-confirmation .btn--primary {
		width: auto;
		margin-right: 24px;
	}
	.site-club-container .step-confirmation .btn-with-link {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.site-club-container .step-confirmation .btn-with-link a:not(.btn--primary) {
		text-decoration: underline;
	}
	.site-club-container .input-with-edit {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-bottom: 16px;
	}
	.site-club-container .input-with-edit .input {
		width: 70%;
		margin-bottom: 0;
	}
	.site-club-container .input-with-edit .btn {
		position: relative;
		width: calc(30% - 16px);
		height: 56px;
		top: 0;
		right: 0;
		line-height: 56px;
	}
	.step-startup .btn {
		width: auto;
		padding: 0 32px;
	}
	.step-startup .box {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.step-startup .box > div:first-child {
		margin-right: 40px;
	}
	.step-checking .btn--full {
		width: auto;
		padding: 0 32px;
	}
	.step-checking .btn--primary {
		margin-right: 16px;
	}
	.step-checking .checkbox label {
		line-height: 16px;
	}
	.step-checking .administrators .btn {
		min-width: inherit;
	}
	.step-monitoring .monitoring {
		width: 100%;
		margin-top: -40px;
		padding: 56px 40px;
		border-radius: 6px 6px 0px 0px;
	}
	.step-monitoring .monitoring .monitoring__head {
		vertical-align: top;
	}
	.step-monitoring .monitoring .monitoring__head p {
		font-size: 30px;
		line-height: 36px;
	}
	.step-monitoring .monitoring .monitoring__head a {
		font-size: 14px;
	}
	.step-monitoring .monitoring .monitoring__status {
		-webkit-transform: translateY(-16px);
		transform: translateY(-16px);
	}
	.step-monitoring .monitoring .buttons {
		float: right;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
	}
	.step-monitoring .monitoring .buttons .btn {
		white-space: nowrap;
		margin-left: 24px;
	}
	.step-monitoring .btn--primary {
		top: 40px;
		right: 40px;
	}
}

.customization {
	position: relative;
	text-align: center;
}
.customization[data-view='desktop'] {
	-webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	overflow: hidden;
	margin: 0 auto;
	max-width: 580px;
}
.customization[data-view='desktop'] .desktop {
	display: block;
}
.customization[data-view='desktop'] .mobile {
	display: none;
}
.customization[data-view='mobile'] .desktop {
	display: none;
}
.customization[data-view='mobile'] .mobile {
	display: block;
}
.customization[data-view='mobile'] .placeholder-container {
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 100%;
	height: 100%;
	max-width: 375px;
}
.customization[data-view='mobile'] .placeholder-banner {
	height: 45%;
	max-width: 375px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
.customization[data-view='mobile'] .placeholder-banner span {
	display: block;
	height: 100%;
	background-size: auto 100%;
	background-repeat: no-repeat;
	background-position: top center;
}
.customization[data-view='mobile'] .placeholder-banner:before {
	position: absolute;
	content: '';
	top: 0;
	width: 100%;
	height: 100%;
}
.customization[data-view='mobile'] .placeholder-logo {
	top: 1.2%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	max-width: 12%;
	max-height: 5%;
}
.customization[data-view='mobile'] .placeholder-club-name {
	position: absolute;
	top: 19%;
	left: 4.5%;
	font-size: 12px;
}
@media (min-width: 768px) {
	.customization[data-view='mobile'] .placeholder-club-name {
		font-size: calc(12px + 18 * (100vw - 768px) / 512);
	}
}
@media (min-width: 1280px) {
	.customization[data-view='mobile'] .placeholder-club-name {
		font-size: 30px;
	}
}
.customization[data-view='mobile'] .placeholder-banner-match {
	top: 36%;
	max-width: 90%;
	width: 345px;
	height: 15%;
}
.customization[data-view='mobile'] .placeholder-banner-match span {
	height: 44%;
}
.customization[data-view='mobile'] .live-first-type-tag,
.customization[data-view='mobile'] .live-second-type-tag,
.customization[data-view='mobile'] .live-third-type-tag,
.customization[data-view='mobile'] .header-button {
	display: none;
}
.customization[data-view='mobile'] .banner-live-tag,
.customization[data-view='mobile'] .banner-score-tag,
.customization[data-view='mobile'] .card-hub-tag,
.customization[data-view='mobile'] .card-hub-score-tag {
	border-radius: 3px;
	height: 2%;
}
.customization[data-view='mobile'] .placeholder-topbar {
	left: 0;
	width: 100%;
	top: 7%;
	height: 4.5%;
	opacity: 0.4;
}
.customization[data-view='mobile'] .banner-live-tag {
	left: 4%;
	top: 8.2%;
	width: 14%;
}
.customization[data-view='mobile'] .banner-score-tag {
	width: 16%;
	left: 84%;
	top: 38%;
	height: 2.5%;
}
.customization[data-view='mobile'] .placeholder-banner-match {
	max-width: 92%;
}
.customization[data-view='mobile'] .placeholder-alaune {
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	top: 61%;
	width: 92%;
	height: 28%;
	border-radius: 4px;
}
.customization[data-view='mobile'] .placeholder-hub-score {
	width: 86%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	height: 15%;
	bottom: 4.7%;
	border-radius: 4px;
}
.customization[data-view='mobile'] .card-hub-tag {
	width: 37.5%;
	height: 8%;
	top: 5.2%;
	left: 4.4%;
}
.customization[data-view='mobile'] .card-hub-score-tag {
	width: 21%;
	height: 14%;
	left: 86.5%;
	top: 13%;
}
.customization .desktop {
	border-radius: 8px;
	overflow: hidden;
	-webkit-box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
}
.customization .template-mask {
	position: relative;
	z-index: 4;
}
.customization .primary-color-placeholder {
	position: absolute;
	content: '';
	background-color: #b02f38;
	border-radius: 1px;
}
.customization .primary-color-placeholder.index3 {
	z-index: 3;
}
.customization .primary-color-placeholder.index4 {
	z-index: 4;
}
.customization .primary-color-placeholder.index5 {
	z-index: 5;
}
.customization .placeholder-topbar {
	position: absolute;
	content: '';
	left: 0;
	top: 2.5%;
	width: 100%;
	height: 2.7%;
	background-color: #280b0d;
	z-index: 3;
}
.customization .placeholder-banner {
	position: absolute;
	top: 2.5%;
	width: 100%;
	height: 41%;
	background-size: cover;
	background-repeat: no-repeat;
	z-index: 2;
}
.customization .placeholder-banner .placeholder {
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
}
.customization .placeholder-banner .secondary-shadow {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	background-color: #280b0d;
}
.customization .placeholder-banner:before {
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(1, 32, 64, 0.6);
}
.customization .placeholder-banner-match {
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	top: 37.5%;
	width: 86%;
	height: 12%;
	z-index: 3;
	background-color: #280b0d;
	border-radius: 4px;
}
.customization .placeholder-banner-match span {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	height: 30%;
	width: 100%;
}
.customization .placeholder-banner-match span:before {
	position: absolute;
	content: '';
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
}
.customization .placeholder-club-name {
	position: absolute;
	top: 22.3%;
	left: 7%;
	z-index: 4;
	color: #fff;
	font-size: 12px;
}
@media (min-width: 768px) {
	.customization .placeholder-club-name {
		font-size: calc(12px + 8 * (100vw - 768px) / 512);
	}
}
@media (min-width: 1280px) {
	.customization .placeholder-club-name {
		font-size: 20px;
	}
}
.customization .placeholder-logo {
	position: absolute;
	left: 5.2%;
	top: 7%;
	max-width: 4%;
	max-height: 4%;
	z-index: 5;
}
.customization .placeholder-color-primary {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}
.customization .placeholder-background {
	position: absolute;
	content: '';
	bottom: 0;
	height: 69%;
	width: 100%;
	background-color: #f0f2f5;
	z-index: 1;
}
.customization .header-button {
	top: 7.2%;
	right: 9.1%;
	width: 11%;
	height: 3%;
	border-radius: 2px;
}
.customization .banner-live-tag,
.customization .banner-score-tag,
.customization .card-hub-tag,
.customization .card-hub-score-tag {
	height: 1.6%;
}
.customization .banner-live-tag {
	left: 6.7%;
	top: 14.5%;
	width: 4.6%;
}
.customization .banner-score-tag {
	top: 38.4%;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 4.25%;
}
.customization .card-hub-tag {
	left: 2.8%;
	top: 6.2%;
	height: 5.5%;
	width: 16.3%;
}
.customization .card-hub-score-tag {
	left: 50.4%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	top: 10.8%;
	width: 8%;
	height: 18%;
}
.customization .live-tag {
	left: 67.4%;
	color: #b02f38;
	background: none !important;
	font-family: 'Din';
	font-weight: 700;
	text-transform: uppercase;
	font-size: 2px;
}
@media (min-width: 768px) {
	.customization .live-tag {
		font-size: calc(2px + 3.4 * (100vw - 768px) / 512);
	}
}
@media (min-width: 1280px) {
	.customization .live-tag {
		font-size: 5.4px;
	}
}
.customization .live-first-type-tag {
	bottom: 30.2%;
}
.customization .live-second-type-tag {
	bottom: 22.7%;
}
.customization .live-third-type-tag {
	bottom: 9.8%;
}
.customization .placeholder-alaune,
.customization .placeholder-comite {
	position: absolute;
	width: 56%;
	height: 32%;
	left: 7%;
	top: 60.1%;
	border-radius: 2px;
	overflow: hidden;
	z-index: 2;
}
.customization .placeholder-alaune .placeholder,
.customization .placeholder-comite .placeholder {
	height: 100%;
}
.customization .placeholder-alaune .secondary-shadow,
.customization .placeholder-comite .secondary-shadow {
	position: absolute;
	content: '';
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #280b0d;
	opacity: 0.6;
}
.customization .placeholder-hub-score {
	position: absolute;
	width: 53%;
	height: 8%;
	left: 8.6%;
	bottom: 4.5%;
	border-radius: 2px;
	background-color: #fff;
	z-index: 3;
}
.customization .primary-shadow {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.9;
}
.customization .placeholder-contact {
	position: absolute;
	top: 57%;
	right: 7%;
	width: 28%;
	height: 64%;
	background-color: #280b0d;
	border-radius: 4px;
}
.customization .btn-cheer-primary {
	position: absolute;
	border: 0.1em solid #b02f38;
	padding: 1%;
	z-index: 4;
	border-radius: 100%;
	font-size: 0.3em;
	color: #b02f38;
}

.customization--comite .template-mask {
	pointer-events: none;
}

.customization--comite[data-view='mobile'] .mobile img {
	-webkit-box-shadow: 0 48px 0 10px #002348, 0 -48px 0 10px #002348;
	box-shadow: 0 48px 0 10px #002348, 0 -48px 0 10px #002348;
	border-radius: 20px;
}

.customization--comite[data-view='mobile'] .placeholder-container {
	overflow: hidden;
}

.customization--comite[data-view='mobile'] .placeholder-banner {
	height: 59.5%;
}

.customization--comite[data-view='mobile'] .banner-score-tag {
	width: 38%;
	left: 23.5%;
	top: 15.2%;
}

.customization--comite[data-view='mobile'] .placeholder-header,
.customization--comite[data-view='mobile'] .placeholder-comite,
.customization--comite[data-view='mobile'] .placeholder-alaune,
.customization--comite[data-view='mobile'] .placeholder-formation,
.customization--comite[data-view='mobile'] .btn-cheer-primary {
	display: none !important;
}

.customization--comite[data-view='mobile'] .banner-live-tag {
	left: 4%;
	top: 14.9%;
	width: 39%;
	height: 3%;
}

.customization--comite[data-view='mobile'] .placeholder-logo {
	top: 3.5%;
}

.customization--comite[data-view='mobile'] .placeholder-contact {
	left: 4%;
	right: 4%;
	width: auto;
	top: 132%;
}

.customization--comite[data-view='mobile'] .placeholder-banner .placeholder-navbar {
	height: 25%;
	top: 100%;
}

.customization--comite[data-view='mobile'] .placeholder-banner .navbar-button {
	height: 15%;
	left: 4%;
	right: 4%;
	width: auto;
	top: 105%;
}

.customization--comite .banner-live-tag {
	top: 15.2%;
	width: 10.6%;
}

.customization--comite .placeholder-logo {
	left: 6.2%;
	top: 8.5%;
	max-width: 6%;
}

.customization--comite .banner-btn {
	top: 36.2%;
	left: 6.7%;
}

.customization--comite .alaune-btn {
	top: 82.9%;
	right: 42%;
}

.customization--comite .placeholder-alaune {
	width: 42%;
	height: 29%;
	right: 7%;
	left: auto;
	top: 59.1%;
}

.customization--comite .placeholder-formation {
	position: absolute;
	z-index: 2;
	width: 42%;
	height: 29%;
	left: 7.2%;
	top: 59.1%;
	opacity: 0.9;
	border-radius: 2px;
	overflow: hidden;
}
.customization--comite .placeholder-formation .placeholder {
	height: 100%;
}

.customization--comite .placeholder-comite {
	top: 98.5%;
}

.customization--comite .placeholder-navbar,
.customization--comite .placeholder-header {
	position: absolute;
	background: #fff;
}

.customization--comite .placeholder-header {
	left: 0;
	top: 5.8%;
	width: 100%;
	height: 7%;
	z-index: 3;
}

.customization--comite .placeholder-navbar {
	left: 0;
	top: 100.5%;
	width: 100%;
	height: 9%;
	z-index: -1;
}

.customization--comite .navbar-button {
	top: 101.1%;
	left: 7.2%;
	width: 13.5%;
	height: 7%;
	border-radius: 2px;
}

.customization--comite .header-button {
	top: 8%;
	width: 13%;
}

.info-bubble {
	position: fixed;
	right: 16px;
	left: 16px;
	z-index: 4;
	bottom: 16px;
}
.info-bubble.is-open .info-bubble__widget .badge {
	line-height: 60px;
}
.info-bubble.is-open .info-bubble__widget .icon-close {
	display: inline-block;
}
.info-bubble.is-open .info-bubble__widget .icon-coment-fb {
	display: none;
}
.info-bubble.is-open .info-bubble__chat {
	display: inline-block;
}
.info-bubble:not(.is-open) .info-bubble__widget:hover .more {
	width: auto;
	max-width: calc(100vw - 32px);
	padding: 0 86px 0 32px;
}
.info-bubble .message__content {
	display: none;
}
.info-bubble .info-bubble__widget {
	position: absolute;
	bottom: 0;
	right: 0;
}
.info-bubble .info-bubble__widget .badge {
	position: relative;
	display: inline-block;
	text-align: center;
	width: 64px;
	height: 64px;
	line-height: 64px;
	border-radius: 50%;
	background-color: #002348;
	z-index: 2;
}
.info-bubble .info-bubble__widget i {
	color: #fff;
	font-size: 30px;
}
.info-bubble .info-bubble__widget .icon-close {
	display: none;
	font-size: 19px;
}
.info-bubble .info-bubble__widget .more {
	position: absolute;
	display: inline-block;
	right: 0;
	top: 0;
	width: 64px;
	height: 64px;
	line-height: 64px;
	border-radius: 32px;
	background-color: #013974;
	font-size: 14px;
	color: #fff;
	z-index: 1;
}
.info-bubble .info-bubble__widget .more p {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.info-bubble .info-bubble__feed {
	padding-bottom: 98px;
}
.info-bubble .info-bubble__feed .message {
	position: relative;
	padding: 24px;
	background-color: #f4f9fe;
	border: 1px solid #e3ecf4;
	color: #002348;
	border-radius: 12px;
}
.info-bubble .info-bubble__feed .message:nth-child(n + 2) {
	margin-top: 32px;
}
.info-bubble .info-bubble__feed .message::before,
.info-bubble .info-bubble__feed .message::after {
	position: absolute;
	content: '';
	width: 0;
	height: 0;
}
.info-bubble .info-bubble__feed .message:before {
	right: 33px;
	bottom: -17px;
	border-top: 17px solid #f4f9fe;
	border-left: 32px solid transparent;
	z-index: 2;
}
.info-bubble .info-bubble__feed .message:after {
	right: 32px;
	bottom: -19px;
	border-top: 19px solid #e3ecf4;
	border-left: 36px solid transparent;
	z-index: 1;
}
.info-bubble .info-bubble__feed .message span,
.info-bubble .info-bubble__feed .message p {
	line-height: 24px;
	margin-bottom: 0;
}
.info-bubble .info-bubble__feed .message.has-message span,
.info-bubble .info-bubble__feed .message.has-message p:not(.message__content) {
	display: none;
}
.info-bubble .info-bubble__feed .message.has-message .message__content {
	display: block;
}
.info-bubble .info-bubble__chat {
	position: absolute;
	bottom: 80px;
	right: 0;
	left: 0;
	border-radius: 12px;
	background-color: #fff;
	overflow: hidden;
	z-index: 3;
	display: none;
}
.info-bubble .info-bubble__chat .chat-head {
	height: 60px;
	line-height: 60px;
	padding: 0 24px;
	background-color: #002348;
	color: #fff;
}
.info-bubble .info-bubble__chat .chat-body {
	padding: 0 16px 16px 16px;
	height: 300px;
	border: 1px solid #e3ecf4;
	border-top: none;
}
.info-bubble .info-bubble__chat .chat-body .input {
	margin-bottom: 0;
	height: 200px;
}
.info-bubble .info-bubble__chat .chat-body textarea {
	height: 100%;
	border: none;
	resize: none;
	padding: 24px 0 0 0;
}
.info-bubble .info-bubble__chat .chat-body .btn {
	height: 60px;
}
.info-bubble .info-bubble__chat .chat-success {
	height: 100%;
	padding: 40px 24px 32px 24px;
	background-color: #f4f9fe;
	color: #7d93a8;
	text-align: center;
	display: none;
}
.info-bubble .info-bubble__chat .chat-success .circle-icon {
	width: 56px;
	height: 56px;
	margin: 0 auto;
}
.info-bubble .info-bubble__chat .btn {
	width: 100%;
}

@media (min-width: 768px) {
	.info-bubble {
		position: absolute;
		bottom: inherit;
		right: 0;
		margin-bottom: 32px;
		min-height: 324px;
	}
	.info-bubble .info-bubble__widget {
		bottom: 48px;
	}
	.info-bubble .info-bubble__feed {
		padding-bottom: 0;
		position: absolute;
		bottom: 140px;
		width: 100%;
	}
	.info-bubble .info-bubble__chat {
		bottom: 128px;
	}
	.info-bubble.is-open {
		top: 268px;
	}
	.info-bubble.is-open .info-bubble__feed {
		padding-bottom: 146px;
	}
	.info-bubble__widget:hover {
		cursor: pointer;
	}
}

@media (min-width: 1280px) {
	.info-bubble {
		left: 40px;
	}
}

.block-favorite-player {
	padding: 24px;
}
.block-favorite-player > div .card--pictureFavorite {
	width: 100%;
}

@media (min-width: 768px) {
	.block-favorite-player > div .card--pictureFavorite {
		width: calc((100% / 3) - 8px);
		display: inline-block;
		margin-right: 12px;
	}
	.block-favorite-player > div .card--pictureFavorite:nth-child(3n) {
		margin-right: 0;
	}
}

.block-newsletter {
	padding: 24px;
}
.block-newsletter a {
	display: block;
	width: 100%;
}
.block-newsletter li {
	display: inline-block;
	width: 100%;
}
.block-newsletter .checkbox {
	margin-top: 10px;
	margin-bottom: 10px;
}
.block-newsletter .checkbox.check-right label {
	width: 100%;
}
.block-newsletter .checkbox.check-right input {
	display: none;
}

@media (min-width: 768px) {
	.block-newsletter ul li {
		width: calc(50% - 2px);
		padding-right: 32px;
	}
	.block-newsletter ul li:nth-child(2n + 1) {
		border-right: 1px solid #ebebeb;
	}
	.block-newsletter ul li:nth-child(2n) {
		padding-left: 24px;
	}
	.block-newsletter a {
		width: auto;
		float: right;
	}
	.block-newsletter a:last-child {
		margin-right: 24px;
		margin-top: 30px;
	}
}

@media (min-width: 1280px) {
	.block-newsletter ul li {
		width: calc(100% / 3 - 4px);
		max-width: 270px;
		padding-left: 24px;
		border-right: 1px solid #ebebeb;
	}
	.block-newsletter ul li:nth-child(3n) {
		border: 0;
		padding-right: 0;
	}
	.block-newsletter ul li:nth-last-child(3n) {
		padding-left: 0;
	}
}

.block-youtube {
	position: relative;
	border-radius: 4px;
	overflow: hidden;
	margin-top: -200px;
}
.block-youtube .icon-close {
	position: absolute;
	top: 20px;
	right: 20px;
	color: rgba(255, 255, 255, 0.6);
	display: none;
	cursor: pointer;
}
.block-youtube .icon-close:hover {
	color: #fff;
}
.block-youtube .btn {
	width: 100%;
	border-radius: 0 0 4px 4px;
}
.block-youtube .ft-h3 {
	max-width: 500px;
}
.block-youtube:before {
	content: '\e91f';
	font-family: 'icon';
	position: absolute;
	top: 0;
	left: -65px;
	font-size: 160px;
	line-height: 1;
	opacity: 0.1;
	pointer-events: none;
}

.block-youtube__content {
	padding: 32px 32px 24px;
}
.block-youtube__content .ft-secondary {
	font-size: 16px;
}

@media (min-width: 768px) {
	.block-youtube {
		padding: 48px 32px 48px 140px;
		margin-top: -180px;
	}
	.block-youtube .icon-close {
		display: block;
	}
	.block-youtube .btn {
		width: auto;
		border-radius: 4px;
		margin-top: 16px;
	}
	.block-youtube:before {
		font-size: 210px;
		left: -100px;
		top: 50%;
		margin-top: -105px;
	}
	.block-youtube__content {
		padding: 0;
	}
}

@media (min-width: 1280px) {
	.block-youtube {
		padding-left: 160px;
	}
	.block-youtube .btn {
		position: absolute;
		right: 70px;
		top: 50%;
		margin-top: -24px;
	}
	.block-youtube:before {
		font-size: 160px;
		left: -40px;
		margin-top: -80px;
	}
	.block-youtube__content {
		padding-right: 450px;
	}
}

.block-site-club.block-site-club--index {
	padding-bottom: 0;
}
.block-site-club.block-site-club--index .block-site-club__header {
	position: relative;
	background-position: left bottom;
	background-repeat: no-repeat;
	background-size: 160%;
	background-color: #f4f9fe;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__header {
		height: 180px;
		background-size: 100%;
		position: relative;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}
	.block-site-club.block-site-club--index .block-site-club__header > .container {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__header {
		height: 204px;
		background-position: inherit;
		background-size: cover;
	}
}
.block-site-club.block-site-club--index .block-site-club__header h3 {
	color: #002348;
}
.block-site-club.block-site-club--index .block-site-club__header p {
	color: #7d93a8;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__header p {
		font-size: 17px;
	}
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__header h3,
	.block-site-club.block-site-club--index .block-site-club__header p {
		max-width: 611px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__header h3,
	.block-site-club.block-site-club--index .block-site-club__header p {
		max-width: 793px;
	}
}
.block-site-club.block-site-club--index .block-site-club__header .info-bubble {
	display: none;
	position: absolute;
}
.block-site-club.block-site-club--index .block-site-club__header .info-bubble .info-bubble__widget {
	cursor: initial;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__header .info-bubble {
		display: block;
		right: 16px;
		top: 124px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__header .info-bubble {
		right: 76px;
		top: 133px;
	}
}
.block-site-club.block-site-club--index .block-site-club__header .icon-coment-fb {
	position: absolute;
	top: 50%;
	right: 32px;
	display: none;
	text-align: center;
	width: 64px;
	height: 64px;
	line-height: 64px;
	margin-top: -32px;
	border-radius: 50%;
	background-color: #002348;
	color: #fff;
	font-size: 30px;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__header .icon-coment-fb {
		display: block;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__header .icon-coment-fb {
		right: 80px;
	}
}
.block-site-club.block-site-club--index .block-site-club__content img {
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content p {
		font-size: 17px;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first p,
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first ul,
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second p,
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second ul,
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third p,
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third ul {
	color: #999999;
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first h3,
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second h3,
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third h3 {
		font-size: 30px;
		line-height: 36px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first {
		margin-bottom: 140px;
	}
}
@media (min-width: 1680px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first {
		margin-bottom: 210px;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first ul li {
	line-height: 28px;
	white-space: break-spaces;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first ul li {
		font-size: 17px;
		line-height: 38px;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first ul li:before {
	display: inline-block;
	content: '•';
	margin-right: 24px;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first h3,
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first p {
		max-width: 361px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first h3,
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first p {
		max-width: 380px;
	}
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first h3 {
		margin-top: 64px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--first h3 {
		margin-top: 127px;
	}
}
.block-site-club.block-site-club--index
	.block-site-club__content.block-site-club__content--first
	.site-club__content__img {
	height: 300px;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--first
		.site-club__content__img {
		padding-left: 51px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--first
		.site-club__content__img {
		padding-left: inherit;
		height: 480px;
	}
}
.block-site-club.block-site-club--index
	.block-site-club__content.block-site-club__content--first
	.site-club__content__img
	div {
	width: calc(100% - 24px);
	height: 100%;
	background-size: contain;
	margin-left: auto;
	background-repeat: no-repeat;
	background-position: center;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--first
		.site-club__content__img
		div {
		margin-right: auto;
		margin-left: 0;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--first
		.site-club__content__img
		div {
		width: 100%;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second {
	background-color: #003974;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second {
		height: 308px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second {
		height: 501px;
	}
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.row
		> div:last-child {
		position: relative;
		height: 308px;
	}
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.row
		> div:last-child
		> div {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.row
		> div:last-child {
		height: 501px;
	}
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.row
		> div:last-child
		> div {
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		left: 50%;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second h3 {
	color: #fff;
	margin-top: 48px;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second h3 {
		margin-top: 0;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second p {
	color: rgba(255, 255, 255, 0.8);
	margin-bottom: 56px;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second p {
		margin-bottom: 0;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second h3,
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second p {
		width: 391px;
	}
}
.block-site-club.block-site-club--index
	.block-site-club__content.block-site-club__content--second
	.site-club__content__img {
	position: relative;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.site-club__content__img
		img {
		position: absolute;
		bottom: -308px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--second
		.site-club__content__img
		img {
		bottom: -501px;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second .row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second .row {
		display: block;
	}
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second img {
		display: none;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second .is-tablet {
	display: none;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second .is-tablet {
		display: block;
		max-width: 360px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--second .is-tablet {
		max-width: 82%;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third {
	overflow: hidden;
	width: 100%;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third {
		height: 334px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third {
		height: 503px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third p {
		max-width: 393px;
	}
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--third
		.row
		> div:first-child {
		height: 334px;
	}
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--third
		.row
		> div:first-child
		> div {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index
		.block-site-club__content.block-site-club__content--third
		.row
		> div:first-child {
		height: 503px;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third img {
	padding: 0 16px;
	margin-bottom: 48px;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third img {
		margin-bottom: 0;
		height: 299px;
		display: block;
		margin: 0 auto;
		margin-top: 26px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--third img {
		height: 463px;
		margin-top: 24px;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth {
	position: relative;
	height: 300px;
	background-size: cover;
	background-position: center center;
	color: #fff;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth {
		height: 256px;
	}
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth h3,
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth p {
		max-width: 375px;
	}
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth h3,
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth p {
		max-width: inherit;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth h3 {
	color: #fff;
}
@media (min-width: 1280px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth h3 {
		margin-top: 92px;
	}
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth p {
	color: rgba(255, 255, 255, 0.8);
}
.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth .btn {
	position: relative;
	z-index: 2;
}
@media (min-width: 768px) {
	.block-site-club.block-site-club--index .block-site-club__content.block-site-club__content--fourth .btn {
		display: block;
		margin-left: auto;
		margin-right: 0;
		margin-top: 104px;
		max-width: 236px;
	}
}

.form-match {
	position: relative;
}
.form-match .dropdown {
	width: 100%;
	min-width: 345px;
	-webkit-box-shadow: 0 0 0 1px #ebebeb inset;
	box-shadow: 0 0 0 1px #ebebeb inset;
	border: 0;
}
.form-match .dropdown:first-child,
.form-match .dropdown .dropdown__head {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.form-match .dropdown:nth-child(2) {
	margin-top: -1px;
}
.form-match .dropdown:nth-child(2),
.form-match .dropdown .dropdown__head {
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.form-match .dropdown__list {
	-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
	box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08), 0 0 0 1px #ebebeb;
}

@media (min-width: 768px) {
	.form-match {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.form-match .dropdown {
		display: inline-block;
	}
	.form-match .dropdown:first-child,
	.form-match .dropdown .dropdown__head {
		border-radius: 4px 0 0 4px;
	}
	.form-match .dropdown:first-child {
		width: 54%;
		min-width: 300px;
	}
	.form-match .dropdown:first-child .dropdown__list {
		overflow: initial;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-top: 0;
		margin-top: 1px;
	}
	.form-match .dropdown:first-child .dropdown__list .dropdown__head {
		height: 79px;
	}
	.form-match .dropdown:first-child.is-open .dropdown__wrapper {
		width: calc(150% - 4px);
	}
	.form-match .dropdown:nth-child(2),
	.form-match .dropdown .dropdown__head {
		border-top-left-radius: 4px;
		border-bottom-right-radius: 0;
	}
	.form-match .dropdown:nth-child(2) {
		margin-top: 0;
		margin-left: -1px;
		width: 27%;
		min-width: 208px;
		border-radius: 0 4px 4px 0;
	}
	.form-match .btn {
		display: inline-block;
		vertical-align: top;
		width: auto;
		min-width: 220px;
		margin-left: 8px;
	}
}

@media (min-width: 1280px) {
	.form-match .dropdown:first-child {
		width: 100%;
	}
	.form-match .dropdown:first-child .dropdown__list {
		overflow: hidden;
	}
	.form-match .dropdown:first-child.is-open .dropdown__wrapper {
		width: 100%;
	}
	.form-match .dropdown:nth-child(2) {
		width: 27%;
		min-width: 236px;
	}
	.form-match .btn {
		margin-left: 16px;
		min-width: 222px;
	}
}

.channel-header {
	margin-bottom: 40px;
	margin-top: -240px;
}
.channel-header .card--video {
	height: 240px;
}

.channel-header__content {
	background: #fff;
	color: #292929;
	border-radius: 0 0 4px 4px;
	padding: 32px 24px 24px;
	margin-top: -4px;
}

@media (min-width: 768px) {
	.channel-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 56px;
		margin-top: -278px;
	}
	.channel-header .card--video {
		height: 330px;
	}
	.channel-header__content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		width: 35%;
		overflow: hidden;
		border-radius: 0 4px 4px 0;
		margin: 8px 0 0;
		padding: 24px;
		height: 314px;
	}
}

@media (min-width: 1280px) {
	.channel-header {
		margin-top: -294px;
	}
	.channel-header .card--video {
		height: 506px;
	}
	.channel-header .card__title {
		font-size: 20px;
		margin-top: 12px;
	}
	.channel-header .card__footer {
		padding: 40px;
	}
	.channel-header .link-icon {
		width: 60px;
		height: 60px;
		line-height: 56px;
		margin-right: 16px;
	}
	.channel-header__content {
		width: 33%;
		height: 426px;
		margin-top: 40px;
		padding: 40px;
	}
}

.grid-centenaire {
	position: relative;
	background-color: #013974;
}
.grid-centenaire > div p {
	position: relative;
	top: 0;
	display: block;
	width: 100%;
	max-width: 1028px;
	margin-left: auto;
	margin-right: auto;
	top: -50px;
	z-index: 2;
}
@media (min-width: 768px) {
	.grid-centenaire > div p {
		top: -110px;
	}
}
.grid-centenaire .container {
	position: relative;
	top: -1px;
	background-color: #013974;
}
.grid-centenaire .grid-centenaire__container {
	z-index: 2;
	margin: 0 auto;
	max-width: 1028px;
	position: relative;
	top: -50px;
}
@media (min-width: 768px) {
	.grid-centenaire .grid-centenaire__container {
		top: -100px;
	}
}
.grid-centenaire .grid-centenaire__container .card--centenaire {
	width: 100%;
	margin-bottom: 32px;
}
@media (min-width: 768px) {
	.grid-centenaire .grid-centenaire__container .card--centenaire {
		width: calc((100% / 3) - 22px);
	}
}
.grid-centenaire .grid-centenaire__more {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 351px;
	bottom: 0;
	left: 0;
	padding-top: 90px;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(3, 57, 113, 0)), color-stop(69%, #033971));
	background: linear-gradient(to bottom, rgba(3, 57, 113, 0) 0%, #033971 69%);
}
.grid-centenaire .grid-centenaire__more a {
	position: absolute;
	bottom: 52px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	min-width: 296px;
}

.centenaire-search .grid-centenaire__container .card--centenaire {
	width: 100%;
	margin-bottom: 32px;
}
@media (min-width: 768px) {
	.centenaire-search .grid-centenaire__container .card--centenaire {
		width: calc((100% / 3) - 21px);
	}
}
@media (min-width: 1280px) {
	.centenaire-search .grid-centenaire__container .card--centenaire {
		width: calc(25% - 24px);
	}
}

.centenaire-publication {
	background-color: #fff;
	border-radius: 4px;
}

.centenaire-publication__body {
	padding: 24px;
}
.centenaire-publication__body > p {
	font-size: 16px;
}

.centenaire-publication__info span {
	margin-right: 24px;
}
.centenaire-publication__info span i {
	position: relative;
	top: -1px;
}

.centenaire-publication__action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.centenaire-publication__action .share {
	margin-left: 8px;
}

.centenaire-publication__footer .ft-right i {
	font-size: 9px;
}

.centenaire-publication__thumbnail {
	background-color: #002348;
}
.centenaire-publication__thumbnail img {
	display: block;
	margin: 0 auto;
}

@media (min-width: 768px) {
	.centenaire-publication {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		background-color: transparent;
		border-radius: 0;
	}
	.centenaire-publication__thumbnail {
		position: relative;
		border-radius: 4px;
		width: 60%;
		padding: 32px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.centenaire-publication__thumbnail img {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		max-height: 350px;
	}
	.centenaire-publication__body {
		width: 40%;
		background-color: #fff;
		border-radius: 0 4px 4px 0;
		margin: 24px 0;
	}
	.centenaire-publication__info span {
		display: block;
		margin-bottom: 4px;
	}
}

@media (min-width: 1280px) {
	.centenaire-publication__thumbnail {
		width: 75%;
	}
	.centenaire-publication__thumbnail img {
		max-height: 550px;
	}
	.centenaire-publication__body {
		overflow: hidden;
		position: relative;
		width: 25%;
		min-height: 550px;
		margin: 32px 0;
		padding: 32px 24px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.centenaire-publication__info span {
		display: inline-block;
		margin-bottom: 0;
	}
	.centenaire-publication__footer {
		width: 100%;
		background-color: #fff;
		margin-top: auto;
	}
}

.billboard {
	background-color: #fff;
	padding-top: 16px;
	padding-bottom: 24px;
}

.billboard__category .btn--primary {
	width: 100%;
}

.billboard__links {
	display: none;
}

@media (min-width: 768px) {
	.billboard {
		padding-bottom: 0;
	}
	.billboard__news .news {
		display: inline-block;
		vertical-align: top;
		width: calc(33.3% - 10px);
		margin-left: 15px;
		margin-bottom: 16px;
	}
	.billboard__news .news:first-child {
		margin-left: 0;
	}
	.billboard__news .news:last-child {
		display: none;
	}
	.billboard__news .news article {
		display: block;
	}
	.billboard__news .news__thumbnail {
		width: 100%;
		height: 188px;
		margin: 0 0 8px;
	}
	.billboard__category {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		padding: 16px;
		margin: 0 -16px;
		border-top: 1px solid #ebebeb;
	}
	.billboard__category .btn--primary {
		width: auto;
	}
	.billboard__links {
		display: block;
		font-size: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		margin: 0;
	}
	.billboard__links li {
		font-size: 14px;
		line-height: 32px;
		display: inline-block;
	}
	.billboard__links a {
		padding: 0 12px;
	}
	.billboard__links span {
		color: #999;
		margin-right: 8px;
	}
	.billboard__plus {
		position: relative;
	}
	.billboard__plus .accordion-trigger:focus {
		color: #292929;
	}
	.billboard__plus .accordion-trigger:after {
		content: '\e906';
		color: #7d93a8;
	}
	.billboard__plus .accordion-trigger.is-selected {
		color: #2a9bff;
	}
	.billboard__plus .accordion-trigger.is-selected:after {
		color: #2a9bff;
		content: '\e914';
	}
	.billboard__sub {
		margin: 0;
		position: absolute;
		z-index: 1;
		top: 100%;
		right: -16px;
		background-color: #fff;
		-webkit-box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
		box-shadow: 0 10px 16px rgba(51, 51, 51, 0.08);
		padding: 16px 32px;
	}
	.billboard__sub li {
		display: block;
		line-height: 32px;
		white-space: nowrap;
	}
	.billboard__sub a {
		padding: 0;
		display: block;
	}
}

@media (min-width: 1280px) {
	.billboard {
		background: none;
	}
	.billboard__wrapper {
		background-color: #fff;
		-webkit-box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
		box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
		border-radius: 4px;
		margin-left: -40px;
		margin-right: -40px;
	}
	.billboard__news {
		padding: 40px;
	}
	.billboard__news--fullWidth {
		padding: 0;
	}
	.billboard__news .news {
		width: calc(25% - 24px);
		margin-left: 32px;
		margin-bottom: 0;
	}
	.billboard__news .news:last-child {
		display: inline-block;
	}
	.billboard__category {
		margin: 0;
		padding: 16px 40px;
	}
}

.home-slider {
	position: relative;
	height: 495px;
	color: #fff;
}
.home-slider .container {
	height: 100%;
}
.home-slider .label {
	margin-bottom: 16px;
}
.home-slider .btn--cheer {
	display: none;
}
.home-slider .img-blue-dark:before {
	opacity: 0.6;
}
.home-slider .img-blue-dark:after {
	background: -webkit-gradient(
			linear,
			left top,
			left bottom,
			color-stop(5%, #003974),
			color-stop(38%, rgba(0, 57, 116, 0))
		),
		-webkit-gradient(linear, left bottom, left top, from(#003974), color-stop(37%, rgba(0, 57, 116, 0)));
	background: linear-gradient(-180deg, #003974 5%, rgba(0, 57, 116, 0) 38%),
		linear-gradient(0deg, #003974 0%, rgba(0, 57, 116, 0) 37%);
	opacity: 0.72;
}
.home-slider h2 a:hover {
	color: #fff;
	text-decoration: none;
}

.home-slider__links {
	display: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
	width: calc(100% - 326px);
	margin-bottom: 0;
}
@media (min-width: 1590px) {
	.home-slider__links {
		width: calc(100% - 370px);
	}
}
@media (max-width: 1080px) {
	.home-slider__links {
		width: calc(100% - 35vw + 60px);
	}
}
.home-slider__links li {
	display: inline-block;
	margin-right: 12px;
}
.home-slider__links li a,
.home-slider__links li span {
	white-space: nowrap;
}
.home-slider__links > li:first-child {
	opacity: 0.5;
}
.home-slider__links .accordion-panel li {
	display: block;
}
.home-slider__links .accordion-trigger:after {
	position: absolute;
	line-height: 22px;
	margin-left: 4px;
}

.home-slider__wrapper {
	position: relative;
	height: 100%;
}

.home-slider__item {
	padding-top: 202px;
}
@media (min-width: 500px) {
	.home-slider__item {
		padding-top: 252px;
	}
}

.home-slider__news-categories .container {
	padding: 0;
}

.home-slider__pagination {
	position: absolute;
	bottom: 50px;
	right: 0;
	width: 100%;
	text-align: center;
	font-size: 0;
	font-weight: 500;
	margin-bottom: 0;
}
.home-slider__pagination li {
	display: inline-block;
	vertical-align: middle;
	width: 20px;
	height: 20px;
	border: 2px solid transparent;
	border-radius: 100%;
	line-height: 15px;
	margin: 0 8px;
	overflow: hidden;
	cursor: pointer;
	color: rgba(255, 255, 255, 0.32);
	font-size: 14px;
}
.home-slider__pagination li:after {
	content: '';
	width: 8px;
	height: 8px;
	background-color: rgba(255, 255, 255, 0.32);
	display: inline-block;
	border-radius: 100%;
}
.home-slider__pagination li.is-active {
	border-color: rgba(255, 255, 255, 0.32);
	color: #fff;
}
.home-slider__pagination li.is-active:after {
	display: none;
}
.home-slider__pagination span {
	display: none;
}

@media (min-width: 768px) {
	.home-slider {
		height: calc(485px + 72px);
		padding-bottom: 40px;
	}
	.home-slider h2 {
		width: 60%;
	}
	.home-slider .btn--cheer {
		display: inline-block;
		position: absolute;
		bottom: 145px;
	}
	.home-slider .container {
		padding: 0 60px;
	}
	.home-slider__links {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.home-slider__news-categories {
		position: absolute;
		bottom: 0;
		padding: 0 60px;
		height: 56px;
		width: 100%;
		border-top: 1px solid rgba(255, 255, 255, 0.12);
	}
	.home-slider__news-categories .container {
		padding: 0;
	}
	.home-slider__news-categories .billboard__sub {
		color: #292929;
	}
	.home-slider__item {
		padding-top: 162px;
	}
	.home-slider__pagination {
		width: auto;
	}
	.home-slider__pagination li {
		width: 48px;
		height: 48px;
		line-height: 44px;
		border-color: rgba(255, 255, 255, 0.32);
	}
	.home-slider__pagination li:after {
		display: none;
	}
	.home-slider__pagination li:hover {
		border-color: #fff;
		color: #fff;
	}
	.home-slider__pagination span {
		display: inline-block;
	}
}

@media (min-width: 1080px) {
	.home-slider {
		height: 780px;
	}
	.home-slider h2 {
		font-size: 48px;
		line-height: 60px;
		width: 60%;
	}
	.home-slider .btn--cheer {
		bottom: 170px;
	}
	.home-slider__item {
		padding-top: 272px;
	}
}

@media (min-width: 1280px) {
	.home-slider h2 {
		width: 50%;
	}
	.home-slider .home-slider__links li {
		margin-right: 24px;
	}
	.home-slider .home-slider__news-categories .container {
		max-width: calc(1680px - 120px);
		padding: 0;
	}
}

.home-tv {
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	white-space: nowrap;
	margin-left: -16px;
	margin-right: -16px;
	padding-left: 16px;
	padding-bottom: 16px;
}
.home-tv .card {
	display: inline-block;
	width: 286px;
	margin-right: 16px;
}

@media (min-width: 768px) {
	.home-tv {
		overflow: visible;
		white-space: normal;
		margin-left: 0;
		margin-right: 0;
		padding: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-ms-flex-line-pack: justify;
		align-content: space-between;
		height: 342px;
	}
	.home-tv .card {
		width: 33%;
		height: 162px;
		margin-right: 0;
	}
	.home-tv .card:first-child {
		width: calc(67% - 16px);
		height: 342px;
	}
	.home-tv .card:first-child .card__title {
		font-size: 20px;
		margin-top: 12px;
	}
	.home-tv .card:first-child .link-icon {
		width: 60px;
		height: 60px;
		line-height: 56px;
		margin-right: 16px;
	}
	.home-tv .card:last-child {
		display: none;
	}
}

@media (min-width: 1280px) {
	.home-tv {
		height: 640px;
	}
	.home-tv .card {
		width: 25%;
		height: 200px;
	}
	.home-tv .card:first-child {
		width: calc(75% - 32px);
		height: 640px;
	}
	.home-tv .card:first-child .card__footer {
		padding: 40px;
	}
	.home-tv .card:first-child .card__type {
		top: 40px;
		left: 40px;
	}
	.home-tv .card:last-child {
		display: block;
	}
}

.centenaire-search {
	position: relative;
	background-color: #013974;
	padding-top: 129px;
}
@media (min-width: 1080px) {
	.centenaire-search {
		padding-top: 209px;
	}
}
.centenaire-search .grid-centenaire__container {
	top: 0;
	max-width: inherit;
	min-height: 100vh;
}
.centenaire-search .centenaire-search__filter {
	position: relative;
	z-index: 3;
	margin-top: 24px;
	margin-bottom: 8px;
}
@media (min-width: 1080px) {
	.centenaire-search .centenaire-search__filter {
		margin-top: 24px;
		margin-bottom: 16px;
	}
}
.centenaire-search .centenaire-search__filter .btn,
.centenaire-search .centenaire-search__filter > p {
	vertical-align: top;
}
.centenaire-search .centenaire-search__filter p {
	position: inherit;
	top: 1px;
	display: inline;
	line-height: 48px;
	margin-right: 16px;
	color: #fff;
}
.centenaire-search .centenaire-search__filter .breadcrumb {
	display: inline-block;
}
@media (min-width: 1080px) {
	.centenaire-search .centenaire-search__filter .breadcrumb {
		line-height: 50px;
	}
}
.centenaire-search .centenaire-search__filter > .centenaire-search__paragraph {
	display: inline;
	position: inherit;
	top: inherit;
	line-height: 48px;
	color: #fff;
	margin-right: 8px;
}
@media (min-width: 1080px) {
	.centenaire-search .centenaire-search__filter > .centenaire-search__paragraph {
		margin-right: 24px;
	}
}
.centenaire-search .centenaire-search__filter .centenaire-search__filter__dropdown {
	display: inline-block;
	width: 100%;
}
.centenaire-search .centenaire-search__filter .centenaire-search__filter__dropdown .dropdown {
	display: inline-block;
	height: 38px;
	float: right;
}
@media (min-width: 1080px) {
	.centenaire-search .centenaire-search__filter .centenaire-search__filter__dropdown .dropdown {
		height: 48px;
		float: none;
	}
}
@media (min-width: 1080px) {
	.centenaire-search .centenaire-search__filter .centenaire-search__filter__dropdown {
		float: right;
		width: inherit;
	}
}
.centenaire-search .centenaire-search__filter .centenaire-search__filter__btn {
	position: fixed;
	left: 0;
	bottom: 16px;
	width: 100%;
	text-align: center;
}
@media (min-width: 1080px) {
	.centenaire-search .centenaire-search__filter .centenaire-search__filter__btn {
		width: auto;
		float: right;
		position: inherit;
		bottom: inherit;
		left: inherit;
		-webkit-transform: inherit;
		transform: inherit;
		text-align: inherit;
	}
}
.centenaire-search .centenaire-search__filter .centenaire-search__filter__btn .btn--gold {
	margin-right: 16px;
}
.centenaire-search .centenaire-search__filter .centenaire-search__filter__btn .btn--white {
	color: #7d93a8;
}
.centenaire-search .centenaire-search__filter .centenaire-search__filter__btn .btn--white:hover i {
	color: #7d93a8;
}
.centenaire-search .centenaire-search__filter .dropdown {
	background-color: transparent;
	color: #fff;
	vertical-align: top;
	margin-top: 0;
	float: none;
	right: -20px;
	top: -2px;
}
@media (min-width: 1080px) {
	.centenaire-search .centenaire-search__filter .dropdown {
		display: inline-block;
		margin-top: 16px;
		right: inherit;
		top: inherit;
	}
}
.centenaire-search .centenaire-search__filter .dropdown .dropdown__list {
	border-radius: 0;
	z-index: 10;
}
.centenaire-search .centenaire-search__filter .dropdown.is-open {
	color: #292929;
}
.centenaire-search .link-chevron {
	color: #fff;
}

.centenaire-search--map {
	position: relative;
	padding-top: 129px;
}
@media (min-width: 768px) {
	.centenaire-search--map {
		padding-top: 137px;
	}
}
@media (min-width: 1080px) {
	.centenaire-search--map {
		padding-top: 209px;
	}
}
.centenaire-search--map .centenaire-search__filter {
	position: absolute;
	bottom: 20px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 1000;
	margin: 0;
	font-family: 'Roboto';
	width: 250px;
}
@media (min-width: 768px) {
	.centenaire-search--map .centenaire-search__filter {
		width: 300px;
		top: 26px;
		right: 52px;
		bottom: inherit;
		left: inherit;
		-webkit-transform: inherit;
		transform: inherit;
	}
}
.centenaire-search--map .centenaire-search__filter .btn--gold {
	color: #fff;
	margin-right: 16px;
}
.centenaire-search--map .centenaire-search__filter .btn--white {
	color: #7d93a8;
}
.centenaire-search--map .centenaire-search__filter .btn--white:hover {
	color: #2a9bff;
}
.centenaire-search--map .leaflet-container a {
	color: inherit;
}
.centenaire-search--map #map {
	position: relative;
	height: 691px;
	z-index: 0;
	top: 1px;
}
.centenaire-search--map #map .leaflet-control-container .leaflet-top {
	top: 26px;
	right: 24px;
}
.centenaire-search--map #map .leaflet-control-container .leaflet-bar {
	-webkit-box-shadow: initial;
	box-shadow: initial;
}
.centenaire-search--map #map .leaflet-control-container .leaflet-control-zoom {
	margin: 0;
	border: 0;
}
.centenaire-search--map #map .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-in {
	margin-bottom: 8px;
}
.centenaire-search--map #map .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-in,
.centenaire-search--map #map .leaflet-control-container .leaflet-control-zoom .leaflet-control-zoom-out {
	width: 32px;
	height: 32px;
	border-radius: 4px;
	border: 2px solid #ebebeb;
	color: #7d93a8;
	font-size: 16px;
	line-height: 26px;
}
.centenaire-search--map #map .leaflet-control-container .leaflet-bottom {
	display: none;
}
.centenaire-search--map .marker {
	position: relative;
	width: auto !important;
	height: auto !important;
}
.centenaire-search--map .marker .marker__image {
	width: 72px;
	height: 77px;
}
.centenaire-search--map .marker__text {
	position: absolute;
	top: 14px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	font-size: 14px;
	color: #fff;
	font-weight: 700;
	font-family: 'Din';
}
.centenaire-search--map .marker--small,
.centenaire-search--map .marker--small .marker__image {
	width: 43px !important;
	height: 48px !important;
}
.centenaire-search--map .marker--small .marker__text,
.centenaire-search--map .marker--small .marker__image .marker__text {
	color: #292929;
	top: 10px;
	left: 21px;
}

.centenaire-search__background {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 874px;
	background-size: cover;
}
.centenaire-search__background::after {
	display: block;
	content: '';
	position: absolute;
	bottom: -1px;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(65%, rgba(0, 39, 82, 0)), to(#033971));
	background-image: linear-gradient(180deg, rgba(0, 39, 82, 0) 65%, #033971 100%);
	width: 100%;
	height: 300px;
	pointer-events: none;
}

.card--hub-wheel {
	min-height: 424px !important;
}

.card--hub-wheel .card__footer {
	text-align: center;
	position: inherit;
	padding-top: 0;
}

.card--hub-wheel .card__body {
	text-align: center;
	padding: 24px !important;
}

.card--hub-wheel .card__title {
	font-size: 36px;
	font-weight: normal;
	line-height: 1.2;
	text-transform: uppercase;
	margin: 26px 0 14px 0;
	font-family: 'DK-Cinnabar-Brush';
}

.card--hub-wheel .card__title span {
	color: red;
}

.card--hub-wheel .img-blue-dark:before {
	background: transparent;
	opacity: 0;
}

@media (min-width: 768px) {
	.card--hub-wheel {
		min-height: 212px !important;
	}
	.card--hub-wheel .card__title {
		font-size: 42px;
	}
	.card--hub-wheel .card__body {
		width: 50%;
		left: 25%;
		padding: 32px !important;
	}
}

@media (min-width: 1080px) {
	.card--hub-wheel .card__footer {
		right: 0;
		left: auto;
		width: auto;
		top: 25%;
		position: absolute;
		padding-top: 32px;
	}
	.card--hub-wheel .card__title {
		font-size: 42px;
	}
	.card--hub-wheel .card__body {
		left: 32%;
	}
}

.is-bretagne .is-ligue .checkbox input:checked ~ label:after {
	background-color: #4cb848;
}

.is-bretagne .is-ligue .btn--primary {
	background-color: #4cb848;
}

.is-bretagne .is-ligue:hover,
.is-bretagne .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(76, 184, 72, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-bretagne .is-ligue:hover i,
.is-bretagne .is-ligue.active i {
	color: #4cb848;
}

.is-bretagne .news__cat.is-ligue {
	color: #4cb848;
}

.is-corse .is-ligue .checkbox input:checked ~ label:after {
	background-color: #00a3da;
}

.is-corse .is-ligue .btn--primary {
	background-color: #00a3da;
}

.is-corse .is-ligue:hover,
.is-corse .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(0, 163, 218, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-corse .is-ligue:hover i,
.is-corse .is-ligue.active i {
	color: #00a3da;
}

.is-corse .news__cat.is-ligue {
	color: #00a3da;
}

.is-auvergne .is-ligue .checkbox input:checked ~ label:after {
	background-color: #007943;
}

.is-auvergne .is-ligue .btn--primary {
	background-color: #007943;
}

.is-auvergne .is-ligue:hover,
.is-auvergne .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(0, 121, 67, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-auvergne .is-ligue:hover i,
.is-auvergne .is-ligue.active i {
	color: #007943;
}

.is-auvergne .news__cat.is-ligue {
	color: #007943;
}

.is-centre .is-ligue .checkbox input:checked ~ label:after {
	background-color: #00a3da;
}

.is-centre .is-ligue .btn--primary {
	background-color: #00a3da;
}

.is-centre .is-ligue:hover,
.is-centre .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(0, 163, 218, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-centre .is-ligue:hover i,
.is-centre .is-ligue.active i {
	color: #00a3da;
}

.is-centre .news__cat.is-ligue {
	color: #00a3da;
}

.is-corse .is-ligue .checkbox input:checked ~ label:after {
	background-color: #00a3da;
}

.is-corse .is-ligue .btn--primary {
	background-color: #00a3da;
}

.is-corse .is-ligue:hover,
.is-corse .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(0, 163, 218, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-corse .is-ligue:hover i,
.is-corse .is-ligue.active i {
	color: #00a3da;
}

.is-corse .news__cat.is-ligue {
	color: #00a3da;
}

.is-grandest .is-ligue .checkbox input:checked ~ label:after {
	background-color: #1b82ab;
}

.is-grandest .is-ligue .btn--primary {
	background-color: #1b82ab;
}

.is-grandest .is-ligue:hover,
.is-grandest .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(27, 130, 171, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-grandest .is-ligue:hover i,
.is-grandest .is-ligue.active i {
	color: #1b82ab;
}

.is-grandest .news__cat.is-ligue {
	color: #1b82ab;
}

.is-hdf .is-ligue .checkbox input:checked ~ label:after {
	background-color: #0664ba;
}

.is-hdf .is-ligue .btn--primary {
	background-color: #0664ba;
}

.is-hdf .is-ligue:hover,
.is-hdf .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(6, 100, 186, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-hdf .is-ligue:hover i,
.is-hdf .is-ligue.active i {
	color: #0664ba;
}

.is-hdf .news__cat.is-ligue {
	color: #0664ba;
}

.is-idf .is-ligue .checkbox input:checked ~ label:after {
	background-color: #e24301;
}

.is-idf .is-ligue .btn--primary {
	background-color: #e24301;
}

.is-idf .is-ligue:hover,
.is-idf .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(226, 67, 1, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-idf .is-ligue:hover i,
.is-idf .is-ligue.active i {
	color: #e24301;
}

.is-idf .news__cat.is-ligue {
	color: #e24301;
}

.is-normandie .is-ligue .checkbox input:checked ~ label:after {
	background-color: #ab2430;
}

.is-normandie .is-ligue .btn--primary {
	background-color: #ab2430;
}

.is-normandie .is-ligue:hover,
.is-normandie .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(171, 36, 48, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-normandie .is-ligue:hover i,
.is-normandie .is-ligue.active i {
	color: #ab2430;
}

.is-normandie .news__cat.is-ligue {
	color: #ab2430;
}

.is-nouvelleaquitaine .is-ligue .checkbox input:checked ~ label:after {
	background-color: #ac1e44;
}

.is-nouvelleaquitaine .is-ligue .btn--primary {
	background-color: #ac1e44;
}

.is-nouvelleaquitaine .is-ligue:hover,
.is-nouvelleaquitaine .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(172, 30, 68, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-nouvelleaquitaine .is-ligue:hover i,
.is-nouvelleaquitaine .is-ligue.active i {
	color: #ac1e44;
}

.is-nouvelleaquitaine .news__cat.is-ligue {
	color: #ac1e44;
}

.is-occitanie .is-ligue .checkbox input:checked ~ label:after {
	background-color: #da322a;
}

.is-occitanie .is-ligue .btn--primary {
	background-color: #da322a;
}

.is-occitanie .is-ligue:hover,
.is-occitanie .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(218, 50, 42, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-occitanie .is-ligue:hover i,
.is-occitanie .is-ligue.active i {
	color: #da322a;
}

.is-occitanie .news__cat.is-ligue {
	color: #da322a;
}

.is-paca .is-ligue .checkbox input:checked ~ label:after {
	background-color: #efa92c;
}

.is-paca .is-ligue .btn--primary {
	background-color: #efa92c;
}

.is-paca .is-ligue:hover,
.is-paca .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(239, 169, 44, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-paca .is-ligue:hover i,
.is-paca .is-ligue.active i {
	color: #efa92c;
}

.is-paca .news__cat.is-ligue {
	color: #efa92c;
}

.is-pdll .is-ligue .checkbox input:checked ~ label:after {
	background-color: #1c9c51;
}

.is-pdll .is-ligue .btn--primary {
	background-color: #1c9c51;
}

.is-pdll .is-ligue:hover,
.is-pdll .is-ligue.active {
	-webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	background: rgba(28, 156, 81, 0.08);
	text-decoration: none;
	color: #292929;
}
.is-pdll .is-ligue:hover i,
.is-pdll .is-ligue.active i {
	color: #1c9c51;
}

.is-pdll .news__cat.is-ligue {
	color: #1c9c51;
}
